import React, { FC, useEffect, useState } from 'react';
import { vars } from '../../../reactive';
import NewBookingModal from '../../Shared/Modals/NewBookingModal/NewBookingModal';
import TabGroup from '../../Shared/TabGroup';
import { CALENDAR_MODAL_TITLES } from '../types';
import BlockSlotModal from './BlockSlotModal';
import { TabGroupContainer } from './styled';

export enum SLOTS_TABS_TYPES {
  BOOK = 'New Service',
  BLOCK = 'Block a slot'
}

const SlotModal: FC<{ tab: SLOTS_TABS_TYPES; branchSlotsIds?: string[]; selectedId?: string }> = ({ tab, branchSlotsIds, selectedId }) => {
  const [activeTab, setActiveTab] = useState(tab || SLOTS_TABS_TYPES.BOOK);

  const tabs = {
    [SLOTS_TABS_TYPES.BOOK]: { component: <NewBookingModal selectedId={selectedId} />, title: CALENDAR_MODAL_TITLES.NEW_BOOKING },
    [SLOTS_TABS_TYPES.BLOCK]: { component: <BlockSlotModal branchSlotId={branchSlotsIds?.[0]} />, title: CALENDAR_MODAL_TITLES.BLOCK_SLOT }
  };

  useEffect(() => {
    vars.modalTitle(tabs[activeTab].title);
  }, [activeTab]);

  return (
    <>
      <div style={{ width: '80%' }}>
        <TabGroupContainer>
          <TabGroup tabs={Object.values(SLOTS_TABS_TYPES)} active={activeTab} setActive={setActiveTab} />
        </TabGroupContainer>
      </div>
      {tabs[activeTab].component}
    </>
  );
};

export default SlotModal;
