import { useLazyQuery } from '@apollo/client';
import React, { forwardRef, useEffect } from 'react';
import { DRAWER_IDS } from '../../components/DrawerBar/types';
import InfiniteList, { InfiniteListGroup } from '../../components/Shared/InfiniteList/InfiniteList';
import useBookingsListBulkActions from '../../hooks/useBookingsListBulkActions';
import useNavigateDrawer from '../../hooks/useNavigateDrawer';
import { UsePagintaedQueryResult } from '../../hooks/usePaginatedQuery';
import useReduceAppointments from '../../hooks/useReduceAppointments';
import useSetDrawerOnNavigation from '../../hooks/useSetDrawerOnNavigation';
import { GetBranchAppointmentTags } from '../../queries';
import { BranchAppointmentTag } from '../Store/BranchTags/types';
import BookingRecord from './BookingRecord_OLD';
import { BOOKING_RECORD_VIEW_SETTINGS, Booking, BookingOrder } from './types';

type BookingsListProps = {
  paginatedQueryResult: UsePagintaedQueryResult<Booking[]>;
  reduceAppointments?: boolean;
  group?: (bookingsList: BookingOrder[]) => InfiniteListGroup;
  reverseItems?: boolean;
  filterBookings?: (bookings: BookingOrder[]) => BookingOrder[];
  showTotal?: boolean;
  showPetTags?: boolean;
  activeViewSettings?: {
    record: (typeof BOOKING_RECORD_VIEW_SETTINGS)[number]['id'];
  };
};

export type BookingsBulkActionsRef = { clearBulkActions: () => void; hideBulkActions: () => void };

const BookingsList = forwardRef<BookingsBulkActionsRef, BookingsListProps>(
  (
    {
      paginatedQueryResult,
      reduceAppointments,
      group,
      reverseItems,
      filterBookings,
      showTotal,
      showPetTags,
      activeViewSettings = {
        record: BOOKING_RECORD_VIEW_SETTINGS[0].id
      }
    },
    ref
  ) => {
    const [[hasMoreItems, setHasMoreItems], queryResult] = paginatedQueryResult;

    const { data: { getBranchAppointments: bookings = [] } = {}, loading, fetchMore, refetch } = queryResult;

    const [getTags, { data: { getBranchAppointmentTags: allTags = [] } = {} }] = useLazyQuery<{
      getBranchAppointmentTags: BranchAppointmentTag[];
    }>(GetBranchAppointmentTags, {
      fetchPolicy: 'cache-and-network',
      variables: { offset: 0, limit: 1000 }
    });

    const quickTags = allTags?.filter(tag => tag?.quick_action) || [];
    const tagsVisibleOnItem = allTags?.filter(tag => tag?.visibility?.showOnTaggedItem) || [];

    const bookingsToRender = useReduceAppointments(bookings, {
      uniqueByOrderIdAndTimestamp: reduceAppointments,
      sortDesc: false
    });

    const { bulkSelect } = useBookingsListBulkActions({
      bookingsToRender,
      ref,
      refetch
    });

    useSetDrawerOnNavigation({
      itemList: bookings,
      drawerId: DRAWER_IDS.BOOKING_DRAWER,
      itemsToFilterFrom: bookingsToRender,
      deepFilter: true,
      fireOnce: false
    });

    useEffect(() => {
      if (showPetTags) {
        getTags();
      }
    }, [showPetTags]);

    const bookingsList = filterBookings ? filterBookings(bookingsToRender) : bookingsToRender;

    const navigateDrawer = useNavigateDrawer({
      drawerData: {
        drawerId: DRAWER_IDS.BOOKING_DRAWER,
        itemsToFilterFrom: bookingsToRender,
        deepFilter: true,
        otherData: {
          refetch
        }
      }
    });

    return (
      <InfiniteList
        list={bookingsList}
        itemRenderer={booking => (
          <BookingRecord
            key={booking[0].id}
            booking={booking}
            navigateDrawer={navigateDrawer}
            showTotal={showTotal}
            quickAppointmentsTags={quickTags}
            bulkSelect={bulkSelect}
            tagsVisibleOnItem={tagsVisibleOnItem}
            recordActiveViewSettings={activeViewSettings?.record}
          />
        )}
        hasMoreItems={hasMoreItems}
        loading={loading}
        group={group?.(bookingsList)}
        reversedItems={reverseItems}
        fetchMore={fetchMore as any}
        offset={bookings?.length}
        setHasMoreItems={setHasMoreItems}
      />
    );
  }
);

export default BookingsList;
