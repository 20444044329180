import React, { FC, Fragment } from 'react';
import { ExtensionDivider, ExtensionPetCaseLabel, ExtensionPetCasesRecord } from '../../../../components/Pets/styled';
import { toReadableDate } from '../../../../utils/dates';
import { report } from './GroomingHistory';

const GroomingReport: FC<{ groomingReport: report; onClick: React.MouseEventHandler<HTMLDivElement>; selected: boolean }> = ({ groomingReport, onClick, selected }) => {
  return (
    <Fragment>
      <ExtensionPetCasesRecord selected={selected} onClick={onClick}>
        <ExtensionPetCaseLabel fontSize={14} fontWeight={'700'} margingB={2}>
          {groomingReport?.name}
        </ExtensionPetCaseLabel>
        <ExtensionPetCaseLabel fontSize={12} fontWeight={'600'} margingB={2}>
          {toReadableDate(groomingReport?.updatedAt, { isLocale: true })}
        </ExtensionPetCaseLabel>
        <ExtensionPetCaseLabel fontSize={12} fontWeight={'500'} margingB={0}>
          {groomingReport?.Branch?.name}
        </ExtensionPetCaseLabel>
      </ExtensionPetCasesRecord>
      <ExtensionDivider />
    </Fragment>
  );
};

export default GroomingReport;
