import React from 'react';
import { sentenceCase } from 'sentence-case';
import ModalDialog from '../../../components/Modal/ModalDialog';
import { Divider } from '../../../components/Shared/SideTabs/styles';
import { RecordBody, RecordDataContainer } from '../../styled';
import { Discount, Voucher } from './types';
import VoucherModal from './VoucherModal';
import { toReadableDate } from '../../../utils/dates';

const VoucherRecord = ({ voucher, discount }: { voucher: Voucher; discount: Discount }) => {
  const handleClick = () => {
    ModalDialog.openModal({
      content: () => (
        <VoucherModal
          voucher={{
            ...voucher,
            appUsers: voucher.AppUsers.map(appUser => appUser.id),
            products: voucher.Products.map(product => product.id),
            discountId: discount.id
          }}
          discount={{
            ...discount,
            vouchers: []
          }}
        />
      ),
      title: 'Edit Voucher'
    });
  };

  return (
    <>
      <RecordDataContainer onClick={handleClick}>
        <RecordBody fontWeight={'800'}>{voucher?.code}</RecordBody>
        <RecordBody>{voucher?.AppUsers?.map(appUser => appUser?.name).join(', ') || 'All'}</RecordBody>
        <RecordBody>{sentenceCase(voucher?.status)}</RecordBody>
        <RecordBody>{voucher?.limit}</RecordBody>
        <RecordBody>Uses: {voucher.ordersCount || 0}</RecordBody>
        <RecordBody>{voucher.auto_apply ? 'Auto apply' : '-'}</RecordBody>
        <RecordBody>{voucher.createdAt ? toReadableDate(new Date(voucher.createdAt)) : '-'}</RecordBody>
        <RecordBody>{sentenceCase(voucher.status || 'ACTIVE')}</RecordBody>
      </RecordDataContainer>
      <Divider />
    </>
  );
};

export default VoucherRecord;
