import React, { forwardRef, useImperativeHandle, useEffect } from 'react';
import { AlignCenterOutlined, AlignLeftOutlined, AlignRightOutlined, BoldOutlined, FileImageOutlined, ItalicOutlined, LinkOutlined, UnderlineOutlined, UndoOutlined } from '@ant-design/icons';
import { Color } from '@tiptap/extension-color';
import Highlight from '@tiptap/extension-highlight';
import Image from '@tiptap/extension-image';
import Link from '@tiptap/extension-link';
import Placeholder from '@tiptap/extension-placeholder';
import Table from '@tiptap/extension-table';
import TableCell from '@tiptap/extension-table-cell';
import TableHeader from '@tiptap/extension-table-header';
import TableRow from '@tiptap/extension-table-row';
import TextAlign from '@tiptap/extension-text-align';
import TextStyle from '@tiptap/extension-text-style';
import Underline from '@tiptap/extension-underline';
import Youtube from '@tiptap/extension-youtube';
import { EditorContent, useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import { useSafeState, useUpdateEffect } from 'ahooks';
import { Button, ColorPicker as AntColorPicker, Divider, Input, Popconfirm, Tooltip, Upload } from 'antd';

import { H1Icon, H2Icon, H3Icon } from '../../images/SvgIcons';

import styled from 'styled-components';
import { getBase64, resizeImage } from '../../hooks/usePhotoUpload';

type MarkupEditorProps = {
  defaultValue?: string;
  onChange?: (data: { html: string; text: string }) => void;
};

export type MarkupEditorRef = {
  getHTML: () => string;
  getText: () => string;
  getValues: () => {
    html: string;
    text: string;
  };
  reset: () => void;
  setEditorText: (data: string) => void;
  focus: () => void;
};

type EditorToolbarProps = {
  editor: NonNullable<ReturnType<typeof useEditor>>;
};

const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-bottom: 1px solid #ccc;
  padding: 12px;
`;

const ButtonStyled = styled(Button)<{ active?: boolean }>`
  background-color: ${({ active }) => (active ? '#e0e0e0' : 'transparent')} !important;
  color: ${({ active }) => (active ? '#333' : '#999')} !important;
`;

const DividerStyled = styled(Divider)`
  margin: 0 8px;
  height: 100%;
`;

const ColorPickerStyled = styled(AntColorPicker)`
  box-shadow: none !important;
`;

const PopconfirmStyled = styled(Popconfirm)`
  display: inline-block;
`;

const UploadStyled = styled(Upload)`
  display: inline-block;
`;

const EditorToolbar = ({ editor }: EditorToolbarProps) => {
  const defaultHref = editor.getAttributes('link').href as string | undefined;
  // const defaultVideo = editor.getAttributes('youtube').src as string | undefined;
  const [href, setHref] = useSafeState(defaultHref);
  // const [video, setVideo] = useSafeState(defaultVideo);

  const defaultButtonProps: React.ComponentPropsWithoutRef<typeof Button> = {
    type: 'text',
    size: 'small',
    shape: 'circle'
  };

  useUpdateEffect(() => {
    setHref(defaultHref);
  }, [defaultHref]);

  const editorFocused = () => editor.chain().focus();
  const editorRunnable = () => editor.can();
  const editorChainRunnable = () => editorRunnable().chain().focus();

  return (
    <Flex>
      <Tooltip title="Heading 1">
        <ButtonStyled active={editor.isActive('heading', { level: 1 })} onClick={() => editorFocused().toggleHeading({ level: 1 }).run()} {...defaultButtonProps}>
          <H1Icon />
        </ButtonStyled>
      </Tooltip>

      <Tooltip title="Heading 2">
        <ButtonStyled active={editor.isActive('heading', { level: 2 })} onClick={() => editorFocused().toggleHeading({ level: 2 }).run()} {...defaultButtonProps}>
          <H2Icon />
        </ButtonStyled>
      </Tooltip>

      <Tooltip title="Heading 3">
        <ButtonStyled active={editor.isActive('heading', { level: 3 })} onClick={() => editorFocused().toggleHeading({ level: 3 }).run()} {...defaultButtonProps}>
          <H3Icon />
        </ButtonStyled>
      </Tooltip>

      <DividerStyled type="vertical" />

      <Tooltip title="Colors">
        <ColorPickerStyled size="small" defaultValue="var(--ant-color-text-base)" value={editor.getAttributes('textStyle').color} onChange={(_, hex) => editorFocused().setColor(hex).run()} />
      </Tooltip>

      <Tooltip title="Bold">
        <ButtonStyled active={editor.isActive('bold')} disabled={!editorChainRunnable().toggleBold().run()} onClick={() => editorFocused().toggleBold().run()} {...defaultButtonProps}>
          <BoldOutlined />
        </ButtonStyled>
      </Tooltip>

      <Tooltip title="Italic">
        <ButtonStyled active={editor.isActive('italic')} disabled={!editorChainRunnable().toggleItalic().run()} onClick={() => editorFocused().toggleItalic().run()} {...defaultButtonProps}>
          <ItalicOutlined />
        </ButtonStyled>
      </Tooltip>

      <Tooltip title="Underline">
        <ButtonStyled active={editor.isActive('underline')} disabled={!editorChainRunnable().toggleUnderline().run()} onClick={() => editorFocused().toggleUnderline().run()} {...defaultButtonProps}>
          <UnderlineOutlined />
        </ButtonStyled>
      </Tooltip>

      <DividerStyled type="vertical" />
      {/* 
      <Tooltip title="Table">
        <ButtonStyled active={editor.isActive('table')} onClick={() => editorFocused().insertTable({ rows: 3, cols: 3, withHeaderRow: true }).run()} {...defaultButtonProps}>
          <TableOutlined />
        </ButtonStyled>
      </Tooltip>

      <Tooltip title="Add Column">
        <ButtonStyled disabled={!editorRunnable().addColumnAfter()} onClick={() => editorFocused().addColumnAfter().run()} {...defaultButtonProps}>
          <TableColumnInsertIcon />
        </ButtonStyled>
      </Tooltip>

      <Tooltip title="Remove Column">
        <ButtonStyled disabled={!editorRunnable().deleteColumn()} onClick={() => editorFocused().deleteColumn().run()} {...defaultButtonProps}>
          <TableColumnRemoveIcon />
        </ButtonStyled>
      </Tooltip>

      <Tooltip title="Add Row">
        <ButtonStyled disabled={!editorRunnable().addRowAfter()} onClick={() => editorFocused().addRowAfter().run()} {...defaultButtonProps}>
          <TableRowInsertIcon />
        </ButtonStyled>
      </Tooltip>

      <Tooltip title="Remove Row">
        <ButtonStyled disabled={!editorRunnable().deleteRow()} onClick={() => editorFocused().deleteRow().run()} {...defaultButtonProps}>
          <TableRowRemoveIcon />
        </ButtonStyled>
      </Tooltip>

      <Tooltip title="Merge or Split Cells">
        <ButtonStyled disabled={!editorRunnable().mergeOrSplit()} onClick={() => editorFocused().mergeOrSplit().run()} {...defaultButtonProps}>
          <TableOutlined />
        </ButtonStyled>
      </Tooltip> */}

      <DividerStyled type="vertical" />

      {/* <PopconfirmStyled
        placement="bottom"
        icon={null}
        title={<Input placeholder="Video URL" value={video} onChange={({ target: { value } }) => setVideo(value)} />}
        showCancel={false}
        okText="Add"
        onConfirm={() => {
          if (video) {
            editor.commands.setYoutubeVideo({ src: video, width: 640, height: 480 });
          }
        }}
      >
        <Tooltip title="YouTube Video">
          <ButtonStyled {...defaultButtonProps}>
            <YoutubeOutlined />
          </ButtonStyled>
        </Tooltip>
      </PopconfirmStyled> */}

      <PopconfirmStyled
        placement="bottom"
        icon={null}
        title={<Input placeholder="Link URL" value={href} onChange={({ target: { value } }) => setHref(value)} />}
        showCancel={false}
        okText="Add"
        onConfirm={() => {
          if (href === '') {
            editorFocused().extendMarkRange('link').unsetLink().run();
          }
          if (href) {
            editorFocused().extendMarkRange('link').setLink({ href }).run();
          }
        }}
      >
        <Tooltip title="Hyperlink">
          <ButtonStyled {...defaultButtonProps}>
            <LinkOutlined />
          </ButtonStyled>
        </Tooltip>
      </PopconfirmStyled>

      {/* <UploadStyled
        showUploadList={false}
        accept='image/*'
        onChange={async ({ file: { originFileObj } }) => {
          if (originFileObj) {
            const src = await getBase64(originFileObj);
            const resizedSrc = await resizeImage(src, 380, 320);
            editorFocused().setImage({ src: resizedSrc }).run();
          }
        }}
      >
        <Tooltip title="Image">
          <ButtonStyled {...defaultButtonProps}>
            <FileImageOutlined />
          </ButtonStyled>
        </Tooltip>
      </UploadStyled> */}

      <DividerStyled type="vertical" />

      <Tooltip title="Align Right">
        <ButtonStyled active={editor.isActive({ textAlign: 'right' })} onClick={() => editorFocused().setTextAlign('right').run()} {...defaultButtonProps}>
          <AlignRightOutlined />
        </ButtonStyled>
      </Tooltip>

      <Tooltip title="Align Left">
        <ButtonStyled active={editor.isActive({ textAlign: 'left' })} onClick={() => editorFocused().setTextAlign('left').run()} {...defaultButtonProps}>
          <AlignLeftOutlined />
        </ButtonStyled>
      </Tooltip>

      <Tooltip title="Align Center">
        <ButtonStyled active={editor.isActive({ textAlign: 'center' })} onClick={() => editorFocused().setTextAlign('center').run()} {...defaultButtonProps}>
          <AlignCenterOutlined />
        </ButtonStyled>
      </Tooltip>

      <Tooltip title="Justify">
        <ButtonStyled active={editor.isActive({ textAlign: 'justify' })} onClick={() => editorFocused().setTextAlign('justify').run()} {...defaultButtonProps}>
          <AlignCenterOutlined />
        </ButtonStyled>
      </Tooltip>

      <DividerStyled type="vertical" />

      {/* <Tooltip title="Code Block">
        <ButtonStyled active={editor.isActive('codeBlock')} onClick={() => editorFocused().toggleCodeBlock().run()} {...defaultButtonProps}>
          <InlineCodeIcon />
        </ButtonStyled>
      </Tooltip> */}

      <Tooltip title="Undo">
        <ButtonStyled disabled={!editorChainRunnable().undo().run()} onClick={() => editorFocused().undo().run()} {...defaultButtonProps}>
          <UndoOutlined />
        </ButtonStyled>
      </Tooltip>

      {/* <Tooltip title="Redo">
        <ButtonStyled disabled={!editorChainRunnable().redo().run()} onClick={() => editorFocused().redo().run()} {...defaultButtonProps}>
          <RedoOutlined />
        </ButtonStyled>
      </Tooltip> */}
    </Flex>
  );
};

const MarkupEditor = forwardRef<MarkupEditorRef, MarkupEditorProps>(({ defaultValue, onChange }, ref) => {
  const opts = {
    extensions: [
      StarterKit.configure(),
      TextStyle.configure(),
      TextAlign.configure(),
      Color.configure(),
      Underline.configure(),
      Highlight.configure(),
      Table.configure(),
      TableRow.configure(),
      TableHeader.configure(),
      TableCell.configure(),
      Youtube.configure(),
      Link.configure(),
      Image.configure(),
      Placeholder.configure()
    ]
  };

  const editor = useEditor(opts, []);

  const reset = () => {
    setEditorText('');
  };

  const getHTML = () => {
    if (editor) {
      return editor.getHTML();
    }
    return '';
  };

  const getText = () => {
    if (editor) {
      return editor.getText();
    }
    return '';
  };

  const getValues = () => {
    const html = getHTML();
    const text = getText();
    console.log({ html, text, editor });
    return { html, text };
  };

  const setEditorText = (data: string) => {
    if (editor) {
      editor.commands.setContent(data);
    }
  };

  const focus = () => {
    if (editor) {
      editor.commands.focus();
    }
  };

  useEffect(() => {
    if (defaultValue) {
      setEditorText(defaultValue);
    }
  }, [defaultValue]);

  useImperativeHandle(ref, () => ({ getHTML, reset, setEditorText, getValues, getText, focus }));

  if (!editor) return null;

  return (
    <div
      style={{
        borderRadius: 4,
        border: '1px solid #ccc',
        width: 400,
        maxWidth: 400,
        padding: 8
      }}
    >
      <EditorToolbar editor={editor} />
      <EditorContent editor={editor} width={400} />
    </div>
  );
});

export default MarkupEditor;
