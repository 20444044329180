import { UseFormMethods } from 'react-hook-form';
import { TransformedCustomization } from '../../Customizations/types';
import { ProductOptionsTypes } from '../../ProductOptions';
import { CHARGE_STATUS, Product } from '../../types';
import { BOOKING_TYPE, SLOT_OFFSET_TYPE } from '../../../Bookings/types';

export type StoreProductModalForm = {
  name: string;
  status: { label: string; value: string } | null;
  category: { label: string; value: string } | null;
  cta_text: string;
  charge_status: CHARGE_STATUS;
  original_price: number;
  price_discount?: number;
  productOptions: ProductOptionsTypes[];
  customizations: TransformedCustomization[];
  booking_type: BOOKING_TYPE;
  branch_schedules: string[];
  duration: number;
  sessions: number;
  slotOffsetType: SLOT_OFFSET_TYPE;
  slots_start_date: string;
  max_slot_offset: number;
  additional_info: {
    payment_description: string;
    booking_order_close_message: string;
  };
  sort_index: number;
  short_description: string;
  description: string;
  max_bulk_orders: number;
  stock: string;
  unit: string;
  sku: string;
  weight: number;
  colors: {
    calendar: string;
  };
  slugs: {
    calendar: string;
    path: string;
  };
  pet_rules: {
    max_pet_count_type: 'no_limit' | 'limited';
    max_pet_count: number;
    allowed_pet_types: string[];
    excluded_pet_breeds: string[];
    pet_age_type: 'below' | 'above' | 'between' | 'none';
    pet_min_age: number;
    pet_max_age: number;
  };
  allow_booking_from: 'no_rule' | 'subscribed_to';
  product_subscription_dependencies: string[];
};

export type StoreProductModalSectionsCommonProps = {
  formOptions: UseFormMethods<StoreProductModalForm>;
  defaultValues?: Partial<StoreProductModalForm>;
  product?: Product;
  type: Product['type'];
};

export const petMaxNumberOptions = [
  { value: 'no_limit', label: 'No Limit' },
  { value: 'limited', label: 'Limited to' }
];

export const petTypesOptions = [
  { value: 'DOG', label: 'Dog' },
  { value: 'CAT', label: 'Cat' },
  { value: 'RABBIT', label: 'Rabbit' }
];

export const unitOptions = [
  { value: 'capsule', label: 'Capsule' },
  { value: 'chew', label: 'Chew' },
  { value: 'drops', label: 'Drops' },
  { value: 'g', label: 'Grams' },
  { value: 'graduation', label: 'Graduation' },
  { value: 'ml', label: 'ML' },
  { value: 'pipette', label: 'Pipette' },
  { value: 'pump', label: 'Pump' },
  { value: 'scoop', label: 'Scoop' },
  { value: 'spray', label: 'Spray' },
  { value: 'tablet', label: 'Tablet' },
  { value: 'tbs', label: 'TBS' }
];

export const allowBookingFromOptions = [
  { value: 'no_rule', label: 'No rule' },
  { value: 'subscribed_to', label: 'Pets Subscribed to' }
];

export const petAgeTypeOptions = [
  { value: 'none', label: 'Allow all ages' },
  { value: 'below', label: 'Below age' },
  { value: 'above', label: 'Above age' },
  { value: 'between', label: 'Between ages' }
];
