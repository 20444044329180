import React from 'react';
import ModalDialog from '../../../components/Modal/ModalDialog';
import Filters from '../../../components/Shared/Filters/Filters';
import { getFilterKeyOptions } from '../../../components/Shared/Filters/helpers';
import { FiltersControl } from '../../../components/Shared/Filters/useFilters';
import OptionDropdown from '../../../components/Shared/Menus/OptionDropdown/OptionDropdown';
import { OPTION_DROPDOWN_MENU_BUTTON_TYPES, OPTION_DROPDOWN_TYPES } from '../../../components/Shared/Menus/OptionDropdown/types';
import ToolBar from '../../../components/Shared/ToolBar/ToolBar';
import { areObjectsEqual } from '../../../utils/helpers';
import BranchScheduleModal from './BranchScheduleModal/BranchScheduleModal';
import { BRANCH_SCHEDULES_FILTER_TYPES, BRANCH_SCHEDULES_MORE_OPTIONS_TYPES } from './BranchSchedulesFilters';

const BranchSchedulesHeader = ({ filtersControl }: { filtersControl: FiltersControl<BRANCH_SCHEDULES_FILTER_TYPES, BRANCH_SCHEDULES_MORE_OPTIONS_TYPES> }) => {
  const { headerAction } = filtersControl;

  const { onChange } = getFilterKeyOptions({
    filtersControl,
    keys: {
      filterType: BRANCH_SCHEDULES_FILTER_TYPES.BRANCH_SCHEDULES_SEARCH,
      moreOption: BRANCH_SCHEDULES_MORE_OPTIONS_TYPES.BRANCH_SCHEDULES_SEARCH
    }
  });

  return (
    <>
      <ToolBar
        toolBarButtons={[]}
        urlSearchParam="product"
        middleAction={
          <>
            {headerAction}
            <OptionDropdown
              menuButtonType={OPTION_DROPDOWN_MENU_BUTTON_TYPES.PLUS}
              options={[
                {
                  id: 'add',
                  optionType: OPTION_DROPDOWN_TYPES.BUTTONS,
                  items: [
                    {
                      name: 'Add Schedule',
                      value: 'add',
                      onClick: () => {
                        ModalDialog.openModal({
                          content: () => <BranchScheduleModal />,
                          title: 'Add Schedule'
                        });
                      }
                    }
                  ]
                }
              ]}
              noApplyButton
            />
          </>
        }
        onSearchChange={onChange}
      />

      <Filters filtersControl={filtersControl} />
    </>
  );
};

export default React.memo(BranchSchedulesHeader, areObjectsEqual);
