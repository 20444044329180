import React, { useState } from 'react';
import useIcons from '../../hooks/useIcons';
import debounce from '../../utils/debounce';
import { CounterBtn, NavigationButtonsContainer } from './styled';

type CalendarNavigationProps = {
  date: Date;
  setUTCDate: (date: Date) => void;
  step: number;
  children?: (date: Date) => JSX.Element;
  hideControls?: boolean;
};

const CalendarNavigation = ({ setUTCDate, children, hideControls = false, date, step }: CalendarNavigationProps) => {
  const icons = useIcons();
  const moreIcon = icons.more.childImageSharp.gatsbyImageData.images.fallback.src;
  const lessIcon = icons.less.childImageSharp.gatsbyImageData.images.fallback.src;

  const [currentValue, setCurrentValue] = useState(date);

  const handleSetCurrentValue = (value: Date) => {
    setCurrentValue(value);

    debounce(() => setUTCDate(value), 300);
  };

  const increment = () => {
    const newDate = new Date(currentValue);
    newDate.setUTCDate(newDate.getUTCDate() + step);
    handleSetCurrentValue(newDate);
  };

  const decrement = () => {
    const newDate = new Date(currentValue);
    newDate.setUTCDate(newDate.getUTCDate() - step);
    handleSetCurrentValue(newDate);
  };

  return (
    <>
      {children && children(currentValue)}
      {!hideControls && (
        <NavigationButtonsContainer>
          <>
            <CounterBtn bgImg={lessIcon} onClick={decrement} style={{ width: '21px', height: ' 20px' }} />
            <CounterBtn bgImg={moreIcon} onClick={increment} style={{ width: '21px', height: ' 20px' }} />
          </>
        </NavigationButtonsContainer>
      )}
    </>
  );
};

export default CalendarNavigation;
