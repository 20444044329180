import React, { Fragment, useRef } from 'react';
import Colors from '../../Colors';
import useIcons from '../../hooks/useIcons';
import useMediaQuery from '../../hooks/useMediaQuery';
import { findMonthName, toReadableDate } from '../../utils/dates';
import useDynamicLinks from '../../utils/dynamicLinks/useDynamicLinks';

import { Booking, PetRecordTag, PetRecordTags, PetRecordTagsContainer } from '../../views/Bookings/components/BookingsBody/styled';
import { BranchAppUser } from '../../views/Store/BranchBilling/types';
import { BranchAppUserTag } from '../../views/Store/BranchTags/types';
import { AppUserImage, AppUserImageContainer, AppUserImageNameContainer, RecordBody, TagsTimeOptionalDropdownContainer } from '../../views/styled';
import { PetRecordOptionsContainer } from '../Pets/styled';
import { RedDot } from '../Shared/Shared';
import { Divider } from '../Shared/SideTabs/styles';
import ChatRecordOptions, { ChatRecordOptionsRef } from './ChatRecordOptions';
import { PetAndInfo, PetContainer, PetImg } from '../../views/Bookings/components/BookingRecord/styled';

export type Room = {
  id: string;
  name: string;
  createdAt: string;
  cover_photo: string;
  AppUsers: BranchAppUser[];
  BusUsers: {
    id: string;
    name: string;
    profile_pic: string;
    email: string;
  }[];
  MessageReadStatuses: {
    id: string;
    status: string;
    last_read_message: string;
    last_read_timestamp: string;
    Branch: {
      id: string;
    };
  }[];
  Messages: {
    id: string;
    body: string;
    photos: string[];
    location: string;
    video: string;
    timestamp: string;
    user_id: string;
    ChatRoomId: string;
    isSilent?: boolean;
  }[];
  last_message: Room['Messages'][0];
  read_status: {
    is_read: boolean;
    user_id: string;
  }[];
};
type Props = {
  room: Room;
  navigateDrawer: ({ drawerLinkId }: { drawerLinkId: string }) => void;
  isRoomSeen: (room: Room) => boolean;
  appUserQuickTags?: BranchAppUserTag[];
  profile: any;
  tagsVisibleOnItem?: BranchAppUserTag[];
  updateChatRooms: (newRooms: Room[]) => void;
};

const resolveMessage = (message: any) => {
  if (message?.body) {
    return message.body;
  }
  if (message?.photos?.length) {
    return 'Photo';
  }
  if (message?.location) {
    return 'Location';
  }
  if (message?.video) {
    return 'Video';
  }
  return '';
};

const ChatRecord = ({ room, navigateDrawer, isRoomSeen, appUserQuickTags, profile, tagsVisibleOnItem, updateChatRooms }: Props) => {
  const icons = useIcons();
  const users = room?.AppUsers || [];
  const time = toReadableDate(new Date(room?.last_message?.timestamp || room?.createdAt), { isLocale: true });

  const mobileTime = new Date(room?.last_message?.timestamp || room?.createdAt);

  const day = () => {
    return mobileTime.getDate() === new Date().getDate()
      ? 'Today,'
      : mobileTime.getDate() === new Date().getDate() - 1
      ? 'Yesterday,'
      : mobileTime.getDate() < 10
      ? `0${mobileTime.getDate()}`
      : mobileTime.getDate();
  };
  const month = () => {
    return mobileTime.getDate() !== new Date().getDate() && mobileTime.getDate() !== new Date().getDate() - 1 ? findMonthName(mobileTime.getMonth()) + ',' : '';
  };
  const hour = () => {
    return mobileTime.getHours() < 10 ? `0${mobileTime.getHours()}` : mobileTime.getHours();
  };
  const minutes = () => {
    return mobileTime.getMinutes() < 10 ? `0${mobileTime.getMinutes()}` : mobileTime.getMinutes();
  };
  const year = () => {
    let numberOfYears = 0;
    numberOfYears = new Date()?.getFullYear() - mobileTime?.getFullYear();
    return numberOfYears > 1 ? `${numberOfYears} years ago` : `A year ago`;
  };

  const timeToReturn = new Date().getFullYear() !== mobileTime.getFullYear() ? year() : `${day()} ${month()} ${hour()}:${minutes()}`;

  const { mobile } = useMediaQuery({ mobile: true });
  const chatRecordOptionsRef = useRef<ChatRecordOptionsRef>(null);
  const toggleMenu = () => {
    chatRecordOptionsRef?.current?.getOptionDropdownRefOptions?.()?.toggleMenu?.();
  };

  const handleClick = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    const isTapToTagElement = e.target instanceof HTMLElement && e.target.tagName.toLowerCase() === 'img' && mobile;

    if (isTapToTagElement) {
      toggleMenu();
    } else {
      if (chatRecordOptionsRef?.current?.getContainerRefOptions?.()?.contains?.(e.target as Node)) {
        return;
      }
      const read_status = (room?.read_status || [])?.filter(status => status?.user_id !== profile?.id);
      updateChatRooms([{ ...room, read_status: [...read_status, { is_read: true, user_id: profile?.id }] }]);
      navigateDrawer({ drawerLinkId: room?.id });
    }
  };

  const hasFilters = false;

  const tags = room?.AppUsers?.flatMap(user => user?.BranchAppUserTags) || [];
  const tagsToRender = tagsVisibleOnItem ? tags.filter(tag => tagsVisibleOnItem.some(visibleTag => visibleTag.id === tag.id)) : tags;

  const recordOptions = (
    <PetRecordOptionsContainer>
      <ChatRecordOptions
        ref={chatRecordOptionsRef}
        showAlways={mobile}
        loadTags={false}
        appUsers={users as BranchAppUser[]}
        appUserQuickTags={appUserQuickTags}
        profile={profile}
        chatRoom={room}
        right={mobile}
        updateChatRooms={updateChatRooms}
      />
    </PetRecordOptionsContainer>
  );

  const renderTags = () => {
    return (
      <>
        {!hasFilters && (
          <PetRecordTagsContainer flex="none">
            <PetRecordTags widthAuto reduceWidth="100px" mobileAutoWidth>
              {tagsToRender?.map((item, index) => (
                <PetRecordTag color={item.color} key={index}>
                  <span>{item.name}</span>
                </PetRecordTag>
              ))}
            </PetRecordTags>
          </PetRecordTagsContainer>
        )}
      </>
    );
  };
  const renderMobile = () => {
    return (
      <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
        <RecordBody mobileWidth="auto">
          <RecordBody mobileWidth="100%" color=" #212121" fontSize={14} mobileFontWeight={800} mobileMarginLeft="40px">
            {users.map(user => user.name).join(' & ') || 'No User'}
          </RecordBody>
          <RecordBody mobileWidth="auto" color="#959595" fontWeight="800">
            {timeToReturn}
          </RecordBody>
        </RecordBody>
        <RecordBody mobileDisplay="block" mobileOverflow="hidden" mobileMarginLeft="40px">
          {messageWithoutDynamicLinks}
        </RecordBody>
      </div>
    );
  };

  const lastMessage = resolveMessage(room?.last_message);

  const { messageWithoutDynamicLinks } = useDynamicLinks(lastMessage, { loadHandler: false });

  const isSeen = isRoomSeen(room);

  return (
    <>
      <Booking onClick={handleClick} gap={12} mobileBorderRadius="unset" mobileMinHeight="0" mobilePadding="14px 20px 16px 20px" MobileFlexDirection="row">
        {
          <AppUserImageNameContainer>
            {!isSeen && <RedDot color={isSeen ? 'transparent' : Colors.alert} mobileBottom="auto" mobileLeft="35px" mobileZIndex mobileTop="30px" />}
            {isSeen && !mobile && <div style={{ height: '16px', width: '16px' }} />}
            <AppUserImageContainer>
              <RecordBody style={{ position: 'absolute', left: '40px', height: 0 }}>{recordOptions}</RecordBody>
              {users.map((user, idx) => (
                <AppUserImage key={user.id} src={user?.profile_pic || icons.user.childImageSharp.gatsbyImageData.images.fallback.src} index={idx} mobileHeight={45} mobileWidth={45} />
              ))}

              {!users.length && <AppUserImage src={icons.user.childImageSharp.gatsbyImageData.images.fallback.src} />}
            </AppUserImageContainer>

            {!mobile && (
              <RecordBody width="120" color=" #212121" fontSize={14} fontWeight="800" noMargin>
                {users.map(user => user.name).join(' & ') || 'No User'}
              </RecordBody>
            )}
          </AppUserImageNameContainer>
        }
        {/* {mobile && (
          <PetContainer>
            {users.map((user, idx) => (
              <PetImg key={user.id} src={user?.profile_pic || icons.user.childImageSharp.gatsbyImageData.images.fallback.src} />
            ))}

            <PetAndInfo>{isNew && mobile && <RedDot />}</PetAndInfo>
          </PetContainer>
        )} */}
        {!mobile && <RecordBody minWidth={120}>{messageWithoutDynamicLinks}</RecordBody>}

        {/* <TagsTimeOptionalDropdownContainer> */}
        {!mobile && tags.length >= 1 && renderTags()}
        {!mobile && (
          <div style={{ display: 'flex', marginLeft: 'auto' }}>
            <RecordBody width="100" minWidth={100} maxWidth={150} color="#959595" fontWeight="800" flex="1" overflow="unset">
              {time}
            </RecordBody>
            {recordOptions}
          </div>
        )}

        {/* </TagsTimeOptionalDropdownContainer> */}
        {mobile && renderMobile()}
      </Booking>
      {!mobile && <Divider />}
    </>
  );
};

export default ChatRecord;
