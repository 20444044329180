import { useMutation, useQuery } from '@apollo/client';
import React, { FC, useCallback, useEffect, useRef } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { sentenceCase } from 'sentence-case';
import { DRAWER_IDS } from '../../../../components/DrawerBar/types';
import ModalDialog from '../../../../components/Modal/ModalDialog';
import { ModalBody, ModalFooter } from '../../../../components/Modal/styled';
import { FormButtonsContainer, FormError, FormInput, FormLabel, FormSelect, FormSubmitButton, InputsWrapper, SectionLabel, WideInputGroup } from '../../../../components/Shared/Forms/Forms';
import { Container } from '../../../../components/Shared/Shared';
import { ActivityIndicator } from '../../../../components/Shared/Spinner';
import useIcons from '../../../../hooks/useIcons';
import usePhotoUpload, { useDeletePhotos } from '../../../../hooks/usePhotoUpload';
import useReduceAppointments from '../../../../hooks/useReduceAppointments';
import { GetBranchAppointments, GetBranchPetProfile, GroomingReportAdd, GroomingReportEdit, NoteAdd, NoteEdit } from '../../../../queries';
import { vars } from '../../../../reactive';
import { toReadableDate } from '../../../../utils/dates';
import { BOOKING_STATUS_TYPES, BOOKING_TYPE } from '../../../Bookings/types';
import { ActionBtn } from '../../../Store/styled';
import NotesMarkupEditor from '../NotesMarkupEditor';
import BinaryUpload from '../BinaryUpload';

export enum NOTE_ACTION_TYPES {
  ADD = 'ADD',
  EDIT = 'EDIT'
}

const coatOptions = ['fairly_clean', 'dirty', 'tangled_matted_fur', 'heavily_matted_fur', 'severely_matted_fur_seek_medical_attention'];

const earsOptions = ['fairly_clean', 'dirty_and_waxy', 'seek_medical_attention'];

const teethOptions = ['fairly_clean', 'some_plaque_suggest_brushing_dental_chews', 'built_up_tartar_suggest_dental_leaning', 'seek_medical_attention'];

const nailsOptions = ['trimmed', 'not_trimmed'];

const coatOptionsGrooming = ['fairly_clean', 'dirty', 'tangled_matted_fur', 'heavily_matted_fur', 'severely_matted_fur_seek_medical_attention'];

const earsOptionsGrooming = ['fairly_clean', 'dirty_and_waxy', 'seek_medical_attention'];

const teethOptionsGrooming = ['fairly_clean', 'some_plaque_suggest_brushing_dental_chews', 'built_up_tartar_suggest_dental_leaning', 'seek_medical_attention'];

const nailsOptionsGrooming = ['trimmed', 'not_trimmed'];

const coatOptionsRecomemdnations = ['fairly_clean', 'dirty', 'tangled_matted_fur', 'heavily_matted_fur', 'severely_matted_fur_seek_medical_attention'];

const earsOptionsRecomemdnations = ['fairly_clean', 'dirty_and_waxy', 'seek_medical_attention'];

const teethOptionsRecomemdnations = ['fairly_clean', 'some_plaque_suggest_brushing_dental_chews', 'built_up_tartar_suggest_dental_leaning', 'seek_medical_attention'];

const nailsOptionsRecomemdnations = ['trimmed', 'not_trimmed'];

const brushingFrequencyOptions = ['1_to_2_per_week', '3_per_week', '4_to_5_per_week', 'daily', 'other'];

const petBehaviorBehaviorOptions = ['patient', 'friendly', 'vocal', 'anxious', 'reactive', 'other'];

const GroomingReportNoteModal: FC<{
  selectedReport?: Record<string, any>;
  selectedNote?: Record<string, any>;
  refetchNotes?: ReturnType<typeof useQuery>['refetch'];
  refetchReports?: ReturnType<typeof useQuery>['refetch'];
}> = ({ selectedReport, selectedNote, refetchNotes, refetchReports }) => {
  const [handleAddNote, { loading: loadingAddNote, error: errorNoteAdd }] = useMutation(NoteAdd);
  const [handleEditNote, { loading: loadingEditNote, error: errorNoteEdit }] = useMutation(NoteEdit);
  const noteIdRef = useRef<string>('');

  const drawerBars = vars.drawerBars();
  const drawer = drawerBars.find(drawerBar => drawerBar.drawerId === DRAWER_IDS.PETS_DRAWER);
  const petId = drawer?.recordData;
  const orderId = drawer?.otherData?.orderId;

  // get pet profile
  const { data: { getBranchPetProfile: petProfile = {} } = {}, loading: loadingPetProfile } = useQuery(GetBranchPetProfile, {
    variables: { id: petId }
  });

  // get past pet appointments

  const { data: { getBranchAppointments: appUserAppointments = [] } = {}, loading: loadingAppUserAppointments } = useQuery(GetBranchAppointments, {
    variables: {
      PetRecordId: [petProfile?.PetRecord?.id],
      status: [BOOKING_STATUS_TYPES.CONFIRMED, BOOKING_STATUS_TYPES.REQUESTED],
      limit: 50,
      offset: 0,
      booking_type: [BOOKING_TYPE.SLOT, BOOKING_TYPE.MULTI_SLOT, BOOKING_TYPE.MULTI_DAY],
      requisite_queries: [],
      alternative_queries: []
    }
  });

  const reducedAppointments = useReduceAppointments(appUserAppointments || []);
  const [handleAddGroomingReport, { loading: loadingAdd, error: errorAdd, data: { groomingReportAdd: addedReport = null } = {} }] = useMutation(GroomingReportAdd);

  const [handleEditGroomingReport, { loading: loadingEdit, error: errorEdit, data: { groomingReportEdit: editedReport = null } = {} }] = useMutation(GroomingReportEdit);

  const photosRef = useRef<Record<string, any>>({});
  const uploadPhotos = usePhotoUpload();
  const [_, { loading: loadingUploadPhotos, error: errorUploadPhotos }] = uploadPhotos;
  const deletePhotos = useDeletePhotos();

  const { control, handleSubmit, errors } = useForm();

  const loading = loadingAddNote || loadingEditNote || loadingUploadPhotos;
  const error = !!errorNoteAdd?.message || !!errorNoteEdit?.message || !!errorUploadPhotos;

  const updatedReport = addedReport || editedReport;

  const handleSaveNote = handleSubmit(async form => {
    if (selectedNote) {
      noteIdRef.current = selectedNote.id;
    }

    if (!selectedNote) {
      const { data: { noteAdd: addedNote = {} } = {} } = await handleAddNote({
        variables: {
          body: form,
          timestamp: new Date(),
          groomingReportId: updatedReport?.id
        }
      });
      noteIdRef.current = addedNote.id;
    }
    const photos = await photosRef.current?.getPhotos();
    const attachments = await photosRef.current?.getAttachments();

    await handleEditNote({
      variables: {
        id: noteIdRef.current,
        body: {
          ...form,
          photos: {
            primary: photos
          },
          attachments: {
            primary: attachments
          }
        },
        timestamp: new Date()
      }
    });

    ModalDialog.closeModal();
    refetchNotes?.();
  });

  const handleSaveReport = handleSubmit(async form => {
    const { appointmentId } = form;
    if (selectedReport) {
      await handleEditGroomingReport({ variables: { ...form, id: selectedReport?.id, appointmentId: appointmentId || null, description: '' } });
    } else {
      await handleAddGroomingReport({ variables: { ...form, petRecordId: petProfile.PetRecord.id, appointmentId: appointmentId || null, description: '' } });
    }

    refetchReports?.();
  });

  const handleSubmitReport = useCallback(() => {
    handleSaveReport();
  }, [handleSaveNote, handleSaveReport]);

  useEffect(() => {
    if (updatedReport?.id) {
      handleSaveNote();
    }
  }, [updatedReport]);

  const defaultAppointment = appUserAppointments.find(appointment => appointment.OrderItem.Order.id === orderId) || {};

  if (loadingPetProfile || loadingAppUserAppointments) {
    return <ActivityIndicator />;
  }

  return (
    <>
      <ModalBody>
        <Container width={500}>
          <WideInputGroup>
            <FormLabel>Name</FormLabel>
            <Controller as={<FormInput error={errors.name} />} control={control} name={'name'} defaultValue={selectedReport?.name || ''} rules={{ required: true }} rows={6} />
            {errors?.name && <FormError>{errors.name.message || 'name is required'}</FormError>}
          </WideInputGroup>
          <WideInputGroup>
            <FormLabel error={errors?.appointmentId}>Appointment</FormLabel>
            <Controller
              render={({ onChange, value }) => (
                <FormSelect
                  height={48}
                  fontSize={16}
                  error={errors?.appointmentId}
                  onChange={e => {
                    onChange(e.target.value);
                  }}
                  value={value || ''}
                >
                  <option value={''}>Not specified</option>
                  {reducedAppointments?.map((orders, index) => (
                    <option key={index} value={orders[0].id}>
                      {orders[0].OrderItem.item.name} - {toReadableDate(orders[0].timestamp || new Date())}
                    </option>
                  ))}
                </FormSelect>
              )}
              control={control}
              name={'appointmentId'}
              defaultValue={defaultAppointment?.id || selectedReport?.Appointment?.id || ''}
            />
          </WideInputGroup>

          <InputsWrapper>
            <SectionLabel>Notes</SectionLabel>
            <WideInputGroup>
              <NotesMarkupEditor control={control} defaultValue={selectedNote?.body?.notes?.general_notes} name={`notes.general_notes`} />
            </WideInputGroup>
          </InputsWrapper>

          <InputsWrapper>
            <SectionLabel> Condition on arrival </SectionLabel>
            <WideInputGroup>
              <FormLabel>Coat</FormLabel>

              <Controller as={<FormInput />} name={`condition_on_arrival.coat.details`} control={control} defaultValue={selectedNote?.body?.condition_on_arrival?.coat?.details || ''} />
            </WideInputGroup>

            <WideInputGroup>
              <FormLabel>Ears</FormLabel>

              <Controller as={<FormInput />} name={`condition_on_arrival.ears.details`} control={control} defaultValue={selectedNote?.body?.condition_on_arrival?.ears?.details || ''} />
            </WideInputGroup>

            <WideInputGroup>
              <FormLabel>Teeth</FormLabel>
              <Controller as={<FormInput />} name={`condition_on_arrival.teeth.details`} control={control} defaultValue={selectedNote?.body?.condition_on_arrival?.teeth?.details || ''} />
            </WideInputGroup>

            <WideInputGroup>
              <FormLabel>Nails</FormLabel>

              <Controller as={<FormInput />} name={`condition_on_arrival.nails.details`} control={control} defaultValue={selectedNote?.body?.condition_on_arrival?.nails?.details || ''} />
            </WideInputGroup>

            {/* <WideInputGroup>
              <FormLabel>Notes</FormLabel>
              <NotesMarkupEditor control={control} defaultValue={selectedNote?.body?.condition?.notes} name={`condition.notes`} />
            </WideInputGroup> */}
          </InputsWrapper>

          <InputsWrapper>
            <SectionLabel>Grooming Service</SectionLabel>

            <WideInputGroup>
              <FormLabel>Coat</FormLabel>

              <Controller as={<FormInput />} name={`grooming_service.coat.details`} control={control} defaultValue={selectedNote?.body?.grooming_service?.coat?.details || ''} />
            </WideInputGroup>

            <WideInputGroup>
              <FormLabel>Style/Blade</FormLabel>
              <Controller as={<FormInput />} name={`grooming_service.style.details`} control={control} defaultValue={selectedNote?.body?.grooming_service?.style?.details || ''} />
            </WideInputGroup>

            <WideInputGroup>
              <FormLabel>Ears</FormLabel>

              <Controller as={<FormInput />} name={`grooming_service.ears.details`} control={control} defaultValue={selectedNote?.body?.grooming_service?.ears?.details || ''} />
            </WideInputGroup>

            <WideInputGroup>
              <FormLabel>Teeth</FormLabel>

              <Controller as={<FormInput />} name={`grooming_service.teeth.details`} control={control} defaultValue={selectedNote?.body?.grooming_service?.teeth?.details || ''} />
            </WideInputGroup>

            <WideInputGroup>
              <FormLabel>Nails</FormLabel>

              <Controller as={<FormInput />} name={`grooming_service.nails.details`} control={control} defaultValue={selectedNote?.body?.grooming_service?.nails?.details || ''} />
            </WideInputGroup>

            <WideInputGroup>
              <FormLabel>Behavior</FormLabel>
              <Controller as={<FormInput />} name={`grooming_service.behavior.details`} control={control} defaultValue={selectedNote?.body?.grooming_service?.behavior?.details || ''} />
            </WideInputGroup>

            {/* <WideInputGroup>
              <FormLabel>Blade</FormLabel>
              <NotesMarkupEditor control={control} defaultValue={selectedNote?.body?.grooming?.blade} name={`grooming.blade`} />
            </WideInputGroup> */}
          </InputsWrapper>

          <InputsWrapper>
            <SectionLabel>Recomemdnations</SectionLabel>

            <WideInputGroup>
              <FormLabel>Coat</FormLabel>

              <Controller as={<FormInput />} name={`recomemdnations.coat.details`} control={control} defaultValue={selectedNote?.body?.recomemdnations?.coat?.details || ''} />
            </WideInputGroup>

            <WideInputGroup>
              <FormLabel>Ears</FormLabel>

              <Controller as={<FormInput />} name={`recomemdnations.ears.details`} control={control} defaultValue={selectedNote?.body?.recomemdnations?.ears?.details || ''} />
            </WideInputGroup>

            <WideInputGroup>
              <FormLabel>Teeth</FormLabel>

              <Controller as={<FormInput />} name={`recomemdnations.teeth.details`} control={control} defaultValue={selectedNote?.body?.recomemdnations?.teeth?.details || ''} />
            </WideInputGroup>

            <WideInputGroup>
              <FormLabel>Nails</FormLabel>

              <Controller as={<FormInput />} name={`recomemdnations.nails.details`} control={control} defaultValue={selectedNote?.body?.recomemdnations?.nails?.details || ''} />
            </WideInputGroup>
          </InputsWrapper>

          {/* <InputsWrapper>
            <SectionLabel>Brushing</SectionLabel>
            <WideInputGroup>
              <FormLabel>Frequency</FormLabel>
              <Controller
                render={({ onChange }) => (
                  <RadioBtnsGroup
                    options={brushingFrequencyOptions}
                    name={'brushing.frequency.status'}
                    defaultValue={selectedNote?.body?.brushing?.frequency.status || brushingFrequencyOptions[0]}
                    onChange={e => onChange(e.target.value)}
                    itemStyle={{ marginBottom: 4 }}
                  />
                )}
                control={control}
                name={'brushing.frequency.status'}
                defaultValue={selectedNote?.body?.brushing?.frequency.status || brushingFrequencyOptions[0]}
              />
            </WideInputGroup>

            <WideInputGroup>
              <FormLabel>Notes on Brushing</FormLabel>
              <NotesMarkupEditor control={control} defaultValue={selectedNote?.body?.brushing?.notes} name={`brushing.notes`} />
            </WideInputGroup>
          </InputsWrapper> */}

          {/* <InputsWrapper>
            <SectionLabel>Pet Behavior</SectionLabel>
            <WideInputGroup>
              <FormLabel>Behavior</FormLabel>
              <Controller
                render={({ onChange }) => (
                  <RadioBtnsGroup
                    options={petBehaviorBehaviorOptions}
                    name={'pet_behavior.behavior.status'}
                    defaultValue={selectedNote?.body?.pet_behavior?.behavior.status || petBehaviorBehaviorOptions[0]}
                    onChange={e => onChange(e.target.value)}
                    itemStyle={{ marginBottom: 4 }}
                  />
                )}
                control={control}
                name={'pet_behavior.behavior.status'}
                defaultValue={selectedNote?.body?.pet_behavior?.behavior.status || petBehaviorBehaviorOptions[0]}
              />
            </WideInputGroup>

            <WideInputGroup>
              <FormLabel>Other on Behavior</FormLabel>
              <NotesMarkupEditor control={control} defaultValue={selectedNote?.body?.pet_behavior?.notes} name={`pet_behavior.notes`} />
            </WideInputGroup>
          </InputsWrapper> */}

          <BinaryUpload
            uploadPhotos={uploadPhotos}
            deletePhotos={deletePhotos}
            ref={photosRef}
            getRoute={() => `reports/primary/${noteIdRef.current}`}
            defaultBinaries={{
              attachments: selectedNote?.body?.attachments?.primary || [],
              photos: selectedNote?.body?.photos?.primary || []
            }}
          />
        </Container>
      </ModalBody>
      <ModalFooter>
        <FormButtonsContainer>
          <FormSubmitButton error={error} loading={loading} onClick={handleSubmitReport}>
            Save note
          </FormSubmitButton>
        </FormButtonsContainer>
      </ModalFooter>
    </>
  );
};

const GroomingReportNoteAction: FC<{
  type: NOTE_ACTION_TYPES;
  note?: Record<string, any>;
  selectedReport: Record<string, any>;
  refetchNotes?: ReturnType<typeof useQuery>['refetch'];
  autoOpenModal?: boolean;
}> = ({ type, note, selectedReport, refetchNotes, autoOpenModal }) => {
  const icons = useIcons();
  const icon = type === NOTE_ACTION_TYPES.ADD ? icons.add.childImageSharp.gatsbyImageData.images.fallback.src : icons.moreIcon.childImageSharp.gatsbyImageData.images.fallback.src;

  const showModal = useCallback(
    () =>
      ModalDialog.openModal({
        content: () => <GroomingReportNoteModal selectedNote={note} selectedReport={selectedReport} refetchNotes={refetchNotes} />,
        title: `${sentenceCase(type)} note`
      }),
    [note, selectedReport, refetchNotes, type]
  );

  useEffect(() => {
    if (autoOpenModal) {
      showModal();
    }
  }, [autoOpenModal, showModal]);

  return <ActionBtn bgImage={icon} onClick={showModal} />;
};

export default GroomingReportNoteAction;
