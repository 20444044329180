import React, { ReactElement, RefAttributes } from 'react';
import ReactSelect, { GroupBase } from 'react-select';
import CreatableSelect, { CreatableProps } from 'react-select/creatable';
import AsyncSelect, { AsyncProps } from 'react-select/async';
import { selectTheme } from './Forms';
import { StateManagerProps } from 'react-select/dist/declarations/src/useStateManager';
import SelectType from 'react-select/dist/declarations/src/Select';
import Colors from '../../../Colors';

const Select: <Option = unknown, IsMulti extends boolean = false, Group extends GroupBase<Option> = GroupBase<Option>>(
  props: StateManagerProps<Option, IsMulti, Group> & RefAttributes<SelectType<Option, IsMulti, Group>>
) => ReactElement = props => {
  return (
    <ReactSelect
      theme={selectTheme}
      menuPosition="fixed"
      styles={{
        container: (provided, state) => ({
          ...provided,
          border: 'none',
          width: '100%'
        }),
        valueContainer: (provided, state) => ({
          ...provided,
          fontSize: 14,
          fontWeight: 600,
          border: 'none'
        }),
        option: (provided, state) => ({
          ...provided,
          fontSize: 14,
          color: state.isSelected ? Colors.white : Colors.black,
          backgroundColor: state.isSelected ? Colors.secondaryGrey : Colors.white,
          '&:hover': {
            backgroundColor: Colors.toolsBarBackgroundColor,
            color: Colors.black
          }
        })
      }}
      {...props}
    />
  );
};

export default Select;
