import { useQuery } from '@apollo/client';
import React, { useRef, useState } from 'react';
import { DRAWER_IDS } from '../../../../components/DrawerBar/types';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import usePaginatedQuery from '../../../../hooks/usePaginatedQuery';
import { GetBranchPetProfile, GetGroomingReportsByPetRecordId } from '../../../../queries';
import { vars } from '../../../../reactive';
import GroomingDetails from './GroomingDetails';
import GroomingHistory from './GroomingHistory';

const Grooming = ({ renderHeader }: { renderHeader: () => JSX.Element }) => {
  const groomingDetailsRef = useRef(null);
  const drawerBar = vars.drawerBars().find(drawer => drawer.drawerId === DRAWER_IDS.PETS_DRAWER);
  const petId = drawerBar?.recordData;
  const autoOpenModal = drawerBar?.otherData?.autoOpenModal;
  const preSelectedReprotId = drawerBar?.otherData?.selectedReportId;

  const { data: { getBranchPetProfile: petProfile = {} } = {} } = useQuery(GetBranchPetProfile, {
    variables: {
      id: petId
    }
  });

  const [[hasMoreItems, setHasMoreItems], { data: { getGroomingReportsByPetRecordId: groomingReports = [] } = {}, loading: loadingGroomingReports, fetchMore, refetch: refetchGroomingReports }] =
    usePaginatedQuery<any>({
      query: GetGroomingReportsByPetRecordId,
      limit: 6,
      otherVariables: { petRecordId: petProfile?.PetRecord?.id },
      otherParams: {
        skip: !petProfile?.id
      }
    });

  const { tablet } = useMediaQuery({ tablet: true });

  const [selectedReportId, setSelectedReportId] = useState<string | null>(preSelectedReprotId || !tablet ? groomingReports[0]?.id : null || null);

  const setSelectedReportIdAndScroll = (noteId: string) => {
    setSelectedReportId(noteId);
    groomingDetailsRef.current?.scrollIntoView({
      behavior: 'smooth'
    });
  };

  const selectedReport = groomingReports.find((note: any) => note.id === selectedReportId);

  return (
    <>
      <GroomingDetails
        innerRef={groomingDetailsRef}
        selectedReport={selectedReport}
        groomingReports={groomingReports}
        loading={loadingGroomingReports}
        deselectReport={() => setSelectedReportId(null)}
      />
      <GroomingHistory
        fetchMore={fetchMore}
        hasMoreItems={hasMoreItems}
        setHasMoreItems={setHasMoreItems}
        groomingReports={groomingReports || []}
        setSelectedReportId={setSelectedReportIdAndScroll}
        loading={loadingGroomingReports}
        selectedReportId={selectedReportId as string}
        refetchGroomingReports={refetchGroomingReports}
        autoOpenModal={autoOpenModal}
        onModalClose={drawerBar?.otherData?.onClose}
        renderHeader={renderHeader}
      />
    </>
  );
};

export default Grooming;
