import { useQuery } from '@apollo/client';
import React from 'react';
import InfiniteList from '../../../components/Shared/InfiniteList/InfiniteList';
import { GetAllBranchAppUsers, GetBranchAppUserTags } from '../../../queries';
import ClientRecord from './ClientRecord';
import { BranchAppUserTag } from '../../Store/BranchTags/types';

const PetClientsBody = () => {
  const { data: { getBranchAppUsers: branchAppUsers } = {}, loading: loadingAppUsers } = useQuery<{ getBranchAppUsers: AppUser }>(GetAllBranchAppUsers);

  const { data: { getBranchAppUserTags: allTags = [] } = {} } = useQuery<{
    getBranchAppUserTags: BranchAppUserTag[];
  }>(GetBranchAppUserTags, {
    fetchPolicy: 'cache-and-network',
    variables: { offset: 0, limit: 1000 }
  });

  const quickTags = allTags?.filter(tag => tag?.quick_action) || [];

  return (
    <InfiniteList
      mobilePadding="8px 16px 0 16px"
      paddingL={20}
      paddingR={20}
      hasMoreItems={false}
      setHasMoreItems={() => null}
      loading={loadingAppUsers}
      offset={0}
      fetchMore={() => null}
      list={branchAppUsers}
      itemRenderer={branchAppUsers => <ClientRecord branchAppUsers={branchAppUsers} quickTags={quickTags} />}
    />
  );
};

export default PetClientsBody;
