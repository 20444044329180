import styled from 'styled-components';
import Colors from '../../../../Colors';

export const DrawerContainer = styled.div<{ drawerWidths?: { normal: number; small: number } }>`
  display: flex;
  flex-direction: column;
  height: 100%;
  max-width: ${props => props.drawerWidths?.normal || 350}px;
  /* min-width: ${props => props.drawerWidths?.normal || 350}px; */

  background-color: ${Colors.white};
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.1);
  transition: right 0.3s ease-out;
  z-index: 1001;
`;

export const DrawerContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding: 24px 20px;
  overflow-y: scroll;
`;

export const DrawerSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;
export const SectionName = styled.span`
  align-self: stretch;
  color: ${Colors.drawerHeaders};
  font-size: 12px;
  font-weight: 600;
`;
export const SectionContent = styled.span`
  align-self: stretch;
  color: ${Colors.secondaryText};
  font-size: 14px;
  font-weight: 800;
`;

export const Slots = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

export const Slot = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const OwnerChatSection = styled.div`
  margin-top: auto;
`;
