import React, { FC, useMemo } from 'react';
import { Controller } from 'react-hook-form';
import { Divider } from '../../../../components/DrawerBar/styled';
import { FormError, FormInput, FormLabel, FormSelect, InputsWrapper, WideInputGroup } from '../../../../components/Shared/Forms/Forms';
import ProductsList from '../../../../components/Shared/Lists/ProductsList';
import { VerticallyCenteredLoader } from '../../../../components/Shared/Spinner';
import { toReadableDate } from '../../../../utils/dates';
import { getBranchCurrencySymbol } from '../../../../utils/getBranchCurrencySymbol';
import { AddNewContainer } from '../../../Store/styled';
import { NewTreatmentFormProps, frequencies } from './types';
import { getFrequenciesEndDateOptions } from './utils';

const NewTreatmentForm: FC<NewTreatmentFormProps> = ({ medicalCaseName, formOptions, products, isRecurring, intervalItems, currentProduct, suppRecord, defaultFormValues, loadingProducts }) => {
  const { errors, control, setValue, watch } = formOptions;

  const watchedValues = watch(['startDate', 'endDateFrequency', 'frequency']);

  const { startDate, endDateFrequency, frequency } = watchedValues || {};

  const endDateOptions = useMemo(() => getFrequenciesEndDateOptions(startDate).find(({ frequencyId }) => frequencyId === Number(frequency))?.options, [startDate, frequency]);

  const selectedEndDate = endDateOptions?.find(({ id }) => id === Number(endDateFrequency))?.date;

  return (
    <AddNewContainer>
      <InputsWrapper noWrap>
        <WideInputGroup>
          <FormLabel>Case Name</FormLabel>
          <FormInput disabled value={medicalCaseName} />
        </WideInputGroup>
      </InputsWrapper>
      <InputsWrapper noWrap>
        <WideInputGroup>
          <FormLabel>Medication Name</FormLabel>
          <ProductsList
            formOptions={formOptions}
            name={'product'}
            onChangeProducts={() => {
              setValue('size', null);
            }}
            defaultValues={currentProduct?.id ? [currentProduct?.id] : []}
          />
          {errors.product && <FormError>{errors.product.message || 'Medication Name is required'}</FormError>}
        </WideInputGroup>
        {!loadingProducts && (
          <>
            {!!currentProduct?.id && (
              <WideInputGroup flexDirection="row" gap={4}>
                <FormLabel>Price: {currentProduct?.id && `${getBranchCurrencySymbol()}${currentProduct?.price}`},</FormLabel>
                {!!currentProduct?.PharmaItemProducts?.length && <FormLabel>Pack Size: {currentProduct?.PharmaItemProducts?.[0]?.PharmaItem?.pack_size},</FormLabel>}
                <FormLabel>Stock: {currentProduct?.stock ?? 'Unlimited'}</FormLabel>
              </WideInputGroup>
            )}
            <WideInputGroup marginBottom={10}>
              {!!currentProduct?.id && <FormLabel>Medication Type {products?.find?.(({ id }) => id === currentProduct.id)?.ProductKinds?.[0]?.name}</FormLabel>}
            </WideInputGroup>
          </>
        )}
        {loadingProducts && <VerticallyCenteredLoader size={20} />}
      </InputsWrapper>
      <Divider marginBottom={24} marginTop={10} />
      <InputsWrapper noWrap>
        <WideInputGroup>
          <FormLabel>Dosage</FormLabel>
          <Controller
            as={<FormInput error={!!errors.quantity} type={'number'} />}
            type={'number'}
            control={control}
            name={'quantity'}
            defaultValue={suppRecord?.quantity || '1'}
            min={0.01}
            max={999}
            rules={{
              required: true,
              min: 0.01,
              max: 999
            }}
          />
          {errors.quantity && <FormError>{errors.quantity.message || 'Dosage is required'}</FormError>}
        </WideInputGroup>
        <WideInputGroup>
          <FormLabel>Frequency</FormLabel>
          <Controller
            render={({ onChange, value }) => (
              <FormSelect
                height={48}
                fontSize={16}
                value={value || ''}
                name={'frequency'}
                defaultValue={defaultFormValues?.frequency || ''}
                onChange={e => {
                  onChange(e.target.value);
                  setValue('endDateFrequency', '');
                  setValue('reccurence', null);
                }}
              >
                <option disabled value={''} selected>
                  -- select an option --
                </option>
                {frequencies.map(({ id, name }) => (
                  <option key={id} value={id}>
                    {name}
                  </option>
                ))}
              </FormSelect>
            )}
            control={control}
            name={'frequency'}
            defaultValue={defaultFormValues?.frequency || ''}
            rules={{ required: true }}
          />
          {errors.frequency && <FormError>{errors.frequency.message || 'frequency is required'}</FormError>}
        </WideInputGroup>
        <WideInputGroup>
          <FormLabel>Count</FormLabel>
          <Controller
            render={({ onChange, value }) => (
              <FormSelect
                height={48}
                fontSize={16}
                value={value || ''}
                name={'count'}
                defaultValue={defaultFormValues?.count || ''}
                onChange={e => {
                  onChange(e.target.value);
                }}
              >
                <option value={1}>{'Once'}</option>
                <option value={2}>{'Twice'}</option>
                <option value={3}>{'Three times'}</option>
                <option value={4}>{'Four times'}</option>
              </FormSelect>
            )}
            control={control}
            name={'count'}
            defaultValue={defaultFormValues?.count || ''}
            rules={{ required: true }}
          />
          {errors.count && <FormError>{errors.count.message || 'count is required'}</FormError>}
        </WideInputGroup>
        {isRecurring && (
          <WideInputGroup>
            <FormLabel>Reccurence</FormLabel>
            <Controller
              render={({ onChange, value }) => (
                <FormSelect
                  height={48}
                  value={value || ''}
                  fontSize={16}
                  defaultValue={defaultFormValues?.reccurence || ''}
                  onChange={e => {
                    onChange(e.target.value);
                  }}
                >
                  <option disabled value={''} selected>
                    -- select an option --
                  </option>
                  {intervalItems?.map(item => (
                    <option key={item.id} value={item.id}>
                      {item.name}
                    </option>
                  ))}
                </FormSelect>
              )}
              control={control}
              name={'reccurence'}
              defaultValue={defaultFormValues?.reccurence || ''}
              rules={{ required: true }}
            />
            {errors.reccurence && <FormError>{errors.reccurence.message || 'reccurence is required'}</FormError>}
          </WideInputGroup>
        )}
        <WideInputGroup>
          <FormLabel>{isRecurring ? 'Start date' : 'Administration date'}</FormLabel>
          <Controller
            render={({ onChange, value }) => (
              <FormInput
                error={!!errors.startDate}
                type={'date'}
                onChange={e => {
                  onChange(e.target.value);
                  setValue('endDateFrequency', '');
                }}
                value={value || ''}
              />
            )}
            control={control}
            name={'startDate'}
            defaultValue={defaultFormValues?.startDate || new Date().toISOString().slice(0, 10)}
            rules={{
              required: true,
              validate: val => !!new Date(val).getTime()
            }}
          />
          {errors.startDate && <FormError>{errors.startDate.message || 'Start date is invalid'}</FormError>}
        </WideInputGroup>
        {isRecurring && (
          <>
            <WideInputGroup>
              <FormLabel>End date</FormLabel>
              <Controller
                render={({ onChange, value }) => (
                  <FormSelect
                    height={48}
                    fontSize={16}
                    value={value || ''}
                    name={'endDateFrequency'}
                    defaultValue={defaultFormValues?.endDateFrequency || ''}
                    onChange={e => {
                      onChange(e.target.value);
                    }}
                  >
                    <option value={''}>No end date</option>
                    {endDateOptions?.map(({ id, name }) => (
                      <option key={id} value={id}>
                        {name}
                      </option>
                    ))}
                  </FormSelect>
                )}
                control={control}
                name={'endDateFrequency'}
                defaultValue={defaultFormValues?.endDateFrequency || ''}
                rules={{ required: false }}
              />
              {errors.endDateFrequency && <FormError>{errors.endDateFrequency.message || 'End date is required'}</FormError>}
            </WideInputGroup>
            {endDateFrequency && selectedEndDate && (
              <WideInputGroup>
                <FormLabel>Ends on</FormLabel>
                {toReadableDate(selectedEndDate, { noTime: true, withDay: true, isLocale: true })}
              </WideInputGroup>
            )}
          </>
        )}
      </InputsWrapper>
    </AddNewContainer>
  );
};

export default React.memo(NewTreatmentForm);
