import React, { useEffect } from 'react';
import { SelectedFilter } from '../../../components/Shared/Filters/config';
import InfiniteList from '../../../components/Shared/InfiniteList/InfiniteList';
import usePaginatedQuery from '../../../hooks/usePaginatedQuery';
import { GetBranchSchedules } from '../../../queries';
import Common from '../Common';
import BranchScheduleRecord from './BranchScheduleRecord';
import { getBranchSchedulesFiltersVariables } from './BranchSchedulesFilters';
import { BranchSchedule } from './types';

const BranchSchedulesBody = ({ selectedFilters }: { selectedFilters: SelectedFilter[] }) => {
  const [[hasMoreItems, setHasMoreItems], queryResult] = usePaginatedQuery<BranchSchedule[]>({
    query: GetBranchSchedules,
    otherVariables: {
      ...getBranchSchedulesFiltersVariables({
        selectedFilters
      })
    }
  });

  const { data: { getBranchSchedules: branchSchedules = [] } = {}, loading, fetchMore, refetch } = queryResult;

  useEffect(() => {
    Common.set(`BranchSchedules.GetBranchSchedules.refetch`, () => {
      refetch?.().then(() => setHasMoreItems(true));
    });
  }, []);

  return (
    <InfiniteList
      paddingL={20}
      paddingR={20}
      list={branchSchedules}
      itemRenderer={branchSchedule => <BranchScheduleRecord key={branchSchedule?.id} branchSchedule={branchSchedule} />}
      hasMoreItems={hasMoreItems}
      loading={loading}
      fetchMore={fetchMore}
      offset={branchSchedules?.length}
      setHasMoreItems={setHasMoreItems}
    />
  );
};

export default BranchSchedulesBody;
