import React from 'react';
import StoreSubscriptionsHeader from './StoreSubscriptionsHeader';
import StoreSubscriptionsBody from './StoreSubscriptionsBody';
import { useStoreSubscriptionsFilters } from './StoreSubscriptionsFilters';

const StoreSubscriptions = () => {
  const filtersControl = useStoreSubscriptionsFilters();

  const { selectedFilters } = filtersControl;

  return (
    <>
      <StoreSubscriptionsHeader filtersControl={filtersControl} />
      <StoreSubscriptionsBody selectedFilters={selectedFilters} />
    </>
  );
};

export default StoreSubscriptions;
