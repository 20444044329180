import { useLazyQuery, useMutation, useQuery } from '@apollo/client';
import React, { FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ReactSelect from '../../../../components/Shared/Forms/Select';
import ModalDialog from '../../../../components/Modal/ModalDialog';
import { ModalBody, ModalFooter } from '../../../../components/Modal/styled';
import { BusUserProfile } from '../../../../components/Profile/types';
import { FormButtonsContainer, FormError, FormInput, FormLabel, FormSubmitButton, InputGroup, InputsWrapper, WideInputGroup, selectTheme } from '../../../../components/Shared/Forms/Forms';
import AppUsersList from '../../../../components/Shared/Lists/AppUsersList';
import { Container } from '../../../../components/Shared/Shared';
import { GetAllServices, GetBusUserProfile, GetUpdateOrderBillsBilledOnDateSummary, UpdateOrderBillsBilledOnDate } from '../../../../queries';
import { getEndOfDate } from '../../../../utils/dates';
import debounce from '../../../../utils/debounce';
import { Divider } from '../../../Pets/Health/styled';
import { Product } from '../../../Store/types';

type AdjustAppUserOrderBillsBilledOnFormValues = {
  selectedAppUsers: string[];
  selectedProducts: string[];
  timestamp_from: string;
  timestamp_to: string;
  billed_on: string;
  reason: string;
};

type AdjustAppUserOrderBillsBilledOnModalProps = {
  initialValues?: Partial<AdjustAppUserOrderBillsBilledOnFormValues>;
};

const getVariables = (form: Partial<AdjustAppUserOrderBillsBilledOnFormValues>) => {
  const { selectedAppUsers, selectedProducts, billed_on, timestamp_from, timestamp_to, reason } = form;

  const variables = {
    AppUserId: selectedAppUsers,
    ProductId: selectedProducts,
    billed_on: new Date(billed_on!).toISOString(),
    timestamp_from: timestamp_from ? new Date(timestamp_from).toISOString() : null,
    timestamp_to: timestamp_to ? getEndOfDate(timestamp_to).toISOString() : null,
    reason
  };

  return variables;
};

const validateForm = (values: Partial<AdjustAppUserOrderBillsBilledOnFormValues>) => {
  const { selectedAppUsers, selectedProducts, timestamp_from, timestamp_to, billed_on } = values;
  return !!selectedAppUsers?.length && !!selectedProducts?.length && !!timestamp_from && !!timestamp_to && !!billed_on;
};

const AdjustAppUserOrderBillsBilledOnModal: FC<AdjustAppUserOrderBillsBilledOnModalProps> = ({ initialValues }) => {
  const defaultValues: AdjustAppUserOrderBillsBilledOnFormValues = {
    selectedAppUsers: initialValues?.selectedAppUsers || [],
    selectedProducts: initialValues?.selectedProducts || [],
    billed_on: initialValues?.billed_on || '',
    reason: initialValues?.reason || '',
    timestamp_from: initialValues?.timestamp_from || '',
    timestamp_to: initialValues?.timestamp_to || ''
  };

  const { data: { getBusUserProfile: userProfile } = {} } = useQuery<{ getBusUserProfile: BusUserProfile }>(GetBusUserProfile, {
    fetchPolicy: 'cache-and-network'
  });

  const allBranchBillingDates = userProfile?.Branch?.allBranchBillingDates || [];

  const currentBranchCycle = userProfile?.Branch?.branchBillingCycle || allBranchBillingDates[0] || {};

  const formOptions = useForm<AdjustAppUserOrderBillsBilledOnFormValues>({ defaultValues });

  const [updateOrderBillsBilledOnDate, { data: dataSubmit, loading: loadingSubmit, error: errorSubmit }] = useMutation(UpdateOrderBillsBilledOnDate);

  const [getUpdateOrderBillsBilledOnDateSummary, { data }] = useLazyQuery<{
    getUpdateOrderBillsBilledOnDateSummary?: { orderBillsToUpdate: number; appUsersToUpdate: number };
  }>(GetUpdateOrderBillsBilledOnDateSummary, {
    fetchPolicy: 'network-only'
  });

  const summary = data?.getUpdateOrderBillsBilledOnDateSummary;

  const { control, handleSubmit, errors, watch } = formOptions;

  const { data: { getProducts: products = [] } = {} } = useQuery<{ getProducts: Product[] }>(GetAllServices, {
    fetchPolicy: 'cache-and-network'
  });

  const watchedValues = watch(['selectedProducts', 'selectedAppUsers', 'timestamp_from', 'timestamp_to', 'billed_on']);

  const onSubmit = handleSubmit(form => {
    if (!validateForm(form)) {
      return;
    }

    const variables = getVariables(form);

    updateOrderBillsBilledOnDate({ variables });
  });

  useEffect(() => {
    if (dataSubmit?.updateOrderBillsBilledOnDate) {
      ModalDialog.closeModal();
    }
  }, [dataSubmit?.updateOrderBillsBilledOnDate]);

  useEffect(() => {
    debounce(() => {
      if (!validateForm(watchedValues)) {
        return;
      }
      const variables = getVariables(watchedValues);
      getUpdateOrderBillsBilledOnDateSummary({ variables });
    }, 500);
  }, [watchedValues?.selectedAppUsers, watchedValues?.selectedProducts, watchedValues?.timestamp_from, watchedValues?.timestamp_to, watchedValues?.billed_on]);

  const timestampFromMin = new Date(currentBranchCycle?.billing_date_from || new Date()).toISOString().split('T')[0];
  const timestampToMin = watchedValues?.timestamp_from || timestampFromMin;

  return (
    <>
      <ModalBody>
        <Container width={600}>
          <InputsWrapper>
            <WideInputGroup>
              <FormLabel>Clients</FormLabel>
              <AppUsersList isMulti formOptions={formOptions} name="selectedAppUsers" defaultValues={defaultValues?.selectedAppUsers} />
            </WideInputGroup>
            <Divider />
            <WideInputGroup marginTop={20}>
              <FormLabel>Services</FormLabel>
              <Controller
                render={({ onChange, value }) => (
                  <ReactSelect
                    isMulti
                    theme={selectTheme}
                    name={'selectedProducts'}
                    onChange={newValue => {
                      if (!newValue?.length) {
                        onChange(undefined);
                        return;
                      }
                      onChange(newValue?.map(item => item?.id));
                    }}
                    value={products?.filter(product => value?.includes(product?.id))}
                    options={products}
                    getOptionLabel={option => option?.name}
                    getOptionValue={option => option?.id}
                  />
                )}
                control={control}
                name={'selectedProducts'}
                defaultValue={[]}
                rules={{ required: false }}
              />
            </WideInputGroup>
            <Divider />
            <WideInputGroup marginTop={20} flexDirection="row" gap={10}>
              <InputGroup>
                <FormLabel>From</FormLabel>
                <Controller as={<FormInput type={'date'} min={timestampFromMin} />} control={control} name={'timestamp_from'} defaultValue={''} rules={{ required: true, min: timestampFromMin }} />
                {errors.timestamp_from && <FormError>{errors.timestamp_from.message || 'From is required'}</FormError>}
              </InputGroup>
              <InputGroup>
                <FormLabel>To</FormLabel>
                <Controller
                  as={<FormInput type={'date'} min={timestampToMin} disabled={!watchedValues?.timestamp_from} />}
                  control={control}
                  name={'timestamp_to'}
                  defaultValue={''}
                  rules={{ required: true, min: timestampToMin, validate: value => watchedValues?.timestamp_from && value >= watchedValues?.timestamp_from }}
                />
                {errors.timestamp_to && <FormError>{errors.timestamp_to.message || 'To is required'}</FormError>}
              </InputGroup>
            </WideInputGroup>{' '}
            <WideInputGroup>
              <FormLabel>Billed On</FormLabel>
              <Controller as={<FormInput type={'date'} />} control={control} name={'billed_on'} defaultValue={''} rules={{ required: true }} />
              {errors.billed_on && <FormError>{errors.billed_on.message || 'Billed On is required'}</FormError>}
            </WideInputGroup>
            <WideInputGroup>
              <FormLabel>Notes</FormLabel>
              <Controller
                as={<FormInput type={'text'} />}
                control={control}
                name={'reason'}
                defaultValue={''}
                rules={{
                  required: true
                }}
              />
              {errors.reason && <FormError>{errors.reason.message || 'Reason is required'}</FormError>}
            </WideInputGroup>
          </InputsWrapper>
          {summary && (
            <WideInputGroup>
              <FormLabel>Orders to update: {summary?.orderBillsToUpdate}</FormLabel>
              <FormLabel>Clients to update: {summary?.appUsersToUpdate}</FormLabel>
            </WideInputGroup>
          )}
        </Container>
      </ModalBody>
      <ModalFooter>
        <FormButtonsContainer>
          <FormSubmitButton error={!!errorSubmit} loading={loadingSubmit} onClick={() => onSubmit()}>
            Confirm Adjustment
          </FormSubmitButton>
        </FormButtonsContainer>
      </ModalFooter>
    </>
  );
};

export default AdjustAppUserOrderBillsBilledOnModal;
