import { navigate } from 'gatsby';
import { vars } from '.';
import { DRAWER_IDS } from '../components/DrawerBar/types';
import {
  BOOKINGS_FILTER_TYPES,
  PETS_FILTER_TYPES,
  PRODUCTS_FILTER_TYPES,
  SUBSCRIPTIONS_FILTER_TYPES,
  SelectedBookingsFilter,
  SelectedPetsFilter,
  SelectedProductsFilter,
  SelectedSubscriptionsFilter,
  HIDDEN_FILTERS,
  CHATS_FILTER_TYPES,
  SelectedChatFilter,
  SelectedFilter
} from '../components/Shared/Filters/config';
import { DrawerBar, ProductId } from './main';

export const setDrawerBar = (newDrawer: DrawerBar) => {
  const newDrawerBar = {
    recordData: {},
    isExtended: false,
    isPinned: false,
    ...newDrawer
  };
  const uniquePinnedDrawerBarIds = [
    ...new Set([
      ...vars
        .drawerBars()
        .filter(drawer => drawer.isPinned)
        .map(drawer => drawer.drawerId),
      newDrawerBar.drawerId
    ])
  ];
  const newDrawerBars = uniquePinnedDrawerBarIds.map(id => (newDrawerBar.drawerId === id ? newDrawerBar : vars.drawerBars().find(drawer => drawer.drawerId === id))) as DrawerBar[];

  vars.drawerBars(newDrawerBars);
};

export const addDrawerBar = (newDrawer: DrawerBar) => {
  const newDrawerBar = {
    recordData: {},
    isExtended: false,
    isPinned: false,
    ...newDrawer
  };

  const newDrawerBars = [...vars.drawerBars().filter(drawer => drawer.drawerId !== newDrawerBar.drawerId), newDrawerBar];

  vars.drawerBars(newDrawerBars);
};

export const unsetDrawerBars = () => {
  vars.drawerBars(vars.drawerBars().filter(drawer => drawer.isPinned)).map(drawer => ({ ...drawer, isExtended: false }));
};

export const clearDrawerBars = () => {
  vars.drawerBars([]);
  // navigate(`${location.pathname?.split('/').slice(0, 4).join('/')}`);
};

export const unsetDrawerBar = (drawerId: DRAWER_IDS) => {
  vars.drawerBars(vars.drawerBars().filter(drawer => drawer.drawerId !== drawerId));
};

export const togglePinDrawerBar = (drawerId: DRAWER_IDS) => {
  const newDrawersState = vars
    .drawerBars()
    .map(drawer => {
      if (drawer.drawerId === drawerId) {
        return { ...drawer, isPinned: !drawer.isPinned };
      }
      return drawer;
    })
    .filter(drawer => drawer.drawerId === drawerId);
  vars.drawerBars(newDrawersState);
};

export const toggleExtendDrawerBar = (drawerId: DRAWER_IDS) => {
  const newDrawersState = vars
    .drawerBars()
    .map(drawer => {
      if (drawer.drawerId === drawerId) {
        return { ...drawer, isExtended: !drawer.isExtended };
      }
      return { ...drawer, isPinned: false };
    })
    .filter(drawer => drawer.drawerId === drawerId);
  vars.drawerBars(newDrawersState);
};

export const setProductId = (productId: ProductId) => {
  vars.productId(productId);
};

export const showCalendarActionMessage = () => {
  vars.calendarMessage(true);
};

export const hideCalendarActionMessage = () => {
  vars.calendarMessage(false);
};

export const manageFilters = (filterType: 'pets' | 'bookings' | 'products' | 'subscriptions' | 'chats') => {
  const reactiveVar = (() => {
    switch (filterType) {
      case 'pets':
        return vars.selectedPetsFilters;
      case 'bookings':
        return vars.selectedBookingsFilters;
      case 'products':
        return vars.selectedProductsFilters;
      case 'subscriptions':
        return vars.selectedSubscriptionsFilters;
      case 'chats':
        return vars.selectedChatsFilters;
      default:
        return vars.selectedPetsFilters;
    }
  })();

  const currentActions = (() => {
    switch (filterType) {
      case 'pets':
        return vars.petsHeaderActions;
      case 'bookings':
        return vars.bookingsHeaderActions;
      case 'products':
        return vars.productsHeaderActions;
      case 'subscriptions':
        return vars.subscriptionsHeaderActions;
      case 'chats':
        return vars.chatsHeaderActions;
      default:
        return vars.petsHeaderActions;
    }
  })();

  const removeFilter = (filterValue: SelectedFilter['value']) => {
    const currentFilters = reactiveVar();
    const newFilters = currentFilters.filter(item => item.value !== filterValue);
    reactiveVar(newFilters);
  };

  const addFilter = (filter: SelectedFilter) => {
    const currentFilters = reactiveVar();
    const newFilters = [...currentFilters.filter(item => item.value !== filter.value), filter];
    reactiveVar(newFilters);
  };

  const setNewVisibleFilters = (filters: SelectedFilter[]) => {
    const existingHiddenFilters = reactiveVar().filter(filter => HIDDEN_FILTERS[filter.value]);
    const existingVisibleFilters = reactiveVar().filter(filter => !HIDDEN_FILTERS[filter.value]);

    if (!filters.length && !existingVisibleFilters.length && existingHiddenFilters.length) {
      return;
    }

    const newFilters = [...filters, ...existingHiddenFilters];

    reactiveVar(newFilters);
  };

  const clearFilter = (filterType: SelectedFilter) => {
    const newFilters = reactiveVar().filter(filter => filter.value !== filterType);
    reactiveVar(newFilters);
  };

  const addHeaderAction = ({ id, action }: { id: string; action: JSX.Element }) => {
    const newActions = currentActions().find(item => item.id === id) ? currentActions() : [...currentActions(), { id, action }];
    currentActions(newActions);
  };

  const clearHeaderAction = ({ id }: { id: string }) => {
    const newActions = currentActions().filter(item => item.id !== id);
    currentActions(newActions);
  };

  return {
    removeFilter,
    addFilter,
    setNewVisibleFilters,
    clearFilter,
    addHeaderAction,
    clearHeaderAction
  };
};

export const managePetsFilters = manageFilters('pets');
export const manageBookingsFilters = manageFilters('bookings');
export const manageSubscriptionsFilters = manageFilters('subscriptions');
