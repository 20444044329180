import React, { useEffect } from 'react';
import { DRAWER_IDS } from '../../../components/DrawerBar/types';
import InfiniteList from '../../../components/Shared/InfiniteList/InfiniteList';
import useNavigateDrawer from '../../../hooks/useNavigateDrawer';
import usePaginatedQuery from '../../../hooks/usePaginatedQuery';
import useSetDrawerOnNavigation from '../../../hooks/useSetDrawerOnNavigation';
import { GetBranchAppUserTags } from '../../../queries';
import Common from '../Common';
import BranchTagsRecord from './BranchTagsRecord';
import { BranchAppUserTag } from './types';

const BranchAppUserTags = () => {
  const [[hasMoreItems, setHasMoreItems], queryResult] = usePaginatedQuery<BranchAppUserTag[]>({
    query: GetBranchAppUserTags
  });

  const { data: { getBranchAppUserTags: branchAppUserTags = [] } = {}, loading, fetchMore, refetch } = queryResult;

  useSetDrawerOnNavigation({
    itemList: branchAppUserTags,
    drawerId: DRAWER_IDS.BRANCH_TAG_DRAWER,
    itemsToFilterFrom: branchAppUserTags
  });

  const navigateDrawer = useNavigateDrawer({
    drawerData: {
      drawerId: DRAWER_IDS.BRANCH_TAG_DRAWER,
      itemsToFilterFrom: branchAppUserTags
    }
  });

  useEffect(() => {
    Common.set(`BranchTags.GetBranchTags.refetch`, () => {
      refetch?.().then(() => setHasMoreItems(true));
    });
  }, []);

  return (
    <InfiniteList
      paddingL={20}
      paddingR={20}
      list={branchAppUserTags}
      itemRenderer={(tag: BranchAppUserTag) => <BranchTagsRecord key={tag.id} branchTag={tag} navigateDrawer={navigateDrawer} />}
      hasMoreItems={hasMoreItems}
      loading={loading}
      fetchMore={fetchMore as any}
      offset={branchAppUserTags?.length}
      setHasMoreItems={setHasMoreItems}
    />
  );
};

export default BranchAppUserTags;
