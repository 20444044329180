import { useLazyQuery } from '@apollo/client';
import { GetBranchAppUserTags } from '../../queries';
import { BranchAppUserTag } from '../../views/Store/BranchTags/types';
import { SelectedFilter } from '../Shared/Filters/config';
import { getFilter, getMoreOptionsValues } from '../Shared/Filters/helpers';
import useFilters from '../Shared/Filters/useFilters';
import { OPTION_DROPDOWN_TYPES } from '../Shared/Menus/OptionDropdown/types';

enum CHATS_FILTER_TYPES {
  CHAT_SEARCH = 'CHAT_SEARCH',
  CHAT_TAGS = 'CHAT_TAGS',
  CHAT_QUICK_TAGS = 'CHAT_QUICK_TAGS'
}

const HIDDEN_CHATS_FILTERS = {
  [CHATS_FILTER_TYPES.CHAT_SEARCH]: true,
  [CHATS_FILTER_TYPES.CHAT_TAGS]: false,
  [CHATS_FILTER_TYPES.CHAT_QUICK_TAGS]: true
};

const CHATS_FILTERS_QUERIES = {
  [CHATS_FILTER_TYPES.CHAT_SEARCH]: ['chat_search'],
  [CHATS_FILTER_TYPES.CHAT_TAGS]: ['chat_branchAppUserTag_name'],
  [CHATS_FILTER_TYPES.CHAT_QUICK_TAGS]: ['chat_branchAppUserTag_name']
};

enum CHATS_MORE_OPTIONS_TYPES {
  CHAT_SEARCH = 'CHAT_SEARCH',
  CHAT_TAGS = 'CHAT_TAGS'
}
const CHATS_FILTER_TYPES_NAMES: Record<CHATS_FILTER_TYPES, string> = {
  [CHATS_FILTER_TYPES.CHAT_TAGS]: 'Tags',
  [CHATS_FILTER_TYPES.CHAT_QUICK_TAGS]: '',
  [CHATS_FILTER_TYPES.CHAT_SEARCH]: 'Search'
};

const CHATS_MORE_OPTIONS_TITLES: Record<CHATS_MORE_OPTIONS_TYPES, string> = {
  [CHATS_MORE_OPTIONS_TYPES.CHAT_TAGS]: 'Tags',
  [CHATS_MORE_OPTIONS_TYPES.CHAT_SEARCH]: 'Search'
};

const CHATS_MORE_OPTIONS_DROPDOWN_TYPES: Record<CHATS_MORE_OPTIONS_TYPES, OPTION_DROPDOWN_TYPES> = {
  [CHATS_MORE_OPTIONS_TYPES.CHAT_TAGS]: OPTION_DROPDOWN_TYPES.MULTI_SELECT,
  [CHATS_MORE_OPTIONS_TYPES.CHAT_SEARCH]: OPTION_DROPDOWN_TYPES.TEXT_INPUT
};

const CHATS_FILTER_TYPES_UNITS: Record<CHATS_FILTER_TYPES, string> = {
  [CHATS_FILTER_TYPES.CHAT_TAGS]: '',
  [CHATS_FILTER_TYPES.CHAT_QUICK_TAGS]: '',
  [CHATS_FILTER_TYPES.CHAT_SEARCH]: ''
};

const CHATS_FILTER_TYPES_MORE_OPTIONS: Record<CHATS_FILTER_TYPES, CHATS_MORE_OPTIONS_TYPES[]> = {
  [CHATS_FILTER_TYPES.CHAT_TAGS]: [CHATS_MORE_OPTIONS_TYPES.CHAT_TAGS],
  [CHATS_FILTER_TYPES.CHAT_QUICK_TAGS]: [CHATS_MORE_OPTIONS_TYPES.CHAT_TAGS],
  [CHATS_FILTER_TYPES.CHAT_SEARCH]: [CHATS_MORE_OPTIONS_TYPES.CHAT_SEARCH]
};

const useChatsFilters = () => {
  const [getAllTags, { data: { getBranchAppUserTags: allTags = [] } = {}, called: calledTags }] = useLazyQuery<{ getBranchAppUserTags: BranchAppUserTag[] }>(GetBranchAppUserTags, {
    fetchPolicy: 'cache-and-network',
    variables: { offset: 0, limit: 1000 }
  });

  const toCall = [{ called: calledTags, get: () => getAllTags({ variables: { offset: 0, limit: 1000 } }), id: CHATS_FILTER_TYPES.CHAT_TAGS }];

  const CHATS_MORE_OPTIONS_ITEMS: Record<CHATS_MORE_OPTIONS_TYPES, { value: string | boolean | number; name: string }[] | []> = {
    [CHATS_MORE_OPTIONS_TYPES.CHAT_TAGS]: allTags?.map(({ id, name }: { id: string; name: string }) => ({ value: id, name })),
    [CHATS_MORE_OPTIONS_TYPES.CHAT_SEARCH]: []
  };

  const filtersControl = useFilters<CHATS_FILTER_TYPES, CHATS_MORE_OPTIONS_TYPES>({
    toCall,
    getOptions: () => {
      return {
        FILTER_TYPES: CHATS_FILTER_TYPES,
        MORE_OPTIONS_TYPES: CHATS_MORE_OPTIONS_TYPES,
        MORE_OPTIONS_ITEMS: CHATS_MORE_OPTIONS_ITEMS,
        FILTERS_QUERIES: CHATS_FILTERS_QUERIES,
        HIDDEN_FILTERS: HIDDEN_CHATS_FILTERS,
        FILTER_TYPES_MORE_OPTIONS: CHATS_FILTER_TYPES_MORE_OPTIONS,
        FILTER_TYPES_NAMES: CHATS_FILTER_TYPES_NAMES,
        FILTER_TYPES_UNITS: CHATS_FILTER_TYPES_UNITS,
        MORE_OPTIONS_DROPDOWN_TYPES: CHATS_MORE_OPTIONS_DROPDOWN_TYPES,
        MORE_OPTIONS_TITLES: CHATS_MORE_OPTIONS_TITLES
      };
    }
  });

  return filtersControl;
};

const getChatsVariables = ({
  defaultVariables,
  selectedFilters,
  overriddenFilters
}: {
  defaultVariables?: Record<string, any>;
  selectedFilters: SelectedFilter[];
  overriddenFilters?: Partial<Record<(typeof CHATS_FILTERS_QUERIES)[keyof typeof CHATS_FILTERS_QUERIES][number], any>>;
}) => {
  const getFilterValue = (filter: CHATS_FILTER_TYPES) => getFilter(selectedFilters, filter);
  const tagsFilter = getFilterValue(CHATS_FILTER_TYPES.CHAT_TAGS);
  const quickTagsFilter = getFilterValue(CHATS_FILTER_TYPES.CHAT_QUICK_TAGS);
  const tagsFilterValue = getMoreOptionsValues(tagsFilter, CHATS_MORE_OPTIONS_TYPES.CHAT_TAGS, { name: true });
  const quickTagsFilterValue = getMoreOptionsValues(quickTagsFilter, CHATS_MORE_OPTIONS_TYPES.CHAT_TAGS, { name: true });
  const allTags = [...new Set([...(tagsFilterValue || []), ...(quickTagsFilterValue || [])])];

  // search
  const searchFilter = getFilterValue(CHATS_FILTER_TYPES.CHAT_SEARCH);
  const searchFilterValue = getMoreOptionsValues(searchFilter, CHATS_MORE_OPTIONS_TYPES.CHAT_SEARCH);

  const variables = {
    ...defaultVariables,
    chat_branchAppUserTag_name: allTags?.length ? allTags : null,
    chat_search: searchFilterValue?.length ? searchFilterValue : null,
    ...overriddenFilters,
    requisite_queries: Object.values(CHATS_FILTER_TYPES)
      .map(filter => {
        const overriddenFiltersValues = CHATS_FILTERS_QUERIES[filter]?.filter(query => overriddenFilters?.[query]);
        if (overriddenFiltersValues?.length) {
          return overriddenFiltersValues;
        }
        const filterType = getFilterValue(filter);
        return filterType?.requisite === 'true' ? CHATS_FILTERS_QUERIES[filter] : null;
      })
      .filter(Boolean)
      .flat(),
    alternative_queries: Object.values(CHATS_FILTER_TYPES)
      .map(filter => {
        const overriddenFiltersValues = CHATS_FILTERS_QUERIES[filter]?.filter(query => overriddenFilters?.[query]);
        if (overriddenFiltersValues?.length) {
          return null;
        }
        const filterType = getFilterValue(filter);
        return filterType?.requisite === 'false' ? CHATS_FILTERS_QUERIES[filter] : null;
      })
      .filter(Boolean)
      .flat()
  };

  return variables;
};

export {
  CHATS_FILTER_TYPES,
  CHATS_FILTER_TYPES_NAMES,
  CHATS_FILTER_TYPES_UNITS,
  CHATS_FILTERS_QUERIES,
  CHATS_MORE_OPTIONS_DROPDOWN_TYPES,
  CHATS_MORE_OPTIONS_TITLES,
  CHATS_MORE_OPTIONS_TYPES,
  getChatsVariables,
  HIDDEN_CHATS_FILTERS,
  useChatsFilters
};
