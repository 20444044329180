import { useQuery } from '@apollo/client';
import React, { useRef, useState } from 'react';
import { DRAWER_IDS } from '../../../../components/DrawerBar/types';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import usePaginatedQuery from '../../../../hooks/usePaginatedQuery';
import { GetBranchPetProfile, GetWalkingReportsByPetRecordId } from '../../../../queries';
import { vars } from '../../../../reactive';
import WalkingDetails from './WalkingDetails';
import WalkingHistory from './WalkingHistory';

const Walking = ({ renderHeader }: { renderHeader: () => JSX.Element }) => {
  const walkingDetailsRef = useRef(null);
  const drawerBar = vars.drawerBars().find(drawer => drawer.drawerId === DRAWER_IDS.PETS_DRAWER);
  const petId = drawerBar?.recordData;
  const autoOpenModal = drawerBar?.otherData?.autoOpenModal;
  const preSelectedReprotId = drawerBar?.otherData?.selectedReportId;

  const { data: { getBranchPetProfile: petProfile = {} } = {} } = useQuery(GetBranchPetProfile, {
    variables: {
      id: petId
    }
  });

  const [[hasMoreItems, setHasMoreItems], { data: { getWalkingReportsByPetRecordId: walkingReports = [] } = {}, loading: loadingWalkingReports, fetchMore, refetch: refetchWalkingReports }] =
    usePaginatedQuery<any>({
      query: GetWalkingReportsByPetRecordId,
      limit: 6,
      otherVariables: { petRecordId: petProfile?.PetRecord?.id },
      otherParams: {
        skip: !petProfile?.id
      }
    });

  const { tablet } = useMediaQuery({ tablet: true });

  const [selectedReportId, setSelectedReportId] = useState<string | null>(preSelectedReprotId || !tablet ? walkingReports[0]?.id : null || null);

  const setSelectedReportIdAndScroll = (noteId: string) => {
    setSelectedReportId(noteId);
    walkingDetailsRef.current?.scrollIntoView({
      behavior: 'smooth'
    });
  };

  const selectedReport = walkingReports.find((note: any) => note.id === selectedReportId);

  return (
    <>
      <WalkingDetails innerRef={walkingDetailsRef} selectedReport={selectedReport} walkingReports={walkingReports} loading={loadingWalkingReports} deselectReport={() => setSelectedReportId(null)} />
      <WalkingHistory
        fetchMore={fetchMore}
        hasMoreItems={hasMoreItems}
        setHasMoreItems={setHasMoreItems}
        walkingReports={walkingReports || []}
        setSelectedReportId={setSelectedReportIdAndScroll}
        loading={loadingWalkingReports}
        selectedReportId={selectedReportId as string}
        refetchWalkingReports={refetchWalkingReports}
        autoOpenModal={autoOpenModal}
        onModalClose={drawerBar?.otherData?.onClose}
        renderHeader={renderHeader}
      />
    </>
  );
};

export default Walking;
