import React, { useEffect } from 'react';
import InfiniteList from '../../../components/Shared/InfiniteList/InfiniteList';
import usePaginatedQuery from '../../../hooks/usePaginatedQuery';
import { GetProviderBranches } from '../../../queries';
import { RecordsContainer } from '../../styled';
import Common from '../Common';
import { Branch } from '../../../components/Profile/types';
import ToolBar from '../../../components/Shared/ToolBar/ToolBar';
import ProviderBranchRecord from './ProviderBranchRecord';

const ProviderBranches = () => {
  const [[hasMoreItems, setHasMoreItems], queryResult] = usePaginatedQuery<Branch[]>({ query: GetProviderBranches, otherParams: { fetchPolicy: 'cache-and-network' } });

  const { data: { getProviderBranches: branches = [] } = {}, loading, fetchMore, refetch } = queryResult;

  useEffect(() => {
    Common.set(`Branches.GetProviderBranches.refetch`, () => {
      refetch?.().then(() => setHasMoreItems(true));
    });
  }, []);

  return (
    <>
      <ToolBar displayString="Branches" urlSearchParam="status" toolBarButtons={[]} displayTools={false} />
      <RecordsContainer>
        <InfiniteList
          list={branches}
          itemRenderer={(branch: Branch) => <ProviderBranchRecord branch={branch} />}
          hasMoreItems={hasMoreItems}
          loading={loading}
          fetchMore={fetchMore}
          offset={branches?.length}
          setHasMoreItems={setHasMoreItems}
        />
      </RecordsContainer>
    </>
  );
};

export default ProviderBranches;
