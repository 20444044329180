import styled from 'styled-components';
import Colors from '../../../../Colors';

export const SidePanelButton = styled.button`
  font-size: 14px;
  width: 305px;
  height: 45px;
  padding: 12px 73px 12px 72px;
  border-radius: 22.5px;
  background-color: #dedede;
  border: none;
  outline: none;
  span {
    font-weight: 800;
    color: ${Colors.secondaryText};
  }
  cursor: pointer;
  &:hover {
    cursor: pointer;
    filter: brightness(95%);
  }
  margin-bottom: 12px;
`;

export const ButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const AppointmentsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const TimestampUntilDate = styled.div`
  padding: 12px 8px;
  font-size: 16px;
  border-radius: 4px;
  height: 32px;
`;

export const BookingDrawerButtonsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  justify-self: flex-end;
  margin-left: auto;
`;

export const UsersMessageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
`;

export const UsersContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const UsersNamesContainer = styled.div``;

export const GroupProfilesContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 10px;
  overflow-x: auto;
  padding: 8px 0;
  overflow-y: hidden;
`;
export const GroupProfileContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${Colors.grey};
  padding: 4px 8px;
  gap: 8px;
  border-radius: 8px;
`;

export const GroupFiltersWrapper = styled.div<{ isActive: boolean; large?: boolean }>`
  display: flex;
  height: ${({ isActive, large }) => (isActive ? (large ? '160px' : '120px') : '0')};
  max-height: ${({ isActive, large }) => (isActive ? (large ? '160px' : '120px') : '0')};
  min-height: ${({ isActive, large }) => (isActive ? (large ? '160px' : '120px') : '0')};
  transition: all 0.3s ease-in-out;
  flex-direction: column;
  padding: ${({ isActive }) => (isActive ? '16px' : '0')} 16px;
`;

export const GroupFiltersContainer = styled.div`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  flex-direction: column;
  gap: 16px;
  background-color: ${Colors.grey};
  box-shadow: 20px -20px 0 40px ${Colors.grey};
`;

export const GroupFilterTitle = styled.span`
  font-size: 14px;
  font-weight: 800;
  min-width: 100px;
  color: ${Colors.black};
`;

export const GroupFilterSelectContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 170px;
`;

export const GroupDrawerContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px;
  background-color: ${Colors.white};
  position: relative;
`;

export const GroupPetDetails = styled.div<{ backgroundColor: string }>`
  display: flex;
  padding: 8px;
  border-radius: 8px;
  color: ${Colors.black};
  font-size: 14px;
  font-weight: 600;
  padding: 8px 16px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  margin-bottom: 4px;
`;

export const TagLoaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex: 1;
  justify-content: flex-end;
  margin-left: auto;
`;
