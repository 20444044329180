import styled from 'styled-components';

export const DaycareReportNotesContainer = styled.div`
  padding-top: 8px;
  padding-right: 8px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const DaycareReportNoteContainer = styled.div``;

export const ReportNoteSubCategoryContainer = styled.div`
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 12px;
  &:not(:first-child) {
    margin-top: 12px;
  }
`;

export const ReportNoteSubCategoryTitle = styled.span`
  font-weight: 700;
  display: inline-block;
  width: 120px !important;
`;
