import React from 'react';
import InfiniteList from '../../components/Shared/InfiniteList/InfiniteList';
import usePaginatedQuery from '../../hooks/usePaginatedQuery';
import { GetBranchSubmittedForms } from '../../queries';
import { SubmittedForm } from '../Store/BranchForms/types';
import PetsFormRecord from './PetsFormRecord';

const PetsForms = () => {
  const [[hasMoreItems, setHasMoreItems], { data: { getBranchSubmittedForms: allForms = [] } = {}, loading: loadingPetFormsReports, fetchMore }] = usePaginatedQuery<SubmittedForm[]>({
    query: GetBranchSubmittedForms,
    limit: 20
  });
  return (
    <InfiniteList
      paddingL={20}
      paddingR={20}
      fetchMore={fetchMore as any}
      hasMoreItems={hasMoreItems}
      setHasMoreItems={setHasMoreItems}
      itemRenderer={(form: SubmittedForm) => <PetsFormRecord key={form.id} submittedForm={form} />}
      list={allForms}
      loading={loadingPetFormsReports}
      offset={allForms?.length}
    />
  );
};

export default PetsForms;
