import React, { useEffect } from 'react';
import { DRAWER_IDS } from '../../../components/DrawerBar/types';
import { SelectedFilter } from '../../../components/Shared/Filters/config';
import InfiniteList from '../../../components/Shared/InfiniteList/InfiniteList';
import useNavigateDrawer from '../../../hooks/useNavigateDrawer';
import usePaginatedQuery from '../../../hooks/usePaginatedQuery';
import useSetDrawerOnNavigation from '../../../hooks/useSetDrawerOnNavigation';
import { GetBranchProducts } from '../../../queries';
import Common from '../Common';
import ServiceRecord from './ServiceRecord';
import { getServicesFiltersVariables } from './ServicesFilters';

const ServicesBody = ({ selectedFilters }: { selectedFilters: SelectedFilter[] }) => {
  const [[hasMoreItems, setHasMoreItems], queryResult] = usePaginatedQuery({
    query: GetBranchProducts,
    otherVariables: {
      ...getServicesFiltersVariables({
        selectedFilters,
        defaultVariables: {
          type: ['service']
        },
        overriddenFilters: {
          type: ['service']
        }
      })
    }
  });

  const { data: { getBranchProducts: services = [] } = {}, loading, fetchMore, refetch, variables: previousVariables } = queryResult;

  useSetDrawerOnNavigation({
    itemList: [],
    drawerId: DRAWER_IDS.SERVICE_DRAWER
  });

  const navigateDrawer = useNavigateDrawer({
    drawerData: {
      drawerId: DRAWER_IDS.SERVICE_DRAWER
    }
  });

  useEffect(() => {
    Common.set(`Services.GetServices.refetch`, () => {
      refetch?.().then(() => setHasMoreItems(true));
    });
  }, []);

  return (
    <InfiniteList
      paddingL={20}
      paddingR={20}
      list={services}
      itemRenderer={service => <ServiceRecord key={service?.id} service={service} navigateDrawer={navigateDrawer} />}
      hasMoreItems={hasMoreItems}
      loading={loading}
      fetchMore={fetchMore}
      offset={services?.length}
      setHasMoreItems={setHasMoreItems}
    />
  );
};

export default ServicesBody;
