import { useMutation } from '@apollo/client';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Notifier from '../../Notifier';
import { UpdateOrderInvoiceAmount } from '../../queries';
import { getBranchCurrencySymbol } from '../../utils/getBranchCurrencySymbol';
import { isValidAmount } from '../../utils/validators';
import { OrderInvoice } from '../../views/Store/BranchBilling/types';
import ModalDialog from '../Modal/ModalDialog';
import { ModalBody, ModalFooter } from '../Modal/styled';
import { FormButtonsContainer, FormError, FormInput, FormLabel, FormSubmitButton, InputGroup, InputsWrapper } from '../Shared/Forms/Forms';
import { Container } from '../Shared/Shared';

const AdjustInvoiceAmountModal = ({ OrderInvoice, refetchOrderInvoices }: { OrderInvoice: OrderInvoice; refetchOrderInvoices: () => void }) => {
  const { control, handleSubmit, errors } = useForm();
  const onChange = (args: any) => ({ value: args[0].target.value });
  const [updateOrderInvoiceAmount, { data: { updateOrderInvoiceAmount: updatedOrderInvoice = {} } = {}, loading: loadingUpdate, error: errorUpdate }] = useMutation(UpdateOrderInvoiceAmount);

  const onSubmit = handleSubmit(form => {
    updateOrderInvoiceAmount({ variables: { OrderInvoiceId: OrderInvoice.id, amount: form.amount } });
  });

  useEffect(() => {
    if (updatedOrderInvoice?.id) {
      refetchOrderInvoices();
      Notifier.success({ title: 'Payment Adjusted', message: 'Successfuly' });

      ModalDialog.closeModal();
    }
  }, [updatedOrderInvoice?.id]);

  return (
    <>
      <ModalBody>
        <Container>
          <InputsWrapper>
            <InputGroup>
              <FormLabel>Current Amount</FormLabel>
              <FormInput disabled value={getBranchCurrencySymbol() + ' ' + OrderInvoice?.amount} />
            </InputGroup>
            <InputGroup>
              <FormLabel error={errors.amount}>New Amount</FormLabel>
              <Controller
                as={<FormInput error={errors.amount} type={'number'} />}
                control={control}
                name={'amount'}
                onChange={onChange}
                defaultValue={''}
                rules={{
                  required: true,
                  pattern: {
                    value: isValidAmount,
                    message: 'Invalid Amount'
                  }
                }}
              />
              {errors.amount && <FormError>{errors.amount.message || 'New Amount is required'}</FormError>}
            </InputGroup>
          </InputsWrapper>
        </Container>
      </ModalBody>
      <ModalFooter>
        <FormButtonsContainer>
          <FormSubmitButton error={!!errorUpdate?.message} loading={loadingUpdate} onClick={() => onSubmit()}>
            Confirm Adjustment
          </FormSubmitButton>
        </FormButtonsContainer>
      </ModalFooter>
    </>
  );
};

export default AdjustInvoiceAmountModal;
