export const getTypeName = (type: string) => {
  switch (type) {
    case 'product':
      return 'Product';
    case 'service':
      return 'Service';
    case 'subscription':
      return 'Subscription';
    default:
      return 'Product';
  }
};
