import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { Controller, UseFormMethods, useForm } from 'react-hook-form';
import { sentenceCase } from 'sentence-case';
import ModalDialog from '../../../components/Modal/ModalDialog';
import { ModalBody, ModalFooter } from '../../../components/Modal/styled';

import { FormButtonsContainer, FormCheckbox, FormError, FormInput, FormLabel, FormSelect, FormSubmitButton, InputsWrapper, WideInputGroup, selectTheme } from '../../../components/Shared/Forms/Forms';
import { Container } from '../../../components/Shared/Shared';
import { CenteredLoader } from '../../../components/Shared/Spinner';
import { AddBranchBusUser, AddBranchRoom, DeleteBranchRoom, EditBranchBusUser, EditBranchRoom, GetBranchRooms, GetReducedBusUserProfile } from '../../../queries';

import Common from '../Common';
import { BranchRoom } from './types';

type BranchRoomModalForm = {
  name: string;
  availablity: number;
};

type BranchRoomModalFormProps = {
  loading: boolean;
  formOptions: UseFormMethods<BranchRoomModalForm>;
  saveError: boolean;
  saveLoading: boolean;
  defaultValues?: Partial<BranchRoomModalForm>;
  isSelf?: boolean;
  branchRoom?: BranchRoom;
  onSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
};

const BranchRoomModalForm = ({ loading, formOptions, saveError, saveLoading, onSubmit, branchRoom, defaultValues, isSelf, onDelete, deleteLoading, deleteError }: BranchRoomModalFormProps) => {
  const { control, errors, watch } = formOptions;

  return (
    <>
      <ModalBody>
        <Container width={500}>
          {loading && <CenteredLoader />}
          {!loading && (
            <InputsWrapper>
              <WideInputGroup>
                <FormLabel error={errors.name?.message}>Name</FormLabel>
                <Controller as={<FormInput error={!!errors.name} type={'text'} />} control={control} name={'name'} rules={{ required: true }} defaultValue={defaultValues?.name} />
                {errors.name && <FormError>{errors.name.message || 'name is required'}</FormError>}
              </WideInputGroup>
              <WideInputGroup>
                <FormLabel error={errors.availablity?.message}>Availablity</FormLabel>
                <Controller
                  as={<FormInput error={!!errors.availablity} type={'number'} />}
                  control={control}
                  name={'availablity'}
                  rules={{ required: true }}
                  defaultValue={defaultValues?.availablity}
                />
                {errors.availablity && <FormError>{errors.availablity.message || 'availablity is required'}</FormError>}
              </WideInputGroup>
            </InputsWrapper>
          )}
        </Container>
      </ModalBody>
      <ModalFooter>
        <FormButtonsContainer>
          <FormSubmitButton error={saveError} loading={saveLoading} onClick={onSubmit}>
            Save
          </FormSubmitButton>
          {branchRoom && (
            <FormSubmitButton error={deleteError} loading={deleteLoading} onClick={onDelete} danger>
              Delete
            </FormSubmitButton>
          )}
        </FormButtonsContainer>
      </ModalFooter>
    </>
  );
};

const BranchRoomModal = ({ branchRoom }: { branchRoom?: BranchRoom }) => {
  const { data: { getBranchRooms: currentBranchRoom } = {} } = useQuery<{ getBranchRooms: BranchRoom }>(GetBranchRooms);

  const isSelf = branchRoom?.id === currentBranchRoom?.id;

  const defaultValues = {
    name: branchRoom?.name || '',
    availablity: branchRoom?.availablity || 0
  };

  const formOptions = useForm<BranchRoomModalFormProps>({ defaultValues });

  const { handleSubmit } = formOptions;
  const [saveBranchRoom, { data: addedBranchRoom, loading: addLoading, error: addError }] = useMutation(AddBranchRoom, {
    refetchQueries: ['getBranchRooms'],
    awaitRefetchQueries: true
  });

  const [editBranchRoom, { data: editedBranchRoom, loading: editLoading, error: editError }] = useMutation(EditBranchRoom, {
    refetchQueries: ['getBranchRooms'],
    awaitRefetchQueries: true
  });

  const [deleteBranchRoom, { loading: deleteLoading, error: deleteError }] = useMutation(DeleteBranchRoom, {
    refetchQueries: ['getBranchRooms'],
    awaitRefetchQueries: true
  });

  const onSubmit = handleSubmit(data => {
    const { name, availablity } = data;
    const variables = {
      name,
      availablity
    };

    if (branchRoom) {
      editBranchRoom({
        variables: {
          id: branchRoom.id,
          ...variables
        }
      });

      return;
    }

    saveBranchRoom({ variables });
  });

  const onDelete = () => {
    if (branchRoom) {
      deleteBranchRoom({
        variables: {
          id: branchRoom?.id
        }
      });
      ModalDialog.closeModal();
    }
  };

  const loading = false;
  const saveError = !!addError?.message || !!editError?.message;
  const saveLoading = addLoading || editLoading;
  const savedBranchRoom = addedBranchRoom?.addBranchRoom || editedBranchRoom?.editBranchRoom;

  useEffect(() => {
    if (savedBranchRoom) {
      ModalDialog.closeModal();
      Common.get<() => Promise<void>>(`BranchRooms.GetBranchRooms.refetch`)?.();
    }
  }, [savedBranchRoom]);

  return (
    <BranchRoomModalForm
      loading={loading}
      formOptions={formOptions}
      saveError={saveError}
      saveLoading={saveLoading}
      onSubmit={onSubmit}
      branchRoom={branchRoom}
      defaultValues={defaultValues}
      isSelf={isSelf}
      onDelete={onDelete}
      deleteLoading={deleteLoading}
      deleteError={deleteError}
    />
  );
};

export default BranchRoomModal;
