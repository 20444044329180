import React, { useMemo, useRef } from 'react';
import useBulkSelect from '../../hooks/useBulkSelect';
import useIcons from '../../hooks/useIcons';
import useMediaQuery from '../../hooks/useMediaQuery';
import { setDrawerBar } from '../../reactive/actions';
import { getRandomPetPlaceholder } from '../../utils/helpers';

import { PetAndInfo, PetContainer } from '../../views/Bookings/components/BookingRecord/styled';
import { Booking, PetImg, PetRecordTag, PetRecordTags, PetRecordTagsContainer } from '../../views/Bookings/components/BookingsBody/styled';
import { HIDDEN_PETS_FILTERS } from '../../views/Pets/PetsFilters';
import { BranchPetRecordTag } from '../../views/Store/BranchTags/types';
import { AppUserImage, AppUserImageAnimation, AppUserImageContainer, AppUserImageContainerAnimation, AppUserImageNameContainer, RecordBody } from '../../views/styled';
import { Divider } from '../DrawerBar/styled';
import { DRAWER_IDS } from '../DrawerBar/types';
import { SelectedFilter } from '../Shared/Filters/config';
import { Icon } from '../Shared/Forms/Forms';
import { Checkbox, CheckboxItemContainer } from '../Shared/Forms/styled';
import { VerticalDivider } from '../Shared/ToolBar/styles';
import PetRecordFilters from './PetRecordFilters';
import PetRecordOptions from './PetRecordOptions';
import { AppUserImagePetRecordOptions, PetRecordOptionsContainer } from './styled';
import { Pet } from './types';

const PetRecord = ({
  pet,
  profile,
  navigateDrawer,
  quickTags,
  selectedFilters = [],
  tagsVisibleOnItem,
  bulkSelect
}: {
  pet: Pet;
  profile: Record<string, any>;
  navigateDrawer: ({ drawerLinkId }: { drawerLinkId: string }) => void;
  quickTags: BranchPetRecordTag[];
  tagsVisibleOnItem?: BranchPetRecordTag[];
  bulkSelect: ReturnType<typeof useBulkSelect>;
  selectedFilters?: SelectedFilter[];
}) => {
  const icons = useIcons();
  const optionsRef = useRef<HTMLDivElement>(null);
  const { mobile } = useMediaQuery({ mobile: true });

  const existingTags: BranchPetRecordTag[] = pet?.PetRecord?.BranchPetRecordTags;

  const { show: showBulkSelect, toggleSelectMultiple, isSelected } = bulkSelect || {};

  const handleClick = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    if (optionsRef?.current?.contains(e.target as Node)) {
      return;
    }

    if (showBulkSelect) {
      toggleSelectMultiple([pet?.id]);
      return;
    }

    setDrawerBar({ drawerId: DRAWER_IDS.PETS_DRAWER, recordData: pet?.id });

    navigateDrawer({ drawerLinkId: pet?.id });
  };

  const isCollarSupport = profile?.Branch?.name === 'Collar';

  const profilePic = useMemo(() => (isCollarSupport ? getRandomPetPlaceholder(icons) : pet?.profile_pic || getRandomPetPlaceholder(icons)), [icons, pet]);

  const petBreed = pet?.Breed?.name;

  const petOwnerName = pet?.AppUser?.name;

  const hasFilters = !!selectedFilters?.length;
  const hasVisibileFilters = !!selectedFilters.filter(({ value }) => !HIDDEN_PETS_FILTERS[value]).length;
  const petRecordOptions = (
    <PetRecordOptionsContainer>
      <PetRecordOptions pets={[pet]} quickTags={quickTags} ref={optionsRef} showAlways={mobile} refetchPetsAfterTag={hasFilters} />
    </PetRecordOptionsContainer>
  );

  const tagsToRender = tagsVisibleOnItem ? existingTags.filter(tag => tagsVisibleOnItem.some(visibleTag => visibleTag.id === tag.id)) : existingTags;

  const renderTags = () => {
    return (
      <PetRecordTagsContainer>
        <PetRecordTags>
          {tagsToRender?.map(({ name, id, color }) => (
            <PetRecordTag color={color} key={id}>
              {name}
            </PetRecordTag>
          ))}
        </PetRecordTags>
      </PetRecordTagsContainer>
    );
  };

  return (
    <>
      <Booking gap={8} onClick={handleClick} mobilePadding="14px 10px" mobileMinHeight="0">
        {!mobile && (
          <AppUserImagePetRecordOptions>
            <AppUserImageNameContainer>
              <AppUserImageContainer noPadding={!showBulkSelect}>
                <AppUserImageContainerAnimation rotateOption={!!showBulkSelect} noPadding={showBulkSelect}>
                  {!showBulkSelect && (
                    <AppUserImageAnimation>
                      <AppUserImage src={pet?.profile_pic || icons.user.childImageSharp.gatsbyImageData.images.fallback.src} />
                    </AppUserImageAnimation>
                  )}
                  {showBulkSelect && (
                    <AppUserImageAnimation>
                      <CheckboxItemContainer checked={isSelected(pet?.id)} noMargin>
                        <Checkbox>
                          <Icon viewBox="0 0 24 24">
                            <polyline points="20 6 9 17 4 12" />
                          </Icon>
                        </Checkbox>
                      </CheckboxItemContainer>
                    </AppUserImageAnimation>
                  )}
                </AppUserImageContainerAnimation>
              </AppUserImageContainer>
              {!mobile && (
                <RecordBody width="150" flex="1">
                  <RecordBody fontWeight="800" flex="1" color="#212121">
                    {pet?.name}
                  </RecordBody>
                  , <RecordBody flex="1">{petBreed}</RecordBody>
                </RecordBody>
              )}
            </AppUserImageNameContainer>
          </AppUserImagePetRecordOptions>
        )}

        {!hasVisibileFilters && (
          <>
            {mobile && (
              <PetContainer>
                <PetImg src={pet?.profile_pic || icons.user.childImageSharp.gatsbyImageData.images.fallback.src} alt={pet?.name} />
                <PetAndInfo mobileFlexDirection="column">
                  <RecordBody mobileFlexDirection="column" mobileWidth="100%">
                    <RecordBody fontWeight="800" color="#212121" mobileWidth="100%">
                      {pet?.name},{' '}
                      <RecordBody width="80" mobileFontWeight={500} mobileWidth="100%">
                        {' '}
                        {pet?.Breed?.name}
                      </RecordBody>
                    </RecordBody>
                  </RecordBody>
                  <RecordBody mobileWidth="100%">{petOwnerName}</RecordBody>
                </PetAndInfo>
                {petRecordOptions}
              </PetContainer>
            )}

            {!mobile && (
              <>
                <RecordBody minWidth={120} width="120" flex="0 0 120" fontWeight="800">
                  {petOwnerName}
                </RecordBody>
                <VerticalDivider />
              </>
            )}
          </>
        )}
        {!hasFilters && !mobile && <RecordBody width="360">{renderTags()}</RecordBody>}
        <PetRecordFilters selectedFilters={selectedFilters} pet={pet} />
        {!mobile && petRecordOptions}
      </Booking>
      {!mobile && <Divider />}
    </>
  );
};

export default PetRecord;
