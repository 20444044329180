import styled from 'styled-components';

export const GroomingReportNotesContainer = styled.div`
  padding-top: 8px;
  padding-right: 8px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const GroomingReportNoteContainer = styled.div``;

export const ReportNoteSubCategoryContainer = styled.div`
  font-size: 12px;
  font-weight: 700;
  margin-bottom: 12px;
  margin-top: 12px;
`;

export const ReportNoteSubCategoryTitle = styled.span`
  font-weight: 700;
`;
