import styled, { css } from 'styled-components';
import Colors from '../../Colors';
import { FadeInRight, SlideDown, SlideDownAndUp, SlideUp } from '../Shared/Animations';
import { getKeys } from '../../utils/helpers';
import { CalendarEventColors } from '../Shared/Shared';
import { isToday } from '../../utils/dates';

export const ExtensionFullBody = styled.div<{ fullHeight?: boolean }>`
  height: ${props => (props.fullHeight ? 'calc(100% - 60px)' : 'calc(100% - 220px)')};
  max-height: calc(100% - 180px);
  max-height: 100%;
  padding: 0px;
  @media (max-width: 768px) {
    height: calc(100% - 80px);
  }
`;

export const DayColumn = styled.div<{ maxWidth?: number; minWidth?: number; mobileMaxWidth?: number; mobileMinWidth?: number }>`
  position: relative;
  height: fit-content;
  flex: 1;
  min-width: ${props => props.minWidth || 0}px;
  box-sizing: border-box;
  ${props =>
    props.maxWidth &&
    css`
      max-width: ${props.maxWidth}px;
      width: auto;
    `};

  @media (max-width: 768px) {
    ${props =>
      (props.mobileMaxWidth || props.maxWidth) &&
      css`
        max-width: ${props.mobileMaxWidth || props.maxWidth}px;
      `}
    ${props =>
      (props.mobileMinWidth || props.minWidth) &&
      css`
        min-width: ${props.mobileMinWidth || props.minWidth}px;
      `}
  }
`;

export const DayLabel = styled.div<{ isToday?: boolean }>`
  font-size: 18px;
  font-weight: 800;
  color: ${props => (props.isToday ? Colors.primary : Colors.secondaryText)};
`;

export const HourLabel = styled.div`
  height: 1rem;
  font-weight: 500;
  color: #878787;
  margin-bottom: 2.755rem;
  &:after {
    content: '';
    background-color: #878787;
    display: inline-block;
    height: 1px;
    vertical-align: middle;
    width: 70%;
    margin-left: 14px;
  }
`;

export const HourMarker = styled.div<{ hour: number; minute: number }>`
  position: absolute;
  height: 6px;
  width: 6px;
  background-color: ${Colors.orange};
  border-radius: 50%;
  top: ${props => `calc(24px + ${props.hour * 60}px + ${props.minute * 1}px)`};
  left: 10px;
  &:after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 342px;
    background-color: white;
    border: solid 1px ${Colors.orange};
    position: absolute;
    top: 2px;
    left: 5px;
  }
`;

export const CounterBtn = styled.button<{ bgImg?: string; height?: string; width?: string }>`
  height: ${props => props.height || '30px'};
  width: ${props => props.width || '30px'};
  border: none;
  border-radius: 50%;
  font-size: 16px;
  margin: 0 6px;

  ${({ bgImg }) =>
    bgImg &&
    css`
      background-image: url(${bgImg});
    `}

  &:hover {
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.09);
    cursor: pointer;
  }
  @media (max-width: 768px) {
    margin: 0;
  }
`;
export const TodayBtn = styled.button`
  height: 30px;
  width: 88px;
  border: none;
  border-radius: 22px;
  font-size: 17px;
  font-weight: 800;
  margin: 0 3px;
  &:hover {
    box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.09);
    cursor: pointer;
  }
`;

export const CollapseCalendarContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: space-between;
  padding: 0 24px;
  height: 80px;
`;

export const MonthLabel = styled.div<{ padding?: string }>`
  white-space: nowrap;
  display: 'flex';
  justify-content: 'center';
  align-items: 'center';
  padding: ${props => props.padding || '15px'};
  flex-direction: 'column';
  font-weight: 800;
  text-align: 'center';
  margin: auto;
  @media (max-width: 768px) {
    display: none;
  }
`;

export const AllBookingsCalendarContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 100%;
  box-sizing: border-box;

  .split {
    height: 100%;
    max-height: 100%;

    .gutter-vertical {
      width: 100%;
      height: 15px !important;
      border: solid 1px #e4e4e4;
      background-color: #fff;
      cursor: row-resize;
      transition: all 0.1s ease-in-out;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 2px;
    }

    .gutter-vertical::after {
      content: '';
      display: block;
      width: 20px;
      height: 2px;
      background-color: #d8d8d8;
    }
    .gutter-vertical::before {
      content: '';
      display: block;
      width: 20px;
      height: 2px;
      background-color: #d8d8d8;
    }

    .gutter-vertical:hover {
      background-color: #e4e4e4;
      height: 20px !important;
    }

    .gutter-vertical:active {
      background-color: ${Colors.primary};
      height: 20px !important;
    }

    .gutter-vertical:hover::after {
      background-color: #fff;
    }

    .gutter-vertical:hover::before {
      background-color: #fff;
    }
  }
`;

export const MultiStaffCalendarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 100%;
  box-sizing: border-box;
  overflow: auto;
  ::-webkit-scrollbar {
    width: 6px;
  }
`;

export const SlotCalendarContainer = styled.div`
  display: flex;
  width: 100%;
  overflow: auto;
  overflow-x: hidden;
  height: 100%;
  box-sizing: border-box;
  position: relative;
  ::-webkit-scrollbar {
    width: 6px;
  }
`;

export const MultiStaffCalendarContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;
`;

export const DaysContainer = styled.div`
  display: flex;
  height: 100%;
  position: relative;
`;

export const HeaderDaysContainer = styled.div`
  display: flex;
  z-index: 100;
  width: calc(100% - 6px);
  box-sizing: border-box;
`;
export const WeekDayTitle = styled.span<{ isToday?: boolean }>`
  font-size: 14px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 4px;
  color: ${props => (props.isToday ? Colors.primary : Colors.secondaryText)};
`;

export const WeekDay = styled.button<{ isToday?: boolean }>`
  font-size: 14px;
  font-weight: 600;
  border-radius: ${props => (props.isToday ? '20px' : '0')};
  width: 20px;
  height: 20px;
  background-color: ${props => (props.isToday ? Colors.primary : 'transparent')};
  color: ${props => (props.isToday ? Colors.white : Colors.black)};
  padding: ${props => (props.isToday ? '12px' : '0')};
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
`;

export const WeekDayTitleAndWeekDayContainer = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  padding: 12px;
  justify-content: center;
`;

export const DayDiv = styled.div<{ isToday?: boolean; multiStaff?: boolean; showOverFlow?: boolean; padding?: string; height?: number; renderBorders?: boolean; noBorderBottom?: boolean }>`
  padding: 16px 0;
  ${({ padding }) =>
    padding &&
    css`
      padding: ${padding};
    `}
  font-size: 16px;
  font-weight: bold;
  background-color: ${Colors.white};
  border: solid 1px #dddddd;
  border-left: none;
  position: sticky;
  top: 0;
  z-index: 1000;
  color: ${props => (props.isToday ? Colors.primary : Colors.secondaryText)};
  overflow: ${({ showOverFlow }) => (showOverFlow ? 'visible' : 'hidden')};
  text-overflow: ellipsis;
  white-space: nowrap;
  height: ${({ multiStaff }) => (multiStaff ? '60px' : '100px')};
  ${props =>
    props.height &&
    css`
      height: ${props.height}px;
    `}
  ${WeekDayTitle} {
    text-align: center;
  }
  ${WeekDay} {
    width: 30px;
    height: 30px;
    min-width: 30px;
    min-height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    ${props =>
      props.isToday &&
      css`
        border-radius: 50%;
        background-color: ${Colors.primary};
        color: ${Colors.white};
      `}
    ${WeekDayTitle} {
      text-align: center;
    }
  }
  ${props =>
    props.noBorderBottom &&
    css`
      border-bottom: none;
    `}
  display: flex;
  align-items: center;
  flex-direction: column;
  text-align: center;
  gap: 4px;
`;

export const HourDiv = styled.div<{
  noHover?: boolean;
  renderBorders?: boolean;
  smallSize?: boolean;
  mediumSize?: boolean;
  heightMultiplier?: number;
  alternateColors?: boolean;
  backgroundColor?: string;
}>`
  position: relative;
  display: flex;
  height: ${props => {
    let height = 120;
    if (props.mediumSize) {
      height = 80;
    }
    if (props.smallSize) {
      height = 40;
    }
    if (props.heightMultiplier) {
      return height * props.heightMultiplier;
    }
    return height;
  }}px;

  background-color: ${Colors.white};
  ${props =>
    props.alternateColors &&
    css`
      &:nth-child(2n) {
        background-color: #ffffff;
      }
    `}
  ${props =>
    props.backgroundColor &&
    css`
      background-color: ${props.backgroundColor};
    `}
  ${props =>
    props.renderBorders &&
    css`
      border-bottom: solid 1px #dddddd;
    `}
  border-right: solid 1px #dddddd;
  ${props =>
    !props.noHover &&
    css`
      &:hover {
        background-color: #dddddd;
      }
    `}
`;

export const CapacityDiv = styled.div`
  height: 100%;
  width: 100%;
  gap: 4px;
  display: flex;
  flex-direction: column;
  padding: 0 4px;
  align-items: center;
  text-align: center;
  justify-content: center;
`;

export const Hour = styled.div`
  position: absolute;
  bottom: -8px;
  left: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  font-size: 14px;
  z-index: 5;
`;

export const GroupIconContainer = styled.div`
  position: absolute;
  top: 6px;
  right: 6px;
`;

export const MoreInfoContainer = styled.div<{
  dayIndex: number;
  total: number;
  index: number;
  hour: number;
  minute: number;
  color: string;
  duration: number;
  first?: boolean;
  last?: boolean;
  mediumSize?: boolean;
}>`
  position: absolute;
  ${({ dayIndex, index, total, last, first }) => {
    const left = css`
      left: calc(85% / ${total / (index + 1)});
    `;
    const right = css`
      right: calc(90% - (95% / ${total / index}));
    `;

    if (first) {
      return left;
    }
    if (last) {
      return right;
    }

    return dayIndex < 2 ? left : right;
  }};

  --bookingCardHeight: ${({ mediumSize }) => (mediumSize ? '60px' : '120px')};

  ${({ hour, minute, duration, mediumSize }) => {
    const hoursOffset = 1;
    const hoursHeight = `calc(${hour} * calc(var(--bookingCardHeight) + ${hoursOffset}px))`;
    const minutesHeight = `calc(${minute} * 1px)`;
    const durationMultiplier = 1;
    const durationHeight = duration ? `${duration * durationMultiplier}px` : mediumSize ? '45px' : '60px';
    return hour < 22
      ? css`
          top: calc(var(--bookingCardHeight) + ${hoursHeight} + ${minutesHeight} + ${duration / 2}px);
        `
      : css`
          top: calc(-20px + ${hoursHeight} + ${minutesHeight} + ${duration / 2}px);
        `;
  }};
  display: none;
  width: 200px;
  padding: 20px;
  min-height: 190px;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 10px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.18);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 2px solid ${({ color }) => color};
  flex-direction: column;
  z-index: 100;
  &:hover {
    display: flex;
  }
`;

export const BookingCard = styled.div<{ hour: number; minute: number; duration: number; total?: number; index?: number; group?: boolean; mediumSize?: boolean }>`
  position: absolute;
  box-sizing: border-box;
  --width: calc(96% / ${({ total }) => total || 1});
  width: var(--width);
  margin-left: calc(${props => props.index} * var(--width) + 2%);
  left: 0%;
  z-index: ${({ hour }) => hour + 1};
  animation: ${FadeInRight} 0.35s cubic-bezier(0.04, 0.98, 0.71, 0.99);
  transition: all 0.1s ease-in-out;
  &:hover {
    cursor: pointer;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.09);
    filter: invert(0.05);
  }
  --bookingCardHeight: ${({ mediumSize }) => (mediumSize ? '60px' : '120px')};

  ${({ hour, minute, duration, group, mediumSize }) => {
    const hoursOffset = 1;
    const hoursHeight = `calc(${hour} * calc(var(--bookingCardHeight) + ${hoursOffset}px))`;
    const minutesHeight = `calc(${minute} * 1px)`;
    const durationMultiplier = mediumSize ? 1 : 2;
    const durationHeight = duration ? `${duration * durationMultiplier}px` : 'var(--bookingCardHeight)';
    const groupHeight = group ? '9px' : '0px';
    return css`
      top: calc(var(--bookingCardHeight) + ${hoursHeight} + ${minutesHeight});
      height: calc(${durationHeight} - ${groupHeight});
    `;
  }};

  box-shadow: 0px 4px 6px 2px rgba(0, 0, 0, 0.12);
  border-radius: 10px;

  &:hover ~ ${MoreInfoContainer} {
    display: flex;
    @media (max-width: 768px) {
      display: none;
    }
  }
`;

export const BookingCardContent = styled.div<{ color?: string; group?: boolean; isCalendarStyle?: boolean }>`
  height: 100%;
  width: 100%;
  background-color: ${({ color }) => color};
  padding: 8px;
  font-size: 14px;
  font-weight: 600;
  color: ${Colors.white};
  border-radius: 10px;
  box-sizing: border-box;
  user-select: none;
  overflow: hidden;

  ${({ group, color }) =>
    group &&
    css`
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15);
      &:before {
        content: '';
        position: absolute;
        background-color: ${color};
        bottom: -4px;
        width: 94%;
        height: 8px;
        left: 3%;
        border-radius: 0 0 20px 20px;
        z-index: -1;
        opacity: 0.71;
        box-shadow: 0 2px 4px 0 rgba(161, 161, 161, 0.5);
      }

      &:after {
        content: '';
        position: absolute;
        width: var(--width);
        background-color: ${color};
        opacity: 0.7;
        width: 90%;
        height: 6px;
        left: 5%;
        bottom: -7px;
        border-radius: 0 0 20px 20px;
        z-index: -2;
      }
    `}

  ${({ isCalendarStyle }) =>
    isCalendarStyle &&
    css`
      border: solid 2px rgb(3, 155, 229);
      box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.15);
    `}
`;

export const MultiDayMoreInfoContainer = styled.div<{
  dayIndex: number;
  index: number;
  hour_from: number;
  minute_from: number;
  color: string;
  duration: number;
  isTimestampUntilAfterWeek: boolean;
  smallSize?: boolean;
  mediumSize?: boolean;
}>`
  position: absolute;

  ${({ dayIndex, duration, isTimestampUntilAfterWeek }) => {
    const durationFixed = duration || 1;
    const left = `100% * ${durationFixed}`;
    return dayIndex < 2
      ? isTimestampUntilAfterWeek || durationFixed > 4
        ? css`
            left: calc(${left} - 200%);
          `
        : css`
            left: calc(${left});
          `
      : css`
          right: 100%;
        `;
  }};
  --multiDayHeight: ${({ smallSize, mediumSize }) => (smallSize || mediumSize ? '40px' : '120px')};
  --contentHeight: ${({ smallSize, mediumSize }) => (smallSize || mediumSize ? '0px' : '80px')};

  ${({ index, duration }) =>
    css`
      top: calc(var(--contentHeight) + ${index} * var(--multiDayHeight) + ${duration || 60}px);
    `};

  display: none;
  width: 200px;
  padding: 20px;
  min-height: 190px;
  background-color: rgba(255, 255, 255, 0.7);
  border-radius: 10px;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.18);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 2px solid ${({ color }) => color};
  flex-direction: column;
  z-index: 100;
  &:hover {
    display: flex;
  }
`;

export const MultiDayBookingCard = styled.div<{
  hour_from: number;
  minute_from: number;
  duration: number;
  index: number;
  color: string;
  smallSize?: boolean;
  mediumSize?: boolean;
}>`
  position: absolute;
  box-sizing: border-box;

  --multiDayHeight: ${({ smallSize, mediumSize }) => {
    let height = 120;
    if (smallSize || mediumSize) {
      height = 40;
    }

    return `${height}px`;
  }};

  --extraOffset: ${({ index, mediumSize }) => {
    if (mediumSize) {
      return `calc(${(index + 1) * 20}px - 10px)`;
    }
    return '0px';
  }};

  height: var(--multiDayHeight);
  --width: calc(100% * ${({ duration }) => duration || 1});

  width: var(--width);

  background-color: ${({ color }) => color};
  border-radius: 10px;
  border: solid 1px ${Colors.grey};
  top: calc(${({ index }) => index} * var(--multiDayHeight) + var(--extraOffset));
  left: ${({ hour_from, minute_from }) => ((hour_from + minute_from / 60) / 24) * 100}%;

  z-index: 1;
  padding: ${({ smallSize, mediumSize }) => {
    if (smallSize || mediumSize) {
      return '8px';
    }

    return '16px 8px';
  }};

  font-size: 14px;
  font-weight: 600;
  color: ${Colors.white};
  overflow: hidden;
  animation: ${FadeInRight} 0.35s cubic-bezier(0.04, 0.98, 0.71, 0.99);
  transition: transform 0.1s ease-in-out;

  &:hover {
    cursor: pointer;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.09);
    filter: invert(0.05);
  }

  &:hover ~ ${MultiDayMoreInfoContainer} {
    display: flex;
    @media (max-width: 768px) {
      display: none;
    }
  }
`;

export const BookingCardTitle = styled.span`
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 16px;
`;

export const BookingCardLabel = styled.span<{ fontSize: number; fontWeight: string; marginTop?: number; maxWidth?: string; isCalendarStyle?: boolean }>`
  font-size: ${props => props.fontSize}px;
  font-weight: ${props => props.fontWeight};
  margin-bottom: 4px;
  margin-top: ${props => props.marginTop || 0}px;
  color: ${props => (props.isCalendarStyle ? 'rgb(3, 155, 229)' : Colors.black)};
  max-width: ${props => props.maxWidth || 'unset'};
  display: block;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;

export const MoreInfoGroupBookingsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const MoreInfoGroupBookingItem = styled.div<{ color: string }>`
  background-color: ${({ color }) => color};
  border-radius: 10px;
  padding: 8px 16px;
  transition: transform 0.1s ease-in-out;
  &:hover {
    cursor: pointer;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.09);
    filter: invert(0.05);
    transition: all 0.1s ease-in-out;
    transform: scale(1.03);
  }

  &:active {
    transform: scale(0.97);
  }
`;

export const CalendarContainer = styled.div`
  height: 100%;
  background-color: ${Colors.grey};
  display: flex;
  flex-direction: column;
  z-index: 10;
  box-shadow: 0px 5px 33px -10px #ccc;
  overflow: hidden;
`;

export const CalendarHeaderContainer = styled.div`
  display: flex;
  position: sticky;
  top: 0;
  left: 0;
  z-index: 100;
  flex-direction: column;
`;

// export const CalendarHeaderOptions = styled.div`
//   justify-content: space-between;
//   align-items: center;
//   padding: 0 24px;
//   height: 80px;
//   background-color: #ffffff;
//   display: flex;
//   z-index: 101;
// `;

export const CalendarNavigationControlsContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const HourHand = styled.div<{ hour: number; minute: number }>`
  position: absolute;
  height: 40px;
  width: 100%;
  top: ${props => `calc(60px + ${props.hour * 61}px + ${props.minute}px - 8px)`};
  z-index: 10;
  user-select: none;
  pointer-events: none;
  &:before {
    content: '';
    position: absolute;
    height: 10px;
    width: 10px;
    background-color: ${Colors.black};
    border-radius: 50%;
    display: inline-block;
    top: 4px;
    left: 4px;
  }
  &:after {
    content: '';
    top: 8px;
    width: 100%;
    z-index: 10000;
    position: absolute;
    height: 1px;
    background-color: ${Colors.black};
  }
`;

export const BookingCardContainer = styled.div``;

export const AddButton = styled.button<{ bgImage?: string }>`
  height: 40px;
  outline: none;
  border: none;
  width: 40px;
  border-radius: 50%;
  box-shadow: 4px 4px 30px 0 rgba(31, 151, 153, 0.33);
  margin-left: 6px;
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  &:hover {
    transform: scale(1.2);
  }
  ${props =>
    props.bgImage &&
    css`
      background-image: url(${props.bgImage});
      background-position: center;
      background-size: contain;
      background-repeat: no-repeat;
    `}
`;

export const ExtendDrawerButton = styled.button`
  height: 40px;
  outline: none;
  border: none;
  cursor: pointer;
  opacity: 0.85;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.white};
  span {
    font-weight: 400;
    font-size: 28px;
  }

  &:hover {
    opacity: 1;
  }
`;

export const CalendarNavigationContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
`;

export const AddNewServiceContainer = styled.div`
  position: absolute;
  top: 20px;
  right: 30px;
`;

export const BookingMessageContainer = styled.div<{ active: boolean }>`
  position: absolute;
  opacity: ${props => (props.active ? 1 : 0)};
  transition: opacity 0.3s ease-in-out;
  top: 155px;
  right: 50%;
  transform: translateX(50%);
  pointer-events: none;
  z-index: 1000;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const BookingMessageWrapper = styled.div<{ active: boolean; closeButton: boolean; success?: boolean }>`
  width: 459px;
  height: 40px;
  opacity: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 20px;
  z-index: 100;
  background-color: ${props => (props.success ? Colors.primary : Colors.orangeMedium)};
  color: ${Colors.white};
  text-align: center;
  box-shadow: 0 0 30px 0 ${props => (props.success ? 'rgba(31, 151, 153, 0.33)' : 'rgba(209, 56, 0, 0.33)')};

  ${props =>
    props.active &&
    !props.closeButton &&
    css`
      animation: ${SlideDownAndUp} 3.6s ease-in-out;
    `}

  ${props =>
    props.active &&
    props.closeButton &&
    css`
      animation: ${SlideDown} 0.3s ease-in;
      opacity: 1;
    `}

  ${props =>
    !props.active &&
    css`
      animation: ${SlideUp} 0.3s ease-out;
      opacity: 0;
    `}

    pointer-events: ${props => (props.active ? 'all' : 'none')};

  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const BookingMessageText = styled.span`
  font-size: 16px;
  font-weight: 800;
  text-align: center;
  width: calc(100% - 30px);
`;

export const BookingMessageButton = styled.button<{ bgImage?: string; rotateOnClick?: boolean }>`
  height: 30px;
  width: 30px;
  object-fit: cover;
  border: 1px solid #e0e0e0;
  border-radius: 50%;
  margin-right: 4px;
  outline: none;
  cursor: pointer;
  margin-left: auto;
  ${props =>
    props.bgImage &&
    css`
      background: url(${props.bgImage}) center no-repeat;
      &:hover {
        background-color: ${Colors.orangeBorder};
      }
    `};
`;

export const MultiDayButton = styled.label`
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin-right: 10px;
  &:hover {
    transform: scale(1.05);
  }

  span {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 34px;

    ::before {
      position: absolute;
      content: '';
      height: 26px;
      width: 26px;
      left: 4px;
      bottom: 4px;
      background-color: white;
      transition: 0.4s;
      border-radius: 50%;
    }
  }
  input {
    display: none;
    &:checked + span {
      background-color: ${Colors.primary};
      ::before {
        transform: translateX(26px);
      }
    }
    &:focus + span {
      box-shadow: 0 0 1px ${Colors.primary};
    }
  }
`;

export const MultiDayTitle = styled.span`
  margin-right: 10px;
  font-weight: 800;
  text-decoration: underline ${Colors.primary};
`;

export const MultiStaffDaysHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  top: 0;
  position: sticky;
  z-index: 8;
`;

export const CalendarRangeContainer = styled.div<{ show: boolean }>`
  display: ${props => (props.show ? 'flex' : 'none')};
  padding: 18px 16px;
  flex-direction: column;
  gap: 32px;
  flex: 1;
  border-radius: 32px;

  @media (max-width: 768px) {
    left: 0;
    width: 95%;
    border-radius: 0;
    top: 80px;
  }
  .tp-calendar-header {
    background-color: ${Colors.white} !important;
    border: none;
  }
  .tp-calendar-header p {
    color: black;
    font-weight: 800;
    font-size: 18px;
  }

  .tp-calendar-day.range-select,
  .tp-calendar-day.selected {
    background-color: ${Colors.black} !important;
    box-shadow: none;
    border-radius: 25px;
  }

  .tp-calendar-day:not(.range-select):hover {
    background-color: ${Colors.black} !important;
    color: ${Colors.white};
  }

  .tp-calendar-months {
    gap: 44px;
  }
  .tp-calendar-month {
    gap: 10px;
    height: 100%;
  }

  .tp-calendar-week-titles {
    color: #767676;
    font-size: 12px;
    font-weight: 500;
  }
  .tp-calendar-week-titles p {
    margin-left: 0;
    width: 50px;
  }

  .tp-calendar-week-titles {
    border-bottom: none;
  }
  .tp-calendar-week {
    gap: 18px;
    /* width: 332px; */
  }
  .tp-calendar-day {
    font-size: 14px;
    border-radius: 25px;
    width: 32px;
    height: 32px;
    margin-left: 0;
    margin-bottom: 0;
  }

  .tp-calendar-month-select div {
    background-color: #767676;
    @media (max-width: 768px) {
      background-color: ${Colors.black};
    }
  }
  @media (max-width: 768px) {
    left: 0;
    width: 90%;
    border-radius: 0;
    top: 80px;
    border-radius: 20px !important;
  }

  .range-select {
    @media (max-width: 768px) {
      background-color: ${Colors.black} !important;
    }
  }

  .tp-calendar-day {
    @media (max-width: 768px) {
      &:hover {
        background-color: ${Colors.black};
        color: ${Colors.white};
      }
    }
  }
`;

export const CalendarNavigationWrapper = styled.div`
  user-select: none;
  width: 200px;
`;

export const TagCategoryDayDiv = styled.div`
  height: 100%;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-width: 100%;
`;

export const TagCategoryContainer = styled.div`
  font-size: 12px;
  line-height: 16px;
  color: #000;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  white-space: pre-wrap;
`;

export const NavigationButtonsContainer = styled.div`
  margin-left: auto;
`;

export const BigCalendarContainer = styled.div<{ display?: string; isStaff?: boolean; isToday?: boolean }>`
  display: ${props => props.display || 'block'};
  width: 100%;
  height: 100%;
  .rbc-time-content {
    & > *:nth-child(odd) {
      background-color: #f7f7f7;
      border-bottom: #e4e4e4;
    }
    & > *:nth-child(even) {
      background-color: #ffffff;
      border-bottom: #e1e1e1;
    }
    & > *:first-child {
      background-color: #ffffff;
      border-bottom: #e1e1e1;
    }
  }
  .rbc-time-slot {
    height: 80px;
    color: #727272;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .rbc-time-header {
    height: 48px;
  }
  .rbc-timeslot-group {
    & > *:nth-child(even) {
      display: none;
    }
  }
  // .rbc-time-header .rbc-row (not:first-child){
  // display: none;}

  .rbc-allday-cell {
    display: none;
  }
  .rbc-today {
    background-color: transparent;
    color: ${Colors.primary};
  }
  .rbc-button-link {
    gap: 5px;
  }
  .rbc-time-header-gutter {
    span {
      color: ${Colors.black} !important;
    }
    cursor: pointer;
    /* @media (max-width: 768px) {
      display: none;
    } */
  }

  .rbc-event {
    background-color: transparent;
  }

  .rbc-day-slot .rbc-time-slot {
    border-top: none !important;
  }
  .rbc-current-time-indicator {
    background-color: ${Colors.black};
    stroke-width: 1px;
    stroke: #212121;
    height: 2.302px;
    flex-shrink: 0;
  }
  .rbc-time-header-content .rbc-time-header-cell .rbc-header:nth-child(odd) {
    background-color: #ffffff;
  }

  .rbc-time-header-content .rbc-time-header-cell .rbc-header:nth-child(even) {
    background-color: #f7f7f7;
  }
  //Staff
  ${props =>
    props.isStaff &&
    !props.selectedUser &&
    css`
      .rbc-day-slot {
        min-width: 299px;
        max-width: 299px;
        width: 299px;
      }
      .rbc-time-header-content {
        min-width: 300px;
        /* max-width: 300px; */
        width: 300px;
      }
    `}
  ${props =>
    props.selectedUser &&
    css`
      .rbc-row-resource {
        display: none;
      }
    `}  
  
  //Boarding
  
    .ec-events {
  }
  .ec-day {
    flex: 1;
    justify-content: center;
    align-items: center;
  }
  .ec-days {
    & > *:nth-child(odd) {
      background-color: #ffffff;
    }
    & > *:nth-child(even) {
      background-color: #f7f7f7;
    }
    height: 50px;
    flex-basis: 50px !important;
  }
  /* .ec-body {
    width: 100vw;
  } */
  .ec-main {
    flex: 1;
  }
  .ec-lines {
    .ec-line {
      flex: 1;
    }
  }
  .ec-time {
    overflow: visible !important;
    border-left: none !important;
  }
  .ec-events {
    height: 50px;
  }
  .ec-resource {
    flex-basis: 50px !important;
    span {
      color: ${Colors.black};
      font-size: 11px;
      font-weight: 800;
    }
    justify-content: center;
    align-items: center;
  }
  .ec-event-body {
    align-items: center;
  }
  article {
    height: 48px;
    color: ${Colors.black};
  }
  .ec-event {
    padding: 10px !important;
    border-radius: 4px;
  }

  .today {
    color: ${Colors.primary};
  }
  .today-number {
    font-size: 14px;
    font-weight: 600;
    border-radius: 50%;
    background-color: ${props => (props.isToday ? Colors.primary : 'transparent')};
    color: ${props => (props.isToday ? Colors.white : Colors.black)};
    width: 20px;
    height: 20px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: ${Colors.primary};
    color: ${Colors.white};
    padding: 12px;
  }
  .ec-sidebar {
    .ec-content {
      background-color: #f7f7f7 !important;
    }
  }
  .ec-timeline .ec-sidebar .ec-resource:last-child {
    flex-basis: unset !important;
    max-height: 50px !important;
  }
`;
export const MultiDayCalendar = styled.div``;
export const EventLabel = styled.span<{ fontWeight?: number; fontSize?: number; width?: string }>`
  overflow: hidden;
  color: #191919;
  text-overflow: ellipsis;
  font-weight: ${props => props.fontWeight || 600};
  font-size: ${props => props.fontSize || 14}px;
  display: block;
  white-space: nowrap;
`;

export const EventLabelsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 8px;
  border-radius: 3px;
`;
export const StaffContainer = styled.div`
  display: flex;
  color: ${Colors.black};
  height: 50px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  /* width: 300px; */
`;

export const ToolsContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
`;

export const ToolbarButton = styled.button<{ selected: boolean; isToday?: boolean }>`
  background-color: ${props => (props.selected ? '#DADADA' : props.isToday ? 'transparent' : '#F4F4F4')};
  color: ${Colors.black};
  border: none;
  padding: ${props => (props.isToday ? '0' : '4px 8px')};
  border-radius: 3px;
  cursor: pointer;
  font-weight: ${props => (props.isToday ? '800' : '600')};
  font-size: 14px;
  &:nth-child(even) {
    border-radius: 0;
  }
  &:first-child {
    border-radius: 3px 0 0 3px;
  }
  &:last-child {
    border-radius: 0 3px 3px 0;
  }
`;
