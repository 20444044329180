import React, { useRef } from 'react';
import ModalDialog from '../../../../components/Modal/ModalDialog';
import { ExtensionDivider, ExtensionPetCaseLabel, ExtensionWideColumn, ExtensionWideColumnInfo, FlexContainer } from '../../../../components/Pets/styled';
import { BranchNotesRef } from '../../../../components/Shared/BranchNotes/types';
import InfiniteList from '../../../../components/Shared/InfiniteList/InfiniteList';
import OptionDropdown from '../../../../components/Shared/Menus/OptionDropdown/OptionDropdown';
import { OPTION_DROPDOWN_MENU_BUTTON_TYPES, OPTION_DROPDOWN_TYPES } from '../../../../components/Shared/Menus/OptionDropdown/types';
import { CenteredLoader } from '../../../../components/Shared/Spinner';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import usePaginatedQuery from '../../../../hooks/usePaginatedQuery';
import { GetNotesByDaycareReportId } from '../../../../queries';
import { toReadableDate } from '../../../../utils/dates';
import { DeselectReportButton, MedicalCaseDetailsContainer } from '../Medical/styled';
import DaycareReportAction from './DaycareReportAction';
import DaycareReportNote from './DaycareReportNote';
import DaycareReportNoteAction, { NOTE_ACTION_TYPES } from './DaycareReportNoteAction';

enum OptionDropdownItems {
  EDIT_CASE = 'Edit Case',
  ADD_NOTE = 'Add Note',
  ADD_PRIVATE_NOTE = 'Add Private Note'
}

const DaycareDetails = ({
  innerRef,
  selectedReport,
  deselectReport,
  daycareReports,
  hideNotes,
  fullWidth = false,
  loading,
  autoOpenModal,
  onModalClose
}: {
  innerRef?: React.Ref<HTMLDivElement>;
  selectedReport: Record<string, any>;
  daycareReports: Record<string, any>;
  deselectReport?: () => void;
  hideNotes?: boolean;
  fullWidth?: boolean;
  loading?: boolean;
}) => {
  const { tablet } = useMediaQuery({ tablet: true });

  const [[hasMoreItems, setHasMoreItems], { data: { getNotesByDaycareReportId: notes = [] } = {}, loading: notesLoading, refetch, fetchMore }] = usePaginatedQuery<any>({
    query: GetNotesByDaycareReportId,
    limit: 2,
    otherVariables: { daycareReportId: [selectedReport?.id] },
    otherParams: { skip: !selectedReport?.id || hideNotes }
  });
  const branchNotesRef = useRef<BranchNotesRef>(null);

  const { addNote: addBranchNote } = branchNotesRef?.current || {};

  const sharedOptionDropdownItems = {
    [OptionDropdownItems.ADD_NOTE]: () =>
      ModalDialog.openModal({ content: () => <DaycareReportNoteAction type={NOTE_ACTION_TYPES.ADD} selectedReport={selectedReport} refetchNotes={refetch} autoOpenModal /> }),
    [OptionDropdownItems.ADD_PRIVATE_NOTE]: () => addBranchNote?.()
  };

  const optionDropdownItems = {
    [OptionDropdownItems.EDIT_CASE]: () => ModalDialog.openModal({ content: () => <DaycareReportAction selectedReport={selectedReport} type={NOTE_ACTION_TYPES.EDIT} autoOpenModal /> })
    // ...sharedOptionDropdownItems
  };

  return (
    <>
      <ExtensionWideColumn ref={innerRef} active={!!selectedReport} noTextOverFlow={!!hideNotes} fullWidth={fullWidth}>
        {!daycareReports.length && loading && <CenteredLoader size={70} />}
        {!!daycareReports.length && selectedReport && (
          <InfiniteList
            list={notes}
            itemRenderer={(note: Record<string, any>) => <DaycareReportNote key={note?.id} note={note} selectedReport={selectedReport} />}
            loading={notesLoading}
            hasMoreItems={hasMoreItems}
            fetchMore={fetchMore}
            refetch={refetch}
            offset={notes.length}
            setHasMoreItems={setHasMoreItems}
            emptyListProps={{ dimensions: { width: '100%', height: 'auto' } }}
            noEmptyList={hideNotes}
            ListHeader={
              <>
                <ExtensionPetCaseLabel fontSize={18} fontWeight={'800'} margingB={16}>
                  {tablet && <DeselectReportButton onClick={deselectReport}>{'<'}</DeselectReportButton>}
                  {selectedReport?.name}

                  {/* {!hideNotes && !tablet && <DaycareReportNoteAction type={NOTE_ACTION_TYPES.EDIT} refetchReports={refetch} autoOpenModal={autoOpenModal} onClose={onModalClose} />} */}

                  {tablet && (
                    <OptionDropdown
                      menuButtonType={OPTION_DROPDOWN_MENU_BUTTON_TYPES.MORE}
                      options={[
                        {
                          optionType: OPTION_DROPDOWN_TYPES.BUTTONS,
                          id: 'OPTIONS',
                          items: Object.keys(optionDropdownItems).map(key => ({
                            name: key,
                            value: key,
                            onClick: optionDropdownItems[key as OptionDropdownItems]
                          }))
                        }
                      ]}
                      noApplyButton
                    />
                  )}
                </ExtensionPetCaseLabel>

                <ExtensionWideColumnInfo>
                  {/* {tablet && (
                    <>
                      <FlexContainer>
                        <ExtensionPetCaseLabel fontSize={20} fontWeight={'900'} margingB={8}>
                          {selectedReport?.name}
                        </ExtensionPetCaseLabel>
                      </FlexContainer>
                      <ExtensionDivider />
                    </>
                  )} */}

                  <FlexContainer gap={8} column>
                    <FlexContainer>
                      <ExtensionPetCaseLabel fontSize={14} fontWeight="700">
                        Date:
                      </ExtensionPetCaseLabel>

                      <ExtensionPetCaseLabel fontSize={14} fontWeight="500">
                        {toReadableDate(selectedReport?.timestamp || new Date(), { isLocale: true })}
                      </ExtensionPetCaseLabel>
                    </FlexContainer>

                    <ExtensionDivider />

                    <FlexContainer>
                      <ExtensionPetCaseLabel fontSize={14} fontWeight="700">
                        Created By:
                      </ExtensionPetCaseLabel>
                      <ExtensionPetCaseLabel fontSize={14} fontWeight={'500'}>
                        {selectedReport?.Branch?.name}
                      </ExtensionPetCaseLabel>
                    </FlexContainer>

                    <ExtensionDivider />
                  </FlexContainer>

                  {selectedReport?.Appointment?.id && (
                    <FlexContainer>
                      <ExtensionPetCaseLabel fontSize={16} width={150} fontWeight={'bold'}>
                        Appointment:
                      </ExtensionPetCaseLabel>
                      <ExtensionPetCaseLabel fontSize={16} fontWeight={'500'}>
                        {selectedReport.Appointment?.OrderItem?.item?.name} - {toReadableDate(selectedReport.Appointment?.timestamp || new Date(), { isLocale: true })}
                      </ExtensionPetCaseLabel>
                    </FlexContainer>
                  )}
                </ExtensionWideColumnInfo>

                {/* <ExtensionDivider />
                <ExtensionPetCaseLabel margingB={24} fontSize={18} fontWeight={'800'} primary>
                  Description
                </ExtensionPetCaseLabel> */}

                {/* <Note>{selectedReport?.description}</Note> */}
                {/* <ExtensionDivider /> */}
                {/* {!hideNotes && (
                  <ExtensionPetCaseLabel margingB={24} fontSize={18} fontWeight={'800'} primary>
                    Notes
                    {!tablet && (
                      <OptionDropdown
                        menuButtonType={OPTION_DROPDOWN_MENU_BUTTON_TYPES.PLUS}
                        options={[
                          {
                            optionType: OPTION_DROPDOWN_TYPES.BUTTONS,
                            id: 'OPTIONS',
                            items: getKeys(sharedOptionDropdownItems).map(key => ({
                              name: key,
                              value: key,
                              onClick: sharedOptionDropdownItems[key]
                            }))
                          }
                        ]}
                        noApplyButton
                        containerRelative
                      />
                    )}
                  </ExtensionPetCaseLabel>
                )} */}

                {/* {selectedReport?.id && (
                  <BranchNotes
                    type={BranchNoteType.GROOMING_REPORT}
                    GroomingReportId={selectedReport?.id}
                    options={{
                      busUserName: true,
                      timestamp: true,
                      expandable: false,
                      hideHeader: true,
                      deletable: true,
                      bottomDivider: false,
                      privateTag: true,
                      html: true,
                      cancelButton: true
                    }}
                    ref={branchNotesRef}
                    styles={{
                      containerStyle: () => ({ padding: 0 }),
                      itemStyle: () => ({ backgroundColor: '#f0f0f0', marginTop: 10, borderRadius: 20, padding: 20, boxSizing: 'border-box', border: '2px solid #e0e0e0' })
                    }}
                  />
                )} */}
              </>
            }
          />
        )}

        {!selectedReport && !loading && (
          <MedicalCaseDetailsContainer>
            <ExtensionPetCaseLabel fontSize={22} fontWeight={'900'} margingB={8}>
              Please select a report to view details.
            </ExtensionPetCaseLabel>
          </MedicalCaseDetailsContainer>
        )}
      </ExtensionWideColumn>
    </>
  );
};

export default DaycareDetails;
