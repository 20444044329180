import { navigate } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { FC, forwardRef, useCallback, useImperativeHandle } from 'react';
import useIcons from '../../hooks/useIcons';
import useMediaQuery from '../../hooks/useMediaQuery';
import useWindowLocation from '../../hooks/useWindowLocation';
import { setProductId, toggleExtendDrawerBar, togglePinDrawerBar, unsetDrawerBar } from '../../reactive/actions';
import { DrawerBar } from '../../reactive/main';
import BranchFormModal from '../../views/Store/BranchForms/BranchFormModal';
import { BranchForm } from '../../views/Store/BranchForms/types';
import BranchMessageModal from '../../views/Store/BranchMessages/BranchMessageModal';
import { BranchMessage } from '../../views/Store/BranchMessages/BranchMessages';
import BranchTagsModal from '../../views/Store/BranchTags/BranchTagsModal';
import { BranchPetRecordTag } from '../../views/Store/BranchTags/types';
import ProductModal from '../../views/Store/Products/ProductModal';
import StoreProductModal from '../../views/Store/Operations/StoreProductModal/StoreProductModal';
import SubscriptionModal from '../../views/Store/StoreSubscriptions/StoreSubscriptionModal';
import ModalDialog from '../Modal/ModalDialog';
import { BackButton, DrawerActionButton } from './styled';
import { DRAWER_IDS } from './types';
import useURLSearchParams from '../../hooks/useURLSearchParams';

const handleDrawerClose = ({ drawer, location }: { drawer: DrawerBar; location: { pathname: string } }) => {
  const currentLocation = location.pathname.split('/');

  const nextLocation = currentLocation.slice(4).some(item => item === drawer.recordData)
    ? currentLocation.filter(item => item !== drawer.recordData).join('/')
    : currentLocation.filter(item => item !== currentLocation[4]).join('/');

  unsetDrawerBar(drawer?.drawerId);
  navigate(nextLocation, { replace: true });
};

export const EditBtn: FC<any> = props => {
  const icons = useIcons();
  const editIcon = icons.edit.childImageSharp.gatsbyImageData.images.fallback.src;
  return <DrawerActionButton bgImage={editIcon} isToggled={false} {...props} />;
};

export const ExtendButton = ({ drawer }: { drawer: DrawerBar }) => {
  const icons = useIcons();
  const extendIcon = icons.more.childImageSharp.gatsbyImageData.images.fallback.src;
  const { mobile } = useMediaQuery({ mobile: true });
  const handleExtending = () => {
    toggleExtendDrawerBar(drawer?.drawerId);
  };

  return mobile ? <></> : <DrawerActionButton bgImage={extendIcon} isToggled={drawer?.isExtended} onClick={() => handleExtending()} />;
};

export const PinButton = ({ drawer }: { drawer: DrawerBar }) => {
  const icons = useIcons();
  const pinIcon = icons.pin.childImageSharp.gatsbyImageData.images.fallback.src;
  const { mobile } = useMediaQuery({ mobile: true });
  const handlePinned = () => {
    togglePinDrawerBar(drawer?.drawerId);
  };
  return mobile ? <></> : <DrawerActionButton bgImage={pinIcon} isToggled={drawer?.isPinned} onClick={() => handlePinned()} />;
};

export const MobileCloseButton = ({ drawer, showAlways = false }: { drawer: DrawerBar; showAlways?: boolean }) => {
  const icons = useIcons();
  const { location } = useWindowLocation();
  const { mobile } = useMediaQuery({ mobile: true });

  const chevronIcon = icons.chevronLeft.childImageSharp.gatsbyImageData;

  if (mobile || showAlways) {
    return (
      <BackButton onClick={() => handleDrawerClose({ drawer, location })}>
        <GatsbyImage image={chevronIcon} alt="Back" />
      </BackButton>
    );
  }
  return <></>;
};

export const CloseButton = ({ drawer, showAlways = false }: { drawer: DrawerBar; showAlways?: boolean }) => {
  const icons = useIcons();
  const { location } = useWindowLocation();
  const { mobile } = useMediaQuery({ mobile: true });

  const closeIcon = icons.closeModal.childImageSharp.gatsbyImageData.images.fallback.src;

  if (!mobile || showAlways) {
    return <DrawerActionButton bgImage={closeIcon} onClick={() => handleDrawerClose({ drawer, location })} />;
  }
  return <></>;
};

export const StoreEditButton = forwardRef<{ handleClick: () => void }, { drawer: DrawerBar }>(({ drawer }, ref) => {
  const type = drawer.drawerId;
  const productType = type === DRAWER_IDS.PRODUCT_DRAWER;
  const serviceType = type === DRAWER_IDS.SERVICE_DRAWER;
  const subscriptionType = type === DRAWER_IDS.STORE_SUBSCRIPTION_DRAWER;
  const branchMessageType = type === DRAWER_IDS.BRANCH_MESSAGE_DRAWER;
  const branchFormType = type === DRAWER_IDS.BRANCH_FORM_DRAWER;
  const branchTagType = type === DRAWER_IDS.BRANCH_TAG_DRAWER;

  const title = productType
    ? 'Edit Product'
    : serviceType
    ? 'Edit Service'
    : subscriptionType
    ? 'Edit Membership'
    : branchMessageType
    ? 'Edit Branch Message'
    : branchTagType
    ? 'Edit Smart Tag'
    : branchFormType
    ? 'Edit Form'
    : '';

  const handleOnClose = () => {
    setProductId(null);
  };
  const handleEdit = () => {
    setProductId(drawer?.recordData);
  };

  const handleClick = useCallback(() => {
    ModalDialog.openModal({
      content: () => ModalContent,
      title,
      onClose: () => handleOnClose()
    });
  }, [title, handleOnClose]);

  useImperativeHandle(
    ref,
    () => ({
      handleClick
    }),
    [handleClick]
  );

  const ModalContent = productType ? (
    <StoreProductModal productId={drawer.recordData as string} type="product" />
  ) : serviceType ? (
    <StoreProductModal productId={drawer.recordData as string} type="service" />
  ) : subscriptionType ? (
    <StoreProductModal productId={drawer.recordData as string} type="subscription" />
  ) : branchMessageType ? (
    <BranchMessageModal branchMessage={drawer.recordData as BranchMessage} />
  ) : branchTagType ? (
    <BranchTagsModal branchTag={drawer.recordData as BranchPetRecordTag} />
  ) : branchFormType ? (
    <BranchFormModal branchForm={drawer.recordData as BranchForm} />
  ) : (
    <></>
  );

  return (
    <div onClick={() => handleEdit()} style={{ display: 'flex' }}>
      <EditBtn serviceId={drawer?.recordData} onClick={handleClick} />
    </div>
  );
});
