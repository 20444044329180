import React, { Fragment, useRef } from 'react';
import { sentenceCase } from 'sentence-case';
import { DRAWER_IDS } from '../../../../components/DrawerBar/types';
import { PetRecordOptionsContainer } from '../../../../components/Pets/styled';
import { setDrawerBar } from '../../../../reactive/actions';
import { RecordBody } from '../../../styled';
import BookingRecordOptions, { BookingRecordOptionsRef } from './BookingRecordOptions';

import { useReactiveVar } from '@apollo/client';
import { RedDot } from '../../../../components/Shared/Shared';
import { Divider } from '../../../../components/Shared/SideTabs/styles';
import { VerticalDivider } from '../../../../components/Shared/ToolBar/styles';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import { vars } from '../../../../reactive';
import { calculateNights, displayTime, findMonthName, findThreeLetterDayName } from '../../../../utils/dates';
import { BOOKING_TABS_VALUES_TYPES } from '../../types';
import { Booking, PetImg, PetRecordTag, PetRecordTags, PetRecordTagsAndTimeContainer, PetRecordTagsContainer, StaffMemberName } from '../BookingsBody/styled';
import { PetContainer } from '../ServiceHeader/styled';
import { PetAndInfo, PetBioExtraInfoContainer } from './styled';

const BookingRecord = ({ appointment, refetch, recordOptions, selectedTab }) => {
  const { mobile } = useMediaQuery({ mobile: true });
  const isMultiStaffView = useReactiveVar(vars.isMultiStaffView);
  const bookingRecordOptionsRef = useRef<BookingRecordOptionsRef>(null);
  const petContainerRef = useRef<HTMLDivElement>(null);
  const {
    id: appointmentId,
    PetRecord: { Pet: { id: petId, profile_pic, name, Breed } = {} } = {},
    BusUsers,
    timestamp,
    timestamp_until,
    BranchAppointmentTags,
    OrderItem: { Order: { id: orderId, payment, status: orderStatus } = {}, item: { name: serviceName, booking_type } = {} } = {}
  } = appointment;

  const { displayName, displayBreed, displayServiceName, displayBookingTime, dispalyBookingTags, dispalyOptionalDropDown, displayOrderStatus, displayStaffName } = recordOptions;

  const isConfirmed = selectedTab === BOOKING_TABS_VALUES_TYPES.CONFIRMED;

  const isNew = selectedTab === BOOKING_TABS_VALUES_TYPES.NEW;
  const isPayment = selectedTab === BOOKING_TABS_VALUES_TYPES.PAYMENT;
  const isUpcoming = selectedTab === BOOKING_TABS_VALUES_TYPES.UPCOMING;
  const isPaymentPending = orderStatus === 'PAYMENT_PENDING';
  const isPaymntRejected = orderStatus === 'PAYMENT_REJECTED';
  const isMultiDayBooking = booking_type === 'MULTI_DAY';

  const time = `${findThreeLetterDayName(new Date(timestamp).getDay())}, ${new Date(timestamp).getDate()} ${findMonthName(new Date(timestamp).getMonth())} at ${displayTime(
    new Date(timestamp).getUTCHours(),
    new Date(timestamp).getMinutes()
  )}`;

  const mobileTime = `${findThreeLetterDayName(new Date(timestamp).getDay())}, ${new Date(timestamp).getDate()} ${findMonthName(new Date(timestamp).getMonth())}`;
  const dailyBookingTime = `${displayTime(new Date(timestamp).getUTCHours(), new Date(timestamp).getMinutes())}`;

  const multiDayBookingTime = `${new Date(timestamp).getDate()} ${findMonthName(new Date(timestamp).getMonth())} to ${new Date(timestamp_until).getDate()} ${findMonthName(
    new Date(timestamp_until).getMonth()
  )} ${new Date(timestamp).getFullYear()} (${calculateNights(timestamp, timestamp_until)})`;

  const mobileMultiDayBookingTime = `${new Date(timestamp).getDate()} ${findMonthName(new Date(timestamp).getMonth())} - ${new Date(timestamp_until).getDate()} ${findMonthName(
    new Date(timestamp_until).getMonth()
  )} ${new Date(timestamp).getFullYear()} (${calculateNights(timestamp, timestamp_until)})`;

  const toggleMenu = () => {
    bookingRecordOptionsRef?.current?.getOptionDropdownRefOptions?.()?.toggleMenu?.();
  };

  const handleClick = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    const isTapToTagElement = e.target instanceof HTMLElement && e.target.innerText === 'Tap to tag this booking';

    if (isTapToTagElement) {
      toggleMenu();
    } else {
      if (bookingRecordOptionsRef?.current?.getContainerRefOptions?.()?.contains?.(e.target as Node)) {
        return;
      } else if (petContainerRef?.current?.contains?.(e.target as Node)) {
        setDrawerBar({
          drawerId: DRAWER_IDS.PETS_DRAWER,
          recordData: petId
        });
      } else
        setDrawerBar({
          drawerId: DRAWER_IDS.BOOKING_DRAWER,
          recordData: [appointment]
        });
    }
  };

  return (
    <Fragment key={appointmentId}>
      <Booking onClick={handleClick} mobilePadding="10px 8px">
        {isNew && !mobile && <RedDot />}
        <PetBioExtraInfoContainer flex={isPayment}>
          {!mobile && (
            <PetContainer ref={petContainerRef} width={200}>
              <PetImg src={profile_pic} alt={name} />
              <PetAndInfo>
                <RecordBody fontWeight="800" width="100" color="#212121">
                  {name}, <RecordBody width="80"> {Breed?.name}</RecordBody>
                </RecordBody>
              </PetAndInfo>
            </PetContainer>
          )}

          {mobile && (
            <PetContainer ref={petContainerRef}>
              <PetImg src={profile_pic} alt={name} />
              <PetAndInfo>
                {isNew && mobile && <RedDot />}
                <RecordBody mobileFlexDirection="column" mobileWidth="100%">
                  <RecordBody fontWeight="800" color="#212121" mobileWidth="100%">
                    {name},{' '}
                    <RecordBody width="80" mobileFontWeight={500} mobileWidth="100%">
                      {' '}
                      {Breed?.name}
                    </RecordBody>
                  </RecordBody>

                  <RecordBody mobileWidth="100%">
                    {displayServiceName && (
                      <RecordBody fontWeight="500" color="#212121" mobileWidth="100%">
                        {serviceName}
                      </RecordBody>
                    )}

                    {!isMultiDayBooking && displayBookingTime && !isConfirmed && (
                      <RecordBody fontWeight="800" noMargin marginLeft="auto">
                        {mobileTime}
                      </RecordBody>
                    )}

                    {displayBookingTime && isMultiDayBooking && !isConfirmed && (
                      <RecordBody fontWeight="800" width="210" noMargin color="#212121" marginLeft="auto">
                        {mobileMultiDayBookingTime}
                      </RecordBody>
                    )}
                  </RecordBody>
                </RecordBody>
              </PetAndInfo>
            </PetContainer>
          )}

          {displayServiceName && !mobile && (
            <RecordBody fontWeight="700" width="100" color="#212121" maxWidth={isPayment && 100}>
              {serviceName}
            </RecordBody>
          )}
          {displayBookingTime && !isConfirmed && !isMultiDayBooking && !mobile && (
            <RecordBody fontWeight="800" noMargin width={!isConfirmed ? '210' : '100'}>
              {time}
            </RecordBody>
          )}

          {displayBookingTime && isConfirmed && !isMultiDayBooking && (
            <RecordBody fontWeight="800" width="100" noMargin mobileMarginLeft="auto" mobileWidth="auto">
              {dailyBookingTime}
            </RecordBody>
          )}

          {displayBookingTime && isMultiDayBooking && !isConfirmed && !mobile && (
            <RecordBody fontWeight="800" width="210" noMargin color="#212121">
              {multiDayBookingTime}
            </RecordBody>
          )}

          {displayStaffName && !isMultiStaffView && !mobile && (
            <StaffMemberName displayNone={!isConfirmed}>
              <RecordBody fontWeight="600" width="80" noMargin>
                {BusUsers[0]?.name}
              </RecordBody>
            </StaffMemberName>
          )}
          {displayBookingTime && isMultiDayBooking && isConfirmed && (
            <RecordBody fontWeight="800" width="210" noMargin color="#212121">
              {multiDayBookingTime}
            </RecordBody>
          )}
          {displayOrderStatus && !mobile && (
            <RecordBody fontWeight="800" width="170" marginLeft="auto" flex="1" maxWidth={130} noMargin color={isPaymntRejected ? '#DA3636' : isPaymentPending ? '#eb5e00' : ''}>
              {payment?.fail_reason || sentenceCase(orderStatus)}
            </RecordBody>
          )}
        </PetBioExtraInfoContainer>

        {mobile && <Divider />}

        {!(isMultiDayBooking && isConfirmed) && dispalyBookingTags && dispalyOptionalDropDown && (
          <PetRecordTagsAndTimeContainer displayNone={!isConfirmed}>
            {dispalyBookingTags && !isMultiStaffView && (
              <PetRecordTagsContainer displayNone={!isConfirmed}>
                {!mobile && <VerticalDivider />}
                <PetRecordTags mediaWidth={!isConfirmed}>
                  {BranchAppointmentTags?.map(({ name, id, color }) => (
                    <PetRecordTag color={color} key={id}>
                      {name}
                    </PetRecordTag>
                  ))}
                </PetRecordTags>
              </PetRecordTagsContainer>
            )}

            {dispalyOptionalDropDown && (
              <PetRecordOptionsContainer>
                <BookingRecordOptions appointments={[appointment]} loadTags={false} refetch={refetch} ref={bookingRecordOptionsRef} showAlways={mobile} />
              </PetRecordOptionsContainer>
            )}
          </PetRecordTagsAndTimeContainer>
        )}
        {displayOrderStatus && mobile && (
          <RecordBody fontWeight="800" width="170" marginLeft="auto" flex="1" maxWidth={130} noMargin color={isPaymntRejected ? '#DA3636' : isPaymentPending ? '#eb5e00' : ''}>
            {payment?.fail_reason || sentenceCase(orderStatus)}
          </RecordBody>
        )}
      </Booking>
      {!mobile && <Divider />}
    </Fragment>
  );
};

export default BookingRecord;
