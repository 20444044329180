export enum CHAT_TABS_TYPES {
  CLIENTS = 'ALL',
  STAFF = 'STAFF',
  BROADCASTS = 'BROADCASTS',
  GROUPS = 'GROUPS'
}

export const CHAT_TABS_TYPES_LABELS = {
  CLIENTS: 'Clients',
  STAFF: 'Staff',
  BROADCASTS: 'Broadcasts',
  GROUPS: 'Groups'
};
