import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useCallback, useEffect } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import ReactSelect from '../../../components/Shared/Forms/Select';
import { DRAWER_IDS } from '../../../components/DrawerBar/types';
import ModalDialog from '../../../components/Modal/ModalDialog';
import { ModalBody, ModalFooter } from '../../../components/Modal/styled';
import { FormButtonsContainer, FormError, FormInput, FormLabel, FormSelect, FormSubmitButton, InputsWrapper, WideInputGroup, selectTheme } from '../../../components/Shared/Forms/Forms';
import { Container } from '../../../components/Shared/Shared';
import { CenteredLoader } from '../../../components/Shared/Spinner';
import { AddBranchForm, DeleteBranchForm, EditBranchForm, GetBusUserProfile, GetServicesAndSubscriptionsByBranchId, ReplaceFormQuestions } from '../../../queries';
import { vars } from '../../../reactive';
import { setDrawerBar, unsetDrawerBars } from '../../../reactive/actions';
import Common from '../Common';
import BranchFormQuestions from './BranchFormQuestions';
import { BRANCH_FORM_LEVELS, BRANCH_FORM_LEVELS_LABELS, BRANCH_FORM_TYPES, BRANCH_FORM_TYPES_LABELS, BranchForm } from './types';

type BranchFormModalFormProps = {
  loading: boolean;
  products: Record<string, any>[];
  branchForm?: BranchForm;
  formOptions: ReturnType<typeof useForm>;
  saveError: boolean;
  saveLoading: boolean;
  onSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
  deleteForm: () => void;
  defaultFormQuestions: BranchForm['Questions'];
};

const BranchFormModalForm = ({ loading, products, branchForm, formOptions, saveError, saveLoading, onSubmit, deleteForm, defaultFormQuestions }: BranchFormModalFormProps) => {
  const { control, errors } = formOptions;
  const types = Object.values(BRANCH_FORM_TYPES);
  const levels = Object.values(BRANCH_FORM_LEVELS);
  const selectedType = useWatch({ control, name: 'type', defaultValue: branchForm?.type || types[0] });
  const selectedLevel = useWatch({ control, name: 'level', defaultValue: branchForm?.level || levels[0] });

  const showLevel = selectedType === BRANCH_FORM_TYPES.ATTACHED;
  const showProduct = showLevel && selectedLevel !== BRANCH_FORM_LEVELS.BRANCH;
  return (
    <>
      <ModalBody maxWidth={500}>
        <Container>
          {loading && <CenteredLoader />}
          {!loading && (
            <InputsWrapper noWrap>
              <WideInputGroup>
                <FormLabel>Form Title</FormLabel>
                <Controller as={<FormInput error={errors.name} />} control={control} name={'name'} defaultValue={branchForm?.name || 'New Form'} rules={{ required: true }} />
                {errors.name && <FormError>{errors.name.message || 'Title is required'}</FormError>}
              </WideInputGroup>
              <WideInputGroup>
                <FormLabel>Form type</FormLabel>
                <Controller
                  render={({ onChange, value }) => (
                    <FormSelect
                      height={48}
                      fontSize={16}
                      name={'type'}
                      onChange={e => {
                        onChange(e.target.value);
                      }}
                      value={value || ''}
                    >
                      <option disabled value={''}>
                        -- select an option --
                      </option>
                      {types.map((type, index) => (
                        <option key={index} value={type}>
                          {BRANCH_FORM_TYPES_LABELS[type]}
                        </option>
                      ))}
                    </FormSelect>
                  )}
                  control={control}
                  name={'type'}
                  defaultValue={branchForm?.type || types[0]}
                  rules={{ required: true }}
                />
              </WideInputGroup>
              {showLevel && (
                <WideInputGroup>
                  <FormLabel>When would this form be filled?</FormLabel>
                  <Controller
                    render={({ onChange, value }) => (
                      <FormSelect
                        height={48}
                        fontSize={16}
                        name={'level'}
                        onChange={e => {
                          onChange(e.target.value);
                        }}
                        value={value || ''}
                      >
                        <option disabled value={''}>
                          -- select an option --
                        </option>
                        {levels.map((level, index) => (
                          <option key={index} value={level}>
                            {BRANCH_FORM_LEVELS_LABELS[level]}
                          </option>
                        ))}
                      </FormSelect>
                    )}
                    control={control}
                    name={'level'}
                    defaultValue={branchForm?.level || levels[0]}
                    rules={{ required: true }}
                  />
                </WideInputGroup>
              )}

              {showProduct && (
                <WideInputGroup>
                  <FormLabel>Services</FormLabel>
                  <Controller
                    render={({ onChange, value }) => (
                      <ReactSelect
                        isMulti
                        name={'productId'}
                        onChange={onChange}
                        value={value || ''}
                        options={products.map((product, index) => ({
                          value: product.id,
                          label: product.name
                        }))}
                        theme={selectTheme}
                      />
                    )}
                    control={control}
                    name={'productId'}
                    defaultValue={branchForm?.Products?.map(product => ({ value: product.id, label: product.name })) || []}
                    rules={{ required: true }}
                  />
                </WideInputGroup>
              )}
            </InputsWrapper>
          )}
        </Container>
        <BranchFormQuestions formOptions={formOptions} defaultValues={defaultFormQuestions} />
      </ModalBody>
      <ModalFooter>
        <FormButtonsContainer>
          <FormSubmitButton error={saveError} loading={saveLoading} onClick={onSubmit}>
            Save
          </FormSubmitButton>
          {branchForm && (
            <FormSubmitButton type="button" onClick={deleteForm} loading={saveLoading} error={!!saveError} secondary danger>
              Delete
            </FormSubmitButton>
          )}
        </FormButtonsContainer>
      </ModalFooter>
    </>
  );
};
const BranchFormModal = ({ branchForm }: { branchForm?: BranchForm }) => {
  const formOptions = useForm();
  const { handleSubmit, getValues } = formOptions;
  const [getBusUser] = useLazyQuery(GetBusUserProfile);
  const [getProducts, { data: { productGet: products = [] } = {}, loading: loadingProducts }] = useLazyQuery(GetServicesAndSubscriptionsByBranchId);

  const [saveBranchForm, { data: addedForm, loading: addLoading, error: addError }] = useMutation(AddBranchForm);

  const [editBranchForm, { data: editedForm, loading: editLoading, error: editError }] = useMutation(EditBranchForm);

  const [deleteBranchForm, { data: deletedForm, loading: deleteLoading, error: deleteError }] = useMutation(DeleteBranchForm);

  const [replaceFormQuestions, { data: replacedFormQuestions, loading: replaceLoading, error: replaceError }] = useMutation(ReplaceFormQuestions);

  const onSubmit = handleSubmit(data => {
    const { name, type, productId, level } = data;
    const variables = {
      name,
      type,
      ProductId: productId?.map((product: { value: string }) => product.value) || null,
      level: type === BRANCH_FORM_TYPES.DETACHED ? null : level || BRANCH_FORM_LEVELS.BRANCH
    };

    if (branchForm) {
      editBranchForm({
        variables: {
          id: branchForm.id,
          ...variables
        }
      });

      return;
    }

    saveBranchForm({ variables });
  });

  const loading = loadingProducts;
  const saveError = !!addError?.message || !!editError?.message || !!deleteError?.message || !!replaceError?.message;
  const saveLoading = addLoading || editLoading || deleteLoading || replaceLoading;
  const savedForm = addedForm?.addBranchForm || editedForm?.editBranchForm;
  const formDeleted = deletedForm?.formDelete;
  const formQuestionsReplaced = replacedFormQuestions?.replaceFormQuestions;

  const deleteForm = useCallback(() => {
    if (branchForm) {
      deleteBranchForm({
        variables: {
          id: branchForm.id
        }
      });
    }
  }, [branchForm]);

  const dismissModal = useCallback(() => {
    ModalDialog.closeModal();
    Common.get<() => Promise<void>>(`BranchForms.GetBranchForms.refetch`)?.();
    if (formDeleted) {
      unsetDrawerBars();
    }

    const drawerBar = vars.drawerBars().find(drawerBar => drawerBar.drawerId === DRAWER_IDS.BRANCH_FORM_DRAWER);
    if (drawerBar) {
      setDrawerBar({ drawerId: DRAWER_IDS.BRANCH_FORM_DRAWER, recordData: savedForm });
    }
  }, [formDeleted, savedForm]);

  useEffect(() => {
    if (branchForm?.id) {
      getProducts({ variables: { branchId: branchForm?.Branch?.id } });
    } else {
      getBusUser().then(({ data }) => {
        const branchId = data.getBusUserProfile?.Branch?.id;
        getProducts({ variables: { branchId } });
      });
    }
  }, [branchForm]);

  useEffect(() => {
    if (formDeleted) {
      dismissModal();
    }
    if (savedForm) {
      const categories = getValues().questionCategories as { category: string; questions: { id: string; name: string }[] }[];
      const questions = categories?.flatMap(questionCategory => questionCategory.questions.map(question => question));
      replaceFormQuestions({ variables: { FormId: branchForm?.id || savedForm?.id, QuestionId: questions } });
    }
  }, [savedForm, formDeleted]);

  useEffect(() => {
    if (formQuestionsReplaced) {
      dismissModal();
    }
  }, [formQuestionsReplaced]);

  const defaultFormQuestions = branchForm?.Questions || [];

  return (
    <BranchFormModalForm
      loading={loading}
      products={products}
      formOptions={formOptions}
      saveError={saveError}
      saveLoading={saveLoading}
      branchForm={branchForm}
      onSubmit={onSubmit}
      deleteForm={deleteForm}
      defaultFormQuestions={defaultFormQuestions}
    />
  );
};

export default BranchFormModal;
