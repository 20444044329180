import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import OptionDropdown from '../Menus/OptionDropdown/OptionDropdown';
import { OPTION_DROPDOWN_MENU_BUTTON_TYPES, OPTION_DROPDOWN_TYPES } from '../Menus/OptionDropdown/types';
import { FILTER_TYPES, FilterItem, MORE_OPTIONS_TYPES, SelectedMoreOptions } from './Filters';
import { FILTERS_ID, HIDDEN_FILTERS, SelectedFilter as SelectedFilterType } from './config';

type useFiltersProps<T extends FILTER_TYPES, U extends MORE_OPTIONS_TYPES> = {
  toCall: { called: boolean; get: () => void; id: FILTER_TYPES }[];
  getOptions: (
    watchedValues: Record<string, FILTER_TYPES[]>,
    selectedFilters: SelectedFilterType[]
  ) => {
    FILTER_TYPES: Record<T, string>;
    MORE_OPTIONS_TYPES: Record<U, string>;
    FILTER_TYPES_NAMES: Record<T, string>;
    FILTER_TYPES_UNITS: Record<T, string>;
    FILTER_TYPES_MORE_OPTIONS: Record<T, U[]>;
    MORE_OPTIONS_TITLES: Record<U, string>;
    MORE_OPTIONS_DROPDOWN_TYPES: Record<U, OPTION_DROPDOWN_TYPES>;
    MORE_OPTIONS_ITEMS: Record<U, { value: string | boolean | number; name: string }[] | []>;
  };
};

const useFilters = <T extends FILTER_TYPES = FILTER_TYPES, U extends MORE_OPTIONS_TYPES = MORE_OPTIONS_TYPES>({ toCall, getOptions }: useFiltersProps<T, U>) => {
  const selectedFiltersForm = useForm({
    mode: 'onTouched'
  });

  const [selectedFilters, setSelectedFilters] = useState<SelectedFilterType[]>([]);
  const REQUISITE_ID = 'REQUISITE';
  const { watch } = selectedFiltersForm;
  const watchedValues = watch() as Record<string, FILTER_TYPES[]>;
  const options = getOptions(watchedValues, selectedFilters);
  const filterItems = Object.values(options.FILTER_TYPES).map(filter => ({
    name: options.FILTER_TYPES_NAMES[filter as T],
    value: filter,
    unit: options.FILTER_TYPES_UNITS[filter as T],
    moreOptions: options.FILTER_TYPES_MORE_OPTIONS[filter as T].map(option => ({
      type: option,
      filterType: filter,
      id: `${filter}.${option}`,
      title: options.MORE_OPTIONS_TITLES[option] ? `${options.MORE_OPTIONS_TITLES[option]} Filter` : '',
      optionType: options.MORE_OPTIONS_DROPDOWN_TYPES[option],
      items: options.MORE_OPTIONS_ITEMS[option]
    }))
  })) as FilterItem[];

  useEffect(() => {
    if (!watchedValues?.[FILTERS_ID]?.length) {
      return;
    }

    toCall.forEach(({ called, get, id }) => {
      if (!called && watchedValues?.[FILTERS_ID]?.includes(id)) {
        get();
      }
    });
  }, [toCall.map(({ called }) => called), watchedValues]);

  const defaultFilters = {
    title: 'Filters',
    id: FILTERS_ID,
    optionType: OPTION_DROPDOWN_TYPES.CHECKBOX,
    items: filterItems.filter(({ value }) => !HIDDEN_FILTERS[value])
  };

  const selectedMoreOptionsState = useState<SelectedMoreOptions>(
    filterItems.map(({ value }) => ({ filterType: value, optionsValues: [{ optionValue: REQUISITE_ID, filterType: value, values: 'true' }] }))
  );

  const [selectedMoreOptions] = selectedMoreOptionsState;

  const filters = {
    ...defaultFilters,
    items: defaultFilters?.items.map(item => ({
      ...item,
      requisite: selectedMoreOptions.find(({ filterType }) => filterType === item.value)?.optionsValues.find(({ optionValue }) => optionValue === REQUISITE_ID)?.values || 'true'
    }))
  };

  const headerAction = filters.items.length ? (
    <OptionDropdown selectedItemsForm={selectedFiltersForm} options={[filters]} menuButtonType={OPTION_DROPDOWN_MENU_BUTTON_TYPES.FILTER} noApplyButton />
  ) : null;

  const selectedFiltersTypes: FILTER_TYPES[] = watchedValues?.[FILTERS_ID] || [];

  const selectedFiltersItems = filters.items.filter(item => selectedFiltersTypes.includes(item.value));

  return {
    watchedValues,
    selectedFiltersForm,
    REQUISITE_ID,
    setSelectedFilters,
    selectedFilters,
    headerAction,
    filters,
    defaultFilters,
    selectedFiltersItems,
    selectedMoreOptionsState,
    selectedFiltersTypes,
    filterItems,
    options
  };
};

export type FiltersControl<T extends FILTER_TYPES, U extends MORE_OPTIONS_TYPES> = ReturnType<typeof useFilters<T, U>>;

export default useFilters;
