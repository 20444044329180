import React from 'react';
import { sentenceCase } from 'sentence-case';
import { Divider } from '../../../components/Shared/SideTabs/styles';
import { getBranchCurrencySymbol } from '../../../utils/getBranchCurrencySymbol';
import { RecordBody, RecordDataContainer } from '../../styled';
import { Discount } from './types';
import { navigate } from 'gatsby';

const DiscountRecord = ({ discount }: { discount: Discount }) => {
  const handleClick = () => {
    navigate(`/app/operations/discounts/${discount.id}`);
  };

  return (
    <>
      <RecordDataContainer onClick={handleClick}>
        <RecordBody fontWeight={'800'}>{discount?.name}</RecordBody>
        <RecordBody>
          Discounts {discount?.type === 'amount' ? getBranchCurrencySymbol() : '%'}
          {discount?.amount}
        </RecordBody>
        <RecordBody>{sentenceCase(discount?.status)}</RecordBody>
      </RecordDataContainer>
      <Divider />
    </>
  );
};

export default DiscountRecord;
