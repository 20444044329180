import { OPTION_DROPDOWN_TYPES } from '../../components/Shared/Menus/OptionDropdown/types';

enum SUBSCRIPTIONS_FILTER_TYPES {
  SUBSCRIPTION_SEARCH = 'SUBSCRIPTION_SEARCH',
  SUBSCRIPTION_PRODUCT_NAME = 'SUBSCRIPTION_PRODUCT_NAME',
  SUBSCRIPTION_TRIAL_END = 'SUBSCRIPTION_TRIAL_END'
}
const HIDDEN_SUBSCRIPTIONS_FILTERS = {
  [SUBSCRIPTIONS_FILTER_TYPES.SUBSCRIPTION_SEARCH]: true,
  [SUBSCRIPTIONS_FILTER_TYPES.SUBSCRIPTION_PRODUCT_NAME]: false,
  [SUBSCRIPTIONS_FILTER_TYPES.SUBSCRIPTION_TRIAL_END]: false
};
const SUBSCRIPTIONS_FILTERS_QUERIES = {
  [SUBSCRIPTIONS_FILTER_TYPES.SUBSCRIPTION_SEARCH]: ['subscription_search'],
  [SUBSCRIPTIONS_FILTER_TYPES.SUBSCRIPTION_PRODUCT_NAME]: ['subscription_product_name'],
  [SUBSCRIPTIONS_FILTER_TYPES.SUBSCRIPTION_TRIAL_END]: ['subscription_trial_end_from', 'subscription_trial_end_to', 'subscription_trial_ended']
};
enum SUBSCRIPTIONS_MORE_OPTIONS_TYPES {
  SUBSCRIPTION_SEARCH = 'SUBSCRIPTION_SEARCH',
  SUBSCRIPTION_PRODUCT_NAME = 'SUBSCRIPTION_PRODUCT_NAME',
  SUBSCRIPTION_TRIAL_ACTIVE = 'SUBSCRIPTION_TRIAL_ACTIVE',
  SUBSCRIPTION_TRIAL_END_FROM = 'SUBSCRIPTION_TRIAL_END_FROM',
  SUBSCRIPTION_TRIAL_END_TO = 'SUBSCRIPTION_TRIAL_END_TO'
}

const SUBSCRIPTIONS_FILTER_TYPES_NAMES: Record<SUBSCRIPTIONS_FILTER_TYPES, string> = {
  [SUBSCRIPTIONS_FILTER_TYPES.SUBSCRIPTION_SEARCH]: 'Search',
  [SUBSCRIPTIONS_FILTER_TYPES.SUBSCRIPTION_PRODUCT_NAME]: 'Membership',
  [SUBSCRIPTIONS_FILTER_TYPES.SUBSCRIPTION_TRIAL_END]: 'Trial'
};

const SUBSCRIPTIONS_MORE_OPTIONS_TITLES: Record<SUBSCRIPTIONS_MORE_OPTIONS_TYPES, string> = {
  [SUBSCRIPTIONS_MORE_OPTIONS_TYPES.SUBSCRIPTION_SEARCH]: '',
  [SUBSCRIPTIONS_MORE_OPTIONS_TYPES.SUBSCRIPTION_PRODUCT_NAME]: 'Membership',
  [SUBSCRIPTIONS_MORE_OPTIONS_TYPES.SUBSCRIPTION_TRIAL_ACTIVE]: 'Trial Active',
  [SUBSCRIPTIONS_MORE_OPTIONS_TYPES.SUBSCRIPTION_TRIAL_END_FROM]: 'Trial active from',
  [SUBSCRIPTIONS_MORE_OPTIONS_TYPES.SUBSCRIPTION_TRIAL_END_TO]: 'Trial active to'
};

const SUBSCRIPTIONS_MORE_OPTIONS_DROPDOWN_TYPES: Record<SUBSCRIPTIONS_MORE_OPTIONS_TYPES, OPTION_DROPDOWN_TYPES> = {
  [SUBSCRIPTIONS_MORE_OPTIONS_TYPES.SUBSCRIPTION_SEARCH]: OPTION_DROPDOWN_TYPES.TEXT_INPUT,
  [SUBSCRIPTIONS_MORE_OPTIONS_TYPES.SUBSCRIPTION_PRODUCT_NAME]: OPTION_DROPDOWN_TYPES.MULTI_SELECT,
  [SUBSCRIPTIONS_MORE_OPTIONS_TYPES.SUBSCRIPTION_TRIAL_ACTIVE]: OPTION_DROPDOWN_TYPES.RADIO,
  [SUBSCRIPTIONS_MORE_OPTIONS_TYPES.SUBSCRIPTION_TRIAL_END_FROM]: OPTION_DROPDOWN_TYPES.DATE,
  [SUBSCRIPTIONS_MORE_OPTIONS_TYPES.SUBSCRIPTION_TRIAL_END_TO]: OPTION_DROPDOWN_TYPES.DATE
};

const SUBSCRIPTIONS_FILTER_TYPES_UNITS: Record<SUBSCRIPTIONS_FILTER_TYPES, string> = {
  [SUBSCRIPTIONS_FILTER_TYPES.SUBSCRIPTION_SEARCH]: '',
  [SUBSCRIPTIONS_FILTER_TYPES.SUBSCRIPTION_PRODUCT_NAME]: '',
  [SUBSCRIPTIONS_FILTER_TYPES.SUBSCRIPTION_TRIAL_END]: ''
};

export {
  HIDDEN_SUBSCRIPTIONS_FILTERS,
  SUBSCRIPTIONS_FILTERS_QUERIES,
  SUBSCRIPTIONS_FILTER_TYPES,
  SUBSCRIPTIONS_FILTER_TYPES_NAMES,
  SUBSCRIPTIONS_FILTER_TYPES_UNITS,
  SUBSCRIPTIONS_MORE_OPTIONS_DROPDOWN_TYPES,
  SUBSCRIPTIONS_MORE_OPTIONS_TITLES,
  SUBSCRIPTIONS_MORE_OPTIONS_TYPES
};
