import React, { FC, useCallback } from 'react';
import { DrawerBar as DrawerBarType } from '../../reactive/main';
import { CurrentDrawerContainer, DrawerActionButtonContainer, DrawerAnimationGroupContainer, DrawerBackground } from './styled';
import { DRAWER_BARS_BUTTONS, DRAWER_BARS_COMPONENTS } from './types';
import { useWindowSize } from '../../hooks/useWindowResize';
import { vars } from '../../reactive';
import { clearDrawerBars, unsetDrawerBar } from '../../reactive/actions';
import useMediaQuery from '../../hooks/useMediaQuery';

type DrawerBarWrapperProps = {
  drawer: DrawerBarType;
};

const DrawerBarWrapper: FC<DrawerBarWrapperProps> = ({ drawer }) => {
  const { mobile } = useMediaQuery({ mobile: true });
  const DrawerBarComponent = DRAWER_BARS_COMPONENTS[drawer?.drawerId];
  const DrawerBarButtons = DRAWER_BARS_BUTTONS[drawer?.drawerId];
  const isFloating = drawer?.isFloating;
  const { height } = useWindowSize();
  const drawerBars = vars.drawerBars();
  const isExtended = !!drawer?.isExtended;
  const drawerIndex = drawerBars.findIndex(({ drawerId }) => drawerId === drawer?.drawerId);
  const nonFloatingDrawerBars = drawerBars.filter(drawer => !drawer?.isFloating);
  const drawerCount = nonFloatingDrawerBars.length;
  const hasExtended = !!drawerBars.some(drawer => drawer?.isExtended);
  const noOpacityBackground = drawerIndex === 1 && hasExtended;
  const showBackground = drawerIndex === 0 || noOpacityBackground;
  const handleBackground = useCallback(() => {
    if (hasExtended) {
      unsetDrawerBar(drawer.drawerId);
      return;
    }

    clearDrawerBars();
  }, [hasExtended, drawer.drawerId]);

  return (
    <>
      {showBackground && <DrawerBackground isExtended={isExtended} onClick={handleBackground} hide={(noOpacityBackground && !isFloating) || mobile} />}
      <CurrentDrawerContainer isExtended={isExtended} drawerCount={drawerCount} isFloating={isFloating} drawerWidths={drawer?.drawerWidths}>
        {/* <DrawerAnimationGroupContainer windowHeight={height}> */}
        <DrawerBarComponent data={drawer.recordData} />
        <DrawerActionButtonContainer>
          <DrawerBarButtons drawer={drawer} />
        </DrawerActionButtonContainer>
        {/* </DrawerAnimationGroupContainer> */}
      </CurrentDrawerContainer>
    </>
  );
};

export default DrawerBarWrapper;
