import { useQuery } from '@apollo/client';
import { useLocation } from '@reach/router';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { FC, Fragment, useState } from 'react';
import { GetBranchUnreadItemsCountReturn } from '../../../hooks/useBranchUnreadItemsCount';
import useIcons from '../../../hooks/useIcons';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { GetBranchUnreadItemsCount } from '../../../queries';
import { NOTIFICATION_COUNT_TYPES } from './notificationCountTypes';
import { ActionButtonsContainer, Count, Divider, NotificationDotIconContainer, SideTabButton, SideTabContainer, SideTabLink, TabName } from './styles';

type Tab = {
  name: string;
  value: string;
  onClick?: (tabValue: string) => void;
  divider?: boolean;
};
export type Section = {
  tabs: Tab[];
};
type SideTabsProps = {
  sections: Section[];
  type?: SIDE_TABS_TYPES;
  count?: boolean;
  tabsWithCount?: string[];
  defaultTab?: string;
  notificationType?: string;
  tabsState?: [string, (tab: string) => void];
  actionButtons?: { url: string; onClickBtn: () => void; id: string }[];
  components?: FC[];
  isModal?: boolean;
  onClickTab?: (tabValue: string) => Promise<boolean> | boolean;
};

type StateSideTabsProps = {
  sections: Section[];
  count: boolean;
  tabsToDisplayWithCount: string[];
  unreadOrdersCount: number;
  countToReturn: number | undefined;
  defaultTab: string;
  tabsState?: [string, (tab: string) => void];
  actionButtons?: { url: string; onClickBtn: () => void; id: string }[];
  onClickTab?: (tabValue: string) => Promise<boolean> | boolean;
};

export enum SIDE_TABS_TYPES {
  LOCATION_BUTTONS = 'LOCATION_BUTTONS',
  STATE_BUTTONS = 'STATE_BUTTONS'
}

const LocationSideTabs: FC<StateSideTabsProps> = ({ sections, countToReturn, unreadOrdersCount, count, tabsToDisplayWithCount }) => {
  const { mobile } = useMediaQuery({ mobile: true });
  const location = useLocation();
  const icons = useIcons();
  const absolutePath = location.pathname.split('/').slice(0, 3).join('/');
  const notificationDotIcon = icons.notificationDot?.childImageSharp.gatsbyImageData;
  let currentTab = location.pathname.split('/')[3];

  return (
    <>
      {sections.map((section, index) => {
        return (
          <Fragment key={index}>
            {section.tabs.map(({ name, value }) => {
              const isSelected = currentTab === value;
              {
                if (count && tabsToDisplayWithCount.includes(name)) {
                  return (
                    <SideTabLink to={`${absolutePath}/${value}${location.search}`} key={value} isSelected={isSelected}>
                      {unreadOrdersCount?.getBranchUnreadItemsCount && countToReturn !== 0 && (
                        <NotificationDotIconContainer>
                          <GatsbyImage image={notificationDotIcon} alt="notificationDotIcon" />
                        </NotificationDotIconContainer>
                      )}
                      {countToReturn === 0 && (
                        <>
                          <div style={{ height: '7px', width: '7px' }} />
                          <TabName>{name}</TabName>
                        </>
                      )}
                      {countToReturn !== 0 && (
                        <>
                          <TabName>{name}</TabName>
                          <Count>{countToReturn}</Count>
                        </>
                      )}
                    </SideTabLink>
                  );
                } else {
                  return (
                    <SideTabLink to={`${absolutePath}/${value}${location.search}`} key={value} isSelected={isSelected}>
                      <div style={{ height: '7px', width: '7px' }} />
                      <TabName>{name}</TabName>
                    </SideTabLink>
                  );
                }
              }
            })}
            {index < sections.length - 1 && !mobile ? <Divider /> : null}
          </Fragment>
        );
      })}
    </>
  );
};

const StateSideTabs: FC<StateSideTabsProps> = ({ sections, count, tabsToDisplayWithCount, unreadOrdersCount, countToReturn, defaultTab, tabsState, onClickTab }) => {
  const { mobile } = useMediaQuery({ mobile: true });
  const [activeTab, setActiveTab] = tabsState || useState(defaultTab?.toLowerCase());
  const icons = useIcons();
  const notificationDotIcon = icons.notificationDot?.childImageSharp.gatsbyImageData;

  return (
    <>
      {sections.map((section, index) => {
        return (
          <Fragment key={index}>
            {section.tabs.map(({ name, value, onClick, divider }) => {
              const isSelected = activeTab === value;
              {
                if (count && tabsToDisplayWithCount.includes(name)) {
                  return (
                    <SideTabButton
                      key={value}
                      isSelected={isSelected}
                      onClick={async () => {
                        if (onClickTab && !(await onClickTab(value))) {
                          return;
                        }
                        setActiveTab(value);
                        onClick?.(value);
                      }}
                    >
                      {unreadOrdersCount?.getBranchUnreadItemsCount && countToReturn !== 0 && (
                        <NotificationDotIconContainer>
                          <GatsbyImage image={notificationDotIcon} alt="notificationDotIcon" />
                        </NotificationDotIconContainer>
                      )}

                      {countToReturn === 0 && (
                        <>
                          <div style={{ height: '7px', width: '7px' }} />
                          <TabName>{name}</TabName>
                          {divider && !mobile && <Divider />}
                        </>
                      )}
                      {countToReturn !== 0 && (
                        <>
                          <TabName>{name}</TabName>
                          <Count>{countToReturn}</Count>
                          {divider && !mobile && <Divider />}
                        </>
                      )}
                    </SideTabButton>
                  );
                } else {
                  return (
                    <SideTabButton
                      key={value}
                      isSelected={isSelected}
                      onClick={async () => {
                        if (onClickTab && !(await onClickTab(value))) {
                          return;
                        }
                        setActiveTab(value);
                        onClick?.(value);
                      }}
                    >
                      <div style={{ height: '7px', width: '7px' }} />
                      <TabName>{name}</TabName>
                    </SideTabButton>
                  );
                }
              }
            })}
            {index < sections.length - 1 && !mobile ? <Divider /> : null}
          </Fragment>
        );
      })}
    </>
  );
};

const SideTab: FC<SideTabsProps> = ({
  sections,
  count = false,
  tabsWithCount = [],
  defaultTab = '',
  notificationType = '',
  type = SIDE_TABS_TYPES.LOCATION_BUTTONS,
  tabsState,
  actionButtons,
  components,
  onClickTab,
  isModal
}) => {
  const { mobile } = useMediaQuery({ mobile: true });
  const { data: unreadOrdersCount } = useQuery<{ getBranchUnreadItemsCount: GetBranchUnreadItemsCountReturn }>(GetBranchUnreadItemsCount, {
    fetchPolicy: 'cache-only',
    notifyOnNetworkStatusChange: true
  });
  const { unreadServiceOrdersCount } = unreadOrdersCount?.getBranchUnreadItemsCount || {};

  const { unreadChatRoomsCount } = unreadOrdersCount?.getBranchUnreadItemsCount || {};

  const { unreadProductOrdersCount } = unreadOrdersCount?.getBranchUnreadItemsCount || {};

  const countToReturn =
    notificationType === NOTIFICATION_COUNT_TYPES.BOOKING
      ? unreadServiceOrdersCount
      : notificationType === NOTIFICATION_COUNT_TYPES.MESSAGE
      ? unreadChatRoomsCount
      : notificationType === NOTIFICATION_COUNT_TYPES.ORDER
      ? unreadProductOrdersCount
      : 0;

  let currentTab = location.pathname.split('/')[3];
  const tabs = sections.flatMap(section => section.tabs.map(tab => tab));
  const tabsValues = sections.flatMap(section => section.tabs.map(tab => tab.value));
  const tabsNames = sections.flatMap(section => section.tabs.map(tab => tab.name));

  const tabsToDisplayWithCount = tabsNames.filter(tabName => tabsWithCount.includes(tabName));
  const isTab = tabsValues.includes(currentTab);
  if (!isTab) {
    currentTab = defaultTab?.toLowerCase();
  }

  return (
    <SideTabContainer isModal={isModal}>
      {type === SIDE_TABS_TYPES.LOCATION_BUTTONS && (
        <LocationSideTabs sections={sections} countToReturn={countToReturn} unreadOrdersCount={unreadOrdersCount} count={count} tabsToDisplayWithCount={tabsToDisplayWithCount} />
      )}
      {type === SIDE_TABS_TYPES.STATE_BUTTONS && (
        <StateSideTabs
          count={count}
          countToReturn={countToReturn}
          sections={sections}
          tabsToDisplayWithCount={tabsToDisplayWithCount}
          unreadOrdersCount={unreadOrdersCount}
          defaultTab={defaultTab}
          tabsState={tabsState}
          onClickTab={onClickTab}
        />
      )}

      {mobile && (
        <ActionButtonsContainer>
          {components?.map((component, index) => {
            const Component = component;
            return <Component key={index} />;
          })}
        </ActionButtonsContainer>
      )}
    </SideTabContainer>
  );
};

export default SideTab;
