import { useMutation } from '@apollo/client';
import React, { FC } from 'react';
import { DRAWER_IDS } from '../../components/DrawerBar/types';
import { RedDot } from '../../components/Shared/Shared';
import { Divider } from '../../components/Shared/SideTabs/styles';
import useIcons from '../../hooks/useIcons';
import { MarkOrderSubscriptionAsReadByBranch } from '../../queries';
import { setDrawerBar } from '../../reactive/actions';
import { AppUserImage, AppUserImageContainer, AppUserImageNameContainer, RecordBody } from '../styled';
import { Subscription } from './types';
import { getSubscriptionTitles } from './utils';
import { Booking } from '../Bookings/components/BookingsBody/styled';

type SubscriptionRecordProps = {
  subscription: Subscription;
  showTotal?: {
    orderTotal?: boolean;
    dateFilter?: { from: string; to: string };
  };
};

const SubscriptionRecord: FC<SubscriptionRecordProps> = ({ subscription, showTotal }) => {
  const icons = useIcons();
  const [markAsRead] = useMutation(MarkOrderSubscriptionAsReadByBranch);

  const handleClick = () => {
    if (!subscription.is_read_by_branch) {
      markAsRead({ variables: { id: subscription.id } });
    }

    setDrawerBar({ drawerId: DRAWER_IDS.SUBSCRIPTION_DRAWER, recordData: subscription });
  };

  const { subscriptionName, petsNames, user, lastRepeatTotal, orderTotal, activeText } = getSubscriptionTitles({ subscription, repeatsFilter: showTotal?.dateFilter });

  const isNew = !subscription.is_read_by_branch;

  return (
    <>
      <Booking onClick={handleClick} gap={16}>
        <AppUserImageNameContainer>
          {isNew && <RedDot />}
          <AppUserImageContainer>
            <AppUserImage src={user?.profile_pic || icons.user.childImageSharp.gatsbyImageData.images.fallback.src} />
          </AppUserImageContainer>
          <RecordBody fontWeight="800" width="200" noMargin>
            {user?.name}
          </RecordBody>
        </AppUserImageNameContainer>

        <RecordBody flex="1 1 0%" width="250px" maxWidth={250}>
          {subscriptionName}
        </RecordBody>

        {/* <RecordBody>{petsNames}</RecordBody> */}
        {showTotal && (
          <RecordBody flex="1 1 0%" width="100px" maxWidth={100} fontWeight="800">
            {showTotal.orderTotal ? orderTotal : lastRepeatTotal}
          </RecordBody>
        )}
        <RecordBody width="100px" flex="1 1 0%" maxWidth={100} marginLeftAuto noMargin>
          {activeText}
        </RecordBody>
      </Booking>
      <Divider />
    </>
  );
};

export default SubscriptionRecord;
