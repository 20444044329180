import React from 'react';
import ToolBar from '../../../components/Shared/ToolBar/ToolBar';
import useURLSearchParams from '../../../hooks/useURLSearchParams';
import BranchAppUserTags from './BranchAppUserTags';
import BranchAppointmentTags from './BranchAppointmentTags';
import BranchPetRecordTags from './BranchPetRecordTags';
import BranchTagsAll from './BranchTagsAll';
import { BranchTagsHeaderContainer } from './styled';
import { BRANCH_TAGS_TABS_TYPES } from './types';

const BranchTags = () => {
  const toolBarButtons = [
    {
      name: BRANCH_TAGS_TABS_TYPES.PETS,
      id: BRANCH_TAGS_TABS_TYPES.PETS,
      component: BranchPetRecordTags
    },
    {
      name: BRANCH_TAGS_TABS_TYPES.BOOKINGS,
      id: BRANCH_TAGS_TABS_TYPES.BOOKINGS,
      component: BranchAppointmentTags
    },
    {
      name: BRANCH_TAGS_TABS_TYPES.APP_USERS,
      id: BRANCH_TAGS_TABS_TYPES.APP_USERS,
      component: BranchAppUserTags
    }
  ];

  const [ids] = useURLSearchParams('status');
  let TagsToReturn;
  const tagsStatus = ['PETS', 'BOOKINGS', 'USERS'];
  const selectedTagsStatuses = [...(ids || [])].flat()?.filter(id => tagsStatus?.includes(id));

  switch (true) {
    case selectedTagsStatuses?.includes('PETS'):
      TagsToReturn = <BranchPetRecordTags />;

      break;

    case selectedTagsStatuses?.includes('BOOKINGS'):
      TagsToReturn = <BranchAppointmentTags />;

      break;
    case selectedTagsStatuses?.includes('USERS'):
      TagsToReturn = <BranchAppUserTags />;
      break;

    default:
      TagsToReturn = <BranchTagsAll />;
      break;
  }

  return (
    <>
      <BranchTagsHeaderContainer>
        <ToolBar toolBarButtons={toolBarButtons} urlSearchParam="status" />
      </BranchTagsHeaderContainer>
      {TagsToReturn}
    </>
  );
};

export default BranchTags;
