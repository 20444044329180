import React from 'react';
import { useBranchSchedulesFilters } from './BranchSchedulesFilters';
import BranchSchedulesBody from './BranchSchedulesBody';
import BranchSchedulesHeader from './BranchSchedulesHeader';

const BranchSchedules = () => {
  const filtersControl = useBranchSchedulesFilters();

  const { selectedFilters } = filtersControl;

  return (
    <>
      <BranchSchedulesHeader filtersControl={filtersControl} />
      <BranchSchedulesBody selectedFilters={selectedFilters} />
    </>
  );
};

export default BranchSchedules;
