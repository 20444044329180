import { useQuery } from '@apollo/client';
import React, { FC, useEffect } from 'react';
import ModalDialog from '../../../../components/Modal/ModalDialog';
import { ExtensionColumn, ExtensionColumnContent, ExtensionColumnHeader, ExtensionPetCaseLabel } from '../../../../components/Pets/styled';
import InfiniteList from '../../../../components/Shared/InfiniteList/InfiniteList';
import { CenteredLoader } from '../../../../components/Shared/Spinner';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import { GetBranchPetProfile } from '../../../../queries';
import { AddNewMedicalCase } from './CaseModal';
import DownloadMedicalReports from './DownloadMedicalReports';
import MedicalCase from './MedicalCase';

type MedicalCasesProps = {
  petId: string;
  cases: [];
  setSelectedCaseId: (caseId: string) => void;
  loading: boolean;
  setHasMoreItems: React.Dispatch<React.SetStateAction<boolean>>;
  fetchMore: ReturnType<typeof useQuery>['fetchMore'];
  hasMoreItems: boolean;
  selectedCaseId: string;
  autoOpenModal: boolean;
  onModalClose: () => void;
  renderHeader: () => JSX.Element;
};

enum OptionDropdownItems {
  DOWNLOAD_REPORTS = 'Download Reports',
  ADD_MEDCOND = 'Add Medical Report'
}

const MedicalCases: FC<MedicalCasesProps> = ({ petId, cases, setSelectedCaseId, loading, setHasMoreItems, fetchMore, hasMoreItems, selectedCaseId, autoOpenModal, onModalClose, renderHeader }) => {
  const { data: { getBranchPetProfile: petProfile = {} } = {} } = useQuery(GetBranchPetProfile, {
    variables: { id: petId },
    fetchPolicy: 'cache-and-network'
  });
  const { tablet } = useMediaQuery({ tablet: true });

  const optionDropdownItems = {
    [OptionDropdownItems.ADD_MEDCOND]: () => ModalDialog.openModal({ content: () => <AddNewMedicalCase petId={petId} petRecordId={petRecordId} autoOpenModal onClose={onModalClose} /> }),
    [OptionDropdownItems.DOWNLOAD_REPORTS]: () => ModalDialog.openModal({ content: () => <DownloadMedicalReports pet={petProfile} petRecordId={petRecordId} />, title: 'Download Reports' })
  };

  useEffect(() => {
    if (autoOpenModal) {
      optionDropdownItems[OptionDropdownItems.ADD_MEDCOND]();
    }
  }, [autoOpenModal]);

  const petRecordId = petProfile?.PetRecord?.id;
  return (
    <ExtensionColumn>
      {/* <ExtensionColumnHeader>
        Reports
        {!tablet && (
          <OptionDropdown
            menuButtonType={OPTION_DROPDOWN_MENU_BUTTON_TYPES.MORE}
            buttonLoading={loading}
            options={[
              {
                optionType: OPTION_DROPDOWN_TYPES.BUTTONS,
                id: 'options',
                items: getKeys(optionDropdownItems).map(key => ({
                  name: key,
                  value: key,
                  onClick: optionDropdownItems[key]
                }))
              }
            ]}
            noApplyButton
          />
        )}
        {tablet && <AddNewMedicalCase petId={petId} petRecordId={petRecordId} autoOpenModal={autoOpenModal} onClose={onModalClose} />}
      </ExtensionColumnHeader> */}
      <ExtensionColumnHeader>{renderHeader()}</ExtensionColumnHeader>

      <ExtensionColumnContent>
        {tablet && !cases?.length && loading && <CenteredLoader />}
        {!!cases?.length && (
          <InfiniteList
            mobilePadding="8px 16px"
            list={cases}
            loading={loading}
            offset={cases?.length}
            setHasMoreItems={setHasMoreItems}
            fetchMore={fetchMore}
            hasMoreItems={hasMoreItems}
            itemRenderer={(medicalCase: Record<string, any>, index: number) => (
              <MedicalCase key={index} medicalCase={medicalCase} onClick={() => setSelectedCaseId(medicalCase.id)} selected={selectedCaseId === medicalCase.id} />
            )}
            listContainerStyles={tablet ? { width: '100%' } : {}}
          />
        )}

        {!cases?.length && !loading && (
          <ExtensionPetCaseLabel fontSize={16} fontWeight={'700'}>
            There are no recorded cases
          </ExtensionPetCaseLabel>
        )}
      </ExtensionColumnContent>
    </ExtensionColumn>
  );
};

export default MedicalCases;
