import { useMutation } from '@apollo/client';
import React, { FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ModalDialog from '../../../components/Modal/ModalDialog';
import { ModalBody, ModalFooter } from '../../../components/Modal/styled';
import { Pet } from '../../../components/Pets/types';
import { FormButtonsContainer, FormError, FormInput, FormLabel, FormSubmitButton, InputsWrapper, WideInputGroup } from '../../../components/Shared/Forms/Forms';
import { Container } from '../../../components/Shared/Shared';
import { EditBranchPet, EditPetRecord } from '../../../queries';

type HealthPetRecordModalFormProps = {
  onSubmit: () => void;
  pet: Pet;
  loading: boolean;
  error: boolean;
  formOptions: ReturnType<typeof useForm>;
};

const HealthPetRecordStateModalForm: FC<HealthPetRecordModalFormProps> = ({ loading, error, pet, formOptions, onSubmit }) => {
  const { control, errors, setValue } = formOptions;

  const currentNeck: string = pet.PetRecord?.measurements?.necks?.slice(-1)?.[0]?.neck;
  const currentBack: string = pet.PetRecord?.measurements?.backs?.slice(-1)?.[0]?.back;
  const currentChest: string = pet.PetRecord?.measurements?.chests?.slice(-1)?.[0]?.chest;
  const height: string = pet?.PetRecord?.measurements?.heights?.slice(-1)?.[0]?.height?.toFixed(1);

  return (
    <>
      <ModalBody>
        <Container>
          <InputsWrapper noWrap>
            <WideInputGroup>
              <FormLabel>Height</FormLabel>
              <Controller
                as={<FormInput error={errors.height} type={'number'} flexBasis={78} />}
                control={control}
                name={'height'}
                defaultValue={height || 0}
                rules={{
                  min: 0,
                  max: 999
                }}
              />
              {errors.height && <FormError>{errors.height.message || 'height is required'}</FormError>}
            </WideInputGroup>
          </InputsWrapper>

          <InputsWrapper noWrap>
            <WideInputGroup>
              <FormLabel>Neck</FormLabel>
              <Controller
                as={<FormInput error={errors.neck} type={'number'} flexBasis={78} />}
                control={control}
                name={'neck'}
                defaultValue={currentNeck || 0}
                rules={{
                  min: 0,
                  max: 999
                }}
              />
              {errors.neck && <FormError>{errors.neck.message || 'Neck is required'}</FormError>}
            </WideInputGroup>
          </InputsWrapper>
          <InputsWrapper noWrap>
            <WideInputGroup>
              <FormLabel>Back</FormLabel>
              <Controller
                as={<FormInput error={errors.back} type={'number'} flexBasis={78} />}
                control={control}
                name={'back'}
                defaultValue={currentBack || 0}
                rules={{
                  min: 0,
                  max: 999
                }}
              />
              {errors.back && <FormError>{errors.back.message || 'Back is required'}</FormError>}
            </WideInputGroup>
          </InputsWrapper>
          <InputsWrapper noWrap>
            <WideInputGroup>
              <FormLabel>Chest</FormLabel>
              <Controller
                as={<FormInput error={errors.chest} type={'number'} flexBasis={78} />}
                control={control}
                name={'chest'}
                defaultValue={currentChest || 0}
                rules={{
                  min: 0,
                  max: 999
                }}
              />
              {errors.chest && <FormError>{errors.chest.message || 'Chest is required'}</FormError>}
            </WideInputGroup>
          </InputsWrapper>

          <InputsWrapper noWrap>
            <WideInputGroup>
              <FormLabel>Microchip Provider</FormLabel>
              <Controller
                as={<FormInput error={errors.microchip_provider} type={'text'} flexBasis={78} />}
                control={control}
                name={'microchip_provider'}
                defaultValue={pet?.PetRecord?.microchip_provider || ''}
                rules={{ required: false }}
              />
              {errors.microchip_provider && <FormError>{errors.microchip_provider.message || 'microchip provider is required'}</FormError>}
            </WideInputGroup>
          </InputsWrapper>
        </Container>
      </ModalBody>
      <ModalFooter>
        <FormButtonsContainer>
          <FormSubmitButton error={error} loading={loading} onClick={onSubmit}>
            Update
          </FormSubmitButton>
        </FormButtonsContainer>
      </ModalFooter>
    </>
  );
};

const HealthPetRecordStateModal = ({ pet }: { pet: Pet }) => {
  const formOptions = useForm();
  const { handleSubmit } = formOptions;

  const [handleEditPetRecord, { data: editedPetRecord, loading: loadingEditPetRecord, error: errorEditPetRecord }] = useMutation(EditPetRecord);

  const [handleEditPet, { data: editedPet, loading: loadingEditPet, error: errorEditPet }] = useMutation(EditBranchPet);

  const onSubmit = handleSubmit(form => {
    handleEditPet({
      variables: {
        id: pet?.id,
        name: form.name,
        BreedId: form.breedId,
        type: form.type,
        gender: form.gender,
        birthdate: form.birthdate,
        PetColorId: form.petColorId || null
      }
    });

    handleEditPetRecord({
      variables: {
        id: pet?.PetRecord?.id,
        measurements: {
          ...pet?.PetRecord?.measurements,
          necks: !form.neck ? pet?.PetRecord?.measurements?.necks : [...(pet?.PetRecord?.measurements?.necks || []), { neck: Number(form.neck), date: new Date().toISOString() }],
          backs: !form.back ? pet?.PetRecord?.measurements?.backs : [...(pet?.PetRecord?.measurements?.backs || []), { back: Number(form.back), date: new Date().toISOString() }],
          chests: !form.chest ? pet?.PetRecord?.measurements?.chests : [...(pet?.PetRecord?.measurements?.chests || []), { chest: Number(form.chest), date: new Date().toISOString() }],
          heights: !form.height ? pet?.PetRecord?.measurements?.heights : [...(pet?.PetRecord?.measurements?.heights || []), { height: Number(form.height), date: new Date().toISOString() }]
        },
        neutred: form.neutred === 'true',
        microchip_provider: form.microchip_provider
      }
    });
  });

  useEffect(() => {
    if (editedPetRecord?.editPetRecord?.id && editedPet?.editBranchPet?.id) {
      ModalDialog?.closeModal();
    }
  }, [editedPetRecord, editedPet]);

  const loading = loadingEditPetRecord || loadingEditPet;
  const error = !!errorEditPetRecord?.message || !!errorEditPet?.message;

  return <HealthPetRecordStateModalForm onSubmit={onSubmit} loading={loading} error={error} pet={pet} formOptions={formOptions} />;
};

export default HealthPetRecordStateModal;
