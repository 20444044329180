import React, { useEffect } from 'react';
import { DRAWER_IDS } from '../../../components/DrawerBar/types';
import InfiniteList from '../../../components/Shared/InfiniteList/InfiniteList';
import useNavigateDrawer from '../../../hooks/useNavigateDrawer';
import usePaginatedQuery from '../../../hooks/usePaginatedQuery';
import useSetDrawerOnNavigation from '../../../hooks/useSetDrawerOnNavigation';
import { GetBranchMessageTemplates } from '../../../queries';
import { RecordsContainer } from '../../styled';
import Common from '../Common';
import BranchMessageRecord from './BranchMessageRecord';

export type BranchMessage = {
  name: string;
  Products: { id: string; name: string }[] | null;
  Branch: { id: string; name: string };
  id: string;
  message: string;
  html_message: string;
  type: BRANCH_MESSAGE_TYPES;
};

export enum BRANCH_MESSAGE_TYPES {
  NEW_ORDER = 'NEW_ORDER',
  APPOINTMENT_CONFIRMED = 'APPOINTMENT_CONFIRMED',
  // APPOINTMENT_CONFIRMED_EMAIL = 'APPOINTMENT_CONFIRMED_EMAIL',
  NONE = 'NONE',
  OUT_OF_OFFICE = 'OUT_OF_OFFICE',
  APPOINTMENT_REJECTED = 'APPOINTMENT_REJECTED'
}

export enum BRANCH_MESSAGE_TYPES_LABELS {
  NEW_ORDER = 'After purchase of selected product(s) or service(s)',
  APPOINTMENT_CONFIRMED = 'After appointment is confirmed',
  // APPOINTMENT_CONFIRMED_EMAIL = 'After appointment is confirmed (email)',
  NONE = 'Tags Initiated',
  OUT_OF_OFFICE = 'Out of Office',
  APPOINTMENT_REJECTED = 'After appointment is rejected'
}

const BranchMessages = () => {
  const [[hasMoreItems, setHasMoreItems], queryResult] = usePaginatedQuery<BranchMessage[]>({ query: GetBranchMessageTemplates });

  const { data: { branchMessageTemplateGet: branchMessages = [] } = {}, loading, fetchMore, refetch } = queryResult;

  useSetDrawerOnNavigation({
    itemList: branchMessages,
    drawerId: DRAWER_IDS.BRANCH_MESSAGE_DRAWER,
    itemsToFilterFrom: branchMessages
  });

  const navigateDrawer = useNavigateDrawer({
    drawerData: {
      drawerId: DRAWER_IDS.BRANCH_MESSAGE_DRAWER,
      itemsToFilterFrom: branchMessages
    }
  });

  useEffect(() => {
    Common.set(`BranchMessages.GetBranchMessages.refetch`, () => {
      refetch?.().then(() => setHasMoreItems(true));
    });
  }, []);

  return (
    <InfiniteList
      paddingL={20}
      paddingR={20}
      list={branchMessages}
      itemRenderer={(message: BranchMessage) => <BranchMessageRecord key={message.id} branchMessage={message} navigateDrawer={navigateDrawer} />}
      hasMoreItems={hasMoreItems}
      loading={loading}
      fetchMore={fetchMore}
      offset={branchMessages?.length}
      setHasMoreItems={setHasMoreItems}
    />
  );
};

export default BranchMessages;
