import { useLazyQuery, useMutation, useQuery, useReactiveVar } from '@apollo/client';
import addMinutes from 'date-fns/addMinutes';
import differenceInDays from 'date-fns/differenceInDays';
import differenceInMinutes from 'date-fns/differenceInMinutes';
import endOfDay from 'date-fns/endOfDay';
import endOfMonth from 'date-fns/endOfMonth';
import endOfWeek from 'date-fns/endOfWeek';
import startOfMonth from 'date-fns/startOfMonth';
import startOfDay from 'date-fns/startOfDay';
import CoreTimelineCalendar from '@event-calendar/core';
import Interaction from '@event-calendar/interaction';
import TimelineCalendar from '@event-calendar/resource-timeline';
import format from 'date-fns/format';
import getDay from 'date-fns/getDay';
import enGB from 'date-fns/locale/en-GB';
import parse from 'date-fns/parse';
import startOfWeek from 'date-fns/startOfWeek';
import { GatsbyImage } from 'gatsby-plugin-image';
import { FC, useCallback, useEffect, useId, useMemo, useRef, useState } from 'react';
import { Calendar, CalendarProps, View, dateFnsLocalizer } from 'react-big-calendar';
import withDragAndDrop, { withDragAndDropProps } from 'react-big-calendar/lib/addons/dragAndDrop';
import styled from 'styled-components';

import Colors from '../../Colors';
import useIcons from '../../hooks/useIcons';
import { useWindowSize } from '../../hooks/useWindowResize';
import { AssignBranchRoomToAppointment, AssignBusUserToAppointment, EditBranchSlot, GetBranchCalendarAppointmentsViews, GetBranchRooms, GetBranchSchedules, GetBusUserProfile } from '../../queries';
import { vars } from '../../reactive';
import { hideCalendarActionMessage, setDrawerBar } from '../../reactive/actions';
import { findDayName, findThreeLetterDayName, formatDate, getMonthName, getStartOfDate, getStartOfMonth, getStartOfNMonth, isToday, toDateInput, toDay, toThreeLetterWeekDay } from '../../utils/dates';
import BookingAssignModal from '../../views/Bookings/components/BookingsDrawer/BookingAssignModal';
import BookingDurationModal from '../../views/Bookings/components/BookingsDrawer/BookingDurationModal';
import BookingRescheduleModal from '../../views/Bookings/components/BookingsDrawer/BookingRescheduleModal';
import { BOOKING_STATUS_TYPES, BookingOrder, CalendarEvents } from '../../views/Bookings/types';
import { DRAWER_IDS } from '../DrawerBar/types';
import ModalDialog from '../Modal/ModalDialog';
import { BusUserProfile } from '../Profile/types';
import { FormSelect } from '../Shared/Forms/Forms';
import CalendarRange, { CalendarRangeRef, getRange } from './CalendarRange';
import SlotModal, { SLOTS_TABS_TYPES } from './Modals/SlotModal';
import { CALENDAR_VIEWS, CALENDAR_VIEWS_NAMES, CALENDAR_VIEWS_TO_SHOW, CALENDAR_VIEW_BOOKING_TYPE, CALENDAR_VIEW_LENGTH } from './types';

import '@event-calendar/core/index.css';
import React from 'react';
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import {
  WeekDayTitleAndWeekDayContainer,
  WeekDayTitle,
  WeekDay,
  ServiceName,
  EventLabel,
  EventLabelsContainer,
  MonthLabel,
  StaffContainer,
  MultiDayCalendar,
  ToolbarButton,
  CounterBtn,
  ToolsContainer
} from './styled';
import { PetContainer } from '../../views/Bookings/components/BookingRecord/styled';
import { PetImg } from '../../views/Bookings/components/BookingsBody/styled';
import { CalendarEventColors } from '../Shared/Shared';
import MultiStaffCalendar from './MultiStaffCalendar/MultiStaffCalendar';
import { RecordBody } from '../../views/styled';
import useMediaQuery from '../../hooks/useMediaQuery';
import { BusinessUserImage, BusinessUserImageContainer, BusinessUserName, CalendarToolBarContainer, DefaultBusinessUserImage, Divider, LogOutButton } from '../SideBar/styled';
import { logout } from '../../utils/auth';
import OptionDropdown from '../Shared/Menus/OptionDropdown/OptionDropdown';
import { OPTION_DROPDOWN_MENU_BUTTON_TYPES, OPTION_DROPDOWN_MENU_POSITIONS, OPTION_DROPDOWN_TYPES } from '../Shared/Menus/OptionDropdown/types';
import { isUserAdminOrSuper } from '../../utils/helpers';
import { timeStamp } from 'console';
import { convertDurationToMinutes } from '../Shared/DurationSelector';
import { multi } from 'linkifyjs';
import { BranchRoom } from '../../views/Store/BranchRooms/types';
import { BranchSchedule } from '../../views/Store/BranchSchedules/types';
import { colors } from 'react-select/dist/declarations/src/theme';
import SideBar from '../SideBar/SideBar';

const getRandomColor = () => {
  const colors = Object.values(CalendarEventColors);
  const randomIndex = Math.floor(Math.random() * colors.length);
  return colors[randomIndex];
};

const locales = {
  'en-GB': enGB
};

const MARGIN_HORIZONTAL = 250;
const ITEM_SIZE = 50;

const localizer = dateFnsLocalizer({
  format,
  parse,
  startOfWeek: (
    date: number | Date,
    options?:
      | {
          locale?: Locale | undefined;
          weekStartsOn?: 0 | 1 | 2 | 3 | 5 | 6 | 4 | undefined;
        }
      | undefined
  ) => startOfWeek(date, { ...options, weekStartsOn: 1 }),
  getDay,
  locales
});

const DnDCalendar = withDragAndDrop(Calendar);

function extractRange(range: Date[] | { start: Date; end: Date }) {
  let start = range.start;
  let end = range.end;

  if (range.length === 1) {
    // day
    start = range[0];
    end = endOfDay(range[0]);
  } else if (range.length > 1) {
    // week
    start = range[0];
    end = range.slice(-1)[0];
  }
  return { start, end };
}

const BigCalendar: FC = ({ selectedUser, setSelectedUser }) => {
  const modalTitle = useReactiveVar(vars.modalTitle);
  const icons = useIcons();
  const lessIcon = icons.lessLarge.childImageSharp.gatsbyImageData;
  const moreIcon = icons.moreLarge.childImageSharp.gatsbyImageData;
  const [calendarView, setCalendarView] = useState<View>('week');
  const rangeRef = useRef<CalendarRangeRef>(null);
  const { mobile } = useMediaQuery({ mobile: true });
  const [dateRange, setDateRange] = useState({ from: new Date(), to: new Date() });

  const { height } = useWindowSize();

  const [addStaff] = useMutation<{ assignBusUserToAppointment: BookingOrder }>(AssignBusUserToAppointment);
  const [addBranchRoom] = useMutation<{ assignBranchRoomToAppointment: BookingOrder }>(AssignBranchRoomToAppointment);

  const calendarTagCategoryId = useReactiveVar(vars.calendarTagCategoryId);

  const { data: { getBusUserProfile: busUserProfile } = {}, refetch: refetchProfile } = useQuery<{ getBusUserProfile: BusUserProfile }>(GetBusUserProfile, {
    fetchPolicy: 'cache-and-network'
  });
  const { data: { getBranchRooms: branchRooms = [] } = {}, refetch: refetchRooms } = useQuery<{ getBranchRooms: BranchRoom[] }>(GetBranchRooms, {
    fetchPolicy: 'cache-and-network'
  });

  const { data: { getBranchSchedules: schedules = [] } = {}, loading: loadingServices } = useQuery<{ getBranchSchedules: BranchSchedule[] }>(GetBranchSchedules, {
    fetchPolicy: 'cache-and-network'
  });
  const [selectedCategoryTab, setSelectedCategoryTab] = useState<string>('');
  const selectedBookingType = useReactiveVar(vars.calendarSelectedBookingType) || CALENDAR_VIEWS.MY_SCHEDULE;
  const setSelectedBookingType = value => {
    vars.calendarSelectedBookingType(value);
  };
  const multiStaffBookingType = selectedBookingType === CALENDAR_VIEWS.MULTI_STAFF;
  const myScheduleBookingType = selectedBookingType === CALENDAR_VIEWS.MY_SCHEDULE;
  const operationsCalendarBookingType = selectedBookingType === CALENDAR_VIEWS.ALL;
  const isMonth = calendarView === 'month';
  const isMultiDay = selectedBookingType === CALENDAR_VIEWS.MULTI_DAY;
  const schedulesToReturn = !selectedCategoryTab || isMultiDay ? null : [selectedCategoryTab];
  const fromDateForWeek = startOfWeek(new Date(), { weekStartsOn: 1 });
  const toDateForWeek = endOfWeek(new Date(), { weekStartsOn: 1 });
  const fromDateForMonth = startOfMonth(new Date());
  const toDateForMonth = endOfMonth(new Date());

  const fromDate = isMultiDay ? fromDateForMonth : fromDateForWeek;
  const toDate = isMultiDay ? toDateForMonth : toDateForWeek;

  const [isTimeMode, setIsTimeMode] = useState(false);

  const profile = (busUserProfile || {}) as BusUserProfile;

  const BusUsers = profile?.Branch?.BusUsers || [];

  const bookingTypeId = useId();

  const bookingTypeOptions = {
    id: bookingTypeId,
    optionType: OPTION_DROPDOWN_TYPES.BUTTONS,

    items: mobile
      ? [
          ...CALENDAR_VIEWS_TO_SHOW.map(view => ({
            name: CALENDAR_VIEWS_NAMES[view],
            value: CALENDAR_VIEWS_NAMES[view],
            onClick: () => {
              setSelectedBookingType(view);
              // setCurrentCalendarLength(mobile ? 1 : CALENDAR_VIEW_LENGTH[view]);
              // calendarRangeRef.current?.clearRange();
              // if (view === CALENDAR_VIEWS.MULTI_STAFF) {
              //   resetView({ goToToday: true });
              //   return;
              // }
              // resetView({ goToToday: true });
            },
            green: view === selectedBookingType,
            disabled: [CALENDAR_VIEWS.MULTI_STAFF, CALENDAR_VIEWS.ALL].includes(view) && (!isUserAdminOrSuper(profile) || !BusUsers?.length)
          })),
          {
            name: 'Log Out',
            value: 'logout',
            onClick: logout,
            danger: true
          }
        ]
      : []
  };
  const [handleEditBranchSlot] = useMutation(EditBranchSlot);

  const [getCalendarAppointments, { data: calendarAppointmentsData, refetch: refetchCalendarAppointments, loading: loadingCalendarAppointments }] = useLazyQuery<{
    getBranchCalendarAppointmentsViews: CalendarEvents;
  }>(GetBranchCalendarAppointmentsViews, {
    variables: {
      status: [BOOKING_STATUS_TYPES.CONFIRMED],
      timestamp_from: new Date(Date.UTC(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate(), fromDate.getHours(), fromDate.getMinutes(), fromDate.getSeconds())).toISOString(),
      timestamp_to: new Date(Date.UTC(toDate.getFullYear(), toDate.getMonth(), toDate.getDate(), toDate.getHours(), toDate.getMinutes(), toDate.getSeconds())).toISOString(),
      filter_by_role: profile.role,
      booking_type: CALENDAR_VIEW_BOOKING_TYPE[selectedBookingType],
      BranchTagCategoryId: calendarTagCategoryId ? [calendarTagCategoryId] : null,
      ...(multiStaffBookingType && {
        appointment_busUserAssigned: true
      }),
      ...(myScheduleBookingType && {
        appointment_busUserAssigned_id: profile?.id
      }),
      product_branchSchedule_id: schedulesToReturn
    },
    fetchPolicy: 'cache-and-network',
    onCompleted(data) {
      const dates = data?.getBranchCalendarAppointmentsViews?.singleDayAppointmentsViews?.map(item => item.date);

      const count = dates.map(date => {
        const checkInCheckOut = data?.getBranchCalendarAppointmentsViews?.multiDayAppointmentsViews?.reduce(
          (accumulator, appointment) => {
            if (appointment.timestamp && new Date(appointment.timestamp).setHours(0, 0, 0, 0) === new Date(date).setHours(0, 0, 0, 0)) {
              accumulator.checkIn++;
            }
            if (appointment.timestamp_until && new Date(appointment.timestamp_until).setHours(0, 0, 0, 0) === new Date(date).setHours(0, 0, 0, 0)) {
              accumulator.checkOut++;
            }
            return accumulator;
          },
          { checkIn: 0, checkOut: 0 }
        );

        return checkInCheckOut;
      });
      vars.calendarDatesCheckInCheckOutCount(count);
    },
    pollInterval: 60000
  });

  useEffect(() => {
    if (multiStaffBookingType) {
      // getBusUserAssignedCalendarAppointments();
    } else {
      getCalendarAppointments();
    }
  }, [multiStaffBookingType]);
  useEffect(() => {
    if (operationsCalendarBookingType || multiStaffBookingType) {
      setCalendarView('week');
    }
  }, [operationsCalendarBookingType, multiStaffBookingType]);

  const adjustCalendarDomView = () => {
    setTimeout(() => {
      const allDayHeaders = document.querySelectorAll('.rbc-allday-cell');

      if (allDayHeaders) {
        allDayHeaders.forEach(h => (h.style.display = 'none'));
      }
    }, 100);
  };

  useEffect(adjustCalendarDomView, [selectedUser]);

  const events = useMemo(() => {
    const list = isMultiDay
      ? calendarAppointmentsData?.getBranchCalendarAppointmentsViews?.multiDayAppointmentsViews
      : calendarAppointmentsData?.getBranchCalendarAppointmentsViews?.singleDayAppointmentsViews?.map(e => e.views)?.flat();
    return (list || [])?.map(view => {
      const dt = new Date(view.timestamp);
      const dtUtc = new Date(dt.getUTCFullYear(), dt.getUTCMonth(), dt.getUTCDate(), dt.getUTCHours(), dt.getUTCMinutes(), dt.getUTCSeconds());
      const dtUntil = new Date(view.timestamp_until);
      const dtUntilUtc = new Date(dtUntil.getUTCFullYear(), dtUntil.getUTCMonth(), dtUntil.getUTCDate(), dtUntil.getUTCHours(), dtUntil.getUTCMinutes(), dtUntil.getUTCSeconds());
      const nightsNumber = Math.floor(Number(view.duration));
      const nightsToReturn = nightsNumber > 1 ? ` - ${nightsNumber} nights` : '- night';
      const color = view.color;
      return {
        view,
        title: view.petsNames + ' - ' + view.itemName + nightsToReturn,
        start: dtUtc,
        end: new Date(view.timestamp_until ? dtUntilUtc : addMinutes(dtUtc, Number(view.duration))),
        allDay: false,
        resourceId: isMultiDay ? view.BranchRooms?.[0]?.id : view.BusUsers?.[0]?.id,
        color
      };
    });
  }, [calendarAppointmentsData]);

  const blockedEvents = useMemo(() => {
    return (calendarAppointmentsData?.getBranchCalendarAppointmentsViews?.blockedSlotsViews || [])
      ?.map(e => e.views)
      .flat()
      ?.map(view => {
        const count = view.count;
        const time = view.time;
        const duration = view.duration;
        const branch_schedules = view.branch_schedules;
        const dt = new Date(view.timestamp);
        const dtUtc = new Date(dt.getUTCFullYear(), dt.getUTCMonth(), dt.getUTCDate(), dt.getUTCHours(), dt.getUTCMinutes(), dt.getUTCSeconds());
        const dtUntil = new Date(view.timestamp_until);
        const dtUntilUtc = new Date(dtUntil.getUTCFullYear(), dtUntil.getUTCMonth(), dtUntil.getUTCDate(), dtUntil.getUTCHours(), dtUntil.getUTCMinutes(), dtUntil.getUTCSeconds());
        const variables = {
          timeStamp: dtUtc,
          count: Number(count),
          duration: Number(duration !== undefined ? convertDurationToMinutes(duration) || 0 : 0),
          description: view.description,
          branch_schedules,
          status: 'BLOCKED'
        };

        return {
          view,
          title: view.description,
          start: dtUtc,
          end: new Date(view.timestamp_until ? dtUntilUtc : addMinutes(dtUtc, Number(view.duration))),
          allDay: false,
          resourceId: view.BusUsers?.[0]?.id,
          isBlocked: true,
          time,
          variables
        };
      });
  }, [calendarAppointmentsData]);

  const onEventDrop: withDragAndDropProps['onEventDrop'] = e => {
    const isBlocked = e.event.isBlocked;
    const { start, end } = extractRange({ start: e.start, end: e.end });
    const appointmentsIds = e.event.view.appointmentsIds;
    const branchSlotsIds = e.event.view.branchSlotsIds;
    const blockedNewDate = new Date(Date.UTC(start.getFullYear(), start.getMonth(), start.getDate(), start.getHours(), start.getMinutes()));
    const ecStart = new Date(e.event.view.timestamp);
    console.log(e);

    if (isBlocked) {
      handleEditBranchSlot({
        variables: {
          ...e.event.variables,
          id: branchSlotsIds[0],
          timestamp: blockedNewDate
        }
      });
      e.event.start = start;
      e.event.end = end;
    }

    const reschedule = () => {
      if (start.getTime() === e?.event?.start?.getTime() && !isMultiDay) {
        return;
      }

      // if (start.getDay() === ecStart.getDay() && isMultiDay) {
      //   return;
      // }
      console.log(start.getDate() === ecStart.getDate() && isMultiDay);
      console.log('start', start, 'ecStart', ecStart);

      const group = appointmentsIds.length > 1 ? true : false;
      ModalDialog.openModal({
        content: () => <BookingRescheduleModal appointmentsIds={appointmentsIds} newDate={start} newDateUntil={isMultiDay && end} group={group} />,
        title: isMultiDay ? 'Amend Booking' : 'Reschedule',
        onClose: refetchCalendarAppointments
      });
    };

    if (e.resourceId !== e.event.resourceId && multiStaffBookingType) {
      addStaff({
        variables: {
          id: appointmentsIds,
          BusUserId: [e.resourceId]
        },
        refetchQueries: ['getBranchCalendarAppointmentsViews']
      });
    }
    if (e.resourceId !== e.event.resourceId && isMultiDay) {
      addBranchRoom({
        variables: {
          id: appointmentsIds,
          BranchRoomId: [e.resourceId]
        },
        refetchQueries: ['getBranchCalendarAppointmentsViews']
      });
    }
    reschedule();
  };

  const onEventResize: withDragAndDropProps['onEventResize'] = data => {
    const isBlocked = data.event.isBlocked;
    const { start, end } = extractRange({ start: data.start, end: data.end });
    const newDuration = differenceInMinutes(end, start);
    const newDate = data.event.start !== start ? start : null;
    const appointmentsIds = data.event.view.appointmentsIds;
    const branchSlotsIds = data.event.view.branchSlotsIds;

    if (isBlocked) {
      handleEditBranchSlot({
        variables: {
          ...data.event.variables,
          id: branchSlotsIds[0],
          duration: newDuration
        }
      });
      data.event.start = start;
      data.event.end = end;
    }

    if (isMultiDay) {
      return ModalDialog.openModal({
        content: () => <BookingRescheduleModal appointmentsIds={appointmentsIds} newDate={start} newDateUntil={end} />,
        title: 'Amend Booking',
        onClose: refetchCalendarAppointments
      });
    }
    {
      !isBlocked &&
        ModalDialog.openModal({
          content: () => <BookingDurationModal appointmentsIds={appointmentsIds} newDuration={newDuration} newDate={newDate} />,
          title: 'Update Duration',
          onClose: refetchCalendarAppointments
        });
    }
  };

  const onRangeChange: CalendarProps<object, object>['onRangeChange'] = range => {
    adjustCalendarDomView();
    const { start, end } = extractRange(range);
    setDateRange({ from: start, to: end });
    refetchCalendarAppointments({
      status: [BOOKING_STATUS_TYPES.CONFIRMED],
      timestamp_from: new Date(Date.UTC(start.getFullYear(), start.getMonth(), start.getDate(), start.getHours(), start.getMinutes(), start.getSeconds(), start.getMilliseconds())),
      timestamp_to: new Date(Date.UTC(end.getFullYear(), end.getMonth(), end.getDate(), end.getHours(), end.getMinutes(), end.getSeconds(), end.getMilliseconds())),
      filter_by_role: profile.role,
      booking_type: CALENDAR_VIEW_BOOKING_TYPE[selectedBookingType],
      BranchTagCategoryId: calendarTagCategoryId ? [calendarTagCategoryId] : null,
      ...(multiStaffBookingType && {
        appointment_busUserAssigned: true
      }),
      ...(myScheduleBookingType && {
        appointment_busUserAssigned_id: profile?.id
      })
    });
  };

  const onSelectEvent: CalendarProps<object, object>['onSelectEvent'] = event => {
    const drawerId = event.view.isGroup ? DRAWER_IDS.GROUP_BOOKINGS_DRAWER : DRAWER_IDS.BOOKING_DRAWER;
    if (event.isBlocked) {
      return ModalDialog.openModal({
        title: modalTitle,
        content: () => <SlotModal tab={SLOTS_TABS_TYPES.BLOCK} branchSlotsIds={event.view.branchSlotsIds} />,
        onClose: refetchCalendarAppointments
      });
    }
    const recordData = event.view.appointmentsIds.map(id => ({ id }));
    setDrawerBar({ drawerId, recordData, otherData: { refetch: refetchCalendarAppointments } });
  };

  const onSelectSlot: CalendarProps<object, object>['onSelectSlot'] = slotInfo => {
    const slotStart = slotInfo.slots[0];
    const selectedId = slotInfo.resourceId;
    vars.selectedDate({ date: slotStart, hour: slotStart.getHours() });
    ModalDialog.openModal({
      title: modalTitle,
      content: () => <SlotModal tab={SLOTS_TABS_TYPES.BOOK} selectedId={selectedId} />,
      onCloseBySave: () => {
        hideCalendarActionMessage();
        setTimeout(() => {
          refetchProfile();
          refetchCalendarAppointments();
          vars.selectedDate(null);
          // refetchAvailability();
        }, 3000);
      }
    });
  };

  const onNavigate: CalendarProps['onNavigate'] = (date, view, action) => {
    let range = { from: date, to: date };
    if (view === 'day') {
      range = { from: startOfDay(date), to: endOfDay(date) };
    } else if (view === 'week') {
      range = { from: startOfWeek(date, { weekStartsOn: 1 }), to: endOfDay(endOfWeek(date)) };
    } else if (view === 'month') {
      range = { from: startOfMonth(date), to: endOfDay(endOfMonth(date)) };
    }
    // setDateRange(range);
    onRangeChange([range.from, range.to], view);
  };

  const handleHeaderClick = () => {
    const currentCalendarLength = differenceInDays(dateRange.to, dateRange.from) + 1;

    ModalDialog.openModal({
      noHeader: true,
      overlayClose: true,
      mobileBorderRadius: '38px',
      content: () => (
        <CalendarRange
          currentCalendarLength={currentCalendarLength}
          setUTCDate={date => {
            ModalDialog.closeModal();
            onNavigate(date, calendarView, 'DATE');
          }}
          useDatePicker={multiStaffBookingType}
          defaultValues={getRange(dateRange.from, currentCalendarLength)}
          ref={rangeRef}
        />
      )
    });
  };

  const rangeFrom = multiStaffBookingType ? format(dateRange.from, 'dd MMM') : format(dateRange.from, 'MMM');

  const lastDateSet = useRef({ start: new Date(), end: new Date() });
  const ec = useRef(null);

  const multiDayRooms = useMemo(() => branchRooms?.map(room => ({ id: room.id, title: room.name })), [isMultiDay, branchRooms?.[0]?.id]);
  const randomColorsRef = useRef([]);
  useEffect(() => {
    if (!isMultiDay) {
      return;
    }
    console.warn('recreating Calendar');
    ec.current = new CoreTimelineCalendar({
      target: document.getElementById('timeline-calendar'),
      props: {
        plugins: [Interaction, TimelineCalendar],
        options: {
          duration: { month: 1 },
          firstDay: 1,
          slotDuration: { days: 1 }, //'12:00',
          view: 'resourceTimelineWeek',
          resources: multiDayRooms,
          height: '100%',
          dayHeaderFormat: { weekday: 'short', day: 'numeric' },
          viewDidMount: () => {
            document.querySelectorAll('.ec-day').forEach(dayElement => {
              const timeElement = dayElement.querySelector('time[datetime]');
              if (timeElement) {
                const headerDate = new Date(timeElement.getAttribute('datetime'));
                if (headerDate.toDateString() === new Date().toDateString()) {
                  const dayName = headerDate.toLocaleDateString('en-US', { weekday: 'short' });
                  const dayNumber = headerDate.getDate();

                  const formattedContent = `<span class="today">${dayName}</span> <span class="today-number">${dayNumber}</span>`;

                  timeElement.innerHTML = formattedContent;
                }
              }
            });
          },

          // customButtons: {
          //   timeMode: {
          //     text: 'half days?',
          //     click: () => {
          //       setIsTimeMode(tm => !tm);
          //     }
          //   }
          // },
          headerToolbar: {
            // start: 'title',
            // center: 'timeMode',
            start: '',
            center: '',
            end: 'prev today next'
          },
          eventClick: ({ event }) => {
            onSelectEvent({ view: event.extendedProps, title: event.title });
          },
          eventDrop: ({ event, newResource, oldResource }) => {
            const dtStart = new Date(event.start.setDate(event.start.getDate() + 1));
            const dtEnd = new Date(event.end.setDate(event.end.getDate() + 1));
            onEventDrop({ start: dtStart, end: dtEnd, event: { view: event.extendedProps, isBlocked: event.isBlocked, resourceId: oldResource?.id }, resourceId: newResource?.id });
          },
          eventResize: ({ event }) => {
            const dtStart = new Date(event.start.setDate(event.start.getDate() + 1));
            const dtEnd = new Date(event.end.setDate(event.end.getDate()));
            onEventResize({ start: dtStart, end: dtEnd, event: { view: event.extendedProps, isBlocked: event.isBlocked, resourceId: event.resourceId } });
          },
          dateClick: ({ date, resource }) => {
            const dt = new Date(date.setDate(date.getDate()));
            onSelectSlot({ slots: [dt], action: 'select', resourceId: resource.id });
          },
          datesSet: ({ start, end, view }) => {
            if (start.getTime() === lastDateSet.current.start.getTime() && end.getTime() === lastDateSet.current.end.getTime()) {
              return;
            }
            lastDateSet.current = { start, end };
            onRangeChange({ start, end });
          }
        }
      }
    });

    return () => {
      ec.current?.destroy?.();
    };
  }, [isMultiDay, multiDayRooms]);

  useEffect(() => {
    if (randomColorsRef.current.length === 0) {
      const numberOfColors = events.length + blockedEvents.length;
      randomColorsRef.current = Array.from({ length: numberOfColors }, getRandomColor);
    }
  }, [events, blockedEvents]);

  useEffect(() => {
    ec.current?.setOption?.(
      'events',
      [...events, ...blockedEvents].map((e, index) => ({
        resourceId: e.resourceId,
        start: e.start,
        end: e.end,
        title: e.title,
        allDay: e.isBlocked ? false : true,
        backgroundColor: e.isBlocked ? Colors.blocked : e.view?.color || randomColorsRef.current[index],
        extendedProps: e.view
      }))
    );
    ec.current?.setOption?.('slotDuration', isTimeMode ? '12:00' : { days: 1 });
  }, [isMultiDay, events, blockedEvents, loadingCalendarAppointments, isTimeMode, multiDayRooms]);

  if (isMultiDay) {
    return <MultiDayCalendar id={'timeline-calendar'} style={{ minHeight: '100%', minWidth: '100%' }} />;
  }
  console.log(calendarView);
  return (
    <div style={{ display: 'flex', justifyContent: 'space-between', height: '100%' }}>
      {isMonth && operationsCalendarBookingType && (
        <div style={{ width: '5%' }}>
          <Divider style={{ marginTop: '68px' }} />
          <div
            onClick={handleHeaderClick}
            style={{ cursor: 'pointer', minWidth: '5%', width: '5%', overflow: 'visible', fontWeight: '800', fontSize: '16px', padding: '10px 15px', borderBottom: '1px solod black' }}
          >
            {format(dateRange.from, 'MMM')}
          </div>
          <Divider />
        </div>
      )}
      <DnDCalendar
        style={{ width: isMonth && operationsCalendarBookingType ? '95%' : '100%' }}
        date={dateRange.from}
        defaultView={mobile ? 'day' : 'week'}
        events={[...events, ...blockedEvents]}
        localizer={localizer}
        onEventDrop={onEventDrop}
        onEventResize={onEventResize}
        // onRangeChange={onRangeChange}
        onSelectEvent={onSelectEvent}
        onSelectSlot={onSelectSlot}
        onView={view => {
          setCalendarView(view);
          onNavigate(dateRange.from, view, 'DATE');
        }}
        onNavigate={onNavigate}
        step={30}
        resizable
        selectable
        popup
        showMultiDayTimes={false}
        scrollToTime={new Date()}
        enableAutoScroll
        allDayMaxRows={(height - MARGIN_HORIZONTAL) / ITEM_SIZE}
        {...(multiStaffBookingType &&
          selectedUser === '' && {
            view: 'day'
          })}
        {...(multiStaffBookingType && {
          resources: BusUsers.map(user => ({ resourceId: user.id, resourceTitle: user.name })).filter(user => (selectedUser !== '' ? user.resourceId === selectedUser : true)),
          resourceIdAccessor: r => r.resourceId,
          resourceTitleAccessor: r => r.resourceTitle
        })}
        components={{
          header: props => {
            const dateLabel = new Date(props.date);
            const isToday = dateLabel.toDateString() === new Date().toDateString();
            const isTodayMonth = dateLabel.getDay() === new Date().getDay();
            return (
              <WeekDayTitleAndWeekDayContainer>
                <WeekDayTitle isToday={isTodayMonth && isMonth}>{toThreeLetterWeekDay(dateLabel)}</WeekDayTitle>
                {calendarView !== 'month' && <WeekDay isToday={isToday}>{toDay(dateLabel)}</WeekDay>}
              </WeekDayTitleAndWeekDayContainer>
            );
          },
          timeGutterHeader: () => {
            return (
              <MonthLabel onClick={handleHeaderClick} padding={multiStaffBookingType ? '15px 0 10px 5px' : '15px'}>
                {rangeFrom}
              </MonthLabel>
            );
          },
          event: event => {
            const isBlocked = event.event.isBlocked;
            const blockedTitle = event.event.view?.description;
            const pets = event?.event?.view?.petsNames;
            const service = event?.event?.view?.itemName || 'Blocked';
            const eventTime = `${format(event?.event?.start, 'HH:mm')} - ${format(event?.event?.end, 'HH:mm')}`;
            const titleToDisplay = isBlocked ? blockedTitle : service;
            const eventColor = isBlocked ? Colors.blocked : event?.event?.color;

            return (
              <EventLabelsContainer style={{ backgroundColor: eventColor }}>
                <div>
                  <EventLabel fontWeight={800}>{titleToDisplay}</EventLabel>
                  <EventLabel>{pets}</EventLabel>
                </div>
                <EventLabel>{eventTime}</EventLabel>
              </EventLabelsContainer>
            );
          },
          resourceHeader: ({ index, label, resource: { resourceId, resourceTitle } }) => {
            const user = BusUsers.find(u => u.id === resourceId);
            return (
              !selectedUser && (
                <StaffContainer onClick={() => setSelectedUser(selectedUser ? '' : user.id)}>
                  {!selectedUser && (
                    <>
                      <PetContainer>
                        {!user?.profile_pic && <GatsbyImage image={icons?.user?.childImageSharp?.gatsbyImageData} alt={resourceTitle} style={{ height: 30, width: 30, borderRadius: 15 }} />}
                        {user?.profile_pic && <PetImg src={user.profile_pic} alt={resourceTitle} height="30px" width="30px" />}
                      </PetContainer>
                      <span
                        style={{
                          wordWrap: 'break-word',
                          overflow: 'hidden'
                        }}
                      >
                        {label}
                      </span>
                    </>
                  )}
                </StaffContainer>
              )
            );
          },

          toolbar: props => {
            const date = props.date;
            const dateLabel = getMonthName(date);
            const dayName = toThreeLetterWeekDay(date);
            const mobileDateLabel = `${dayName}, ${format(date, 'dd  MMM  yyyy')}`;
            return (
              <CalendarToolBarContainer padding={isMonth ? '19px' : '10px'}>
                {!multiStaffBookingType && (
                  <FormSelect
                    height={48}
                    fontSize={14}
                    fontWeight={700}
                    width={250}
                    borderRadius="6px"
                    noBorder
                    padding="8px 10px 8px 8px"
                    backgroundColor="#F3F3F3"
                    onChange={e => {
                      setSelectedCategoryTab(e.target.value);
                    }}
                    value={selectedCategoryTab}
                    style={{ position: isMonth ? 'absolute' : 'unset', left: '15px' }}
                  >
                    {[{ id: '', name: 'All' }, ...schedules]?.map(category => (
                      <option key={category.id} value={category.id} style={{ cursor: 'pointer' }}>
                        {category.name}
                      </option>
                    ))}
                  </FormSelect>
                )}

                {!multiStaffBookingType && <div />}
                {multiStaffBookingType && (
                  <FormSelect
                    height={48}
                    fontSize={14}
                    fontWeight={700}
                    width={250}
                    borderRadius="6px"
                    noBorder
                    backgroundColor="#F3F3F3"
                    onChange={e => {
                      setSelectedUser(e.target.value);
                    }}
                    value={selectedUser}
                  >
                    <option value={''}>All Staff</option>
                    {BusUsers.map(user => (
                      <option key={user.id} value={user.id}>
                        {user.name}
                      </option>
                    ))}
                  </FormSelect>
                )}

                {!mobile && (
                  <ToolsContainer>
                    {!(multiStaffBookingType && selectedUser === '') && (
                      <div style={{ display: 'flex', gap: 0 }}>
                        <ToolbarButton onClick={() => props.onView('day')} selected={props.view === 'day'}>
                          Day
                        </ToolbarButton>
                        <ToolbarButton onClick={() => props.onView('week')} selected={props.view === 'week'}>
                          Week
                        </ToolbarButton>
                        {!multiStaffBookingType && (
                          <ToolbarButton onClick={() => props.onView('month')} selected={props.view === 'month'}>
                            Month
                          </ToolbarButton>
                        )}
                      </div>
                    )}
                    <div style={{ display: 'flex', gap: 10 }}>
                      <CounterBtn onClick={() => props.onNavigate('PREV')} height="30px" width="30px">
                        <GatsbyImage image={lessIcon} alt={'Prev'} />
                      </CounterBtn>
                      <ToolbarButton
                        onClick={() => props.onNavigate('TODAY')}
                        selected={isToday(props.date)}
                        isToday
                        style={{ background: 'transparent', fontWeight: `${isToday(props.date) ? 800 : 600}` }}
                      >
                        Today
                      </ToolbarButton>
                      <CounterBtn onClick={() => props.onNavigate('NEXT')} height="30px" width="30px">
                        <GatsbyImage image={moreIcon} alt={'Next'} />
                      </CounterBtn>
                    </div>
                  </ToolsContainer>
                )}
                {mobile && (
                  <div style={{ display: 'flex' }}>
                    <RecordBody onClick={handleHeaderClick} fontWeight="800" fontSize={16} style={{ cursor: 'pointer' }}>
                      {mobileDateLabel}
                    </RecordBody>
                  </div>
                )}
                {mobile && (
                  <BusinessUserImageContainer>
                    <RecordBody style={{ position: 'absolute', right: '3px', height: 0 }}>
                      <OptionDropdown
                        mobileDisplayIcon={mobile}
                        menuButtonType={OPTION_DROPDOWN_MENU_BUTTON_TYPES.MORE}
                        options={[bookingTypeOptions]}
                        noApplyButton
                        menuPosition={OPTION_DROPDOWN_MENU_POSITIONS.LEFT}
                      />
                    </RecordBody>
                    {/* {!profile?.profile_pic && <DefaultBusinessUserImage image={icons?.user?.childImageSharp?.gatsbyImageData} alt="Business user" />}
                  {profile?.profile_pic && <BusinessUserImage src={profile.profile_pic} alt="Business user" />} */}
                  </BusinessUserImageContainer>
                )}
              </CalendarToolBarContainer>
            );
          }
        }}
      />
    </div>
  );
};

export default BigCalendar;
