import styled from 'styled-components';

export const DateContainer = styled.div`
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  cursor: pointer;
`;

export const NavigationButtonsContainer = styled.div`
  display: flex;
  align-items: center;
`;
