import { useQuery, useReactiveVar } from '@apollo/client';
import React from 'react';
import useURLSearchParams from '../../../../hooks/useURLSearchParams';
import { GetBranchAppointmentTags, GetBranchTagCategories, GetSlotServices } from '../../../../queries';
import { vars } from '../../../../reactive';
import { getNextDay, getStartOfDate, getStartOfToday } from '../../../../utils/dates';
import { ORDER_STATUS } from '../../../Orders/types';
import { BranchAppointmentTag } from '../../../Store/BranchTags/types';
import { Product } from '../../../Store/types';
import { useBookingsFilters } from '../../BookingsFilters';
import { BOOKING_STATUS_TYPES, BOOKING_TAB, BOOKING_TABS_TYPES, BOOKING_TABS_VALUES_TYPES, BOOKING_TYPE } from '../../types';
import BookingsBody from '../BookingsBody/BookingsBody';
import BookingsHeader from '../BookingsHeader/BookingsHeader';

const BookingsContent = () => {
  const today = getStartOfToday();
  const isMultiStaffView = useReactiveVar(vars.isMultiStaffView);
  const [urlDate] = useURLSearchParams('date');

  const selectedDate = new Date(urlDate)?.getTime() ? new Date(urlDate) : new Date();

  const tabs: BOOKING_TAB[] = [
    {
      name: BOOKING_TABS_TYPES.CONFIRMED,
      value: BOOKING_TABS_VALUES_TYPES.CONFIRMED,
      status: [BOOKING_STATUS_TYPES.CONFIRMED],
      booking_type: [BOOKING_TYPE.SLOT, BOOKING_TYPE.MULTI_SLOT, BOOKING_TYPE.MULTI_DAY],
      timestamp_from: getStartOfDate(selectedDate),
      timestamp_to: getStartOfDate(getNextDay(selectedDate)),
      order_status: null,
      recordOptions: {
        dispalyName: true,
        displayBreed: true,
        displayServiceName: isMultiStaffView,
        displayBookingTime: true,
        dispalyBookingTags: true,
        dispalyOptionalDropDown: !isMultiStaffView,
        displayOrderStatus: false,
        displayStaffName: true
      }
    },
    {
      name: BOOKING_TABS_TYPES.NEW,
      value: BOOKING_TABS_VALUES_TYPES.NEW,
      status: [BOOKING_STATUS_TYPES.REQUESTED],
      booking_type: [BOOKING_TYPE.SLOT, BOOKING_TYPE.MULTI_SLOT, BOOKING_TYPE.MULTI_DAY],
      timestamp_from: null,
      timestamp_to: null,
      order_status: null,
      recordOptions: {
        dispalyName: true,
        displayBreed: true,
        displayServiceName: true,
        displayBookingTime: true,
        dispalyBookingTags: true,
        dispalyOptionalDropDown: true,
        displayOrderStatus: false,
        displayStaffName: true
      }
    },

    // {
    //   name: BOOKING_TABS_TYPES.MULTI_DAY,
    //   value: BOOKING_TABS_VALUES_TYPES.MULTI_DAY,
    //   status: [BOOKING_STATUS_TYPES.CONFIRMED],
    //   booking_type: [BOOKING_TYPE.MULTI_DAY],
    //   timestamp_from: today.toISOString(),
    //   timestamp_to: null,
    //   order_status: null,
    //   recordOptions: {
    //     dispalyName: true,
    //     displayBreed: true,
    //     displayServiceName: true,
    //     displayBookingTime: true,
    //     dispalyBookingTags: true,
    //     dispalyOptionalDropDown: true,
    //     displayOrderStatus: false,
    //     displayStaffName: true
    //   }
    // },

    {
      name: BOOKING_TABS_TYPES.UPCOMING,
      value: BOOKING_TABS_VALUES_TYPES.UPCOMING,
      status: [BOOKING_STATUS_TYPES.CONFIRMED],
      booking_type: [BOOKING_TYPE.SLOT, BOOKING_TYPE.MULTI_SLOT, BOOKING_TYPE.MULTI_DAY],
      timestamp_from: today.toISOString(),
      timestamp_to: null,
      order_status: null,
      recordOptions: {
        dispalyName: true,
        displayBreed: true,
        displayServiceName: true,
        displayBookingTime: true,
        dispalyBookingTags: true,
        dispalyOptionalDropDown: true,
        displayOrderStatus: false,
        displayStaffName: true
      }
    },

    {
      name: BOOKING_TABS_TYPES.PAYMENT,
      value: BOOKING_TABS_VALUES_TYPES.PAYMENT,
      status: [BOOKING_STATUS_TYPES.CONFIRMED],
      booking_type: [BOOKING_TYPE.SLOT, BOOKING_TYPE.MULTI_SLOT, BOOKING_TYPE.MULTI_DAY],
      order_status: [ORDER_STATUS.PAYMENT_PENDING, ORDER_STATUS.PAYMENT_REJECTED],
      recordOptions: {
        dispalyName: true,
        displayBreed: true,
        displayServiceName: true,
        displayBookingTime: true,
        dispalyBookingTags: false,
        dispalyOptionalDropDown: false,
        displayOrderStatus: true,
        displayStaffName: false
      }
    },
    {
      name: BOOKING_TABS_TYPES.CANCELED,
      value: BOOKING_TABS_VALUES_TYPES.CANCELED,
      status: [BOOKING_STATUS_TYPES.CANCELED],
      booking_type: [BOOKING_TYPE.SLOT, BOOKING_TYPE.MULTI_SLOT, BOOKING_TYPE.MULTI_DAY],
      timestamp_from: today.toISOString(),
      timestamp_to: null,
      order_status: null,
      recordOptions: {
        dispalyName: true,
        displayBreed: true,
        displayServiceName: true,
        displayBookingTime: true,
        dispalyBookingTags: true,
        dispalyOptionalDropDown: true,
        displayOrderStatus: false,
        displayStaffName: true
      }
    }
  ];
  const filtersControl = useBookingsFilters();
  const { data: { getProducts: services = [] } = {} } = useQuery<{ getProducts: Product[] }>(GetSlotServices, {
    fetchPolicy: 'cache-and-network'
  });

  const { data: { branchTagCategoryGet: tagCategories = [] } = {}, loading: loadingTagCategories } = useQuery<{ branchTagCategoryGet: { id: string; name: string }[] }>(GetBranchTagCategories);

  const { data: { getBranchAppointmentTags: allQuickTags = [] } = {}, loading: loadingAllQuickTags } = useQuery<{
    getBranchAppointmentTags: BranchAppointmentTag[];
  }>(GetBranchAppointmentTags, {
    fetchPolicy: 'cache-and-network',
    variables: {
      offset: 0,
      limit: 1000,
      where: { quick_action: true }
    }
  });

  return (
    <>
      <BookingsHeader tabs={tabs} filtersControl={filtersControl} services={services} />
      <BookingsBody tabs={tabs} filtersControl={filtersControl} services={services} />
    </>
  );
};

export default BookingsContent;
