import { useMutation } from '@apollo/client';
import React, { forwardRef, useCallback, useEffect } from 'react';
import { RetryOrderInvoice, RetryOrderSubscriptionRepeatInvoice, SendOrderInvoiceEmail, UpdateOrderInvoiceStatus, UpdateOrderSubscriptionRepeatInvoiceStatus } from '../../queries';
import { getSubscriptionRepeatTitles } from '../../views/Subscriptions/utils';
import { HoverRecordOptionsContainer } from '../../views/styled';
import ModalDialog from '../Modal/ModalDialog';
import OptionDropdown, { OptionDropDownItem } from '../Shared/Menus/OptionDropdown/OptionDropdown';
import { OPTION_DROPDOWN_MENU_BUTTON_TYPES, OPTION_DROPDOWN_TYPES } from '../Shared/Menus/OptionDropdown/types';
import RefundModal from '../Shared/Modals/RefundModal/RefundModal';
import AdjustInvoiceAmountModal from '../UserDrawer/AdjustInvoiceAmountModal';
import { ORDER_INVOICE_STATUS, OrderInvoice } from './types';
import { getOrderInvoiceTitles } from './utils';

type OrderInvoiceOptionsProps = {
  orderInvoice: OrderInvoice;
  refetchOrderInvoices: () => void;
};

const OrderInvoiceOptions = forwardRef<HTMLDivElement, OrderInvoiceOptionsProps>(({ orderInvoice, refetchOrderInvoices, isOrderSubscriptionRepeat }, optionsRef) => {
  const { id } = orderInvoice;
  const { shouldShow: { shouldShowRetry, shouldShowUpdate, shouldShowSendEmail, shouldShowMarkAsPaid, shouldDisplayRefund } = {} } = getOrderInvoiceTitles(orderInvoice);

  const [retryOrderInvoice, { data: { retryOrderInvoice: newOrderInvoice = {} } = {}, loading: loadingRetry }] = useMutation(RetryOrderInvoice);

  const [sendOrderInvoiceEmail, { loading: loadingSendEmail }] = useMutation(SendOrderInvoiceEmail);

  const [updateOrderInvoiceStatus, { loading: loadingUpdateStatus }] = useMutation(UpdateOrderInvoiceStatus);

  const [updateInvoiceStatus] = useMutation(UpdateOrderSubscriptionRepeatInvoiceStatus);
  const [retryInvoice] = useMutation(RetryOrderSubscriptionRepeatInvoice);

  const voidInvoice = () => {
    updateInvoiceStatus({ variables: { id: orderInvoice.id, invoice_status: 'void' } });
  };
  const markInvoiceUncollectible = () => {
    updateInvoiceStatus({ variables: { id: orderInvoice.id, invoice_status: 'uncollectible' } });
  };
  const markInvoiceAsPaid = () => {
    updateInvoiceStatus({ variables: { id: orderInvoice.id, invoice_status: 'mark_as_paid' } });
  };
  const retryInvoicePayment = () => {
    retryInvoice({ variables: { id: orderInvoice.id } });
  };

  const handleRefund = useCallback(
    (then: (...args: unknown[]) => void = () => {}) => {
      ModalDialog.openModal({
        content: () => <RefundModal order={orderInvoice.Order} />,
        title: 'Refund Payment',
        onClose() {
          setTimeout(() => {
            then();
          }, 3000);
        }
      });
    },
    [orderInvoice]
  );

  const loading = loadingRetry || loadingSendEmail || loadingUpdateStatus;

  const { isDraft, isUncollectible, isSubscriptionRepeatBilled } = getSubscriptionRepeatTitles(orderInvoice);

  const items = [
    shouldShowRetry && {
      name: 'Retry Charge',
      value: 'retry',
      onClick: () => {
        retryOrderInvoice({ variables: { OrderInvoiceId: [id] } });
      }
    },
    shouldShowUpdate && {
      name: 'Update Amount',
      value: 'update',
      onClick: () => {
        ModalDialog.openModal({
          title: 'Update Amount',
          content: () => <AdjustInvoiceAmountModal OrderInvoice={orderInvoice} refetchOrderInvoices={refetchOrderInvoices} />
        });
      }
    },
    shouldShowSendEmail && {
      name: 'Send Invoice',
      value: 'send',
      onClick: () => {
        sendOrderInvoiceEmail({ variables: { OrderInvoiceId: id } });
      }
    },
    shouldShowMarkAsPaid && {
      name: 'Mark as Paid',
      value: 'mark',
      onClick: () => {
        updateOrderInvoiceStatus({ variables: { OrderInvoiceId: id, status: ORDER_INVOICE_STATUS.PAYMENT_SUCCEEDED } });
      }
    },
    shouldDisplayRefund && {
      name: 'Refund payment',
      value: 'refund',
      onClick: () => handleRefund(() => refetchOrderInvoices())
    }
  ].filter(Boolean) as OptionDropDownItem[];

  const options: OptionDropDownItem[] = isSubscriptionRepeatBilled
    ? []
    : isDraft || isUncollectible
    ? [
        {
          name: 'Mark As Paid',
          value: 'mark_as_paid',
          onClick: markInvoiceAsPaid
        },
        {
          name: 'Retry Payment',
          value: 'retry',
          onClick: retryInvoicePayment
        }
      ]
    : [
        {
          name: 'Void Invoice',
          value: 'void',
          onClick: voidInvoice
        },
        {
          name: 'Mark Uncollectible',
          value: 'uncollectible',
          onClick: markInvoiceUncollectible
        },
        {
          name: 'Mark As Paid',
          value: 'mark_as_paid',
          onClick: markInvoiceAsPaid
        },
        {
          name: 'Retry Payment',
          value: 'retry',
          onClick: retryInvoicePayment
        }
      ];

  const itemsToReturn = !isOrderSubscriptionRepeat ? items : options;

  useEffect(() => {
    if (newOrderInvoice?.id) {
      refetchOrderInvoices();
    }
  }, [newOrderInvoice?.id]);

  return (
    <HoverRecordOptionsContainer ref={optionsRef} show={loading}>
      <OptionDropdown
        menuButtonType={OPTION_DROPDOWN_MENU_BUTTON_TYPES.MORE}
        noApplyButton
        containerRelative
        buttonLoading={loading}
        options={[{ optionType: OPTION_DROPDOWN_TYPES.BUTTONS, id: 'options', items: itemsToReturn }]}
      />
    </HoverRecordOptionsContainer>
  );
});

export default OrderInvoiceOptions;
