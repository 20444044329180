import { useQuery } from '@apollo/client';
import React from 'react';
import ModalDialog from '../../components/Modal/ModalDialog';
import OptionDropdown from '../../components/Shared/Menus/OptionDropdown/OptionDropdown';
import { OPTION_DROPDOWN_MENU_BUTTON_TYPES, OPTION_DROPDOWN_TYPES } from '../../components/Shared/Menus/OptionDropdown/types';
import { MainContainer, ViewWrapper } from '../../components/Shared/Shared';
import SideTab from '../../components/Shared/SideTabs/SideTabs';
import AppUserDetailsModal from '../../components/UserDrawer/AppUserDetailsModal';
import PetDetailsModal from '../../components/UserDrawer/PetDetailsModal';
import useMediaQuery from '../../hooks/useMediaQuery';
import { GetBusUserProfile } from '../../queries';
import { isUserAdminOrSuper } from '../../utils/helpers';
import PetsBody from './PetsBody/PetsBody';
import PetClientsBody from './PetsClients/PetsClientsBody';
import { usePetsFilters } from './PetsFilters';
import PetsForms from './PetsForms';
import PetsHeader from './PetsHeader/PetsHeader';

export enum PETS_TABS_TYPES {
  ALL = 'All',
  FORMS = 'Forms',
  ACTIVE = 'Active',
  CLIENTS = 'Clients',
  ARCHIVED = 'Archived',
  PETS = 'Pets'
}

const sections = [
  {
    tabs: [
      {
        name: PETS_TABS_TYPES.ALL,
        value: PETS_TABS_TYPES.ALL.toLowerCase()
        // component: () => <PetsBody selectedTabState={selectedTabState} />
      }
    ]
  },
  // {
  //   tabs: [
  //     {
  //       name: PETS_TABS_TYPES.ACTIVE,
  //       value: PETS_TABS_TYPES.ACTIVE.toLowerCase()
  //     },
  //     {
  //       name: PETS_TABS_TYPES.ARCHIVED,
  //       value: PETS_TABS_TYPES.ARCHIVED.toLowerCase()
  //     }
  //   ]
  // },
  {
    tabs: [
      {
        name: PETS_TABS_TYPES.CLIENTS,
        value: PETS_TABS_TYPES.CLIENTS.toLowerCase()
      }
    ]
  },
  {
    tabs: [
      {
        name: PETS_TABS_TYPES.FORMS,
        value: PETS_TABS_TYPES.FORMS.toLowerCase()
      }
    ]
  }
];

const mobileSections = [
  {
    tabs: [
      {
        name: PETS_TABS_TYPES.PETS,
        value: PETS_TABS_TYPES.ALL.toLowerCase()
      }
    ]
  },

  {
    tabs: [
      {
        name: PETS_TABS_TYPES.CLIENTS,
        value: PETS_TABS_TYPES.CLIENTS.toLowerCase()
      }
    ]
  }
];

const Pets = () => {
  const { data: { getBusUserProfile: currentBusUserProfile = {} } = {} } = useQuery(GetBusUserProfile, {
    fetchPolicy: 'cache-only'
  });
  const isAdminOrSuperAdmin = isUserAdminOrSuper(currentBusUserProfile);
  const { mobile } = useMediaQuery({ mobile: true });
  const sectionsToDisplay = mobile ? mobileSections : sections;
  const filtersControl = usePetsFilters();
  const { selectedFilters } = filtersControl;
  const tabs = sections.flatMap(section => section.tabs);

  const tabsValues = tabs.map(tab => tab.value);
  const tabValue = tabsValues?.find(tab => tab === location.pathname.split('/')[3]);
  const selectedTabState = tabValue || PETS_TABS_TYPES.ALL.toLowerCase();

  let SelectedTab;
  switch (tabValue) {
    case PETS_TABS_TYPES.ALL.toLowerCase():
      SelectedTab = <PetsBody selectedTabState={selectedTabState} selectedFilters={selectedFilters} />;
      break;

    case PETS_TABS_TYPES.CLIENTS.toLowerCase():
      SelectedTab = <PetClientsBody />;
      break;
    case PETS_TABS_TYPES.FORMS.toLowerCase():
      SelectedTab = !mobile ? <PetsForms /> : <PetsBody selectedTabState={selectedTabState} selectedFilters={selectedFilters} />;
      break;
    default:
      SelectedTab = <PetsBody selectedTabState={selectedTabState} />;
      break;
  }

  const ActionsDropdown = () => {
    return (
      <OptionDropdown
        options={[
          {
            id: 'addItems',
            optionType: OPTION_DROPDOWN_TYPES.BUTTONS,
            items: [
              ...(isAdminOrSuperAdmin
                ? [
                    {
                      name: 'Add Client',
                      value: 'addAppUser',
                      onClick: () => {
                        ModalDialog.openModal({
                          content: () => <AppUserDetailsModal />,
                          title: 'Add Client'
                        });
                      }
                    },
                    {
                      name: 'Add Pet',
                      value: 'addPet',
                      onClick: () => {
                        ModalDialog.openModal({
                          content: () => <PetDetailsModal />,
                          title: 'Add Pet'
                        });
                      }
                    }
                  ]
                : [])
            ]
          }
        ]}
        menuButtonType={OPTION_DROPDOWN_MENU_BUTTON_TYPES.PLUS_BLACK}
        noApplyButton
      />
    );
  };

  return (
    <ViewWrapper>
      {!mobile && <SideTab sections={sectionsToDisplay} count={true} tabsWithCount={[PETS_TABS_TYPES.FORMS]} defaultTab={PETS_TABS_TYPES.ALL} />}

      <MainContainer>
        {mobile && <SideTab sections={sectionsToDisplay} count={true} tabsWithCount={[PETS_TABS_TYPES.FORMS]} defaultTab={PETS_TABS_TYPES.ALL} components={[ActionsDropdown]} />}
        <PetsHeader filtersControl={filtersControl} SelectedTab={selectedTabState} />
        {SelectedTab}
      </MainContainer>
    </ViewWrapper>
  );
};

export default Pets;
