import { useLazyQuery } from '@apollo/client';
import { SelectedFilter } from '../../components/Shared/Filters/config';
import { getFilter, getMoreOptionsValues } from '../../components/Shared/Filters/helpers';
import useFilters from '../../components/Shared/Filters/useFilters';
import { OPTION_DROPDOWN_TYPES } from '../../components/Shared/Menus/OptionDropdown/types';
import { GetAllBreeds, GetAllProducts, GetBranchCategoryByName, GetBranchPetRecordTags, GetProductKinds, GetVaccRecordTypes } from '../../queries';
import { WEEK_DAYS, ageToBirthdate, dateInUTC, getPreviousYearDate } from '../../utils/dates';
import { getBirthdateDateFromTo } from '../../utils/helpers';
import { BranchPetRecordTag } from '../Store/BranchTags/types';

enum PETS_FILTER_TYPES {
  DAYCARE = 'DAYCARE',
  BREED = 'BREED',
  AGE = 'AGE',
  BIRTHDATE = 'BIRTHDATE',
  NEUTERING = 'NEUTERING',
  VACCINATION = 'VACCINATION',
  TREATMENT = 'TREATMENT',
  WEIGHT = 'WEIGHT',
  SEARCH = 'SEARCH',
  TAGS = 'TAGS',
  QUICK_TAGS = 'QUICK_TAGS'
}

const HIDDEN_PETS_FILTERS = {
  [PETS_FILTER_TYPES.DAYCARE]: false,
  [PETS_FILTER_TYPES.BREED]: false,
  [PETS_FILTER_TYPES.AGE]: false,
  [PETS_FILTER_TYPES.NEUTERING]: false,
  [PETS_FILTER_TYPES.VACCINATION]: false,
  [PETS_FILTER_TYPES.TREATMENT]: false,
  [PETS_FILTER_TYPES.WEIGHT]: false,
  [PETS_FILTER_TYPES.SEARCH]: true,
  [PETS_FILTER_TYPES.TAGS]: false,
  [PETS_FILTER_TYPES.BIRTHDATE]: false,
  [PETS_FILTER_TYPES.QUICK_TAGS]: true
};
const PETS_FILTERS_QUERIES = {
  [PETS_FILTER_TYPES.DAYCARE]: ['branchPetRecordItem_BranchId', 'branchPetRecordItem_days', 'branchPetRecordItem_type'],
  [PETS_FILTER_TYPES.BREED]: ['pet_BreedId'],
  [PETS_FILTER_TYPES.AGE]: ['pet_birthdate_from', 'pet_birthdate_to'],
  [PETS_FILTER_TYPES.NEUTERING]: ['petRecord_neutred'],
  [PETS_FILTER_TYPES.VACCINATION]: ['vaccRecordType_name', 'vaccRecord_nextdate_from', 'vaccRecord_nextdate_to'],
  [PETS_FILTER_TYPES.TREATMENT]: ['treatment_productKind_name', 'treatment_product_name', 'treatment_nextdate_from', 'treatment_nextdate_to'],
  [PETS_FILTER_TYPES.WEIGHT]: ['measurements_weight_from', 'measurements_weight_to'],
  [PETS_FILTER_TYPES.SEARCH]: ['pet_name'],
  [PETS_FILTER_TYPES.TAGS]: ['branchPetRecordTag_name'],
  [PETS_FILTER_TYPES.QUICK_TAGS]: ['branchPetRecordTag_name'],
  [PETS_FILTER_TYPES.BIRTHDATE]: ['pet_birthdate_day_from', 'pet_birthdate_day_to', 'pet_birthdate_month_from', 'pet_birthdate_month_to']
};
enum PETS_MORE_OPTIONS_TYPES {
  DUE = 'DUE',
  DATE = 'DATE',
  VACC_TYPE = 'VACC_TYPE',
  DAY_CARE = 'DAY_CARE',
  DAY = 'DAY',
  BREED = 'BREED',
  NEUTERED = 'NEUTERED',
  FROM_NUMBER = 'FROM_NUMBER',
  TO_NUMBER = 'TO_NUMBER',
  TREATMENT_NAME = 'TREATMENT_NAME',
  TREATMENT_TYPE = 'TREATMENT_TYPE',
  SEARCH = 'SEARCH',
  TAGS = 'TAGS',
  BIRTHDATE = 'BIRTHDATE'
}

const PETS_FILTER_TYPES_NAMES: Record<PETS_FILTER_TYPES, string> = {
  [PETS_FILTER_TYPES.DAYCARE]: 'Daycare',
  [PETS_FILTER_TYPES.BREED]: 'Breed',
  [PETS_FILTER_TYPES.AGE]: 'Age',
  [PETS_FILTER_TYPES.NEUTERING]: 'Neutering',
  [PETS_FILTER_TYPES.VACCINATION]: 'Vaccination',
  [PETS_FILTER_TYPES.TREATMENT]: 'Treatment',
  [PETS_FILTER_TYPES.WEIGHT]: 'Weight',
  [PETS_FILTER_TYPES.SEARCH]: 'Search',
  [PETS_FILTER_TYPES.TAGS]: 'Tags',
  [PETS_FILTER_TYPES.BIRTHDATE]: 'Birthday',
  [PETS_FILTER_TYPES.QUICK_TAGS]: ''
};

const PETS_MORE_OPTIONS_TITLES: Record<PETS_MORE_OPTIONS_TYPES, string> = {
  [PETS_MORE_OPTIONS_TYPES.DUE]: 'Due',
  [PETS_MORE_OPTIONS_TYPES.DATE]: '',
  [PETS_MORE_OPTIONS_TYPES.VACC_TYPE]: 'Vaccination Type',
  [PETS_MORE_OPTIONS_TYPES.DAY_CARE]: 'Daycare',
  [PETS_MORE_OPTIONS_TYPES.DAY]: 'Day',
  [PETS_MORE_OPTIONS_TYPES.BREED]: 'Breed',
  [PETS_MORE_OPTIONS_TYPES.NEUTERED]: 'Neutered',
  [PETS_MORE_OPTIONS_TYPES.FROM_NUMBER]: 'From',
  [PETS_MORE_OPTIONS_TYPES.TO_NUMBER]: 'To',
  [PETS_MORE_OPTIONS_TYPES.TREATMENT_NAME]: 'Treatment Name',
  [PETS_MORE_OPTIONS_TYPES.TREATMENT_TYPE]: 'Treatment Type',
  [PETS_MORE_OPTIONS_TYPES.SEARCH]: '',
  [PETS_MORE_OPTIONS_TYPES.TAGS]: 'Tags',
  [PETS_MORE_OPTIONS_TYPES.BIRTHDATE]: 'Birthday'
};

const PETS_MORE_OPTIONS_DROPDOWN_TYPES: Record<PETS_MORE_OPTIONS_TYPES, OPTION_DROPDOWN_TYPES> = {
  [PETS_MORE_OPTIONS_TYPES.DUE]: OPTION_DROPDOWN_TYPES.SELECT,
  [PETS_MORE_OPTIONS_TYPES.DATE]: OPTION_DROPDOWN_TYPES.DATE,
  [PETS_MORE_OPTIONS_TYPES.VACC_TYPE]: OPTION_DROPDOWN_TYPES.MULTI_SELECT,
  [PETS_MORE_OPTIONS_TYPES.DAY_CARE]: OPTION_DROPDOWN_TYPES.MULTI_SELECT,
  [PETS_MORE_OPTIONS_TYPES.DAY]: OPTION_DROPDOWN_TYPES.MULTI_SELECT,
  [PETS_MORE_OPTIONS_TYPES.BREED]: OPTION_DROPDOWN_TYPES.MULTI_SELECT,
  [PETS_MORE_OPTIONS_TYPES.NEUTERED]: OPTION_DROPDOWN_TYPES.RADIO,
  [PETS_MORE_OPTIONS_TYPES.FROM_NUMBER]: OPTION_DROPDOWN_TYPES.NUMBER,
  [PETS_MORE_OPTIONS_TYPES.TO_NUMBER]: OPTION_DROPDOWN_TYPES.NUMBER,
  [PETS_MORE_OPTIONS_TYPES.TREATMENT_NAME]: OPTION_DROPDOWN_TYPES.MULTI_SELECT,
  [PETS_MORE_OPTIONS_TYPES.TREATMENT_TYPE]: OPTION_DROPDOWN_TYPES.MULTI_SELECT,
  [PETS_MORE_OPTIONS_TYPES.SEARCH]: OPTION_DROPDOWN_TYPES.TEXT_INPUT,
  [PETS_MORE_OPTIONS_TYPES.TAGS]: OPTION_DROPDOWN_TYPES.MULTI_SELECT,
  [PETS_MORE_OPTIONS_TYPES.BIRTHDATE]: OPTION_DROPDOWN_TYPES.SELECT
};

const PETS_FILTER_TYPES_UNITS: Record<PETS_FILTER_TYPES, string> = {
  [PETS_FILTER_TYPES.DAYCARE]: '',
  [PETS_FILTER_TYPES.BREED]: '',
  [PETS_FILTER_TYPES.AGE]: 'Years',
  [PETS_FILTER_TYPES.NEUTERING]: '',
  [PETS_FILTER_TYPES.VACCINATION]: '',
  [PETS_FILTER_TYPES.TREATMENT]: '',
  [PETS_FILTER_TYPES.WEIGHT]: 'kg',
  [PETS_FILTER_TYPES.SEARCH]: '',
  [PETS_FILTER_TYPES.TAGS]: '',
  [PETS_FILTER_TYPES.QUICK_TAGS]: '',
  [PETS_FILTER_TYPES.BIRTHDATE]: ''
};
const PETS_FILTER_TYPES_MORE_OPTIONS: Record<PETS_FILTER_TYPES, PETS_MORE_OPTIONS_TYPES[]> = {
  [PETS_FILTER_TYPES.DAYCARE]: [PETS_MORE_OPTIONS_TYPES.DAY_CARE, PETS_MORE_OPTIONS_TYPES.DAY],
  [PETS_FILTER_TYPES.BREED]: [PETS_MORE_OPTIONS_TYPES.BREED],
  [PETS_FILTER_TYPES.AGE]: [PETS_MORE_OPTIONS_TYPES.FROM_NUMBER, PETS_MORE_OPTIONS_TYPES.TO_NUMBER],
  [PETS_FILTER_TYPES.NEUTERING]: [PETS_MORE_OPTIONS_TYPES.NEUTERED],
  [PETS_FILTER_TYPES.VACCINATION]: [PETS_MORE_OPTIONS_TYPES.VACC_TYPE, PETS_MORE_OPTIONS_TYPES.DUE, PETS_MORE_OPTIONS_TYPES.DATE],
  [PETS_FILTER_TYPES.TREATMENT]: [PETS_MORE_OPTIONS_TYPES.TREATMENT_TYPE, PETS_MORE_OPTIONS_TYPES.TREATMENT_NAME, PETS_MORE_OPTIONS_TYPES.DUE, PETS_MORE_OPTIONS_TYPES.DATE],
  [PETS_FILTER_TYPES.WEIGHT]: [PETS_MORE_OPTIONS_TYPES.FROM_NUMBER, PETS_MORE_OPTIONS_TYPES.TO_NUMBER],
  [PETS_FILTER_TYPES.SEARCH]: [PETS_MORE_OPTIONS_TYPES.SEARCH],
  [PETS_FILTER_TYPES.TAGS]: [PETS_MORE_OPTIONS_TYPES.TAGS],
  [PETS_FILTER_TYPES.QUICK_TAGS]: [PETS_MORE_OPTIONS_TYPES.TAGS],
  [PETS_FILTER_TYPES.BIRTHDATE]: [PETS_MORE_OPTIONS_TYPES.BIRTHDATE]
};

const usePetsFilters = () => {
  const [getVacc, { data: { vaccRecordTypeGet: vaccRecordTypes = [] } = {}, called: calledVacc }] = useLazyQuery(GetVaccRecordTypes);
  const [getProductKinds, { data: { productKindGet: productKinds = [] } = {}, called: calledProductKinds }] = useLazyQuery(GetProductKinds);
  const [getBreeds, { data: { getBreeds: breeds = [] } = {}, called: calledBreeds }] = useLazyQuery(GetAllBreeds);
  const [getBranchCategories, { data: { branchCategoryGet: [{ Branches: dayCares = [] } = {}] = [] } = {}, called: calledBranchCategories }] = useLazyQuery(GetBranchCategoryByName, {
    variables: {
      name: 'Daycare'
    }
  });
  const [getProducts, { data: { productGet: allProducts = [] } = {}, called: calledProducts }] = useLazyQuery(GetAllProducts);

  const [getAllTags, { data: { getBranchPetRecordTags: allTags = [] } = {}, called: calledTags }] = useLazyQuery<{ getBranchPetRecordTags: BranchPetRecordTag[] }>(GetBranchPetRecordTags, {
    fetchPolicy: 'cache-and-network',
    variables: { offset: 0, limit: 1000 }
  });

  const toCall = [
    { called: calledVacc, get: getVacc, id: PETS_FILTER_TYPES.VACCINATION },
    { called: calledProductKinds, get: getProductKinds, id: PETS_FILTER_TYPES.TREATMENT },
    { called: calledBreeds, get: getBreeds, id: PETS_FILTER_TYPES.BREED },
    { called: calledBranchCategories, get: getBranchCategories, id: PETS_FILTER_TYPES.DAYCARE },
    { called: calledProducts, get: getProducts, id: PETS_FILTER_TYPES.TREATMENT },
    { called: calledTags, get: getAllTags, id: PETS_FILTER_TYPES.TAGS }
  ];

  const filtersControl = useFilters({
    toCall,
    getOptions: watchedValues => {
      const selectedTreatmentTypes = watchedValues?.[PETS_FILTER_TYPES.TREATMENT]?.[PETS_MORE_OPTIONS_TYPES.TREATMENT_TYPE] || [];

      const selectedTreatmentTypesIds = selectedTreatmentTypes?.map((treatmentType: any) => treatmentType.value);

      const treatmentProducts = allProducts?.filter((product: Record<string, any>) => product.ProductKinds.some((kind: Record<string, string>) => selectedTreatmentTypesIds.includes(kind.id)));

      const vaccFilterValue = watchedValues?.[PETS_FILTER_TYPES.VACCINATION]?.[PETS_MORE_OPTIONS_TYPES.VACC_TYPE] || [];

      const vaccDueDateValue = watchedValues?.[PETS_FILTER_TYPES.VACCINATION]?.[PETS_MORE_OPTIONS_TYPES.DUE];

      const treatmentFilterValue = watchedValues?.[PETS_FILTER_TYPES.TREATMENT]?.[PETS_MORE_OPTIONS_TYPES.TREATMENT_TYPE] || [];

      const treatmentDueDateValue = watchedValues?.[PETS_FILTER_TYPES.TREATMENT]?.[PETS_MORE_OPTIONS_TYPES.DUE];

      return {
        FILTER_TYPES: PETS_FILTER_TYPES,
        FILTER_TYPES_MORE_OPTIONS: {
          ...PETS_FILTER_TYPES_MORE_OPTIONS,
          [PETS_FILTER_TYPES.VACCINATION]: [PETS_MORE_OPTIONS_TYPES.VACC_TYPE, vaccFilterValue.length && PETS_MORE_OPTIONS_TYPES.DUE, vaccDueDateValue && PETS_MORE_OPTIONS_TYPES.DATE].filter(Boolean),
          [PETS_FILTER_TYPES.TREATMENT]: [
            PETS_MORE_OPTIONS_TYPES.TREATMENT_TYPE,
            PETS_MORE_OPTIONS_TYPES.TREATMENT_NAME,
            treatmentFilterValue.length && PETS_MORE_OPTIONS_TYPES.DUE,
            treatmentDueDateValue && PETS_MORE_OPTIONS_TYPES.DATE
          ].filter(Boolean)
        },
        FILTER_TYPES_NAMES: PETS_FILTER_TYPES_NAMES,
        FILTER_TYPES_UNITS: PETS_FILTER_TYPES_UNITS,
        MORE_OPTIONS_DROPDOWN_TYPES: PETS_MORE_OPTIONS_DROPDOWN_TYPES,
        MORE_OPTIONS_ITEMS: {
          [PETS_MORE_OPTIONS_TYPES.VACC_TYPE]: vaccRecordTypes?.map(({ id, name }: { id: string; name: string }) => ({ value: id, name })),
          [PETS_MORE_OPTIONS_TYPES.TREATMENT_TYPE]: productKinds?.map(({ id, name }: { id: string; name: string }) => ({ value: id, name })),
          [PETS_MORE_OPTIONS_TYPES.TREATMENT_NAME]: treatmentProducts?.map(({ id, name }: { id: string; name: string }) => ({ value: id, name })),
          [PETS_MORE_OPTIONS_TYPES.BREED]: breeds?.map(({ id, name }: { id: string; name: string }) => ({ value: id, name })),
          [PETS_MORE_OPTIONS_TYPES.DAY]: WEEK_DAYS.map(day => ({ value: day.toUpperCase(), name: day })),
          [PETS_MORE_OPTIONS_TYPES.DAY_CARE]: dayCares?.map(({ id, name }: { id: string; name: string }) => ({ value: id, name })),
          [PETS_MORE_OPTIONS_TYPES.TAGS]: allTags?.map(({ id, name }: { id: string; name: string }) => ({ value: id, name })),
          [PETS_MORE_OPTIONS_TYPES.DUE]: [
            { value: '', name: '-- None --' },
            { value: true, name: 'On or After' },
            { value: false, name: 'Before' }
          ],
          [PETS_MORE_OPTIONS_TYPES.NEUTERED]: [
            { value: true, name: 'Yes' },
            { value: false, name: 'No' }
          ],
          [PETS_MORE_OPTIONS_TYPES.DATE]: [],
          [PETS_MORE_OPTIONS_TYPES.FROM_NUMBER]: [],
          [PETS_MORE_OPTIONS_TYPES.TO_NUMBER]: [],
          [PETS_MORE_OPTIONS_TYPES.SEARCH]: [],
          [PETS_MORE_OPTIONS_TYPES.BIRTHDATE]: [
            { value: 'today', name: 'Today' },
            { value: 'inThreeDays', name: 'Within 3 Days' },
            { value: 'inAWeek', name: 'Within a Week' }
          ]
        },
        MORE_OPTIONS_TITLES: PETS_MORE_OPTIONS_TITLES,
        MORE_OPTIONS_TYPES: PETS_MORE_OPTIONS_TYPES
      };
    }
  });

  return filtersControl;
};

const requiredPetsFiltersData = {
  [PETS_FILTER_TYPES.DAYCARE]: [`daycare_filter`],
  [PETS_FILTER_TYPES.BREED]: [],
  [PETS_FILTER_TYPES.AGE]: [`age_filter`],
  [PETS_FILTER_TYPES.NEUTERING]: [`neutering_filter`],
  [PETS_FILTER_TYPES.VACCINATION]: [`vaccination_filter`],
  [PETS_FILTER_TYPES.TREATMENT]: [`treatment_filter`],
  [PETS_FILTER_TYPES.WEIGHT]: [`weight_filter`],
  [PETS_FILTER_TYPES.SEARCH]: [],
  [PETS_FILTER_TYPES.TAGS]: [],
  [PETS_FILTER_TYPES.BIRTHDATE]: [`age_filter`],
  [PETS_FILTER_TYPES.QUICK_TAGS]: []
};

const getPetsVariables = ({ defaultVariables, selectedFilters }: { defaultVariables: Record<string, any>; selectedFilters: SelectedFilter[] }) => {
  const getFilterValue = (filter: PETS_FILTER_TYPES) => getFilter(selectedFilters, filter);

  const dayCareFilterType = getFilterValue(PETS_FILTER_TYPES.DAYCARE);
  const dayCareFilter = getMoreOptionsValues(dayCareFilterType, PETS_MORE_OPTIONS_TYPES.DAY_CARE);
  const dayCareDayFilter = getMoreOptionsValues(dayCareFilterType, PETS_MORE_OPTIONS_TYPES.DAY);

  const breedFilterType = getFilterValue(PETS_FILTER_TYPES.BREED);
  const breedFilter = getMoreOptionsValues(breedFilterType, PETS_MORE_OPTIONS_TYPES.BREED);

  const ageFilterType = getFilterValue(PETS_FILTER_TYPES.AGE);
  const ageFilterFrom = getMoreOptionsValues(ageFilterType, PETS_MORE_OPTIONS_TYPES.FROM_NUMBER);
  const ageFilterTo = getMoreOptionsValues(ageFilterType, PETS_MORE_OPTIONS_TYPES.TO_NUMBER);

  const birthdateFilterType = getFilterValue(PETS_FILTER_TYPES.BIRTHDATE);
  const birthdateFilter = getMoreOptionsValues(birthdateFilterType, PETS_MORE_OPTIONS_TYPES.BIRTHDATE) as 'today' | 'inAWeek' | 'inThreeDays';

  const nuetrudedFilterType = getFilterValue(PETS_FILTER_TYPES.NEUTERING);
  const nuetrudedFilter = getMoreOptionsValues(nuetrudedFilterType, PETS_MORE_OPTIONS_TYPES.NEUTERED);

  const vaccinationFilterType = getFilterValue(PETS_FILTER_TYPES.VACCINATION);
  const vaccinationFilter = getMoreOptionsValues(vaccinationFilterType, PETS_MORE_OPTIONS_TYPES.VACC_TYPE, { name: true });
  const vaccinationDateFilter = getMoreOptionsValues(vaccinationFilterType, PETS_MORE_OPTIONS_TYPES.DATE);
  const vaccinationDueDateFilter = getMoreOptionsValues(vaccinationFilterType, PETS_MORE_OPTIONS_TYPES.DUE);

  const suppFilter = getFilterValue(PETS_FILTER_TYPES.TREATMENT);
  const suppFilterProductKind = getMoreOptionsValues(suppFilter, PETS_MORE_OPTIONS_TYPES.TREATMENT_TYPE, { name: true });
  const suppFilterProduct = getMoreOptionsValues(suppFilter, PETS_MORE_OPTIONS_TYPES.TREATMENT_NAME, { name: true });
  const suppFilterDate = getMoreOptionsValues(suppFilter, PETS_MORE_OPTIONS_TYPES.DATE);
  const suppFilterDueDate = getMoreOptionsValues(suppFilter, PETS_MORE_OPTIONS_TYPES.DUE);

  const weightFilterType = getFilterValue(PETS_FILTER_TYPES.WEIGHT);
  const weightFilterFrom = getMoreOptionsValues(weightFilterType, PETS_MORE_OPTIONS_TYPES.FROM_NUMBER);
  const weightFilterTo = getMoreOptionsValues(weightFilterType, PETS_MORE_OPTIONS_TYPES.TO_NUMBER);
  // search
  const searchFilter = getFilterValue(PETS_FILTER_TYPES.SEARCH);
  const searchFilterValue = getMoreOptionsValues(searchFilter, PETS_MORE_OPTIONS_TYPES.SEARCH);
  // tags
  const tagsFilter = getFilterValue(PETS_FILTER_TYPES.TAGS);
  const quickTagsFilter = getFilterValue(PETS_FILTER_TYPES.QUICK_TAGS);
  const tagsFilterValue = getMoreOptionsValues(tagsFilter, PETS_MORE_OPTIONS_TYPES.TAGS, { name: true });
  const quickTagsFilterValue = getMoreOptionsValues(quickTagsFilter, PETS_MORE_OPTIONS_TYPES.TAGS, { name: true });
  const allTags = [...new Set([...(tagsFilterValue || []), ...(quickTagsFilterValue || [])])];
  const fragmentVariables =
    selectedFilters
      ?.map(({ value }) => requiredPetsFiltersData[value])
      .flat()
      ?.reduce((acc, curr) => ({ ...acc, [curr]: true }), {} as Record<string, boolean>) || {};

  const variables = {
    ...defaultVariables,
    branchPetRecordItem_BranchId: dayCareFilter || null,
    branchPetRecordItem_days: dayCareDayFilter || null,
    branchPetRecordItem_type: dayCareFilter || dayCareDayFilter ? ['DAYCARE'] : null,
    pet_BreedId: breedFilter || null,
    pet_birthdate_from: getPreviousYearDate(ageToBirthdate(ageFilterTo as string)) || null,
    pet_birthdate_to: ageToBirthdate(ageFilterFrom as string) || null,
    pet_birthdate_month_from: getBirthdateDateFromTo(birthdateFilter)?.monthFrom || null,
    pet_birthdate_month_to: getBirthdateDateFromTo(birthdateFilter)?.monthTo || null,
    pet_birthdate_day_from: getBirthdateDateFromTo(birthdateFilter)?.dayFrom || null,
    pet_birthdate_day_to: getBirthdateDateFromTo(birthdateFilter)?.dayTo || null,
    petRecord_neutred: nuetrudedFilter === 'true' ? true : nuetrudedFilter === 'false' ? false : null,
    vaccRecordType_name: vaccinationFilter,
    vaccRecord_nextdate_from: String(vaccinationDueDateFilter) === 'true' ? dateInUTC(vaccinationDateFilter as string) : null,
    vaccRecord_nextdate_to: String(vaccinationDueDateFilter) === 'false' ? dateInUTC(vaccinationDateFilter as string) : null,
    treatment_productKind_name: suppFilterProductKind || null,
    treatment_product_name: suppFilterProduct || null,
    treatment_nextdate_from: String(suppFilterDueDate) === 'true' ? dateInUTC(suppFilterDate as string) : null,
    treatment_nextdate_to: String(suppFilterDueDate) === 'false' ? dateInUTC(suppFilterDate as string) : null,
    measurements_weight_from: weightFilterFrom || null,
    measurements_weight_to: weightFilterTo || null,
    pet_name: searchFilterValue ? [searchFilterValue] : null,
    branchPetRecordTag_name: allTags || null,
    ...fragmentVariables,
    requisite_queries: Object.values(PETS_FILTER_TYPES)
      .map(filter => {
        const filterType = getFilterValue(filter);
        return filterType?.requisite === 'true' ? PETS_FILTERS_QUERIES[filter] : null;
      })
      .filter(Boolean)
      .flat(),
    alternative_queries: Object.values(PETS_FILTER_TYPES)
      .map(filter => {
        const filterType = getFilterValue(filter);
        return filterType?.requisite === 'false' ? PETS_FILTERS_QUERIES[filter] : null;
      })
      .filter(Boolean)
      .flat()
  };

  return variables;
};

export { HIDDEN_PETS_FILTERS, PETS_FILTER_TYPES, PETS_MORE_OPTIONS_TYPES, getPetsVariables, usePetsFilters };
