import { useQuery } from '@apollo/client';
import React from 'react';
import { Controller } from 'react-hook-form';
import { sentenceCase } from 'sentence-case';
import DurationSelector from '../../../../components/Shared/DurationSelector';
import { FormError, FormInput, FormLabel, InputGroup, InputsWrapper, selectTheme, WideInputGroup } from '../../../../components/Shared/Forms/Forms';
import { GetBranchSchedules } from '../../../../queries';
import { getKeys } from '../../../../utils/helpers';
import { BOOKING_TYPE, SLOT_OFFSET_TYPE, slotsOffsetTypesOptions } from '../../../Bookings/types';
import { typesOptions } from '../../BranchSchedules/BranchScheduleModal/types';
import { BranchSchedule } from '../../BranchSchedules/types';
import ProductOptions, { getSelectedProductOptions, ProductOptionsTypes } from '../../ProductOptions';
import { StoreProductModalSectionsCommonProps, unitOptions } from './types';
import Select from '../../../../components/Shared/Forms/Select';
import { Divider } from '../../../Pets/Health/styled';
import { FlexRow } from '../../../../components/Shared/Shared';
import { FlexColumnBetweenContainer } from '../../../../components/Pets/styled';
const StoreProductModalAvailability = ({ formOptions, defaultValues, type }: StoreProductModalSectionsCommonProps) => {
  const { control, errors, watch, setValue } = formOptions;
  const isService = type === 'service';
  const isProduct = type === 'product';
  const isSubscription = type === 'subscription';
  const watchedBookingType = watch('booking_type');
  const watchedProductOptions = watch('productOptions');
  const watchedSlotOffsetType = watch('slotOffsetType');

  const isSlot = watchedBookingType === BOOKING_TYPE.SLOT;
  const isMultiSlot = watchedBookingType === BOOKING_TYPE.MULTI_SLOT;

  const schedulesBookingType = isSlot ? [BOOKING_TYPE.SLOT, 'OTHER', 'ONE_OFF'] : [watchedBookingType];

  const { data: { getBranchSchedules: schedules = [] } = {} } = useQuery<{
    getBranchSchedules: BranchSchedule[];
  }>(GetBranchSchedules, {
    variables: {
      booking_type: schedulesBookingType,
      offset: 0,
      limit: 100
    },
    fetchPolicy: 'network-only'
  });

  const schedulesOptions = schedules.map(schedule => ({
    value: schedule.id!,
    label: schedule.name
  }));

  const bookingTypesOptions = Object.keys(BOOKING_TYPE);

  const typesOptionsFiltered = typesOptions.filter(type => bookingTypesOptions.includes(type.id));

  const isRecurring = getSelectedProductOptions(watchedProductOptions)?.allows_repeated_orders;
  const isBulk = getSelectedProductOptions(watchedProductOptions)?.allows_bulk_orders;
  const showSessions = !isRecurring && !isBulk && isSlot && isService;

  const slotOffsetTypes = getKeys(SLOT_OFFSET_TYPE);

  return (
    <InputsWrapper noWrap>
      {isService && (
        <>
          <InputGroup>
            <FormLabel>Schedule type</FormLabel>
            <Controller
              render={({ onChange, value }) => (
                <Select
                  theme={selectTheme}
                  options={typesOptionsFiltered.map(option => ({ value: option.id, label: option.name }))}
                  value={{ value: value, label: typesOptionsFiltered.find(option => option.id === value)?.name }}
                  onChange={option => {
                    onChange(option?.value || typesOptionsFiltered[0].id);
                  }}
                />
              )}
              control={control}
              name={'booking_type'}
              defaultValue={defaultValues?.booking_type}
              rules={{ required: true }}
            />
            {errors.booking_type && <FormError>{errors.booking_type.message || 'type is required'}</FormError>}
          </InputGroup>

          <InputGroup>
            <FormLabel>Schedule(s)</FormLabel>
            <Controller
              render={({ onChange, value }) => (
                <Select
                  options={schedulesOptions}
                  onChange={options => {
                    onChange(options.map(option => option.value));
                  }}
                  value={schedulesOptions.filter(option => value.includes(option.value))}
                  isMulti
                />
              )}
              control={control}
              name={'branch_schedules'}
              defaultValue={defaultValues?.branch_schedules}
              rules={{
                required: true
              }}
            />
            {errors.branch_schedules && <FormError>{errors.branch_schedules.map(error => error?.message).join(', ') || 'Schedule is required'}</FormError>}
          </InputGroup>

          <Divider verticalMargin={20} />

          <InputGroup>
            {watchedBookingType !== BOOKING_TYPE.MULTI_DAY && (
              <DurationSelector
                formOptions={formOptions}
                defaultDuration={defaultValues?.duration || 0}
                options={{
                  title: 'Service Duration',
                  name: 'duration'
                }}
              />
            )}
          </InputGroup>

          {!isMultiSlot && (
            <InputGroup>
              <FormLabel bold>Minumum notice</FormLabel>
              <FlexRow gap={10}>
                <Controller
                  render={({ onChange, value }) => (
                    <FlexColumnBetweenContainer>
                      <FormLabel>Rule</FormLabel>
                      <InputGroup width={200}>
                        <Select
                          theme={selectTheme}
                          options={slotsOffsetTypesOptions}
                          value={{ value: value, label: slotsOffsetTypesOptions.find(option => option?.value === value)?.label }}
                          onChange={option => {
                            const newValue = option?.value || slotOffsetTypes[0];
                            if ([SLOT_OFFSET_TYPE.NO_OFFSET, SLOT_OFFSET_TYPE.START_DATE].includes(newValue)) {
                              setValue('max_slot_offset', 0);
                            }
                            if ([SLOT_OFFSET_TYPE.NO_OFFSET, SLOT_OFFSET_TYPE.SLOT_TIME_OFFSET].includes(newValue)) {
                              setValue('slots_start_date', '');
                            }
                            onChange(newValue);
                          }}
                        />
                      </InputGroup>
                    </FlexColumnBetweenContainer>
                  )}
                  control={control}
                  name={'slotOffsetType'}
                  defaultValue={defaultValues?.slotOffsetType}
                  rules={{ required: true }}
                />
                {(watchedSlotOffsetType === SLOT_OFFSET_TYPE.START_DATE || isMultiSlot) && (
                  <>
                    <Controller
                      render={field => (
                        <FlexColumnBetweenContainer>
                          <FormLabel>Date</FormLabel>
                          <FormInput error={!!errors.slots_start_date?.message} type={'date'} {...field} />
                        </FlexColumnBetweenContainer>
                      )}
                      control={control}
                      name={'slots_start_date'}
                      defaultValue={defaultValues?.slots_start_date}
                      rules={{
                        required: true,
                        validate: val => !!new Date(val).getTime()
                      }}
                    />
                    {errors.slots_start_date && <FormError>{errors.slots_start_date.message || 'Start date is invalid'}</FormError>}
                  </>
                )}

                {watchedSlotOffsetType === SLOT_OFFSET_TYPE.SLOT_TIME_OFFSET && !isMultiSlot && (
                  <DurationSelector
                    formOptions={formOptions}
                    defaultDuration={defaultValues?.max_slot_offset || 0}
                    Container={FlexColumnBetweenContainer}
                    options={{
                      includeDays: true,
                      name: 'max_slot_offset',
                      noTitle: true
                    }}
                  />
                )}
              </FlexRow>
            </InputGroup>
          )}

          <Divider verticalMargin={20} />
        </>
      )}

      {isProduct && (
        <InputGroup>
          <FormLabel>Weight</FormLabel>
          <Controller
            as={<FormInput error={!!errors.weight?.message} type={'number'} />}
            control={control}
            name={'weight'}
            defaultValue={defaultValues?.weight || ''}
            rules={{
              min: 0
            }}
          />
          {errors.weight && <FormError>{errors.weight.message || 'weight is required'}</FormError>}
        </InputGroup>
      )}
      {isProduct && (
        <InputGroup>
          <FormLabel>SKU Number</FormLabel>
          <Controller as={<FormInput error={!!errors.sku?.message} />} control={control} name={'sku'} defaultValue={defaultValues?.sku || ''} rules={{ required: false }} />
          {errors.sku && <FormError>{errors.sku.message || 'sku is required'}</FormError>}
        </InputGroup>
      )}

      {isProduct && (
        <InputGroup>
          <FormLabel>Application Unit</FormLabel>
          <Controller
            render={({ onChange, value }) => (
              <Select
                styles={{ container: (provided, state) => ({ ...provided }), valueContainer: (provided, state) => ({ ...provided, height: 48, overflowY: 'scroll' }) }}
                options={unitOptions}
                theme={selectTheme}
                isClearable
                onChange={onChange}
                value={unitOptions.find(option => option.value === value)}
              />
            )}
            control={control}
            name={'unit'}
            defaultValue={defaultValues?.unit}
          />
          {errors.unit && <FormError>{errors.unit.message || 'unit is required'}</FormError>}
        </InputGroup>
      )}

      <ProductOptions
        formOptions={formOptions}
        type={type}
        defaultValues={defaultValues?.productOptions}
        optionsToShow={[
          ProductOptionsTypes.ALLOWS_REPEATED_ORDERS,
          ProductOptionsTypes.ALLOWS_BULK_ORDERS,
          ProductOptionsTypes.ALLOWS_PAST_SLOTS,
          ProductOptionsTypes.AUTO_CONFIRM_APPOINTMENTS,
          ProductOptionsTypes.USER_CAN_CANCEL,
          ProductOptionsTypes.USER_CAN_RESCHEDULE,
          ProductOptionsTypes.FUTURE_DELIVERY
        ]}
      />

      {isBulk && (
        <InputGroup>
          <FormLabel>Max Bulk Orders</FormLabel>
          <Controller as={<FormInput error={!!errors.max_bulk_orders?.message} type={'number'} />} control={control} name={'max_bulk_orders'} defaultValue={defaultValues?.max_bulk_orders || 1} />
        </InputGroup>
      )}
      {showSessions && (
        <InputGroup>
          <FormLabel>Sessions</FormLabel>
          <Controller as={<FormInput error={!!errors.sessions?.message} type={'number'} />} control={control} name={'sessions'} defaultValue={defaultValues?.sessions || 1} />
        </InputGroup>
      )}
      {!isSubscription && (isService ? isMultiSlot : true) && (
        <InputGroup>
          <FormLabel>{isProduct ? 'Stock' : 'Max allowed bookings'}</FormLabel>
          <Controller
            as={<FormInput error={!!errors.stock?.message} type={'number'} />}
            control={control}
            name={'stock'}
            defaultValue={defaultValues?.stock}
            rules={{
              validate: val => {
                if (!val) {
                  return true;
                }

                const newStock = Number(val);
                const isNumber = !isNaN(newStock);
                const isPositive = newStock >= 0;
                return isNumber && isPositive;
              },
              required: false
            }}
          />
          {errors.stock && <FormError>{errors.stock.message || 'Stock is invalid'}</FormError>}
        </InputGroup>
      )}
    </InputsWrapper>
  );
};

export default StoreProductModalAvailability;
