import React from 'react';
import Linkify from 'react-linkify';
import useIcons from '../../hooks/useIcons';
import config from '../../utils/config';
import { toReadableDate } from '../../utils/dates';
import useDynamicLinks from '../../utils/dynamicLinks/useDynamicLinks';
import { BranchAppUser } from '../../views/Store/BranchBilling/types';
import { CenteredLoader } from '../Shared/Spinner';
import MessageReadStatusIndicator from './MessageReadStatusIndicator';
import {
  ChatImage,
  ChatImageContainer,
  ChatImagesContainer,
  ChatImagesTimeContainer,
  DateLabel,
  DownloadIcon,
  DynamicLinkButton,
  DynamicLinkModalContainer,
  LinkAnchor,
  LinkContainer,
  MessageContainer,
  MessagePop,
  SenderIcon,
  SenderLabel
} from './styled';

type Message = {
  body: string;
  user_type: string;
  photos: string[];
  video: { duration: number };
  user_id: string;
  id: string;
  timestamp: Date | string;
};
export type MessageReadStatus = {
  id: string;
  status: string;
  last_read_message: string;
  last_read_timestamp: string;
  Branch: {
    id: string;
  };
  AppUser: {
    id: string;
    profile_pic: string;
    name: string;
  };
  BusUser: {
    id: string;
    profile_pic: string;
    name: string;
  };
} | null;

const MessageItem = ({ msg, users, messageReadStatuses }: { msg: Message; users: BranchAppUser[]; messageReadStatuses: MessageReadStatus[] }) => {
  const icons = useIcons();
  const { body = '', user_type = '', photos = [], id: msgId = '', user_id = '', timestamp = '', video = { duration: 0 } } = msg || {};
  const user = users.find(u => u.id === user_id)!;
  const isFromUser = user_type !== 'app';
  const isToUser = user_type === 'app';
  const image = user?.profile_pic || icons.user.childImageSharp.gatsbyImageData.images.fallback.src;

  const { messageWithoutDynamicLinks: message, clickedLink, allLinks, hasDynamicLinks, loadingHandle, loadingData } = useDynamicLinks(body);
  const loading = loadingHandle || loadingData;
  const { loadModal, linkItems: { Modal = () => null } = {} } = clickedLink || {};

  const stillRunning = new Date(timestamp).getTime() + video?.duration > Date.now();

  const foundMessageReadStatuses = messageReadStatuses?.filter(mrs => mrs?.last_read_message === msgId);

  return (
    <MessageContainer isFromUser={isFromUser}>
      {loadModal && hasDynamicLinks && (
        <DynamicLinkModalContainer show={!!clickedLink}>
          {loadingData && <CenteredLoader />}
          {!loadingData && <Modal dynamicLink={clickedLink!} />}
        </DynamicLinkModalContainer>
      )}
      {isToUser && <SenderIcon isToUser={isToUser} src={image} hasMessageReadStatuses={!!foundMessageReadStatuses?.length} />}
      {!!photos?.length && (
        <ChatImagesTimeContainer isFromUser={isFromUser}>
          <SenderLabel>{user?.name || 'No User'}</SenderLabel>
          <ChatImagesContainer>
            {photos.map((photo, index) => (
              <ChatImageContainer download={msgId} href={photo} target="_blank" key={index}>
                <ChatImage src={photo} />
                <DownloadIcon src={icons.more.childImageSharp.gatsbyImageData.images.fallback.src} />
              </ChatImageContainer>
            ))}
          </ChatImagesContainer>
          <DateLabel isFromUser={isFromUser}>{toReadableDate(new Date(timestamp) || new Date())}</DateLabel>
        </ChatImagesTimeContainer>
      )}

      {body && (
        <>
          <MessagePop isFromUser={isFromUser} firstCollection={!!video?.duration}>
            <SenderLabel>{user?.name || 'No User'}</SenderLabel>
            <Linkify
              componentDecorator={(decoratedHref, decoratedText, key) => (
                <LinkAnchor href={decoratedHref} key={key} target="_blank" isFromUser={isFromUser}>
                  {decoratedText}
                </LinkAnchor>
              )}
            >
              {message}
            </Linkify>
            {allLinks?.map((link, index) => {
              if (!link) return null;
              const { isDynamic, title, handleClick, externalLink, isEmail } = link;
              const shouldRenderLink = !isDynamic || isEmail;
              return (
                <LinkContainer key={index}>
                  <DynamicLinkButton
                    isFromUser={isDynamic && isFromUser}
                    onClick={isDynamic && !loadingHandle ? handleClick : () => null}
                    as={shouldRenderLink ? 'a' : 'button'}
                    href={isDynamic ? '' : externalLink}
                    target={!isEmail ? '_blank' : ''}
                  >
                    {loading && 'Loading...'}
                    {!loading && isDynamic && `View ${title}`}
                    {!loading && !isDynamic && !isEmail && `Visit ${title}`}
                    {!loading && !isDynamic && isEmail && `Email ${title}`}
                  </DynamicLinkButton>
                </LinkContainer>
              );
            })}
            {!video && (
              <DateLabel isFromUser={isFromUser}>
                {toReadableDate(new Date(timestamp) || new Date())}
                {/* <MessageReadStatusIndicator {...{ isFromUser, foundMessageReadStatuses }} /> */}
              </DateLabel>
            )}
          </MessagePop>
          {!!video?.duration && (
            <MessagePop isFromUser={isFromUser} lastCollection>
              <LinkContainer>
                <DynamicLinkButton
                  isFromUser={!isFromUser}
                  onClick={() =>
                    stillRunning
                      ? window.open(
                          `${config.MEET_URL}/?meetid=${msgId}&name=${user.name}&email=${user.email}&avatar=${user.profile_pic}&isbus=false`,
                          '_blank',
                          'location=yes,height=800,width=800,scrollbars=yes,status=yes'
                        )
                      : null
                  }
                >
                  {stillRunning ? 'Join Video Call' : 'Video Call Ended'}
                </DynamicLinkButton>
              </LinkContainer>
              {
                <DateLabel isFromUser={isFromUser}>
                  {toReadableDate(new Date(timestamp) || new Date())}
                  {/* <MessageReadStatusIndicator {...{ isFromUser, foundMessageReadStatuses }} /> */}
                </DateLabel>
              }
            </MessagePop>
          )}
        </>
      )}

      {/* <MessageReadStatusIndicator {...{ isFromUser, foundMessageReadStatuses }} /> */}
    </MessageContainer>
  );
};

export default MessageItem;
