import styled, { css } from 'styled-components';
import Colors from '../../Colors';
import { getTextColor } from '../../utils/helpers';

export const ExtensionContainer = styled.div<{ isExtended?: boolean }>`
  height: 100vh;
  background-color: ${Colors.grey};
  display: flex;
  flex-direction: column;
  z-index: 10;
  scroll-snap-align: start;
  flex: 1;
  width: calc(var(--extensionWidth));
  max-width: calc(var(--extensionWidth));
  @media (max-width: 1200px) {
    z-index: unset;
  }

  @media (max-width: 768px) {
    overflow-y: scroll;
    overflow-x: hidden;
    width: 100vw;
    background-color: ${Colors.white};
    flex: 1;
    transition: all 0.5s ease-in-out;
  }
`;
export const ExtensionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 24px;
  background-color: #ffffff;
`;
export const ExtensionBody = styled.div<{ paddingX?: number }>`
  display: flex;
  flex-direction: row;
  height: calc(100% - 80px);
  max-height: calc(100% - 80px);
  padding: 0px 16px;
  flex: 1;
  max-width: 100%;

  @media (max-width: 768px) {
    flex-direction: column;
    overflow-y: scroll;
    box-sizing: border-box;
    min-height: calc(100vh - 150px);
    width: 100%;
    height: 100%;
    min-height: unset;
    padding: 0 0;
    background-color: #f4f4f4;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const TabContainer = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex: 1;
  max-width: 100%;
  @media (max-width: 768px) {
    overflow-y: scroll;
    min-height: 100%;
  }
`;

export const ExtensionColumn = styled.div`
  position: relative;
  background-color: ${Colors.white};
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  padding: 0 8px;
  box-sizing: border-box;
  width: 360px;
  @media (max-width: 1200px) {
    width: 100%;
  }

  @media (max-width: 768px) {
    padding: 0;
  }
`;
export const ExtensionColumnHeader = styled.div<{ justifyContent?: string; gap?: number; padding?: string; background?: boolean; marginT?: number; marginB?: number; mobilePadding?: string }>`
  display: flex;
  align-items: center;
  justify-content: ${props => (props.justifyContent ? props.justifyContent : 'space-between')};
  padding: ${props => (props.padding ? props.padding : '0')};
  border-radius: ${props => (props.background ? '8px' : '0')};
  gap: ${props => (props.gap ? `${props.gap}px` : '0')};
  background-color: ${props => (props.background ? '#F4F4F4' : 'none')};
  margin-top: ${props => (props.marginT ? `${props.marginT}px` : '0')};
  margin-bottom: ${props => (props.marginB ? `${props.marginB}px` : '0')};
  font-size: 16px;
  font-weight: 800;
  color: #222222;
  @media (max-width: 768px) {
    /* height: 0; */
    /* padding: 0; */
    /* width: 0; */
    overflow: hidden;
    padding: ${props => (props.mobilePadding ? props.mobilePadding : '0')};
    margin: 0;
  }
`;
export const ExtensionColumnContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;

  @media (max-width: 768px) {
    padding: 0;
    width: 100%;
  }
`;
export const ExtensionPetCasesRecord = styled.div<{ selected?: boolean }>`
  width: 100%;
  cursor: pointer;
  padding: 10px 8px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  &:hover {
    background-color: #3f3f3f;
    color: ${Colors.white};
    cursor: pointer;
  }
  ${props =>
    props.selected &&
    css`
      background-color: #3f3f3f;
      color: ${Colors.white};
    `}
  @media (max-width: 1200px) {
    width: 100%;
  }
`;
export const FlexContainer = styled.div<{ gap?: number; marginB?: number; column?: boolean }>`
  display: flex;
  flex-direction: ${props => (props.column ? 'column' : 'row')};
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: ${props => props.gap || 0}px;
  margin-bottom: ${props => props.marginB || 0}px;
`;
export const FlexCenterContainer = styled.div`
  display: flex;
  align-items: center;
`;
export const FlexCenterBetweenContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const FlexColumnBetweenContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 60px;
`;
export const FlexColumnCenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 60px;
`;
export const ExtensionPetCaseLabel = styled.div<{
  fontSize: number;
  width?: number;
  fontWeight: string;
  primary?: boolean;
  orange?: boolean;
  margingB?: number;
  margingT?: number;
  marginR?: number;
  normalCursor?: boolean;
  color?: string;
}>`
  font-size: ${props => props.fontSize}px;
  font-weight: ${props => props.fontWeight};
  margin-bottom: ${props => props.margingB || 0}px;
  margin-right: ${props => props.marginR || 0}px;
  margin-top: ${props => props.margingT || 0}px;
  /* color: ${props => (props.primary ? Colors.primary : props.orange ? Colors.orange : '#222222')}; */
  color: ${props => props.color || ''};
  display: flex;
  align-items: center;
  justify-content: space-between;
  overflow-wrap: anywhere;

  ${props =>
    props.width &&
    css`
      width: ${props.width}px;
    `}

  ${props =>
    props.normalCursor &&
    css`
      cursor: pointer;
    `}
`;
export const ExtensionDivider = styled.div`
  width: 100%;
  border-bottom: solid 1px #dddddd;
`;
export const ExtensionWideColumn = styled.div<{ noTextOverFlow?: boolean; active: boolean; fullWidth?: boolean }>`
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  display: flex;
  flex-direction: column;
  /* max-width: calc(100% - 420px); */
  max-width: 600px;
  width: calc(100% - 420px);
  background: #f4f4f4;

  border-radius: 15px;
  @media (max-width: 1200px) {
    margin: 0;
    border-radius: 0;
    width: calc(100vw - 90px);
    max-width: calc(100vw - 90px);
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    visibility: visible;
    transition: all 0.3s ease-in-out;
    box-sizing: border-box;
    height: 100%;
    ${props =>
      !props.active &&
      css`
        position: fixed;
        left: 100vw;
        visibility: hidden;
      `}
    padding: 24px 6px;
  }

  @media (max-width: 768px) {
    width: 100vw;
    max-width: 100vw;
    padding: 20px;
  }

  ${props =>
    props.fullWidth &&
    css`
      max-width: 100%;
      width: 100%;
      position: unset;

      @media (max-width: 1200px) {
        width: 100%;
        position: unset;
      }

      @media (max-width: 768px) {
        width: 100%;
        position: unset;
      }
    `}

  ${ExtensionPetCaseLabel} {
    ${props =>
      props.noTextOverFlow &&
      css`
        text-overflow: ellipsis;
        white-space: nowrap;
        width: 100%;
      `}
  }

  padding-bottom: 10px;
`;
export const ExtensionWideColumnInfo = styled.div<{ scrollable?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 8px 0 8px 0;
  width: 100%;
  ${props =>
    props.scrollable &&
    css`
      overflow-y: scroll;
    `}

  @media (max-width: 1200px) {
    padding: 8px 12px;
  }
  @media (max-width: 768px) {
    padding: 0;
  }
`;

export const ExtensionWideColumnScrollable = styled.ul`
  list-style: none;
  padding: 0;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
`;

export const MedicalCaseBlock = styled.div`
  margin-bottom: 28px;
  overflow-x: hidden;
`;
export const MedicalCaseBlockHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
export const FlexList = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 12px;
  width: 100%;
  min-height: 100px;
  max-height: 250px;
  overflow-y: scroll;
`;
export const FlexListItem = styled.div`
  margin-right: 24px;
  margin-bottom: 24px;
  width: 100%;
  max-width: 220px;
  min-height: 60px;
  padding: 15px;
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 2px 25px 0 rgb(0 0 0 / 10%);
`;
export const CurrentList = styled(FlexList)`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  width: 100%;
  max-height: none;
  overflow-y: scroll;
`;
export const CurrentListItem = styled.div<{ overdue?: boolean }>`
  margin-bottom: 24px;
  cursor: pointer;
  min-width: fill-available;
  min-height: 60px;
  padding: 14px;
  border-radius: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: solid 1px #979797;
  ${props =>
    props.overdue &&
    css`
      border: solid 2px ${Colors.orangeBorder};
    `}
`;
export const Note = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #212121;
  white-space: pre-line;
`;

export const PetItemsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const PetItemsWrapper = styled.div`
  width: 100%;
`;

export const PetRecordTagPillsContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 12px;
  user-select: none;
  margin-right: 12px;

  @media (max-width: 768px) {
    justify-content: flex-start;
    margin: 0;
    width: 600px;
    /* flex-wrap: wrap; */
    padding-right: 8px;
    padding-left: 8px;
    overflow-x: scroll;
    gap: 10px;
    &::-webkit-scrollbar {
      display: none;
    }
  }
`;

export const PetRecordTagPill = styled.div<{ color: string }>`
  display: flex;
  border-radius: 7px;
  background-color: ${props => props.color};
  padding: 4px 8px;
  span {
    color: ${props => getTextColor(props.color || '#a5cdce')};
    font-size: 12px;
    font-weight: bold;
  }
  @media (max-width: 768px) {
    padding: 4px 10px 6px 10px;
    font-size: 13px;
    border-radius: 21px;
    span {
      color: ${Colors.white};
    }
  }
`;

export const ReportButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const AddReportButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Colors.white};
  border: solid 1.5px ${Colors.black};
  border-radius: 8px;
  padding: 8px 48px;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  color: ${Colors.black};
  span {
    visibility: hidden;
    font-size: 16px;
  }
  transition: all 0.2s ease-in-out;
  &:hover {
    background-color: ${Colors.black};
    color: ${Colors.white};
    span {
      visibility: visible;
    }
  }
`;

export const AppUserImagePetRecordOptions = styled.div<{ mobileWidth?: string }>`
  display: flex;

  @media (max-width: 768px) {
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    width: ${props => (props.mobileWidth ? (props.mobileWidth.includes('%') ? props.mobileWidth : `${props.mobileWidth}px`) : '100%')};
  }
`;

export const PetRecordOptionsContainer = styled.div<{ mobileMargin?: string }>`
  @media (max-width: 768px) {
    margin: ${props => props.mobileMargin || 'auto'};
  }
`;
