import { useQuery, useReactiveVar } from '@apollo/client';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { FC, useState } from 'react';
import { BusUserProfile } from '../../../../components/Profile/types';
import Filters from '../../../../components/Shared/Filters/Filters';
import SearchBar from '../../../../components/Shared/Filters/SearchBar';
import { getFilterKeyOptions } from '../../../../components/Shared/Filters/helpers';
import { FiltersControl } from '../../../../components/Shared/Filters/useFilters';
import { TabsHeaderContainer } from '../../../../components/Shared/Shared';
import { Divider } from '../../../../components/Shared/SideTabs/styles';
import ToolBar from '../../../../components/Shared/ToolBar/ToolBar';
import useIcons from '../../../../hooks/useIcons';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import useURLSearchParams from '../../../../hooks/useURLSearchParams';
import { GetAllServices, GetBusUserProfile } from '../../../../queries';
import { vars } from '../../../../reactive';
import debounce from '../../../../utils/debounce';
import { Product } from '../../../Store/types';
import { BOOKINGS_FILTER_TYPES, BOOKINGS_MORE_OPTIONS_TYPES } from '../../BookingsFilters';
import { BOOKING_TAB, BOOKING_TABS_TYPES, BOOKING_TABS_VALUES_TYPES } from '../../types';
import BookingsNavigation from '../BookingsNavigation/BookingsNavigation';
import BookingTagsQuickFilter from './BookingTagsQuickFilter';
import { Header, ViewSwitchContainer } from './styled';

type BookingsHeaderProps = {
  tabs: BOOKING_TAB[];
  services: Product[];
  filtersControl: FiltersControl<BOOKINGS_FILTER_TYPES, BOOKINGS_MORE_OPTIONS_TYPES>;
};

const BookingsHeader: FC<BookingsHeaderProps> = ({ tabs, services, filtersControl }) => {
  const { mobile } = useMediaQuery({ mobile: true });
  const { headerAction } = filtersControl;

  const { onChange: onSearch } = getFilterKeyOptions({
    filtersControl,
    keys: {
      filterType: BOOKINGS_FILTER_TYPES.BOOKING_SEARCH,
      moreOption: BOOKINGS_MORE_OPTIONS_TYPES.BOOKING_SEARCH
    }
  });
  const [selectedServices] = useURLSearchParams('services');
  const icons = useIcons();
  const isMultiStaffView = useReactiveVar(vars.isMultiStaffView);
  const [isClicked, setIsClicked] = useState(isMultiStaffView);
  const viewSewitchOn = icons?.viewSwitchOn?.childImageSharp.gatsbyImageData;
  const viewSewitchOff = icons?.viewSwitchOff?.childImageSharp.gatsbyImageData;

  const { data: { getBusUserProfile: userProfile } = {} } = useQuery<{ getBusUserProfile: BusUserProfile }>(GetBusUserProfile, {
    fetchPolicy: 'cache-only'
  });
  const busUsers = userProfile?.Branch?.BusUsers;
  const handleClick = () => {
    setIsClicked(!isClicked);
    debounce(() => {
      vars.isMultiStaffView(!isClicked);
    }, 300);
  };

  const { data: { getProducts: newTabServices = [] } = {} } = useQuery<{ getProducts: Product[] }>(GetAllServices);

  const tabsValues = tabs.map(tab => tab.value);
  const tabValue = tabsValues?.find(tab => tab === location.pathname.split('/')[3]) || BOOKING_TABS_TYPES.CONFIRMED;
  const selectedTab = tabs.find(tab => tab.value === tabValue);

  const isConfirmed = selectedTab?.value === BOOKING_TABS_VALUES_TYPES.CONFIRMED;
  const isNew = selectedTab?.value === BOOKING_TABS_VALUES_TYPES.NEW;
  const isUpcoming = selectedTab?.value === BOOKING_TABS_VALUES_TYPES.UPCOMING;
  const isCanceled = selectedTab?.value === BOOKING_TABS_VALUES_TYPES.CANCELED;
  const isPayment = selectedTab?.value === BOOKING_TABS_VALUES_TYPES.PAYMENT;
  const toolBarButtonsConfirmed = isConfirmed && !isMultiStaffView && services;

  const toolBarButtonsStaff = isConfirmed && isMultiStaffView && busUsers;

  const toolBarButtonsNew = selectedTab?.value === BOOKING_TABS_VALUES_TYPES.NEW && newTabServices;

  const toolBarButtonsUpcomiung = isUpcoming && newTabServices;

  // const toolBarButtonsMultiDay = selectedTab?.value === BOOKING_TABS_VALUES_TYPES.MULTI_DAY && multiDayToolBarButtons;

  const toolBarButtons = toolBarButtonsConfirmed || toolBarButtonsNew || toolBarButtonsStaff || toolBarButtonsUpcomiung || [];

  const urlSearchParam = (isConfirmed && !isMultiStaffView) || isNew || isUpcoming ? 'services' : isMultiStaffView ? 'staff' : 'status';

  return (
    <>
      {isConfirmed && !mobile && (
        <>
          <Header>
            <BookingsNavigation />
            <ViewSwitchContainer onClick={handleClick}>
              {!isClicked ? (
                <GatsbyImage image={viewSewitchOn} alt="viewSewitchOn" style={{ cursor: 'pointer' }} />
              ) : (
                <GatsbyImage image={viewSewitchOff} alt="viewSewitchOff" style={{ cursor: 'pointer' }} />
              )}
            </ViewSwitchContainer>
          </Header>
          <Divider />
        </>
      )}
      {!mobile && <ToolBar toolBarButtons={toolBarButtons} urlSearchParam={urlSearchParam} onSearchChange={onSearch} middleAction={headerAction} />}

      {mobile && !isNew && !isCanceled && !isPayment && (
        <ToolBar
          toolBarButtons={toolBarButtons}
          urlSearchParam={urlSearchParam}
          onSearchChange={onSearch}
          middleAction={headerAction}
          selectOnlyOne={mobile}
          defaultValue={toolBarButtons[0]?.id}
          displayAll={false}
        />
      )}

      {mobile && (isNew || isCanceled || isPayment) && <SearchBar onSearch={onSearch} showAlways />}

      {!isConfirmed && (
        <TabsHeaderContainer padding="12px 20px" mobilePadding="12px 16px 0 16px">
          <BookingTagsQuickFilter filtersControl={filtersControl} />
        </TabsHeaderContainer>
      )}

      {mobile && isConfirmed && (
        <TabsHeaderContainer padding="12px 20px" mobilePadding="12px 16px 0 16px">
          <BookingTagsQuickFilter filtersControl={filtersControl} />
        </TabsHeaderContainer>
      )}

      {/* <Divider /> */}
      {!mobile && (
        <>
          <Filters filtersControl={filtersControl} />
          <Divider />
        </>
      )}
    </>
  );
};

export default BookingsHeader;
