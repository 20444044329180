import { useLazyQuery, useMutation } from '@apollo/client';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ReactSelect from '../../../components/Shared/Forms/Select';
import { DRAWER_IDS } from '../../../components/DrawerBar/types';
import ModalDialog from '../../../components/Modal/ModalDialog';
import { ModalBody, ModalFooter } from '../../../components/Modal/styled';
import { FormButtonsContainer, FormError, FormInput, FormLabel, FormSelect, FormSubmitButton, FormTextArea, InputsWrapper, WideInputGroup, selectTheme } from '../../../components/Shared/Forms/Forms';
import { Container } from '../../../components/Shared/Shared';
import { CenteredLoader } from '../../../components/Shared/Spinner';
import { AddBranchMessageTemplate, DeleteBranchMessageTemplate, EditBranchMessageTemplate, GetBusUserProfile, GetProductsByBranchId } from '../../../queries';
import { vars } from '../../../reactive';
import { setDrawerBar, unsetDrawerBars } from '../../../reactive/actions';
import Common from '../Common';
import { BRANCH_MESSAGE_TYPES, BRANCH_MESSAGE_TYPES_LABELS, BranchMessage } from './BranchMessages';

type BranchMessageModalFormProps = {
  loading: boolean;
  products: Record<string, any>[];
  branchMessage?: BranchMessage;
  formOptions: ReturnType<typeof useForm>;
  saveError: boolean;
  saveLoading: boolean;
  onSubmit: (e?: React.BaseSyntheticEvent<object, any, any> | undefined) => Promise<void>;
  deleteMessage: () => void;
};

const BranchMessageModalForm = ({ loading, products, branchMessage, formOptions, saveError, saveLoading, onSubmit, deleteMessage }: BranchMessageModalFormProps) => {
  const { control, errors } = formOptions;
  const types = Object.values(BRANCH_MESSAGE_TYPES);

  return (
    <>
      <ModalBody>
        <Container>
          {loading && <CenteredLoader />}
          {!loading && (
            <InputsWrapper noWrap>
              <WideInputGroup>
                <FormLabel>Title</FormLabel>
                <Controller as={<FormInput error={errors.name} />} control={control} name={'name'} defaultValue={branchMessage?.name || 'New Template'} rules={{ required: true }} />
                {errors.name && <FormError>{errors.name.message || 'Title is required'}</FormError>}
              </WideInputGroup>
              <WideInputGroup>
                <FormLabel>Message Text</FormLabel>
                <Controller as={<FormTextArea fieldHeight={120} error={errors.message} />} control={control} name={'message'} defaultValue={branchMessage?.message || ''} rules={{ required: true }} />
                {errors.message && <FormError>{errors.message.message || 'Message Text is required'}</FormError>}
              </WideInputGroup>
              <WideInputGroup>
                <FormLabel>Message HTML Text - Email only (leave empty to use message text)</FormLabel>
                <Controller
                  as={<FormTextArea fieldHeight={120} error={errors.html_message} />}
                  control={control}
                  name={'html_message'}
                  defaultValue={branchMessage?.html_message || ''}
                  rules={{ required: false }}
                />
                {errors.html_message && <FormError>{errors.html_message.message || 'Message HTML Text is required'}</FormError>}
              </WideInputGroup>
              <WideInputGroup>
                <FormLabel>When would you like the message to be sent?</FormLabel>
                <Controller
                  render={({ onChange, value }) => (
                    <FormSelect
                      height={48}
                      fontSize={16}
                      name={'type'}
                      onChange={e => {
                        onChange(e.target.value);
                      }}
                      value={value || ''}
                    >
                      <option disabled value={''}>
                        -- select an option --
                      </option>
                      {types.map((type, index) => (
                        <option key={index} value={type}>
                          {BRANCH_MESSAGE_TYPES_LABELS[type]}
                        </option>
                      ))}
                    </FormSelect>
                  )}
                  control={control}
                  name={'type'}
                  defaultValue={branchMessage?.type || types[0]}
                />
              </WideInputGroup>
              <WideInputGroup>
                <FormLabel>Select Products or Services</FormLabel>
                <Controller
                  render={({ onChange, value }) => (
                    <ReactSelect
                      isMulti
                      name={'productId'}
                      onChange={onChange}
                      value={value || ''}
                      options={products.map(product => ({
                        value: product.id,
                        label: product.name
                      }))}
                      theme={selectTheme}
                    />
                  )}
                  control={control}
                  name={'productId'}
                  defaultValue={branchMessage?.Products?.map(product => ({ value: product.id, label: product.name })) || []}
                  rules={{ required: true }}
                />
              </WideInputGroup>
            </InputsWrapper>
          )}
        </Container>
      </ModalBody>
      <ModalFooter>
        <FormButtonsContainer>
          <FormSubmitButton error={saveError} loading={saveLoading} onClick={onSubmit}>
            Save
          </FormSubmitButton>
          {branchMessage && (
            <FormSubmitButton type="button" onClick={deleteMessage} loading={saveLoading} error={!!saveError} secondary danger>
              Delete
            </FormSubmitButton>
          )}
        </FormButtonsContainer>
      </ModalFooter>
    </>
  );
};
const BranchMessageModal = ({ branchMessage }: { branchMessage?: BranchMessage }) => {
  const formOptions = useForm();
  const { handleSubmit } = formOptions;
  const [getBusUser] = useLazyQuery(GetBusUserProfile);
  const [getProducts, { data: { productGet: products = [] } = {}, loading: loadingProducts }] = useLazyQuery(GetProductsByBranchId);

  const [saveBranchMessage, { data: addedMessage, loading: addLoading, error: addError }] = useMutation(AddBranchMessageTemplate);

  const [editBranchMessage, { data: editedMessage, loading: editLoading, error: editError }] = useMutation(EditBranchMessageTemplate);

  const [deleteBranchMessage, { data: deletedMessage, loading: deleteLoading, error: deleteError }] = useMutation(DeleteBranchMessageTemplate);

  const onSubmit = handleSubmit(data => {
    const { name, message, html_message, type, productId } = data;
    const variables = {
      name,
      message,
      html_message,
      type,
      ProductId: productId?.map((product: { value: string }) => product.value) || null
    };

    if (branchMessage) {
      editBranchMessage({
        variables: {
          id: branchMessage.id,
          ...variables
        }
      });

      return;
    }

    saveBranchMessage({ variables });
  });

  const loading = loadingProducts;
  const saveError = !!addError?.message || !!editError?.message || !!deleteError?.message;
  const saveLoading = addLoading || editLoading || deleteLoading;
  const savedMessage = addedMessage?.addBranchMessageTemplate || editedMessage?.editBranchMessageTemplate;
  const messageDeleted = deletedMessage?.branchMessageTemplateDelete;

  const deleteMessage = () => {
    if (branchMessage) {
      deleteBranchMessage({
        variables: {
          id: branchMessage.id
        }
      });
    }
  };

  useEffect(() => {
    if (branchMessage?.id) {
      getProducts({ variables: { branchId: branchMessage?.Branch?.id } });
    } else {
      getBusUser().then(({ data }) => {
        const branchId = data.getBusUserProfile?.Branch?.id;
        getProducts({ variables: { branchId } });
      });
    }
  }, [branchMessage]);

  useEffect(() => {
    if (savedMessage || messageDeleted) {
      ModalDialog.closeModal();
      Common.get<() => Promise<void>>(`BranchMessages.GetBranchMessages.refetch`)?.();
      if (messageDeleted) {
        unsetDrawerBars();
      }
      const drawerBar = vars.drawerBars().find(drawerBar => drawerBar.drawerId === DRAWER_IDS.BRANCH_MESSAGE_DRAWER);
      if (drawerBar) {
        setDrawerBar({ drawerId: DRAWER_IDS.BRANCH_MESSAGE_DRAWER, recordData: savedMessage });
      }
    }
  }, [savedMessage, messageDeleted]);

  return (
    <BranchMessageModalForm
      loading={loading}
      products={products}
      formOptions={formOptions}
      saveError={saveError}
      saveLoading={saveLoading}
      branchMessage={branchMessage}
      onSubmit={onSubmit}
      deleteMessage={deleteMessage}
    />
  );
};

export default BranchMessageModal;
