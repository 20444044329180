import { GatsbyImage } from 'gatsby-plugin-image';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import useIcons from '../../../hooks/useIcons';
import useMediaQuery from '../../../hooks/useMediaQuery';
import debounce from '../../../utils/debounce';
import { ActionBtn } from '../../../views/Store/styled';
import { CloseButtonContainer } from '../../ChatDrawer/styled';
import { CloseButton, SearchBarContainer, SearchBarWrapper, SearchInput } from './styled';

const SearchBar = ({
  showAlways = false,
  onSearch,
  defaultText = '',
  defaultValue = '',
  onBlur
}: {
  showAlways?: boolean;
  onSearch: (query: string) => void;
  defaultText?: string;
  defaultValue?: string;
  onBlur?: () => void;
}) => {
  const icons = useIcons();
  const searchIcon = icons?.search?.childImageSharp?.gatsbyImageData;
  const closeIcon = icons?.closeTransparentBlack?.childImageSharp.gatsbyImageData;
  const [showSearch, setShowSearch] = useState(false);
  const [inputValue, setInputValue] = useState(defaultValue);
  const inputRef = useRef<HTMLInputElement>(null);
  const { mobile } = useMediaQuery({ mobile: true });

  const toggleSearch = useCallback(() => {
    setShowSearch(!showSearch);
    return new Promise(resolve => {
      setTimeout(() => {
        resolve(inputRef.current?.focus());
      }, 100);
    });
  }, [showSearch]);

  const handleKeyDown: React.KeyboardEventHandler<HTMLInputElement> = e => {
    if (e.key === 'Enter') {
      onSearch(e.currentTarget.value);
    }
  };

  useEffect(() => {
    if (!showSearch) {
      onSearch('');
    }
  }, [showSearch]);

  useEffect(() => {
    debounce(() => onSearch(inputValue), 500);
  }, [inputValue]);

  const clearSearch = () => {
    setInputValue('');
    onSearch('');
  };
  // position: absolute;
  // top: 11px;
  // left: 11px;
  // bottom: 40px;
  // border: none;
  // border-radius: 50%;
  return (
    <SearchBarContainer>
      <SearchBarWrapper show={showAlways || showSearch}>
        <SearchInput
          onKeyDown={handleKeyDown}
          placeholder={defaultText || 'Search'}
          ref={inputRef}
          height={24}
          onChange={e => setInputValue(e.currentTarget.value)}
          value={inputValue}
          onBlur={onBlur}
        />
        {inputValue && (
          <CloseButtonContainer style={{ position: 'absolute', right: mobile ? '5%' : '2%', top: mobile ? 15 : 4 }}>
            <CloseButton onClick={clearSearch}>
              <GatsbyImage image={closeIcon} alt="close" />
            </CloseButton>
          </CloseButtonContainer>
        )}
      </SearchBarWrapper>
      {!showAlways && !mobile && (
        <ActionBtn onClick={toggleSearch} noMargin noBackgroundColor>
          <GatsbyImage image={searchIcon} alt="search" />
        </ActionBtn>
      )}
    </SearchBarContainer>
  );
};

export default SearchBar;
