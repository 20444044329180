import { useQuery } from '@apollo/client';
import React from 'react';
import useIcons from '../../hooks/useIcons';
import { GetBusUserProfile } from '../../queries';
import { logout } from '../../utils/auth';
import { BusinessUserImage, BusinessUserImageContainer, BusinessUserName, DefaultBusinessUserImage, LogOutButton, ServiceName } from './styled';
import { BusUserProfile } from '../Profile/types';

export default function BusinessUserContent({ profile }: { profile: BusUserProfile }) {
  const icons = useIcons();

  return (
    <BusinessUserImageContainer>
      {!profile?.profile_pic && <DefaultBusinessUserImage image={icons?.user?.childImageSharp?.gatsbyImageData} alt="Business user" />}
      {profile?.profile_pic && <BusinessUserImage src={profile.profile_pic} alt="Business user" />}
      <BusinessUserName>{profile?.name}</BusinessUserName>
      <ServiceName>{profile?.Branch?.name || profile?.Provider?.name}</ServiceName>
      <LogOutButton onClick={logout}>Log Out</LogOutButton>
    </BusinessUserImageContainer>
  );
}
