import { useQuery } from '@apollo/client';
import React, { useRef, useState } from 'react';
import { DRAWER_IDS } from '../../../components/DrawerBar/types';
import useMediaQuery from '../../../hooks/useMediaQuery';
import usePaginatedQuery from '../../../hooks/usePaginatedQuery';
import { GetBranchPetProfile, GetBranchSubmittedForms } from '../../../queries';
import { vars } from '../../../reactive';
import { SubmittedForm } from '../../Store/BranchForms/types';
import PetFormsDetails from './PetFormDetails';
import PetFormsHistory, { PetForm } from './PetFormsHistory';

const PetForms = () => {
  const petFormDetailsRef = useRef<HTMLDivElement>(null);
  const drawerBar = vars.drawerBars().find(drawer => drawer.drawerId === DRAWER_IDS.PETS_DRAWER);
  const petId = drawerBar?.recordData;
  const preSelectedFormId = drawerBar?.otherData?.selectedForm;

  const { data: { getBranchPetProfile: petProfile = {} } = {} } = useQuery(GetBranchPetProfile, {
    variables: {
      id: petId
    }
  });

  const [[hasMoreItems, setHasMoreItems], { data: { getBranchSubmittedForms: allForms = [] } = {}, loading: loadingPetFormsReports, fetchMore }] = usePaginatedQuery<SubmittedForm[]>({
    query: GetBranchSubmittedForms,
    limit: 10,
    otherVariables: { PetRecordId: [petProfile?.PetRecord?.id] },
    otherParams: {
      skip: !petProfile?.id
    }
  });

  const petRecordId = petProfile?.PetRecord?.id;

  const petForms = [
    ...allForms.map(submittedForm => {
      const answers = submittedForm?.Answers.filter(answer => answer.PetRecordId === petRecordId || answer.PetRecordId === null);
      const questions = answers?.map(answer => answer?.Question).sort((a, b) => a?.sort_index - b?.sort_index);
      const createdAt = submittedForm?.createdAt;
      return { id: submittedForm?.id, form: submittedForm?.Form, isAnswered: true, answers, questions, createdAt, orderNumber: submittedForm?.Order?.number };
    })
  ] as PetForm[];

  const { tablet } = useMediaQuery({ tablet: true });

  const [selectedFormId, setSelectedFormId] = useState<string | null>(preSelectedFormId || !tablet ? petForms[0]?.id : null || null);

  const setSelectedFormIdAndScroll = (formId: string) => {
    setSelectedFormId(formId);
    petFormDetailsRef.current?.scrollIntoView({
      behavior: 'smooth'
    });
  };

  const selectedForm = petForms.find(({ id }) => id === selectedFormId);

  return (
    <>
      <PetFormsDetails
        innerRef={petFormDetailsRef}
        selectedFormItem={selectedForm!}
        petForms={petForms}
        loadingForm={loadingPetFormsReports}
        deselectForm={() => setSelectedFormId(null)}
        pet={petProfile}
      />
      <PetFormsHistory
        fetchMore={fetchMore as any}
        hasMoreItems={hasMoreItems}
        setHasMoreItems={setHasMoreItems}
        petForms={petForms || []}
        setSelectedFormId={setSelectedFormIdAndScroll}
        loading={loadingPetFormsReports}
        selectedFormId={selectedFormId as string}
      />
    </>
  );
};

export default PetForms;
