import React from 'react';
import { EmptyListContainer, FlexColumnCenterCenterContainer } from './Shared';
import { CenteredLoader } from './Spinner';

const EmptyList = ({ loading, message, title, dimensions }: { loading: boolean; message?: string; title?: string; dimensions?: { height?: string; width?: string } }) => {
  const { height, width } = dimensions || {};

  return (
    <EmptyListContainer containerWidth={width} containerHeight={height}>
      {loading ? (
        <CenteredLoader size={50} />
      ) : (
        <FlexColumnCenterCenterContainer>
          <h3>{title || 'Your pet care prowess deserves a round of ap-paws! Keep it up.'}</h3>
          {/* <p>{message}</p> */}
        </FlexColumnCenterCenterContainer>
      )}
    </EmptyListContainer>
  );
};

export default EmptyList;
