import styled, { css } from 'styled-components';
import Colors from '../../Colors';

export const GroupHeader = styled.div`
  padding: 16px;
  font-size: 16px;
  font-weight: bold;
  color: ${Colors.secondaryText};
`;

export const SubscriptionsDashboardContainer = styled.div`
  display: flex;
  flex: 1;
  // gap: 8px;
  // padding: 0 48px;
  // padding-left: 58px;
`;

export const SubscriptionsDashboardWrapper = styled.div`
  & > :nth-child(odd) {
    background-color: #f8f8f8;
  }
  & > :nth-child(even) {
    background-color: ${Colors.white};
  }

  border-radius: 16px;
  display: flex;
  flex: 1;
  // gap: 8px;
  padding: 16px 16px;
`;

export const SubscriptionsDashboardItem = styled.div<{ flexOne?: boolean; clickable?: boolean; selectable?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 12px;
  outline: none;
  box-sizing: border-box;
  white-space: nowrap;
  user-select: ${({ selectable }) => (selectable ? 'text' : 'none')};
  outline: none;
  border: none;
  font-weight: 800;
  color: #000;
  padding: 6px 12px;
  &:not(:last-child) {
    border-right: 1px solid ${Colors.border};
  }

  ${({ flexOne }) =>
    flexOne &&
    css`
      flex: 1;
    `}
  ${({ clickable }) =>
    clickable &&
    css`
      cursor: pointer;
    `}
`;

export const SubscriptionsDashboardItemTitle = styled.span`
  font-size: 14px;
  font-weight: 600;
  text-align: left;
`;

export const SubscriptionsDashboardItemSubtitle = styled.span`
  font-size: 14px;
  font-weight: 800;
  display: flex;
  justify-content: space-between;
  flex: 1;
`;
