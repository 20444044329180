import styled from 'styled-components';

export const WalkingReportNotesContainer = styled.div`
  padding-top: 8px;
  padding-right: 8px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const WalkingReportNoteContainer = styled.div``;

export const ReportNoteSubCategoryContainer = styled.div`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 14px;
`;

export const ReportNoteSubCategoryTitle = styled.span`
  font-weight: 800;
`;
