import { useReactiveVar } from '@apollo/client';
import React, { FC, Fragment, useRef } from 'react';
import Colors from '../../Colors';
import { DRAWER_IDS } from '../../components/DrawerBar/types';

import { RedDot } from '../../components/Shared/Shared';
import useBookingActions from '../../hooks/useBookingActions';
import useBulkSelect from '../../hooks/useBulkSelect';
import useIcons from '../../hooks/useIcons';
import useMediaQuery from '../../hooks/useMediaQuery';
import { vars } from '../../reactive';
import { setDrawerBar } from '../../reactive/actions';
import { calculateNights, displayTime, findMonthName, findThreeLetterDayName, formatOrdinals, sortByTime, toMealReadableText } from '../../utils/dates';
import { getUnit } from '../../utils/validators';
import { DietMealRecord } from '../Pets/Health/HealthPetRecordDiet';
import { Divider } from '../Pets/Health/styled';
import { BranchAppointmentTag } from '../Store/BranchTags/types';
import {
  AppUserImage,
  AppUserImageAnimation,
  AppUserImageContainer,
  AppUserImageContainerAnimation,
  AppUserImageNameContainer,
  RecordBody,
  RecordDataContainer,
  RecordDataOverflowContainer
} from '../styled';
import { HIDDEN_BOOKINGS_FILTERS } from './BookingsFilters';
import BookingRecordOptions, { BookingRecordOptionsRef } from './components/BookingRecord/BookingRecordOptions';
import { GroupPetDetails } from './components/BookingsDrawer/styled';

import { sentenceCase } from 'sentence-case';
import { Icon } from '../../components/DrawerBar/styled';
import { AppUserImagePetRecordOptions, PetRecordOptionsContainer, PetRecordTagPill, PetRecordTagPillsContainer } from '../../components/Pets/styled';
import { Checkbox, CheckboxItemContainer } from '../../components/Shared/Forms/styled';
import { VerticalDivider } from '../Store/BranchBilling/styled';
import BookingRecordFilters from './BookingRecordFilters';
import {
  Booking,
  PetAndInfo,
  PetBioExtraInfoContainer,
  PetContainer,
  PetImg,
  PetRecordTag,
  PetRecordTags,
  PetRecordTagsAndTimeContainer,
  PetRecordTagsContainer
} from './components/BookingRecord/styled';
import { BOOKING_RECORD_VIEW_SETTINGS, BOOKING_TABS_VALUES_TYPES, BookingOrder } from './types';

type BookingRecordProps = {
  booking: BookingOrder;
  navigateDrawer?: ({ drawerLinkId }: { drawerLinkId: string }) => void;
  showTotal?: boolean;
  quickAppointmentsTags: BranchAppointmentTag[];
  tagsVisibleOnItem?: BranchAppointmentTag[];
  bulkSelect?: ReturnType<typeof useBulkSelect>;
  recordActiveViewSettings?: (typeof BOOKING_RECORD_VIEW_SETTINGS)[number]['id'];
  selectedTab: string;
};

const BookingRecord: FC<BookingRecordProps> = ({
  booking,
  navigateDrawer,
  showTotal,
  quickAppointmentsTags,
  bulkSelect,
  tagsVisibleOnItem,
  recordActiveViewSettings = 'recordTags',
  selectedTab,
  recordOptionsToDisplay,
  refetchAppointments
}) => {
  const { mobile } = useMediaQuery({ mobile: true });
  const selectedFilters = useReactiveVar(vars.selectedBookingsFilters);
  const icons = useIcons();
  // const multiDayProduct = booking?.[0]?.OrderItem?.item?.booking_type === BOOKING_TYPE.MULTI_DAY;

  const pets = booking?.map(orders => orders?.PetRecord?.Pet);
  const uniquePetsByPetId = pets?.filter((pet, index, self) => self.findIndex(p => p?.id === pet?.id) === index);
  // const { day, time, dateTo, numberOfDays, orderItemName, petsNames, priceString } = getBookingTitles(booking);
  const { actions, shouldDisplay } = useBookingActions({ booking });

  const { shouldDisplayConfirmationAndRejection, inConfirmed, inNew } = shouldDisplay;
  const { handleRejectCancel, handleConfirmation } = actions;
  // const bookingRecordOptionsRef = useRef<BookingRecordOptionsRef>(null);
  const appointmentsIds = booking?.map(({ id }) => id);
  const appUserAddress = booking?.[0]?.PetRecord?.Pet?.AppUser?.addresses?.[0];
  const postcode = booking?.[0]?.PetRecord?.Pet?.AppUser?.postcode;
  const line2 = appUserAddress?.line2 ? `, ${appUserAddress?.line2}` : '';
  const appUserAddressString = appUserAddress ? `${appUserAddress.line1}${line2}, ${postcode}, ${appUserAddress.country}` : '';
  const ordersNotes = booking?.flatMap(order => order?.OrderItem?.Order.Notes);
  const uniqueOrderNotes = ordersNotes.filter((note, index, self) => self.findIndex(n => n?.id === note?.id) === index);
  const petsNotes = booking.flatMap(order => order?.PetRecord?.Notes);
  const uniquePetNotes = petsNotes.filter((note, index, self) => self.findIndex(n => n?.id === note?.id) === index);
  const petsContent = uniquePetNotes.map(note => note?.body?.content).join(', ');
  const ordersContent = uniqueOrderNotes.map(note => note?.body?.content).join(', ');
  const uniquePetRecords = booking.map(order => order?.PetRecord).filter((pet, index, self) => self.findIndex(p => p?.id === pet?.id) === index);
  const petsBehaviour = uniquePetRecords.map(pet => pet?.PetBehavior);
  const busUserName = booking[0]?.BusUsers?.map(({ name }) => name).join(', ');
  const hasFilters = !!selectedFilters?.length;
  const hasVisibileFilters = !!selectedFilters.filter(({ value }) => !HIDDEN_BOOKINGS_FILTERS[value]).length;
  const { show: showBulkSelect, toggleSelectMultiple, isSelected } = bulkSelect || {};
  const toggleMenu = () => {
    bookingRecordOptionsRef?.current?.getOptionDropdownRefOptions?.()?.toggleMenu?.();
  };

  // const handleClick = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
  //   const isTapToTagElement = e.target instanceof HTMLElement && e.target.innerText === 'Tap to tag this booking';

  //   if (isTapToTagElement) {
  //     toggleMenu();
  //   } else {
  //     if (bookingRecordOptionsRef?.current?.getContainerRefOptions?.()?.contains?.(e.target as Node)) {
  //       return;
  //     }

  //     if (showBulkSelect) {
  //       toggleSelectMultiple(appointmentsIds);
  //       return;
  //     }

  //     navigateDrawer({ drawerLinkId: booking?.[0].id });
  //   }
  // };

  const handleClick = (e: React.MouseEvent<HTMLLIElement, MouseEvent>) => {
    const isTapToTagElement = e.target instanceof HTMLElement && e.target.innerText === 'Tap to tag this booking';

    if (isTapToTagElement) {
      toggleMenu();
    } else {
      if (bookingRecordOptionsRef?.current?.getContainerRefOptions?.()?.contains?.(e.target as Node)) {
        return;
      } else if (petContainerRef?.current?.contains?.(e.target as Node)) {
        setDrawerBar({
          drawerId: DRAWER_IDS.PET_ORDER_DRAWER,
          recordData: { orderId, appointmentId }
        });
      } else
        setDrawerBar({
          drawerId: DRAWER_IDS.BOOKING_DRAWER,
          recordData: [booking[0]]
        });
    }
  };
  const tags = booking?.[0]?.BranchAppointmentTags;
  const petTags = booking?.flatMap(book => book?.PetRecord?.BranchPetRecordTags || []) || [];
  const uniquePetTags = petTags.filter((tag, index, self) => self.findIndex(t => t?.id === tag?.id) === index);
  const tagsToRender = tagsVisibleOnItem ? tags.filter(tag => tagsVisibleOnItem.some(visibleTag => visibleTag.id === tag.id)) : tags;
  const addedTags = [...tagsToRender, ...uniquePetTags];

  const mealsForPets = uniquePetRecords.map(petRecord => {
    const meals = sortByTime(petRecord?.Meals || []);
    const mealsRecords = meals.map((item, index) => (
      <DietMealRecord key={item?.id} meal={`${formatOrdinals(index + 1)} Meal`} branch={item.Branch?.name} time={toMealReadableText(item.time)} unit={getUnit(item.unit)} quantity={item.quantity} />
    ));
    const mealTypes = petRecord?.BranchCategories?.map(category => category.name).join(' & ');
    if (!mealsRecords.length) {
      return null;
    }

    return (
      <GroupPetDetails
        key={petRecord?.id}
        backgroundColor={Colors.info2}
        style={{
          gap: 10,
          flexDirection: 'column'
        }}
      >
        <span>{mealTypes}</span>
        {mealsRecords}
      </GroupPetDetails>
    );
  });

  const renderTags = () => {
    return (
      <>
        {!!addedTags.length && (
          <PetRecordTagPillsContainer>
            {addedTags?.map((item, index) => (
              <PetRecordTagPill color={item.color} key={index}>
                <span>{item.name}</span>
              </PetRecordTagPill>
            ))}
          </PetRecordTagPillsContainer>
        )}
        {!hasFilters && !addedTags.length && mobile && (
          <RecordBody fontSize={13} mobilePadding="0 0 0 6px" style={{ color: '#ABABAB', textAlign: 'left' }} onClick={() => {}}>
            Tap to tag this booking
          </RecordBody>
        )}
      </>
    );
  };

  const isMultiStaffView = useReactiveVar(vars.isMultiStaffView);
  const bookingRecordOptionsRef = useRef<BookingRecordOptionsRef>(null);
  const petContainerRef = useRef<HTMLDivElement>(null);
  const {
    id: appointmentId,
    PetRecord: { Pet: { id: petId, profile_pic, name, Breed } = {} } = {},
    BusUsers,
    timestamp,
    timestamp_until,
    BranchAppointmentTags,
    OrderItem: { Order: { id: orderId, payment, status: orderStatus } = {}, item: { name: serviceName } = {} } = {}
  } = booking[0];

  const { displayName, displayBreed, displayServiceName, displayBookingTime, dispalyBookingTags, dispalyOptionalDropDown, displayOrderStatus, displayStaffName } = recordOptionsToDisplay;

  const isConfirmed = selectedTab === BOOKING_TABS_VALUES_TYPES.CONFIRMED;

  const isMultiDay = selectedTab === BOOKING_TABS_VALUES_TYPES.MULTI_DAY;
  const isNew = selectedTab === BOOKING_TABS_VALUES_TYPES.NEW;

  const time = `${findThreeLetterDayName(new Date(timestamp).getDay())}, ${new Date(timestamp).getDay()} ${findMonthName(new Date(timestamp).getMonth())} at ${displayTime(
    new Date(timestamp).getUTCHours(),
    new Date(timestamp).getMinutes()
  )}`;

  const dailyBookingTime = `${displayTime(new Date(timestamp).getUTCHours(), new Date(timestamp).getMinutes())}`;

  const multiDayBookingTime = `${new Date(timestamp).getDate()} ${findMonthName(new Date(timestamp).getMonth())} to ${new Date(timestamp_until).getDate()} ${findMonthName(
    new Date(timestamp_until).getMonth()
  )} ${new Date(timestamp).getFullYear()} (${calculateNights(timestamp, timestamp_until)} Nights)`;

  const RecordData = (
    <>
      {displayServiceName && (
        <RecordBody fontWeight="600" width="100">
          {serviceName}
        </RecordBody>
      )}
      {displayBookingTime && !isConfirmed && !isMultiDay && (
        <RecordBody fontWeight="800" noMargin>
          {time}
        </RecordBody>
      )}

      {displayBookingTime && isConfirmed && (
        <RecordBody fontWeight="800" width="100" noMargin>
          {dailyBookingTime}
        </RecordBody>
      )}

      {displayBookingTime && isMultiDay && (
        <RecordBody fontWeight="800" width="250" noMargin>
          {multiDayBookingTime}
        </RecordBody>
      )}

      {displayStaffName && !isMultiStaffView && (
        <RecordBody fontWeight="600" width="80" noMargin>
          {BusUsers?.[0]?.name}
        </RecordBody>
      )}
      {displayOrderStatus && <RecordBody fontWeight="800">{payment?.fail_reason || sentenceCase(orderStatus)}</RecordBody>}
    </>
  );

  const recordOptions = (
    <PetRecordOptionsContainer>
      <BookingRecordOptions
        pets={pets}
        petQuickTags={[]}
        ref={bookingRecordOptionsRef}
        showAlways={mobile}
        defaultAppointments={booking}
        bookingQuickTags={quickAppointmentsTags}
        appointments={booking}
        refetchAppointmentsAfterTag={hasFilters}
        loadTags={false}
      />
    </PetRecordOptionsContainer>
  );

  const petBehaviorContent = petsBehaviour
    .map(behaviour => behaviour?.feeding_routine)
    .filter(Boolean)
    .join(', ');

  const petTreatmentContent = petsBehaviour
    .map(behaviour => behaviour?.treatment_routine)
    .filter(Boolean)
    .join(', ');

  const renderTrailingView = () => {
    return (
      <div style={{ marginRight: 10, marginLeft: 10, display: 'flex', flexDirection: 'column', overflow: 'scroll', gap: 10, width: '100%' }}>
        {recordActiveViewSettings === 'recordTags' && renderTags()}
        {recordActiveViewSettings === 'recordPetNotes' && !!petsContent && <GroupPetDetails backgroundColor={Colors.info2}>{petsContent}</GroupPetDetails>}
        {recordActiveViewSettings === 'recordBookingNotes' && !!ordersContent && <GroupPetDetails backgroundColor={Colors.info3}>{ordersContent}</GroupPetDetails>}
        {recordActiveViewSettings === 'recordAddress' && !!appUserAddressString && <GroupPetDetails backgroundColor={Colors.info}>{appUserAddressString}</GroupPetDetails>}
        {recordActiveViewSettings === 'recordFeedingSchedule' && mealsForPets}
        {recordActiveViewSettings === 'recordFeedingRoutine' && !!petBehaviorContent && <GroupPetDetails backgroundColor={Colors.info}>{petBehaviorContent}</GroupPetDetails>}
        {recordActiveViewSettings === 'recordTreatmentRoutine' && !!petTreatmentContent && <GroupPetDetails backgroundColor={Colors.info3}>{petTreatmentContent}</GroupPetDetails>}
      </div>
    );
  };

  const RecordItem = (
    <Fragment key={appointmentId}>
      <RecordDataContainer onClick={handleClick} fullWidth={mobile && !isConfirmed} streach={mobile} style={{ width: 'auto' }} autoHeight gap={20}>
        <RecordBody width="300" mobileWidth="100%" mobilePadding="12px 12px 0 12px">
          <AppUserImagePetRecordOptions>
            <AppUserImageNameContainer>
              <AppUserImageContainer noPadding={!showBulkSelect}>
                {mobile && inNew && <RedDot />}
                <AppUserImageContainerAnimation rotateOption={!!showBulkSelect} noPadding={showBulkSelect}>
                  {!showBulkSelect &&
                    !mobile &&
                    uniquePetsByPetId.map((pet, idx) => (
                      <AppUserImageAnimation key={pet?.id || idx}>
                        <AppUserImage src={pet?.profile_pic || icons.user.childImageSharp.gatsbyImageData.images.fallback.src} index={idx} />
                      </AppUserImageAnimation>
                    ))}

                  {!showBulkSelect &&
                    mobile &&
                    uniquePetsByPetId.map((pet, idx) => (
                      <>
                        <AppUserImageAnimation key={pet?.id || idx}>
                          <AppUserImage src={pet?.profile_pic || icons.user.childImageSharp.gatsbyImageData.images.fallback.src} index={idx} />
                        </AppUserImageAnimation>
                      </>
                    ))}
                  {mobile && <RecordDataOverflowContainer>{RecordData}</RecordDataOverflowContainer>}
                  {showBulkSelect && (
                    <AppUserImageAnimation>
                      <CheckboxItemContainer checked={isSelected(booking[0].id)} noMargin>
                        <Checkbox>
                          <Icon viewBox="0 0 24 24">
                            <polyline points="20 6 9 17 4 12" />
                          </Icon>
                        </Checkbox>
                      </CheckboxItemContainer>
                    </AppUserImageAnimation>
                  )}
                </AppUserImageContainerAnimation>
              </AppUserImageContainer>
              {!mobile && (
                <RecordBody fontWeight="800" width="100">
                  {name}, <RecordBody width="80"> {Breed?.name}</RecordBody>
                </RecordBody>
              )}
            </AppUserImageNameContainer>
          </AppUserImagePetRecordOptions>
        </RecordBody>

        {!mobile && RecordData}

        {/* {
        !mobile && (
          <RecordBody disableTruncate width="400">
            {renderTrailingView()}
          </RecordBody>
        )
      } */}
        <PetRecordTagsAndTimeContainer>
          <PetRecordTagsContainer>
            <VerticalDivider />
            <PetRecordTags width={isMultiDay ? 70 : 270}>
              {BranchAppointmentTags?.map(({ name, id, color }) => (
                <PetRecordTag color={color} key={id}>
                  {name}
                </PetRecordTag>
              ))}
            </PetRecordTags>
          </PetRecordTagsContainer>
          {!mobile && dispalyOptionalDropDown && recordOptions}
        </PetRecordTagsAndTimeContainer>

        <BookingRecordFilters selectedFilters={selectedFilters} booking={booking} />

        {mobile && (
          <>
            <RecordBody mobileWidth="100%" style={{ padding: '0 0 6px 0', borderBottomLeftRadius: '12px', borderBottomRightRadius: '12px' }} mobileFlexDirection="column" MobileGap={8}>
              <Divider color={Colors.white} />
              <RecordBody mobileWidth="100%" MobileGap={6} mobilePadding="0 8px 0 0" disableTruncate>
                {renderTrailingView()}
                {recordOptions}
              </RecordBody>
            </RecordBody>
          </>
        )}
      </RecordDataContainer>
      <Divider />
    </Fragment>
  );

  const RecordItemmm = (
    <Fragment key={appointmentId}>
      <Booking onClick={handleClick}>
        {isNew && <RedDot />}
        <PetBioExtraInfoContainer>
          <PetContainer ref={petContainerRef} width={200}>
            <PetImg src={profile_pic} alt={name} />
            <PetAndInfo>
              <RecordBody fontWeight="800" width="100">
                {name}, <RecordBody width="80"> {Breed?.name}</RecordBody>
              </RecordBody>
            </PetAndInfo>
          </PetContainer>
          {displayServiceName && (
            <RecordBody fontWeight="600" width="100">
              {serviceName}
            </RecordBody>
          )}
          {displayBookingTime && !isConfirmed && !isMultiDay && (
            <RecordBody fontWeight="800" noMargin>
              {time}
            </RecordBody>
          )}

          {displayBookingTime && isConfirmed && (
            <RecordBody fontWeight="800" width="100" noMargin>
              {dailyBookingTime}
            </RecordBody>
          )}

          {displayBookingTime && isMultiDay && (
            <RecordBody fontWeight="800" width="250" noMargin>
              {multiDayBookingTime}
            </RecordBody>
          )}

          {displayStaffName && !isMultiStaffView && (
            <RecordBody fontWeight="600" width="80" noMargin>
              {BusUsers?.[0]?.name}
            </RecordBody>
          )}
          {displayOrderStatus && <RecordBody fontWeight="800">{payment?.fail_reason || sentenceCase(orderStatus)}</RecordBody>}
        </PetBioExtraInfoContainer>

        <PetRecordTagsAndTimeContainer>
          {dispalyBookingTags && !isMultiStaffView && (
            <PetRecordTagsContainer>
              <VerticalDivider />
              <PetRecordTags width={isMultiDay ? 70 : 270}>
                {BranchAppointmentTags?.map(({ name, id, color }) => (
                  <PetRecordTag color={color} key={id}>
                    {name}
                  </PetRecordTag>
                ))}
              </PetRecordTags>
            </PetRecordTagsContainer>
          )}

          {dispalyOptionalDropDown && (
            <PetRecordOptionsContainer>
              <BookingRecordOptions appointments={[booking]} loadTags={false} ref={bookingRecordOptionsRef} />
            </PetRecordOptionsContainer>
          )}
        </PetRecordTagsAndTimeContainer>
      </Booking>
      <Divider />
    </Fragment>
  );
  return <div>{RecordItem}</div>;
};

export default BookingRecord;
