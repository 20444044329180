import React from 'react';
import ServicesBody from './ServicesBody';
import ServicesHeader from './ServicesHeader';
import { useServicesFilters } from './ServicesFilters';

const Services = () => {
  const filtersControl = useServicesFilters();

  const { selectedFilters } = filtersControl;

  return (
    <>
      <ServicesHeader filtersControl={filtersControl} />
      <ServicesBody selectedFilters={selectedFilters} />
    </>
  );
};

export default Services;
