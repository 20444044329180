import { useLazyQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { Controller, UseFormMethods } from 'react-hook-form';
import AsyncSelect from 'react-select/async';
import { GetReducedBranchProducts } from '../../../queries';
import debounce from '../../../utils/debounce';
import { selectTheme } from '../Forms/Forms';
import { VerticallyCenteredLoader } from '../Spinner';
import { Product } from '../../../views/Store/types';

const getOptions = (data: Record<string, Product[]>, itemsToFilter: string[] = []) => {
  return (
    Object.values(data || {})?.[0]
      ?.filter(product => !itemsToFilter.includes(product.id))
      ?.map(product => ({ value: product.id, label: `${product.name}\n${product.short_description ? `(${product.short_description})` : ''}` })) || []
  );
};

const ProductsList = ({
  name,
  formOptions,
  defaultValues,
  rules,
  isMulti,
  itemsToFilter,
  type = ['product'],
  onChangeProducts
}: {
  formOptions: UseFormMethods<any>;
  defaultValues?: string[];
  name?: string;
  rules?: Record<string, any>;
  isMulti?: boolean;
  itemsToFilter?: string[];
  type?: Product['type'][];
  onChangeProducts?: (products: string[]) => void;
}) => {
  const { control, watch } = formOptions;

  const watchedValue = watch(name || 'productId');

  const [getSearchBranchProducts, { data }] = useLazyQuery(GetReducedBranchProducts, { fetchPolicy: 'cache-and-network' });

  const [getProductById, { data: { getBranchProducts: defaultProducts = [] } = {}, loading, called: calledDefault, previousData }] = useLazyQuery<{ getBranchProducts: Product[] }>(
    GetReducedBranchProducts,
    {
      fetchPolicy: 'cache-and-network'
    }
  );

  const loadOptions = (inputValue: string, callback: (options: { value: string; label: string }[]) => void) => {
    debounce(async () => {
      const { data } = await getSearchBranchProducts({ variables: { product_search: [inputValue], type, requisite_queries: ['type', 'product_search'], alternative_queries: [] } });
      const options = getOptions(data, itemsToFilter);
      callback(options);
    }, 500);
  };

  const defaultSelectedProducts = [...new Set(defaultValues || [])];

  useEffect(() => {
    if (defaultSelectedProducts?.[0] && !loading && !defaultProducts.length && !calledDefault) {
      getProductById({ variables: { id: [...defaultSelectedProducts, ...[watchedValue || []].flat()], type, requisite_queries: ['type'], alternative_queries: [] } });
    }
  }, [defaultSelectedProducts?.[0], loading, defaultProducts.length, calledDefault]);

  const defaultUsers = defaultProducts?.filter(user => defaultSelectedProducts?.includes(user.id))?.map(user => ({ value: user.id, label: user.name }));

  const options = [...(defaultUsers || []), ...getOptions(data)];

  const defaultValue = isMulti ? defaultUsers?.map(item => item.value) : defaultUsers?.[0]?.value;

  if (loading && !previousData && defaultValues?.length) {
    return <VerticallyCenteredLoader size={30} />;
  }

  return (
    <Controller
      name={name || 'productId'}
      control={control}
      isMulti={isMulti}
      defaultValue={defaultValue}
      rules={{ required: true, ...rules }}
      render={({ onChange, value }) => {
        const updatedValue = isMulti
          ? [...(value || [])]?.map((item: string) => ({
              value: item,
              label:
                defaultProducts?.find(user => user.id === item)?.name ||
                options?.find(option => option.value === item)?.label ||
                previousData?.getBranchProducts?.find(user => user.id === item)?.name ||
                'Loading...'
            })) || []
          : value
          ? {
              value: value,
              label:
                defaultProducts?.find(user => user.id === value)?.name ||
                options?.find(option => option.value === value)?.label ||
                previousData?.getBranchProducts?.find(user => user.id === value)?.name ||
                'Loading...'
            }
          : null;

        return (
          <AsyncSelect
            defaultOptions={options}
            styles={{ container: provided => ({ ...provided }), valueContainer: provided => ({ ...provided, height: 48, overflowY: 'scroll' }) }}
            loadOptions={loadOptions}
            theme={selectTheme}
            name={name || 'productId'}
            onChange={(option: any) => {
              let updatedValue = option?.value;
              if (isMulti) {
                updatedValue = option?.map((item: any) => item.value);
              }
              getProductById({ variables: { id: [updatedValue].flat(), type, requisite_queries: ['type'], alternative_queries: [] } });
              if (onChangeProducts) {
                onChangeProducts(updatedValue);
              }
              onChange(updatedValue);
            }}
            value={updatedValue}
            options={options}
            isMulti={isMulti}
            cacheOptions
            noOptionsMessage={({ inputValue }) => (inputValue === '' ? 'Start typing to search' : 'No results found')}
          />
        );
      }}
    />
  );
};

export default ProductsList;
