import { useQuery } from '@apollo/client';
import React, { useRef, useState } from 'react';
import { DRAWER_IDS } from '../../../../components/DrawerBar/types';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import usePaginatedQuery from '../../../../hooks/usePaginatedQuery';
import { GetBranchPetProfile, GetMedCondsByPetRecordId } from '../../../../queries';
import { vars } from '../../../../reactive';
import Common from '../../../Store/Common';
import MedicalCaseDetails from './MedicalCaseDetails';
import MedicalCases from './MedicalCases';

const Medical = ({ renderHeader }: { renderHeader: () => JSX.Element }) => {
  const medicalDetailsRef = useRef(null);
  const drawerBar = vars.drawerBars().find(drawer => drawer.drawerId === DRAWER_IDS.PETS_DRAWER);

  const petId = drawerBar?.recordData;

  const autoOpenModal = drawerBar?.otherData?.autoOpenModal;

  const preSelectedCaseId = drawerBar?.otherData?.selectedReportId;

  const { data: { getBranchPetProfile: petProfile = {} } = {}, refetch: petProfileBranchRefetch } = useQuery(GetBranchPetProfile, {
    variables: {
      id: petId
    },
    fetchPolicy: 'cache-and-network'
  });

  const [[hasMoreItems, setHasMoreItems], { data: { getMedCondsByPetRecordId: cases = [] } = {}, loading, refetch, fetchMore }] = usePaginatedQuery({
    query: GetMedCondsByPetRecordId,
    limit: 6,
    otherVariables: { petRecordId: petProfile?.PetRecord?.id },
    otherParams: {
      skip: !petProfile?.PetRecord?.id
    }
  });
  const { tablet } = useMediaQuery({ tablet: true });

  const [selectedCaseId, setSelectedCaseId] = useState<String | null>(preSelectedCaseId || !tablet ? cases[0]?.id : null || null);

  const medicalCase = cases.find((medicalCase: any) => medicalCase.id === selectedCaseId);
  Common.set(`Medical.GetMedCondsByPetRecordId.refetch`, () => {
    refetch?.();
  });
  Common.set(`Medical.getBranchPetProfile.refetch`, () => {
    petProfileBranchRefetch?.();
  });

  const setSelectedCaseIdAndScroll = (caseId: string) => {
    setSelectedCaseId(caseId);
    medicalDetailsRef.current?.scrollIntoView({
      behavior: 'smooth'
    });
  };

  return (
    <>
      <MedicalCaseDetails
        pet={petProfile}
        medicalCase={medicalCase}
        deselectCase={() => setSelectedCaseId(null)}
        cases={cases}
        petId={petId as string}
        innerRef={medicalDetailsRef}
        loading={loading}
      />
      <MedicalCases
        petId={petId as string}
        cases={cases}
        loading={loading}
        setSelectedCaseId={setSelectedCaseIdAndScroll}
        fetchMore={fetchMore}
        hasMoreItems={hasMoreItems}
        setHasMoreItems={setHasMoreItems}
        selectedCaseId={selectedCaseId as string}
        autoOpenModal={autoOpenModal}
        onModalClose={drawerBar?.otherData?.onClose}
        renderHeader={renderHeader}
      />
    </>
  );
};

export default Medical;
