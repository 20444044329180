import React from 'react';
import ModalDialog from '../../../components/Modal/ModalDialog';
import { RecordBody, RecordDataContainer } from '../../styled';
import BusUserModal from '../BusUsers/BusUserModal';
import { BranchRoom } from './types';
import BranchRoomModal from './BranchRoomModal';

const BranchRoomRecord = ({ branchRoom }: { branchRoom: BranchRoom }) => {
  const handleClick = () => {
    ModalDialog.openModal({
      content: () => <BranchRoomModal branchRoom={branchRoom} />,
      title: 'Edit Branch Room'
    });
  };

  return (
    <RecordDataContainer onClick={handleClick}>
      <RecordBody fontWeight={'800'}>{branchRoom?.name}</RecordBody>
    </RecordDataContainer>
  );
};

export default BranchRoomRecord;
