import { useQuery } from '@apollo/client';
import React, { useRef, useState } from 'react';
import { DRAWER_IDS } from '../../../../components/DrawerBar/types';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import usePaginatedQuery from '../../../../hooks/usePaginatedQuery';
import { GetBranchPetProfile, getDaycareReportsByPetRecordId } from '../../../../queries';
import { vars } from '../../../../reactive';
import DaycareDetails from './DaycareDetails';
import DaycareHistory from './DaycareHistory';

const Daycare = ({ renderHeader }: { renderHeader: () => JSX.Element }) => {
  const daycareDetailsRef = useRef<HTMLElement>(null);
  const drawerBar = vars.drawerBars().find(drawer => drawer.drawerId === DRAWER_IDS.PETS_DRAWER);
  const petId = drawerBar?.recordData;
  const autoOpenModal = drawerBar?.otherData?.autoOpenModal;
  const preSelectedReprotId = drawerBar?.otherData?.selectedReportId;

  const { data: { getBranchPetProfile: petProfile = {} } = {} } = useQuery(GetBranchPetProfile, {
    variables: {
      id: petId
    }
  });

  const [[hasMoreItems, setHasMoreItems], { data: { getDaycareReportsByPetRecordId: daycareReports = [] } = {}, loading: loadingDaycareReports, fetchMore, refetch: refetchDaycareReports }] =
    usePaginatedQuery<any>({
      query: getDaycareReportsByPetRecordId,
      limit: 6,
      otherVariables: { petRecordId: petProfile?.PetRecord?.id },
      otherParams: {
        skip: !petProfile?.id
      }
    });

  const { tablet } = useMediaQuery({ tablet: true });

  const [selectedReportId, setSelectedReportId] = useState<string | null>(preSelectedReprotId || !tablet ? daycareReports[0]?.id : null || null);

  const setSelectedReportIdAndScroll = (noteId: string) => {
    setSelectedReportId(noteId);
    daycareDetailsRef.current?.scrollIntoView({
      behavior: 'smooth'
    });
  };

  const selectedReport = daycareReports.find((note: any) => note.id === selectedReportId);

  return (
    <>
      <DaycareDetails
        innerRef={daycareDetailsRef as any}
        selectedReport={selectedReport}
        daycareReports={daycareReports}
        loading={loadingDaycareReports}
        deselectReport={() => setSelectedReportId(null)}
        autoOpenModal={autoOpenModal}
        onModalClose={drawerBar?.otherData?.onClose}
      />
      <DaycareHistory
        fetchMore={fetchMore as any}
        hasMoreItems={hasMoreItems}
        setHasMoreItems={setHasMoreItems}
        daycareReports={daycareReports || []}
        setSelectedReportId={setSelectedReportIdAndScroll}
        loading={loadingDaycareReports}
        selectedReportId={selectedReportId as string}
        refetchDaycareReports={refetchDaycareReports}
        autoOpenModal={autoOpenModal}
        onModalClose={drawerBar?.otherData?.onClose}
        renderHeader={renderHeader}
      />
    </>
  );
};

export default Daycare;
