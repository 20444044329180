import { useMutation } from '@apollo/client';
import React, { FC, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ModalDialog from '../../../components/Modal/ModalDialog';
import { ModalBody, ModalFooter } from '../../../components/Modal/styled';
import { PetBehavior } from '../../../components/Pets/types';
import { FormButtonsContainer, FormError, FormInput, FormLabel, FormSelect, FormSubmitButton, InputsWrapper, WideInputGroup } from '../../../components/Shared/Forms/Forms';
import { Container } from '../../../components/Shared/Shared';
import { EditPetBehavior } from '../../../queries';

type BahaviorPetRecordModalFormProps = {
  onSubmit: () => void;
  petBehavior: PetBehavior;
  loading: boolean;
  error: boolean;
  formOptions: ReturnType<typeof useForm>;
};

const BahaviorPetRecordModalForm: FC<BahaviorPetRecordModalFormProps> = ({ loading, error, petBehavior, formOptions, onSubmit }) => {
  const { control, errors, setValue } = formOptions;

  const petBehaviorAroundDogs = petBehavior?.behavior_around_same_type;
  const petBehaviorAroundOtherPets = petBehavior?.behavior_around_other_pets;
  const petBehaviorAroundChildren = petBehavior?.behavior_around_children;
  const petBehaviorAroundAdults = petBehavior?.behavior_around_adults;
  const petBehaviorNegative = petBehavior?.behavior_negative;
  const petBehaviorPositive = petBehavior?.behavior_positive;

  const petBehaviorChoices = ['ANXIOUS', 'REACTIVE', 'AGGRESSIVE', 'FRIENDLY'];

  return (
    <>
      <ModalBody>
        <Container>
          <InputsWrapper noWrap>
            <WideInputGroup>
              <FormLabel>Behavior around dogs</FormLabel>

              <Controller
                render={({ onChange }) => (
                  <FormSelect height={48} fontSize={16} name={'behaviorAroundDogs'} onChange={e => onChange(e.target.value)} defaultValue={petBehaviorAroundDogs}>
                    {petBehaviorChoices.map(choice => (
                      <option key={choice} value={choice}>
                        {choice}
                      </option>
                    ))}
                  </FormSelect>
                )}
                control={control}
                name={'behaviorAroundDogs'}
                rules={{ required: true }}
                defaultValue={petBehaviorAroundDogs}
              />
              {errors.behaviorAroundDogs && <FormError>{errors.behaviorAroundDogs.message || 'behaviorAroundDogs is required'}</FormError>}
            </WideInputGroup>
          </InputsWrapper>

          <InputsWrapper noWrap>
            <WideInputGroup>
              <FormLabel>Behavior around other Pets</FormLabel>

              <Controller
                render={({ onChange }) => (
                  <FormSelect height={48} fontSize={16} name={'petBehaviorAroundOtherPets'} onChange={e => onChange(e.target.value)} defaultValue={petBehaviorAroundOtherPets}>
                    {petBehaviorChoices.map(choice => (
                      <option key={choice} value={choice}>
                        {choice}
                      </option>
                    ))}
                  </FormSelect>
                )}
                control={control}
                name={'petBehaviorAroundOtherPets'}
                rules={{ required: true }}
                defaultValue={petBehaviorAroundOtherPets}
              />
              {errors.petBehaviorAroundOtherPets && <FormError>{errors.petBehaviorAroundOtherPets.message || 'Behavior around other Pets is required'}</FormError>}
            </WideInputGroup>
          </InputsWrapper>

          <InputsWrapper noWrap>
            <WideInputGroup>
              <FormLabel>Behavior around Children</FormLabel>

              <Controller
                render={({ onChange }) => (
                  <FormSelect height={48} fontSize={16} name={'petBehaviorAroundChildren'} onChange={e => onChange(e.target.value)} defaultValue={petBehaviorAroundChildren}>
                    {petBehaviorChoices.map(choice => (
                      <option key={choice} value={choice}>
                        {choice}
                      </option>
                    ))}
                  </FormSelect>
                )}
                control={control}
                name={'petBehaviorAroundChildren'}
                rules={{ required: true }}
                defaultValue={petBehaviorAroundChildren}
              />
              {errors.petBehaviorAroundChildren && <FormError>{errors.petBehaviorAroundChildren.message || 'Behavior around Children is required'}</FormError>}
            </WideInputGroup>
          </InputsWrapper>

          <InputsWrapper noWrap>
            <WideInputGroup>
              <FormLabel>Behavior around Adults</FormLabel>

              <Controller
                render={({ onChange }) => (
                  <FormSelect height={48} fontSize={16} name={'petBehaviorAroundAdults'} onChange={e => onChange(e.target.value)} defaultValue={petBehaviorAroundAdults}>
                    {petBehaviorChoices.map(choice => (
                      <option key={choice} value={choice}>
                        {choice}
                      </option>
                    ))}
                  </FormSelect>
                )}
                control={control}
                name={'petBehaviorAroundAdults'}
                rules={{ required: true }}
                defaultValue={petBehaviorAroundAdults}
              />
              {errors.petBehaviorAroundAdults && <FormError>{errors.petBehaviorAroundAdults.message || 'Behavior around Adults is required'}</FormError>}
            </WideInputGroup>
          </InputsWrapper>

          <InputsWrapper noWrap>
            <WideInputGroup>
              <FormLabel>Negative Behavior</FormLabel>
              <Controller
                as={<FormInput error={errors.petBehaviorNegative} type={'string'} flexBasis={78} />}
                control={control}
                name={'petBehaviorNegative'}
                defaultValue={petBehaviorNegative || ''}
              />
              {errors.petBehaviorNegative && <FormError>{errors.petBehaviorNegative.message || 'Negative Behavior is required'}</FormError>}
            </WideInputGroup>
          </InputsWrapper>

          <InputsWrapper noWrap>
            <WideInputGroup>
              <FormLabel>Positive Behavior</FormLabel>
              <Controller
                as={<FormInput error={errors.petBehaviorPositive} type={'string'} flexBasis={78} />}
                control={control}
                name={'petBehaviorPositive'}
                defaultValue={petBehaviorPositive || ''}
              />
              {errors.petBehaviorPositive && <FormError>{errors.petBehaviorPositive.message || 'Positive Behavior is required'}</FormError>}
            </WideInputGroup>
          </InputsWrapper>
        </Container>
      </ModalBody>
      <ModalFooter>
        <FormButtonsContainer>
          <FormSubmitButton error={error} loading={loading} onClick={onSubmit}>
            Update
          </FormSubmitButton>
        </FormButtonsContainer>
      </ModalFooter>
    </>
  );
};

const BahaviorPetRecordModal = ({ petBehavior }: { petBehavior: PetBehavior }) => {
  const formOptions = useForm();
  const { handleSubmit } = formOptions;

  const [handleEditPetBehavior, { data: editPetBehaviorData, loading: loadingEditPerBehavior, error: errorEditPetBehavior }] = useMutation(EditPetBehavior);

  const onSubmit = handleSubmit(form => {
    handleEditPetBehavior({
      variables: {
        id: petBehavior?.id,
        behavior_around_same_type: form.behaviorAroundDogs,
        behavior_around_other_pets: form.petBehaviorAroundOtherPets,
        behavior_around_children: form.petBehaviorAroundChildren,
        behavior_around_adults: form.petBehaviorAroundAdults,
        behavior_negative: form.petBehaviorNegative,
        behavior_positive: form.petBehaviorPositive
      }
    });
  });

  useEffect(() => {
    if (editPetBehaviorData?.editPetBehavior?.id) {
      ModalDialog?.closeModal();
    }
  }, [editPetBehaviorData?.editPetBehavior?.id]);

  const loading = loadingEditPerBehavior;
  const error = !!errorEditPetBehavior?.message;

  return <BahaviorPetRecordModalForm onSubmit={onSubmit} loading={loading} error={error} petBehavior={petBehavior} formOptions={formOptions} />;
};

export default BahaviorPetRecordModal;
