import { useMutation, useQuery, useReactiveVar } from '@apollo/client';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import Colors from '../../../Colors';
import { AddBranchSlot, DeleteBranchSlot, EditBranchSlot, GetBranchSchedules, GetBranchSlots } from '../../../queries';
import { vars } from '../../../reactive';
import { toTimeInput } from '../../../utils/dates';
import { BranchSchedule } from '../../../views/Store/BranchSchedules/types';
import ModalDialog from '../../Modal/ModalDialog';
import { ModalBody, ModalFooter } from '../../Modal/styled';
import DurationSelector, { convertDurationToMinutes } from '../../Shared/DurationSelector';
import { FormButtonsContainer, FormError, FormInput, FormLabel, FormSubmitButton, InputsWrapper, WideInputGroup, selectTheme } from '../../Shared/Forms/Forms';
import { Container } from '../../Shared/Shared';
import { CenteredLoader } from '../../Shared/Spinner';
import Select from '../../Shared/Forms/Select';

type BranchSlot = {
  id: string;
  timestamp: string;
  count: number;
  duration: number;
  description: string;
  BranchSchedules: BranchSchedule[];
};

type BranchSlotForm = {
  branch_schedules: string[];
  description: string;
  date: string;
  time: string;
  count: number;
  duration: number;
};

const BlockSlotModalForm = ({ branchSlot }: { branchSlot?: BranchSlot }) => {
  const selectedDate = useReactiveVar(vars.selectedDate);
  const defaultHour = Number(selectedDate?.hour || 0);
  const defaultMinute = Number(selectedDate?.minute || 0);
  const selectedSlotDateUTC = branchSlot?.timestamp
    ? new Date(branchSlot.timestamp)
    : selectedDate
    ? new Date(Date.UTC(selectedDate.date?.getFullYear(), selectedDate.date?.getMonth(), selectedDate.date?.getDate(), defaultHour > -1 ? defaultHour : 0, defaultMinute))
    : new Date();

  const hour = selectedSlotDateUTC.getUTCHours();
  const minute = selectedSlotDateUTC.getUTCMinutes();

  const defaultValues = {
    scheduleIds: branchSlot?.BranchSchedules.map(schedule => schedule.id) || [],
    description: branchSlot?.description || 'Blocked',
    date: selectedSlotDateUTC?.toISOString().slice(0, -14),
    time: toTimeInput(Number(hour) + Number(minute) / 60),
    count: branchSlot?.count || 1,
    duration: branchSlot?.duration || 60
  };

  const formOptions = useForm<BranchSlotForm>({ defaultValues });

  const { control, handleSubmit, errors } = formOptions;

  const [handleAddBranchSlot, { data: addedBranchSlotData = {}, loading: addLoading, error: addError }] = useMutation(AddBranchSlot);

  const [handleEditBranchSlot, { data: editBranchSlotData = {}, loading: editLoading, error: editError }] = useMutation(EditBranchSlot);

  const [handleDeleteBranchSlot, { data: deletedBranchSlot, loading: deleteLoading, error: deleteError }] = useMutation(DeleteBranchSlot);

  const isLoading = addLoading || editLoading || deleteLoading;
  const isDone = addedBranchSlotData?.addBranchSlot?.id || editBranchSlotData?.editBranchSlot?.id || deletedBranchSlot;
  const isError = !!addError?.message || !!editError?.message || !!deleteError?.message;

  const { data: { getBranchSchedules: schedules = [] } = {}, loading: loadingServices } = useQuery<{ getBranchSchedules: BranchSchedule[] }>(GetBranchSchedules, {
    fetchPolicy: 'cache-and-network'
  });

  const options = schedules.map(schedule => ({
    value: schedule.id,
    label: schedule.name
  }));

  const handleDelete = () => {
    handleDeleteBranchSlot({
      variables: {
        id: branchSlot?.id
      }
    });
  };

  const onSubmit = handleSubmit(data => {
    const { count, duration, description, branch_schedules, date, time } = data;
    const timestamp = new Date(`${date}T${time}:00Z`).toISOString();

    const variables = {
      timestamp,
      count: Number(count),
      duration: Number(duration !== undefined ? convertDurationToMinutes(duration) || 0 : 0),
      description,
      branch_schedules,
      status: 'BLOCKED'
    };

    if (!branchSlot?.id) {
      handleAddBranchSlot({ variables });
      return;
    }

    handleEditBranchSlot({
      variables: {
        id: branchSlot.id,
        ...variables
      }
    });
  });

  useEffect(() => {
    if (isDone && !isError) {
      vars.selectedDate(null);
      ModalDialog.closeModal();
    }
  }, [isDone, isError]);

  return (
    <>
      <ModalBody>
        <Container width={500}>
          {loadingServices && <CenteredLoader />}
          {!loadingServices && (
            <InputsWrapper>
              <WideInputGroup>
                <FormLabel error={!!errors.description?.message}>Title</FormLabel>
                <Controller
                  as={<FormInput error={!!errors.description?.message} />}
                  control={control}
                  name={'description'}
                  defaultValue={defaultValues?.description}
                  rules={{
                    required: true
                  }}
                />
                {errors.description && <FormError>{errors.description.message || 'Title is required'}</FormError>}
              </WideInputGroup>
              <WideInputGroup>
                <FormLabel error={!!errors.branch_schedules?.filter(schedule => !!schedule?.message)?.length}>Schedules</FormLabel>
                <Controller
                  render={({ onChange, value }) => {
                    return (
                      <Select
                        styles={{ container: (provided, state) => ({ ...provided }), valueContainer: (provided, state) => ({ ...provided, height: 48, overflowY: 'scroll' }) }}
                        options={options}
                        isMulti
                        value={options.filter(option => value.includes(option.value))}
                        onChange={selected => {
                          onChange(selected.map(option => option.value));
                        }}
                        theme={selectTheme}
                        name={'branch_schedules'}
                      />
                    );
                  }}
                  control={control}
                  name={'branch_schedules'}
                  defaultValue={defaultValues?.scheduleIds}
                />
                {errors.branch_schedules && <FormError>{errors.branch_schedules.map(error => error?.message).join(', ') || 'schedule is required'}</FormError>}
              </WideInputGroup>

              <WideInputGroup>
                <FormLabel error={errors.date?.message}>Date</FormLabel>
                <Controller
                  as={<FormInput error={!!errors.date?.message} type={'date'} />}
                  control={control}
                  name={'date'}
                  defaultValue={defaultValues?.date}
                  rules={{
                    required: true
                  }}
                />
                {errors.date && <FormError>{errors.date.message || 'date is required'}</FormError>}
              </WideInputGroup>
              <WideInputGroup>
                <FormLabel error={errors.time?.message}>Time</FormLabel>
                <Controller as={<FormInput error={errors.time?.message} type={'time'} />} control={control} name={'time'} defaultValue={defaultValues?.time} rules={{ required: true }} />
                {errors.time && <FormError>{errors.time.message || 'time is required'}</FormError>}
              </WideInputGroup>
              <DurationSelector
                defaultDuration={defaultValues?.duration}
                options={{
                  name: 'duration',
                  title: 'Duration',
                  rules: { required: true }
                }}
                formOptions={formOptions}
              />
              {errors?.duration && <FormError>{errors?.duration?.message || 'duration is required'}</FormError>}
              <WideInputGroup>
                <FormLabel error={errors.count?.message}>Count</FormLabel>
                <Controller
                  as={<FormInput error={errors.count?.message} type={'number'} />}
                  control={control}
                  name={'count'}
                  defaultValue={defaultValues?.count}
                  rules={{ required: true, max: 50, min: 1 }}
                />
                {errors.count && <FormError>{errors.count.message || 'count is required'}</FormError>}
              </WideInputGroup>
            </InputsWrapper>
          )}
        </Container>
      </ModalBody>
      <ModalFooter bgColor={Colors.white}>
        <FormButtonsContainer>
          <FormSubmitButton error={isError} loading={isLoading} onClick={onSubmit}>
            Block Slot
          </FormSubmitButton>
          {branchSlot?.id && (
            <FormSubmitButton type="button" onClick={handleDelete} loading={deleteLoading} error={!!deleteError} secondary danger>
              Unblock Slot
            </FormSubmitButton>
          )}
        </FormButtonsContainer>
      </ModalFooter>
    </>
  );
};

const BlockSlotModal = ({ branchSlotId }: { branchSlotId?: string }) => {
  const { data: { getBranchSlots: [branchSlot] = [] } = {}, loading } = useQuery(GetBranchSlots, {
    fetchPolicy: 'cache-and-network',
    variables: {
      id: [branchSlotId]
    },
    skip: !branchSlotId
  });

  if (loading) {
    return <CenteredLoader />;
  }

  return <BlockSlotModalForm branchSlot={branchSlot} />;
};

export default BlockSlotModal;
