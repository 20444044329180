import { useQuery } from '@apollo/client';
import React, { FC } from 'react';
import useMediaQuery from '../../hooks/useMediaQuery';
import useURLSearchParams from '../../hooks/useURLSearchParams';
import { GetBusUserProfile } from '../../queries';
import { vars } from '../../reactive';
import BehaviorRoutine from '../../views/Pets/BehaviorRoutine/BehaviorRoutine';
import Diet from '../../views/Pets/Diet/Diet';

import Health from '../../views/Pets/Health/Health';
import PetForms from '../../views/Pets/PetForms/PetForms';
import Reports from '../../views/Pets/Reports/Reports';
import VetInsurance from '../../views/Pets/VetInsurance/VetInsurance';
import { Divider, DrawerSection } from '../DrawerBar/styled';
import { DRAWER_IDS } from '../DrawerBar/types';
import { ExtensionBody, ExtensionContainer, TabContainer } from '../Pets/styled';
import { Pet } from '../Pets/types';
import PetProfileInfo from '../Profile/PetProfileInfo';
import ProfileActionBar, { SMALL_DESCRIPTION_POSITIONS } from '../Profile/ProfileActionBar';
import ToolBar from '../Shared/ToolBar/ToolBar';
const { ENV } = require('../../utils/config');

//TODO change this after permissions on branches
export const VET_BRANCHES = [
  // Vet in the city
  '221d3ffa-e7d8-4ece-a672-bdfabb428ecc',
  // Collar Store
  '014ef20d-33e4-4333-a5b3-2ca0d5d1ddc1',
  // Alphas Canine Care
  '58344757-1127-45f8-b303-3a8202c24bd2',
  // The Wag Club - West Hampstead
  '49cc4fbf-2ee8-4182-82d7-242a1813f1da',
  // The Wag Club - Golders Green
  'a1b8d818-021b-4f81-ab19-8ef7aed5dc9e',
  // The Wag Club - Muswell Hill
  'f50f7551-0700-4dd1-8ff3-81f5f448588e',
  // Snoots
  '483c7a7d-547d-465e-9d8e-bad86d9d4793',
  // The Paw and Whistle
  '05870340-e6ee-4f35-aebc-0ae9d734e384'
] as const;

export const TRAINER_BRANCHES = [
  // Country pooch
  '4ccecc7a-8c87-4730-b0ad-c6e18d2d12df'
] as const;

export enum PETS_EXTENSTION_TABS {
  SUMMARY = 'SUMMARY',
  MEDICAL = 'MEDICAL',
  GROOMING = 'GROOMING',
  TRAINING = 'TRAINING',
  WALKING = 'WALKING',
  SITTING = 'SITTING',
  DAYCARE = 'DAYCARE',
  FORMS = 'FORMS',
  DIET = 'DIET',
  PASSPORT = 'PASSPORT'
}

export enum PETS_EXTENSTION_TABS_IDS {
  SUMMARY = 'summary',
  DIET = 'diet',
  REPORTS = 'reports',
  MEDICAL = 'medical',
  GROOMING = 'grooming',
  TRAINING = 'training',
  WALKING = 'walking',
  SITTING = 'sitting',
  DAYCARE = 'daycare',
  FORMS = 'forms',
  BEHAVIOR_ROUTINE = 'behaviorRoutine',
  VET_INSURANCE = 'vetInsurance',
  PASSPORT = 'passport'
}

export enum PETS_EXTENSTION_TABS_NAMES {
  SUMMARY = 'Health',
  DIET = 'Diet',
  REPORTS = 'Reports',
  FORMS = 'Forms',
  BEHAVIOR_ROUTINE = 'Behavior & Routine',
  VET_INSURANCE = 'Vet & Insurance',
  MEDICAL = 'Medical',
  GROOMING = 'Grooming',
  TRAINING = 'Training',
  WALKING = 'Walking',
  SITTING = 'Sitting',
  DAYCARE = 'Daycare',
  PASSPORT = 'Passport'
}

export const isVetBranch = (branchId: string) => VET_BRANCHES.includes(branchId as any) || ENV === 'development';

export const isTrainerBranch = (branchId: string) => TRAINER_BRANCHES.includes(branchId as any) || ENV === 'development';

type PetExtensionProps = {
  petProfile: Pet;
  onContainerScroll?: React.UIEventHandler<HTMLDivElement>;
};

const PetExtension: FC<PetExtensionProps> = ({ petProfile, onContainerScroll }) => {
  const drawerBar = vars.drawerBars().find(drawer => drawer.drawerId === DRAWER_IDS.PETS_DRAWER);
  const { data: { getBusUserProfile: profile = {} } = {} } = useQuery(GetBusUserProfile);
  const isVet = isVetBranch(profile?.Branch?.id);
  const isTrainer = isTrainerBranch(profile?.Branch?.id);

  const { mobile } = useMediaQuery({ mobile: true });
  const [tab] = useURLSearchParams('selectedTab');

  const currentTab = tab[0] || PETS_EXTENSTION_TABS_IDS.SUMMARY;

  const reportsTab = currentTab === PETS_EXTENSTION_TABS_IDS.REPORTS;

  // const tabs = useMemo(() => {
  //   const { SUMMARY, MEDICAL, GROOMING, TRAINING, FORMS, DAYCARE, SITTING, WALKING } = PETS_EXTENSTION_TABS;
  //   // if (isVet) {
  //   //   return [SUMMARY, MEDICAL, GROOMING, TRAINING, WALKING, SITTING, DAYCARE, FORMS];
  //   // }

  //   // if (isTrainer) {
  //   //   return [SUMMARY, TRAINING, FORMS];
  //   // }

  //   return [SUMMARY, MEDICAL, GROOMING, TRAINING, WALKING, SITTING, DAYCARE, FORMS];
  //   // return [SUMMARY, GROOMING, TRAINING, WALKING, SITTING, DAYCARE, FORMS];
  // }, [isVet, isTrainer]);

  // const tabsToRender = mobile ? [PETS_EXTENSTION_TABS.SUMMARY, ...tabs.slice(1)] : tabs;

  // const [currentTab, setCurrentTab] = useState(drawerBar?.otherData?._IDSselectedTab || tabsToRender[0]);

  // useEffect(() => {
  //   if (drawerBar?.otherData?.selectedTab) {
  //     setCurrentTab(drawerBar?.otherData?.selectedTab);
  //   }
  // }, [drawerBar?.otherData?.selectedTab]);

  const toolBarButtons = [
    { name: PETS_EXTENSTION_TABS_NAMES.SUMMARY, id: PETS_EXTENSTION_TABS_IDS.SUMMARY },
    { name: PETS_EXTENSTION_TABS_NAMES.DIET, id: PETS_EXTENSTION_TABS_IDS.DIET },
    { name: PETS_EXTENSTION_TABS_NAMES.BEHAVIOR_ROUTINE, id: PETS_EXTENSTION_TABS_IDS.BEHAVIOR_ROUTINE },
    { name: PETS_EXTENSTION_TABS_NAMES.VET_INSURANCE, id: PETS_EXTENSTION_TABS_IDS.VET_INSURANCE },
    { name: PETS_EXTENSTION_TABS_NAMES.REPORTS, id: PETS_EXTENSTION_TABS_IDS.REPORTS },
    { name: PETS_EXTENSTION_TABS_NAMES.FORMS, id: PETS_EXTENSTION_TABS_IDS.FORMS }
  ];

  const mobileToolBarButtons = [{ name: PETS_EXTENSTION_TABS_NAMES.PASSPORT, id: PETS_EXTENSTION_TABS_IDS.PASSPORT }, ...toolBarButtons];
  return (
    <>
      <ExtensionContainer>
        {mobile && <Divider />}
        <ToolBar
          toolBarButtons={mobile ? mobileToolBarButtons : toolBarButtons}
          urlSearchParam="selectedTab"
          displayTools={false}
          displayAll={false}
          selectOnlyOne={true}
          displayDivider={false}
          defaultValue={!mobile ? PETS_EXTENSTION_TABS_IDS.SUMMARY : PETS_EXTENSTION_TABS_IDS.PASSPORT}
        />
        {/* <TabsHeader
        tabs={Object.values(tabsToRender)}
        selected={currentTab}
        setSelected={setCurrentTab}
        backgroundColor={Colors.white}
        higherRadius={mobile}
        actionsPadding={mobile ? '16px 16px' : '20px'}
        mobilePadding="0 0"
        mobileFontSize={16}
      /> */}

        {mobile && !PETS_EXTENSTION_TABS_IDS.SUMMARY && <Divider />}
        <ExtensionBody onScroll={onContainerScroll}>
          <TabContainer onScroll={onContainerScroll}>
            {currentTab === PETS_EXTENSTION_TABS_IDS.PASSPORT && mobile && <PetProfileInfo profile={petProfile} />}
            {currentTab === PETS_EXTENSTION_TABS_IDS.SUMMARY && <Health />}
            {currentTab === PETS_EXTENSTION_TABS_IDS.DIET && <Diet />}

            {currentTab === PETS_EXTENSTION_TABS_IDS.REPORTS && <Reports />}

            {currentTab === PETS_EXTENSTION_TABS_IDS.FORMS && <PetForms />}
            {currentTab === PETS_EXTENSTION_TABS_IDS.BEHAVIOR_ROUTINE && <BehaviorRoutine />}
            {currentTab === PETS_EXTENSTION_TABS_IDS.VET_INSURANCE && <VetInsurance />}
          </TabContainer>
        </ExtensionBody>
      </ExtensionContainer>
      {mobile && (
        <>
          <Divider />
          <DrawerSection paddingT={20} paddingB={20}>
            <ProfileActionBar smallDescriptionPosition={SMALL_DESCRIPTION_POSITIONS.UP} profile={petProfile?.AppUser} drawerId={DRAWER_IDS.PETS_DRAWER} />
          </DrawerSection>
        </>
      )}
    </>
  );
};

export default PetExtension;
