import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import React, { useCallback } from 'react';
import { sentenceCase } from 'sentence-case';
import useIcons from '../../hooks/useIcons';
import { BranchAppUser } from '../../views/Store/BranchBilling/types';
import { ChatButton, Divider } from '../DrawerBar/styled';
import ModalDialog from '../Modal/ModalDialog';
import { ProfileLabel, ProfileLink, ProfileSection, ProfileSubLabel } from '../Profile/styled';
import AppUserDetailsModal from './AppUserDetailsModal';

const UserProfileInfo = ({ profile }: { profile: BranchAppUser }) => {
  const icons = useIcons();
  const moreIcon = icons.moreIcon.childImageSharp.gatsbyImageData.images.fallback.src;
  const { postcode, email, phone_numbers } = profile || {};

  const sections = [
    { subLabel: 'Email', label: email, unit: '', href: `mailto:${email}` },
    ...((profile?.addresses || [])?.map((address, index, self) => {
      const { line1, line2, city, country } = address || {};
      return {
        subLabel: `Address ${self.length > 1 ? index + 1 : ''}`,
        labels: [line1, line2, city, postcode, country].filter(Boolean),
        unit: '',
        divider: true
      };
    }) || []),
    !profile?.addresses?.length && { subLabel: 'Address', label: '-', unit: '', divider: true },
    ...((phone_numbers || [])?.map((phone, _, self) => {
      const { number, label, country_code } = phone || {};
      return {
        subLabel: `${self.length > 1 ? `${sentenceCase(label || '')} Number` : ''}`,
        label: `${country_code} ${number}`,
        unit: ''
      };
    }) || []),
    !phone_numbers?.length && { subLabel: 'Phone', label: '-', unit: '' }
  ].filter(Boolean) as {
    subLabel: string;
    labels?: string[];
    label?: string;
    unit: string;
    href?: string;
  }[];

  const hasCreditCard = !!profile?.payment?.method;
  const creditCardImage = getImage(icons.creditCard);
  const onEdit = useCallback(() => {
    ModalDialog.openModal({
      content: () => <AppUserDetailsModal profile={profile} />,
      title: 'Edit User Details'
    });
  }, [profile]);

  return (
    <div>
      {sections.map(({ subLabel, label, unit, labels, href, divider }, i) => (
        <ProfileSection key={i}>
          {subLabel === 'Email' && (
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <ProfileSubLabel>{subLabel}</ProfileSubLabel>
              <ChatButton bgImage={moreIcon} onClick={onEdit} style={{ justifyContent: 'start' }} />
            </div>
          )}
          {subLabel !== 'Email' && <ProfileSubLabel>{subLabel}</ProfileSubLabel>}
          {!href && !labels && <ProfileLabel>{label ? label + (unit || '') : '-'}</ProfileLabel>}

          {!href && !label && labels?.map((label, i) => <ProfileLabel key={i}>{label ? label + (unit || '') : '-'}</ProfileLabel>)}

          {href && <ProfileLink href={href}>{label}</ProfileLink>}
          {divider && <Divider marginTop={20} />}
        </ProfileSection>
      ))}

      {hasCreditCard && <GatsbyImage image={creditCardImage!} alt={'User has credit card'} />}
    </div>
  );
};

export default UserProfileInfo;
