import React from 'react';
import { DRAWER_IDS } from '../../components/DrawerBar/types';
import InifiniteList from '../../components/Shared/InfiniteList/InfiniteList';
import useNavigateDrawer from '../../hooks/useNavigateDrawer';
import { UsePagintaedQueryResult } from '../../hooks/usePaginatedQuery';
import useSetDrawerOnNavigation from '../../hooks/useSetDrawerOnNavigation';
import OrderRecord from './OrderRecord';
import { ProductOrder } from './types';

const OrdersList = ({ paginatedQueryResult, totalAtTheEnd }: { paginatedQueryResult: UsePagintaedQueryResult<ProductOrder[]>; totalAtTheEnd?: boolean }) => {
  const [[hasMoreItems, setHasMoreItems], queryResult] = paginatedQueryResult;

  const { data: { getBranchOrders: orders = [] } = {}, loading, fetchMore } = queryResult;

  useSetDrawerOnNavigation({
    itemList: orders,
    drawerId: DRAWER_IDS.ORDER_DRAWER,
    itemsToFilterFrom: orders,
    fireOnce: false
  });

  const navigateDrawer = useNavigateDrawer({
    drawerData: {
      drawerId: DRAWER_IDS.ORDER_DRAWER,
      itemsToFilterFrom: orders
    }
  });

  return (
    <InifiniteList
      paddingL={20}
      paddingR={20}
      list={orders}
      itemRenderer={order => <OrderRecord navigateDrawer={navigateDrawer} key={order.id} order={order} totalAtTheEnd={totalAtTheEnd} />}
      loading={loading}
      hasMoreItems={hasMoreItems}
      fetchMore={fetchMore as any}
      offset={orders?.length}
      setHasMoreItems={setHasMoreItems}
    />
  );
};

export default OrdersList;
