import { GatsbyImage } from 'gatsby-plugin-image';
import React, { Fragment, useRef } from 'react';
import CalendarRange, { CalendarRangeRef } from '../../../../components/Calendar/CalendarRange';
import { CounterBtn } from '../../../../components/Calendar/styled';
import ModalDialog from '../../../../components/Modal/ModalDialog';
import useIcons from '../../../../hooks/useIcons';
import useURLSearchParams from '../../../../hooks/useURLSearchParams';
import { formatDate } from '../../../../utils/dates';
import { DateContainer, NavigationButtonsContainer } from './styles';

const BookingsNavigation = () => {
  const icons = useIcons();
  const [urlDate, setUrlDate] = useURLSearchParams('date');
  const selectedDate = new Date(urlDate)?.getTime() ? urlDate : new Date();

  const lessIcon = icons.less.childImageSharp.gatsbyImageData;
  const moreIcon = icons.more.childImageSharp.gatsbyImageData;
  const rangeRef = useRef<CalendarRangeRef>(null);

  const increment = () => {
    const currentDate = new Date(selectedDate);
    currentDate.setUTCDate(currentDate.getDate() + 1);
    setUrlDate(currentDate.toISOString());
  };

  const decrement = () => {
    const currentDate = new Date(selectedDate);
    currentDate.setUTCDate(currentDate.getDate() - 1);
    setUrlDate(currentDate.toISOString());
  };

  return (
    <Fragment>
      <NavigationButtonsContainer>
        <CounterBtn onClick={decrement} style={{ width: '21px', height: '20px' }}>
          <GatsbyImage image={lessIcon} alt="decrement" />
        </CounterBtn>

        <CounterBtn onClick={increment} style={{ width: '21px', height: '20px' }}>
          <GatsbyImage image={moreIcon} alt="increment" />
        </CounterBtn>
      </NavigationButtonsContainer>

      <DateContainer
        onClick={() => {
          ModalDialog.openModal({
            noHeader: true,
            overlayClose: true,
            content: () => (
              <CalendarRange
                ref={rangeRef}
                currentCalendarLength={1}
                useDatePicker={true}
                setUTCDate={date => {
                  setUrlDate(date);
                  ModalDialog.closeModal();
                }}
              />
            )
          });
        }}
      >
        {formatDate(new Date(selectedDate))}
      </DateContainer>
    </Fragment>
  );
};

export default BookingsNavigation;
