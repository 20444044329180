import { useMutation, useQuery } from '@apollo/client';
import React, { FC, useEffect } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import Notifier from '../../../../Notifier';
import Alert from '../../../../components/Alert/Alert';
import { DatesContainer } from '../../../../components/Calendar/Modals/styled';
import ModalDialog from '../../../../components/Modal/ModalDialog';
import { ModalBody, ModalFooter } from '../../../../components/Modal/styled';
import {
  FormButtonsContainer,
  FormError,
  FormInput,
  FormLabel,
  FormSelect,
  FormSubmitButton,
  InputsWrapper,
  RadioBtnsGroup,
  SectionSpan,
  WideInputGroup
} from '../../../../components/Shared/Forms/Forms';
import { Container } from '../../../../components/Shared/Shared';
import useReduceAppointments from '../../../../hooks/useReduceAppointments';
import { GetBranchAppointments, UpdateAppointmentsTimestamp } from '../../../../queries';
import { getStartOfToday, toDateWithSlashes, toReadableDate } from '../../../../utils/dates';
import { BOOKING_STATUS_TYPES, BOOKING_TYPE, Booking, BookingOrder } from '../../types';
import { AppointmentsContainer, TimestampUntilDate } from './styled';

interface BookingRescheduleFormProps {
  orderAppointments: BookingOrder[];
  formOptions: ReturnType<typeof useForm>;
  loadingAdd: boolean;
  onSubmit: () => void;
  selectedAppointment: Booking;
  newTimestampUntil: Date | null;
  group: boolean;
  newDate?: string;
  newDateUntil?: string;
  watchedDate: string;
}

const getNewTimestampUntil = ({ newTimestamp, timestamp, timestampUntil }: { newTimestamp: Date | string; timestamp: Date | string; timestampUntil: Date | string }): Date | null => {
  if (!newTimestamp || !timestamp || !timestampUntil) return null;
  const timestampDate = new Date(timestamp);
  timestampDate.setUTCHours(12, 0, 0, 0);
  const timestampUntilDate = new Date(timestampUntil);
  timestampUntilDate.setUTCHours(12, 0, 0, 0);
  const newTimestampDate = new Date(newTimestamp);
  newTimestampDate.setUTCHours(12, 0, 0, 0);

  const diff = timestampUntilDate.getTime() - timestampDate.getTime();
  const newTimestampUntil = new Date(newTimestampDate.getTime() + diff);
  return newTimestampUntil;
};

const BookingRescheduleForm: FC<BookingRescheduleFormProps> = ({
  orderAppointments,
  formOptions,
  loadingAdd,
  onSubmit,
  selectedAppointment,
  newTimestampUntil,
  group,
  newDate,
  newDateUntil,
  watchedDate
}) => {
  const { control, errors } = formOptions;
  const firstAppointment = orderAppointments?.[0]?.[0];
  const timestamp = newDate || selectedAppointment?.timestamp || firstAppointment?.timestamp;
  const timestampUntil = newDateUntil || selectedAppointment?.timestamp_until || firstAppointment?.timestamp_until;

  const selectedAppointmentDate = new Date(timestamp).toISOString().slice(0, 10);
  const selectedAppointmentTime = new Date(timestamp).toLocaleTimeString();
  const multiDayEndDate = new Date(newTimestampUntil).toISOString().slice(0, 10);

  // console.log(multiDayEndTime);

  const appUsers = orderAppointments.flatMap(appointment => appointment.map(a => a.OrderItem.Order.AppUser));
  const uniqueAppUsersByIds = [...new Set(appUsers.map(appUser => appUser.id))];
  const appUsersLength = uniqueAppUsersByIds.length;
  const appUsersNames = appUsersLength > 1 ? `${appUsersLength} Clients` : appUsers.find(({ id }) => id === uniqueAppUsersByIds[0])?.name;

  const pets = orderAppointments.flatMap(appointment => appointment.map(a => a.PetRecord.Pet));
  const uniquePetsByIds = [...new Set(pets.map(pet => pet.id))];
  const petsNames = appUsersLength > 1 ? `${uniquePetsByIds.length} Pets` : uniquePetsByIds.map(id => pets.find(pet => pet.id === id)?.name).join(' & ');

  const multiDay = firstAppointment?.OrderItem?.item?.booking_type === BOOKING_TYPE.MULTI_DAY;

  return (
    <>
      <ModalBody>
        <Container width={500}>
          <FormLabel>Service</FormLabel>
          <SectionSpan>{firstAppointment?.OrderItem?.item?.name}</SectionSpan>
          <FormLabel>Booked by</FormLabel>
          <SectionSpan>{appUsersNames}</SectionSpan>
          <FormLabel>Booked for</FormLabel>
          <SectionSpan>{petsNames}</SectionSpan>
          <InputsWrapper noWrap>
            <WideInputGroup>
              <AppointmentsContainer>
                <FormLabel>Booking Slot</FormLabel>
                <Controller
                  control={control}
                  name="appointmentId"
                  render={({ onChange, value }) => (
                    <FormSelect
                      name={'appointmentId'}
                      height={48}
                      fontSize={16}
                      onChange={e => {
                        onChange(e.target.value);
                      }}
                      value={value || ''}
                    >
                      {orderAppointments.map((appointment, index) => (
                        <option key={appointment[0].id} value={appointment[0].id}>
                          Booking slot {index + 1} (
                          {toReadableDate(appointment[0].timestamp, {
                            noTime: multiDay
                          })}
                          {appointment[0].timestamp_until &&
                            ` - ${toReadableDate(timestampUntil, {
                              noTime: true
                            })}`}
                          )
                        </option>
                      ))}
                    </FormSelect>
                  )}
                  defaultValue={firstAppointment?.id}
                />
              </AppointmentsContainer>
            </WideInputGroup>

            <DatesContainer>
              <WideInputGroup>
                <FormLabel error={errors?.date?.message}>{multiDay ? 'Start Date' : 'New Date'}</FormLabel>
                <Controller
                  as={<FormInput error={errors?.date?.message} type={'date'} name={`date`} />}
                  control={control}
                  name={`date`}
                  defaultValue={selectedAppointmentDate}
                  rules={{
                    required: {
                      value: true,
                      message: 'Please select a date'
                    },
                    validate: (value: string) => {
                      const selectedDate = new Date(value);
                      selectedDate.setUTCHours(0, 0, 0, 0);
                      const today = getStartOfToday();
                      today.setUTCHours(0, 0, 0, 0);
                      if (selectedDate.getTime() < today.getTime()) {
                        return "Date can't be in the past";
                      }
                    }
                  }}
                />
                {errors?.date && <FormError>{errors?.date?.message || 'Please enter a valid date'}</FormError>}
              </WideInputGroup>

              {multiDay && (
                <WideInputGroup>
                  {/* <FormLabel error={errors?.endDate?.message}>New End Date</FormLabel>
                  <TimestampUntilDate>{toDateWithSlashes(newTimestampUntil)}</TimestampUntilDate> */}

                  <FormLabel error={errors?.endDate?.message}>End Date</FormLabel>
                  <Controller
                    control={control}
                    name="endDate"
                    defaultValue={multiDayEndDate}
                    render={({ onChange, value }) => <FormInput value={value} onChange={onChange} error={errors?.endDate?.message} type="date" />}
                  />
                  {errors?.endDate && <FormError>{errors?.endDate?.message || 'Please enter a valid date'}</FormError>}
                </WideInputGroup>
              )}

              {!multiDay && (
                <WideInputGroup>
                  <FormLabel error={errors?.time?.message}>New Time</FormLabel>
                  <Controller
                    as={<FormInput error={errors?.time?.message} type={'time'} />}
                    control={control}
                    name={`time`}
                    defaultValue={selectedAppointmentTime}
                    rules={{
                      required: {
                        value: true,
                        message: 'Please select a time'
                      },
                      validate: (value: string) => {
                        const selectedDate = new Date(`${watchedDate}T${value}`);
                        const today = getStartOfToday();
                        if (selectedDate.getTime() < today.getTime()) {
                          return "Time can't be in the past";
                        }
                      }
                    }}
                  />
                  {errors?.time && <FormError>{errors?.time.message}</FormError>}
                </WideInputGroup>
              )}
            </DatesContainer>

            {!multiDay && (
              <WideInputGroup>
                <Controller
                  control={control}
                  name="rescheduleAll"
                  render={({ onChange }) => (
                    <RadioBtnsGroup
                      name="rescheduleAll"
                      options={['Reschedule for all users booked for the same service and booking slot']}
                      itemStyle={{ gap: 10 }}
                      defaultValue={group}
                      inputType={'checkbox'}
                      onChange={e => {
                        onChange(e.target.checked);
                      }}
                      disabled={group}
                    />
                  )}
                  defaultValue={group}
                />
              </WideInputGroup>
            )}

            <WideInputGroup>
              <Controller
                control={control}
                name="notifications_disabled"
                render={({ onChange }) => (
                  <RadioBtnsGroup
                    name="notifications_disabled"
                    options={['Disable notifications']}
                    itemStyle={{ gap: 10 }}
                    defaultValue={false}
                    inputType={'checkbox'}
                    onChange={e => {
                      onChange(e.target.checked);
                    }}
                  />
                )}
                defaultValue={false}
              />
            </WideInputGroup>
          </InputsWrapper>
        </Container>
      </ModalBody>
      <ModalFooter>
        <FormButtonsContainer>
          <FormSubmitButton loading={loadingAdd} onClick={onSubmit}>
            Reschedule
          </FormSubmitButton>
        </FormButtonsContainer>
      </ModalFooter>
    </>
  );
};

export const BookingRescheduleModal = ({
  appointments,
  appointmentsIds,
  group = false,
  newDate,
  newDateUntil
}: {
  appointments?: BookingOrder;
  appointmentsIds?: string[];
  newDate: Date;
  newDateUntil?: Date;
  group?: boolean;
}) => {
  const formOptions = useForm();
  const { handleSubmit, control, setValue } = formOptions;

  const { data: { getBranchAppointments: branchAppointments = [] } = {}, loading: loadingAppointments } = useQuery<{ getBranchAppointments: BookingOrder }>(GetBranchAppointments, {
    variables: {
      appointment_id: appointmentsIds,
      requisite_queries: ['appointment_id'],
      alternative_queries: [],
      offset: 0,
      limit: 1000,
      status: [BOOKING_STATUS_TYPES.CONFIRMED, BOOKING_STATUS_TYPES.CANCELED, BOOKING_STATUS_TYPES.REQUESTED],
      booking_type: [BOOKING_TYPE.SLOT, BOOKING_TYPE.MULTI_SLOT, BOOKING_TYPE.MULTI_DAY]
    },
    fetchPolicy: 'cache-and-network',
    skip: !!appointments?.length
  });

  const orderAppointments = useReduceAppointments(appointments || branchAppointments, {
    uniqueByOrderIdAndTimestamp: true
  });

  const watchedAppointmentId = useWatch({
    control,
    name: 'appointmentId',
    defaultValue: orderAppointments?.[0]?.[0]?.id
  });

  const selectedOrder = orderAppointments?.find(appointment => appointment[0].id === watchedAppointmentId)!;
  const selectedAppointment = selectedOrder?.[0];

  const timestamp = newDate?.toISOString?.() || selectedAppointment?.timestamp;
  const timestampUntil = newDateUntil?.toISOString?.() || selectedAppointment?.timestamp_until;

  const selectedAppointmentDate = new Date(timestamp).toISOString().slice(0, 10);
  const selectedAppointmentTime = new Date(timestamp).toLocaleTimeString();

  const [updateTimestamp, { data: updatedTimestamp, loading: loadingUpdateTimestamp }] = useMutation(UpdateAppointmentsTimestamp);

  const watchedDate = useWatch({
    control,
    name: 'date',
    defaultValue: selectedAppointmentDate
  });

  useEffect(() => {
    if (watchedAppointmentId) {
      setValue(`date`, selectedAppointmentDate);
      setValue(`time`, selectedAppointmentTime);
    }
  }, [watchedAppointmentId]);

  const newTimestampUntil = getNewTimestampUntil({
    newTimestamp: watchedDate,
    timestamp: timestamp.split('T')[0],
    timestampUntil: timestampUntil?.split('T')?.[0]
  });

  const onSubmit = handleSubmit(form => {
    const { date, time, rescheduleAll, notifications_disabled } = form;

    const newTimestamp = new Date(date);
    newTimestamp.setUTCHours(Number(time?.split(':')[0] || 12), Number(time?.split(':')[1] || 12), 0, 0);

    const updateAppointmentsTimestamp = () =>
      updateTimestamp({
        variables: {
          id: rescheduleAll ? null : selectedOrder?.map(appointment => appointment.id),
          timestamp: newTimestamp.toISOString(),
          timestamp_until: newTimestampUntil,
          original_timestamp: rescheduleAll ? timestamp : null,
          ProductId: rescheduleAll ? selectedAppointment?.OrderItem?.item?.id : null,
          notifications_disabled
        }
      });

    if (!rescheduleAll) {
      updateAppointmentsTimestamp();
      return;
    }

    Alert.alert({
      title: 'Are you sure?',
      acceptButtonText: 'Yes, Reschedule All',
      denyButtonText: 'No, Cancel',
      description: 'Are you sure you want to reschedule this booking for all dogs?',
      onAccept: () => {
        updateAppointmentsTimestamp();
      }
    });
  });

  useEffect(() => {
    if (updatedTimestamp?.updateAppointmentsTimestamp?.length) {
      ModalDialog.closeModal();
      Notifier.success({ message: 'Booking has been rescheduled successfully' });
    }
  }, [updatedTimestamp]);

  return (
    <BookingRescheduleForm
      orderAppointments={orderAppointments}
      formOptions={formOptions}
      loadingAdd={loadingUpdateTimestamp}
      onSubmit={onSubmit}
      selectedAppointment={selectedAppointment}
      newTimestampUntil={newTimestampUntil}
      group={group}
      newDate={newDate?.toISOString?.()}
      newDateUntil={newDateUntil?.toISOString?.()}
      watchedDate={watchedDate}
    />
  );
};

export default BookingRescheduleModal;
