import React, { useState } from 'react';
import OptionDropdown from '../../../components/Shared/Menus/OptionDropdown/OptionDropdown';
import { OPTION_DROPDOWN_MENU_BUTTON_TYPES, OPTION_DROPDOWN_TYPES } from '../../../components/Shared/Menus/OptionDropdown/types';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { Divider, PetRecordRecordsOptions } from '../Health/styled';
import { ReportsHeaderContainer } from '../styled';
import Daycare from './Daycare/Daycare';
import Grooming from './Grooming/Grooming';
import Medical from './Medical/Medical';
import Sitting from './Sitting/Sitting';
import Training from './Training/Training';
import Walking from './Walking/Walking';

const Reports = () => {
  const { mobile } = useMediaQuery({ mobile: true });
  const [selectedReport, setSelectedReport] = useState<string>('Daycare');

  const optionDropdownItems = [
    {
      name: 'Daycare',
      value: 'Daycare',
      onClick: () => setSelectedReport('Daycare')
    },
    {
      name: 'Medical',
      value: 'Medical',
      onClick: () => setSelectedReport('Medical')
    },
    {
      name: 'Grooming',
      value: 'Grooming',
      onClick: () => setSelectedReport('Grooming')
    },
    {
      name: 'Training',
      value: 'Training',
      onClick: () => setSelectedReport('Training')
    },
    {
      name: 'Walking',
      value: 'Walking',
      onClick: () => setSelectedReport('Walking')
    },
    {
      name: 'Sitting',
      value: 'Sitting',
      onClick: () => setSelectedReport('Sitting')
    }
  ];

  const reports = [
    {
      name: 'Daycare',
      component: Daycare
    },
    {
      name: 'Medical',
      component: Medical
    },
    {
      name: 'Grooming',
      component: Grooming
    },
    {
      name: 'Training',
      component: Training
    },
    {
      name: 'Walking',
      component: Walking
    },
    {
      name: 'Sitting',
      component: Sitting
    }
  ];

  const SelectedComponent = reports.find(report => report.name === selectedReport)?.component || (() => null);

  const reportName = reports.find(report => report.name === selectedReport)?.name;

  const renderHeader = () => {
    return (
      <ReportsHeaderContainer>
        <PetRecordRecordsOptions padding="12px 8px" width="auto" mobilePadding="12px 16px">
          <span>
            {reportName ? reportName : 'Reports'} {mobile && 'Reports'}
          </span>
          <OptionDropdown
            menuButtonType={OPTION_DROPDOWN_MENU_BUTTON_TYPES.MORE}
            options={[
              {
                optionType: OPTION_DROPDOWN_TYPES.BUTTONS,
                id: 'options',
                items: optionDropdownItems
              }
            ]}
            noApplyButton
          />
        </PetRecordRecordsOptions>
        {!mobile && <Divider />}
      </ReportsHeaderContainer>
    );
  };

  return <SelectedComponent renderHeader={renderHeader} />;
};

export default Reports;
