import React, { useEffect } from 'react';
import InfiniteList from '../../../components/Shared/InfiniteList/InfiniteList';
import usePaginatedQuery from '../../../hooks/usePaginatedQuery';
import { GetReducedBranchDiscounts } from '../../../queries';
import { RecordsContainer } from '../../styled';
import Common from '../Common';
import DiscountRecord from './DiscountRecord';
import { Discount } from './types';
import { DISCOUNTS_FILTER_TYPES, DISCOUNTS_MORE_OPTIONS_TYPES, getDiscountsFiltersVariables, useDiscountsFilters } from './DiscountsFilters';
import { getFilterKeyOptions } from '../../../components/Shared/Filters/helpers';
import ToolBar from '../../../components/Shared/ToolBar/ToolBar';
import Filters from '../../../components/Shared/Filters/Filters';
import { BackButton } from '../../../components/DrawerBar/styled';
import { GatsbyImage } from 'gatsby-plugin-image';
import useIcons from '../../../hooks/useIcons';
import ModalDialog from '../../../components/Modal/ModalDialog';
import DiscountsModal from './DiscountsModal';

const Discounts = () => {
  const filtersControl = useDiscountsFilters();

  const { selectedFilters, headerAction } = filtersControl;

  const [[hasMoreItems, setHasMoreItems], queryResult] = usePaginatedQuery<Discount[]>({
    query: GetReducedBranchDiscounts,
    otherParams: { fetchPolicy: 'cache-and-network' },
    otherVariables: {
      ...getDiscountsFiltersVariables({
        selectedFilters
      })
    }
  });

  const { onChange } = getFilterKeyOptions({
    filtersControl,
    keys: {
      filterType: DISCOUNTS_FILTER_TYPES.DISCOUNTS_SEARCH,
      moreOption: DISCOUNTS_MORE_OPTIONS_TYPES.DISCOUNTS_SEARCH
    }
  });

  const { data: { getBranchDiscounts: discounts = [] } = {}, loading, fetchMore, refetch } = queryResult;

  useEffect(() => {
    Common.set(`Discounts.GetBranchDiscounts.refetch`, () => {
      refetch?.().then(() => setHasMoreItems(true));
    });
  }, []);

  const icons = useIcons();

  const plusBlackIcon = icons?.plusBlack?.childImageSharp?.gatsbyImageData;

  const onAdd = () => {
    ModalDialog.openModal({
      content: () => <DiscountsModal editVouchers={false} />,
      title: 'Add Discount'
    });
  };

  return (
    <>
      <ToolBar
        toolBarButtons={[]}
        urlSearchParam="status"
        middleAction={
          <>
            <BackButton onClick={onAdd}>
              <GatsbyImage image={plusBlackIcon} alt="add" />
            </BackButton>
            {headerAction}
          </>
        }
        displayString={'Discounts & Vouchers'}
        onSearchChange={onChange}
      />
      <Filters filtersControl={filtersControl} />
      <RecordsContainer>
        <InfiniteList
          paddingL={20}
          paddingR={20}
          list={discounts}
          itemRenderer={(discount: Discount) => <DiscountRecord key={discount.id} discount={discount} />}
          hasMoreItems={hasMoreItems}
          loading={loading}
          fetchMore={fetchMore}
          offset={discounts?.length}
          setHasMoreItems={setHasMoreItems}
        />
      </RecordsContainer>
    </>
  );
};

export default Discounts;
