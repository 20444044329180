import React from 'react';
import Filters from '../../../components/Shared/Filters/Filters';
import { getFilterKeyOptions } from '../../../components/Shared/Filters/helpers';
import { FiltersControl } from '../../../components/Shared/Filters/useFilters';
import ToolBar from '../../../components/Shared/ToolBar/ToolBar';
import { areObjectsEqual } from '../../../utils/helpers';
import { STORE_SERVICES_FILTER_TYPES, STORE_SERVICES_MORE_OPTIONS_TYPES } from './ServicesFilters';
import ModalDialog from '../../../components/Modal/ModalDialog';
import OptionDropdown from '../../../components/Shared/Menus/OptionDropdown/OptionDropdown';
import { OPTION_DROPDOWN_MENU_BUTTON_TYPES, OPTION_DROPDOWN_TYPES } from '../../../components/Shared/Menus/OptionDropdown/types';
import StoreProductModal from '../Operations/StoreProductModal/StoreProductModal';

const ServicesHeader = ({ filtersControl }: { filtersControl: FiltersControl<STORE_SERVICES_FILTER_TYPES, STORE_SERVICES_MORE_OPTIONS_TYPES> }) => {
  const { headerAction } = filtersControl;

  const { onChange } = getFilterKeyOptions({
    filtersControl,
    keys: {
      filterType: STORE_SERVICES_FILTER_TYPES.STORE_PRODUCTS_SEARCH,
      moreOption: STORE_SERVICES_MORE_OPTIONS_TYPES.STORE_PRODUCTS_SEARCH
    }
  });

  return (
    <>
      <ToolBar
        toolBarButtons={[]}
        urlSearchParam="status"
        middleAction={headerAction}
        onSearchChange={onChange}
        customizedToolComponent={
          <OptionDropdown
            menuButtonType={OPTION_DROPDOWN_MENU_BUTTON_TYPES.PLUS}
            noApplyButton
            options={[
              {
                id: 'add-service',
                optionType: OPTION_DROPDOWN_TYPES.BUTTONS,
                items: [
                  {
                    name: 'Add Service',
                    value: 'add-service',
                    onClick: () =>
                      ModalDialog.openModal({
                        title: 'Add Service',
                        content: () => <StoreProductModal type="service" />
                      })
                  }
                ]
              }
            ]}
          />
        }
      />
      <Filters filtersControl={filtersControl} />
    </>
  );
};

export default React.memo(ServicesHeader, areObjectsEqual);
