import React from 'react';
import useIcons from '../../hooks/useIcons';
import { AddImageButton } from './styled';
import { GatsbyImage } from 'gatsby-plugin-image';

export const AddPhotoByLink = props => {
  const icons = useIcons();
  return (
    <AddImageButton onClick={() => fileInputRef?.current?.click()} {...props}>
      <GatsbyImage image={icons.link.childImageSharp.gatsbyImageData} alt="Add Photo" />
    </AddImageButton>
  );
};
