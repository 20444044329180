import styled from 'styled-components';

export const FormFillTitle = styled.span`
  font-weight: bold;
  font-size: 20px;
`;

export const FormQuestionTitle = styled.span`
  font-weight: bold;
  font-size: 18px;
`;

export const FormPetName = styled.span`
  font-weight: 700;
  font-size: 18px;
`;

export const FormQustionAnswer = styled.span`
  font-size: 16px;
`;

export const FormQuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const FormInfoFillContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const FormFillContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const PetFormAnswers = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const FormQuestionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const ReportsHeaderContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 8px;
`;
