import React from 'react';
import Filters from '../../../components/Shared/Filters/Filters';
import { getFilterKeyOptions } from '../../../components/Shared/Filters/helpers';
import { FiltersControl } from '../../../components/Shared/Filters/useFilters';
import ToolBar from '../../../components/Shared/ToolBar/ToolBar';
import { areObjectsEqual } from '../../../utils/helpers';
import { STORE_PRODUCTS_FILTER_TYPES, STORE_PRODUCTS_MORE_OPTIONS_TYPES } from './ProductsFilters';

const ProductsHeader = ({ filtersControl }: { filtersControl: FiltersControl<STORE_PRODUCTS_FILTER_TYPES, STORE_PRODUCTS_MORE_OPTIONS_TYPES> }) => {
  const { headerAction } = filtersControl;

  const { onChange } = getFilterKeyOptions({
    filtersControl,
    keys: {
      filterType: STORE_PRODUCTS_FILTER_TYPES.STORE_PRODUCTS_SEARCH,
      moreOption: STORE_PRODUCTS_MORE_OPTIONS_TYPES.STORE_PRODUCTS_SEARCH
    }
  });

  return (
    <>
      <ToolBar toolBarButtons={[]} urlSearchParam="status" middleAction={headerAction} onSearchChange={onChange} />
      <Filters filtersControl={filtersControl} />
    </>
  );
};

export default React.memo(ProductsHeader, areObjectsEqual);
