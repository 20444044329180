import styled, { css } from 'styled-components';
import Colors from '../../../Colors';

export const AddNewBtn = styled.button<{ noSpaceArround?: boolean; disabled?: boolean; center?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: ${({ center }) => (center ? 'center' : 'flex-start')};
  border: none;
  padding: 8px 0;
  font-size: 14px;
  font-weight: 500;
  background: transparent;
  color: ${Colors.black};
  margin-bottom: 15px;
  &:hover {
    cursor: pointer;
    filter: grayscale(0.5);
  }
  &:focus {
    outline: none;
  }

  ${props =>
    props.disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `}

  ${props =>
    props.noSpaceArround &&
    css`
      margin: 0;
      padding: 0;
    `}
`;

export const AddNewBtnIcon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 6px;
`;

export const PharmaItemsListContainer = styled.div`
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100px;
  width: 100%;
  background-color: #f2f2f2;
  border-radius: 4px;
  overflow: hidden;
  margin-bottom: 24px;
`;

export const PharmaItemContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 6px;
  box-sizing: border-box;
  padding: 0 18px;
  padding-bottom: 18px;
`;

export const PharmaItemCost = styled.span`
  font-size: 12px;
  font-weight: 500;
  color: #7b7b7b;
`;

export const PharmaItemCheckboxContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 12px;
`;

export const PharmaItemCostContainer = styled.div`
  display: flex;
  gap: 12px;
`;

export const PharmaItemDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const PharmaItemMarginBoxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

export const LinkedProductContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 12px 8px;
  justify-content: center;
  gap: 6px;
  border-radius: 4px;
  width: 100%;
  background-color: #f0f0f0;
  margin-bottom: 8px;
  user-select: none;
`;
