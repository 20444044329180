import { useReactiveVar } from '@apollo/client';
import React, { useEffect, useImperativeHandle, useRef } from 'react';
import { Icon } from '../components/Shared/Forms/Forms';
import { Checkbox, CheckboxItemContainer } from '../components/Shared/Forms/styled';
import { vars } from '../reactive';
import { manageBookingsFilters } from '../reactive/actions';
import BookingSlideDownActions, { BookingSlideDownActionsRef } from '../views/Bookings/BookingSlideDownActions';
import { BookingsBulkActionsRef } from '../views/Bookings/BookingsList_OLD';
import BookingDrawerButtons from '../views/Bookings/components/BookingsDrawer/BookingDrawerButtons';
import useBulkSelect from './useBulkSelect';
import useReduceAppointments from './useReduceAppointments';

const useBookingsListBulkActions = ({
  bookingsToRender,
  ref,
  inDrawer,
  refetch
}: {
  bookingsToRender: ReturnType<typeof useReduceAppointments>;
  ref: React.ForwardedRef<BookingsBulkActionsRef>;
  inDrawer?: boolean;
  refetch?: () => Promise<any>;
}) => {
  const { addHeaderAction, clearHeaderAction } = manageBookingsFilters;
  const items = bookingsToRender.map(item => ({ id: item[0].id }));
  const bulkSelect = useBulkSelect({ items });
  const { selected, show: showBulkActions, clearSelected, hide, selectedAll, toggleSelectAllItems } = bulkSelect;
  const bulkActionSelectedBookingOrders = bookingsToRender
    .filter(bookingOrder => selected.includes(bookingOrder[0].id))
    .map(bookingOrder => bookingOrder.filter(booking => selected.includes(booking.id)));

  const drawerBars = useReactiveVar(vars.drawerBars);
  const bookingSlideDownActionsRef = useRef<BookingSlideDownActionsRef>(null);

  const handleOnClickAction = bookingSlideDownActionsRef.current?.handleOnClickAction;

  const selectAction = (
    <BookingSlideDownActions bulkSelect={bulkSelect} bulkActionSelectedBookingOrders={bulkActionSelectedBookingOrders} ref={bookingSlideDownActionsRef}>
      <BookingDrawerButtons
        allGroupOrders={bulkActionSelectedBookingOrders}
        appointments={bulkActionSelectedBookingOrders.flat()}
        group
        refetch={refetch}
        containerStyle={{ width: 'auto' }}
        groupOptionsToShow={{
          shouldDisplayGroupAssign: true,
          shouldDisplayGroupCancel: true,
          shouldDisplayGroupConfirmationAndRejection: true,
          shouldDisplayGroupTagsOptions: true,
          shouldDisplayGroupUpdateOrderBills: true
        }}
        onClickAction={handleOnClickAction}
      />
    </BookingSlideDownActions>
  );

  const selectAllAction = (
    <CheckboxItemContainer checked={selectedAll} noMargin onClick={toggleSelectAllItems} noWidth>
      <Checkbox>
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </Checkbox>
      <span style={{ fontWeight: 700 }}>{selectedAll ? 'Deselect All' : 'Select All'}</span>
    </CheckboxItemContainer>
  );

  useEffect(() => {
    if (drawerBars.length && !inDrawer) {
      clearHeaderAction({ id: 'bulkActions' });
      clearHeaderAction({ id: 'bulkSelectAll' });
    }

    if (!drawerBars.length || (drawerBars.length && inDrawer)) {
      addHeaderAction({ action: selectAction, id: 'bulkActions' });
      if (showBulkActions) {
        addHeaderAction({ action: selectAllAction, id: 'bulkSelectAll' });
      }
    }

    return () => {
      clearHeaderAction({ id: 'bulkActions' });
      clearHeaderAction({ id: 'bulkSelectAll' });
    };
  }, [drawerBars, showBulkActions, inDrawer, selected, selectedAll, showBulkActions]);

  useImperativeHandle(ref, () => ({
    clearBulkActions: () => {
      clearSelected();
    },
    hideBulkActions: () => {
      hide();
    }
  }));

  return { bulkSelect, bulkActionSelectedBookingOrders };
};

export default useBookingsListBulkActions;
