import { useReactiveVar } from '@apollo/client';
import React, { Fragment, useRef } from 'react';
import { BusUserProfile } from '../../../../components/Profile/types';
import InfiniteList from '../../../../components/Shared/InfiniteList/InfiniteList';
import { UsePagintaedQueryResult } from '../../../../hooks/usePaginatedQuery';
import { vars } from '../../../../reactive';
import { BranchAppointmentTag, BranchTag } from '../../../Store/BranchTags/types';
import { Product } from '../../../Store/types';

import { BookingsBulkActionsRef } from '../../BookingsList_OLD';
import { BOOKING_TABS_VALUES_TYPES, Booking } from '../../types';
import BookingRecord from '../BookingRecord/BookingRecord';
import { BodyContainer, ServiceContainer } from '../BookingsBody/styled';
import ServiceHeader from '../ServiceHeader/ServiceHeader';
import useURLSearchParams from '../../../../hooks/useURLSearchParams';
import useMediaQuery from '../../../../hooks/useMediaQuery';

export type BookingsListAppointmentsList = {
  Appointments: Booking[];
  Product: Product;
  BusUser: BusUserProfile;
}[];

type BookingsListProps = {
  appointmentsList: BookingsListAppointmentsList;
  allQuickTags?: BranchAppointmentTag[];
  tagCategories?: BranchTag['BranchTagCategories'];
  refetchAppointments: () => void;
  loadingAppointments: boolean;
  selectedTab: { value: string; recordOptions: Record<string, any> };
  itemsToDisplay?: { name: string; id: string }[];
  paginatedQueryResult?: UsePagintaedQueryResult<Booking[]>;
};
const BookingsList = ({
  appointmentsList,
  refetchAppointments,
  loadingAppointments,
  selectedTab,
  itemsToDisplay,
  allQuickTags,
  tagCategories,
  paginatedQueryResult,
  group,
  filtersControl
}: BookingsListProps) => {
  const activeViewSettings = useReactiveVar(vars.activeViewSettings);
  const isMultiStaffView = useReactiveVar(vars.isMultiStaffView);
  const isConfirmed = selectedTab?.value === BOOKING_TABS_VALUES_TYPES.CONFIRMED;
  const isNew = selectedTab?.value === BOOKING_TABS_VALUES_TYPES.NEW;
  const isUpcoming = selectedTab?.value === BOOKING_TABS_VALUES_TYPES.UPCOMING;
  const isCanceled = selectedTab?.value === BOOKING_TABS_VALUES_TYPES.CANCELED;
  const isPayment = selectedTab?.value === BOOKING_TABS_VALUES_TYPES.PAYMENT;

  const options = selectedTab?.recordOptions;
  const currentTab = selectedTab?.value;

  const bookingsListRef = useRef<BookingsBulkActionsRef>(null);

  const [[hasMoreItems, setHasMoreItems], { fetchMore }] = paginatedQueryResult || [[], {}];

  const quickTags = allQuickTags?.filter(tag => tag?.quick_action) || [];
  const tagsVisibleOnItem = allQuickTags?.filter(tag => tag?.visibility?.showOnTaggedItem) || [];

  const upcomingAppointments = appointmentsList?.[0]?.Appointments.filter(appointment => new Date() <= new Date(appointment?.timestamp)) || [];
  const { mobile } = useMediaQuery({ mobile: true });

  // const { bulkSelect } = useBookingsListBulkActions({
  //   bookingsToRender,
  //   bookingsListRef,
  //   refetchAppointments
  // });

  const listToDisplay = (list: Booking[]) => {
    const padding = isConfirmed ? 0 : 20;
    return (
      <InfiniteList
        paddingL={padding}
        paddingR={padding}
        mobilePadding="0 16px"
        hasMoreItems={hasMoreItems || false}
        fetchMore={fetchMore || (() => null)}
        list={list}
        loading={loadingAppointments}
        offset={list?.length}
        setHasMoreItems={setHasMoreItems || (() => null)}
        itemRenderer={appointment => <BookingRecord appointment={appointment} refetch={refetchAppointments} recordOptions={options} selectedTab={currentTab} />}
        group={isUpcoming && group(list)}
      />

      // <InfiniteList
      //   paddingL={padding}
      //   paddingR={padding}
      //   fetchMore={fetchMore || (() => null)}
      //   list={list}
      //   loading={loadingAppointments}
      //   // offset={list?.length}
      //   hasMoreItems={hasMoreItems || (() => null)}
      //   setHasMoreItems={setHasMoreItems || (() => null)}
      //   itemRenderer={appointment => <BookingRecord
      //     key={appointment.id}
      //     booking={[appointment]}
      //     showTotal={false}
      //     quickAppointmentsTags={quickTags}
      //     // bulkSelect={bulkSelect}
      //     tagsVisibleOnItem={tagsVisibleOnItem}
      //     recordActiveViewSettings={activeViewSettings?.record}
      //     selectedTab={currentTab}
      //     recordOptionsToDisplay={options}
      //     refetchAppointments={refetchAppointments}
      //   />}

      // group={group?.(appointmentsList)}
      // />
    );
  };

  return (
    <Fragment key={selectedTab?.value}>
      {(isNew || isCanceled || isPayment || (isConfirmed && mobile)) && listToDisplay(appointmentsList?.[0]?.Appointments)}

      {isUpcoming && listToDisplay(upcomingAppointments)}

      {isConfirmed && !isMultiStaffView && !mobile && (
        <BodyContainer>
          {itemsToDisplay?.map((product, index) => {
            const dailyAppointments = appointmentsList.find(productAppointments => productAppointments.Product.id === product.id)?.Appointments || [];

            return (
              <>
                <ServiceContainer key={index}>
                  <ServiceHeader product={product} appointments={dailyAppointments} filtersControl={filtersControl} />

                  {listToDisplay(dailyAppointments)}
                </ServiceContainer>
              </>
            );
          })}
        </BodyContainer>
      )}

      {isConfirmed && isMultiStaffView && (
        <BodyContainer>
          {itemsToDisplay?.map((item, index) => {
            const userAppointments = appointmentsList.find(productAppointments => productAppointments?.BusUser.id === item.id)?.Appointments || [];

            return (
              <>
                <ServiceContainer key={index} minWidth={600}>
                  <ServiceHeader product={item} appointments={userAppointments} />
                  {listToDisplay(userAppointments)}
                </ServiceContainer>
              </>
            );
          })}
        </BodyContainer>
      )}

      {/* {isMultiDay && (
        <BodyContainer>
        <>
          {itemsToDisplay?.map((item, index) => {
            const upcomingAppointments = appointmentsList?.[0]?.Appointments.filter(appointment => new Date() <= new Date(appointment?.timestamp)) || [];

            const activeAppointments = appointmentsList?.[0]?.Appointments.filter(appointment => new Date() > new Date(appointment?.timestamp)) || [];


            const appointemts = item.id === 'UPCOMING' ? upcomingAppointments : activeAppointments;
            return (
              <ServiceContainer key={index}>
                <ServiceHeader product={item} appointments={appointemts} />
                <TabsHeaderContainer padding="12px 0">
                  <BookingTagsQuickFilter filtersControl={filtersControl} />
                </TabsHeaderContainer>
                {listToDisplay(appointemts)}
              </ServiceContainer>
            );
          })}
        </>
        // </BodyContainer>
      )} */}
    </Fragment>
  );
};

export default BookingsList;
