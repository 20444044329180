import React, { Fragment, useRef } from 'react';
import { Divider } from '../../../components/DrawerBar/styled';
import { DRAWER_IDS } from '../../../components/DrawerBar/types';
import { PetRecordOptionsContainer } from '../../../components/Pets/styled';
import { VerticalDivider } from '../../../components/Shared/ToolBar/styles';
import useIcons from '../../../hooks/useIcons';
import useMediaQuery from '../../../hooks/useMediaQuery';
import useNavigateDrawer from '../../../hooks/useNavigateDrawer';
import { PetAndInfo, PetContainer, PetImg } from '../../Bookings/components/BookingRecord/styled';
import { Booking, PetRecordTag, PetRecordTags, PetRecordTagsContainer } from '../../Bookings/components/BookingsBody/styled';
import { AppUserImage, AppUserImageContainer, AppUserImageNameContainer, BlankAppUserImage, RecordBody } from '../../styled';
import AppUserRecordOptions from '../BranchAppUsers/AppUserRecordOptions';

const ClientRecord = ({ branchAppUsers, quickTags }) => {
  const { mobile } = useMediaQuery({ mobile: true });
  const icons = useIcons();
  // const moreIcon = icons.moreMenu.childImageSharp.gatsbyImageData;
  const optionsRef = useRef<HTMLDivElement>(null);

  const { id: userId, name: userName, profile_pic, postcode, BranchAppUserTags, email, phone_numbers } = branchAppUsers;
  const countryCode = phone_numbers?.[0]?.country_code;
  const number = phone_numbers?.[0]?.number;
  const phoneNumber = countryCode !== undefined && number !== undefined ? `${countryCode} ${number}` : '';

  const navigateDrawer = useNavigateDrawer({
    drawerData: { drawerId: DRAWER_IDS.USER_DRAWER }
  });

  const handleClick = (e: React.MouseEvent) => {
    if (optionsRef?.current?.contains(e.target as Node)) {
      return;
    }
    navigateDrawer({ drawerLinkId: branchAppUsers?.id });
  };

  const recordOptions = (
    <PetRecordOptionsContainer>
      <AppUserRecordOptions ref={optionsRef} showAlways={mobile} loadTags={false} appUsers={[branchAppUsers]} appUserQuickTags={quickTags} />
    </PetRecordOptionsContainer>
  );

  return (
    <Fragment key={userId}>
      <Booking onClick={handleClick} mobilePadding="14px 10px" mobileMinHeight="0" gap={20}>
        {!mobile && (
          <>
            <AppUserImageNameContainer>
              <AppUserImageContainer paddingLeft="0">
                {profile_pic && <AppUserImage key={userId} src={profile_pic} />}
                {!profile_pic && <BlankAppUserImage alt="user" image={icons.user.childImageSharp.gatsbyImageData} />}
              </AppUserImageContainer>
              <RecordBody width="200" noMargin fontWeight="800" marginLeft="60">
                {userName}
              </RecordBody>
            </AppUserImageNameContainer>

            <RecordBody maxWidth={200} width="200" flex="1" minWidth={150} fontWeight="700">
              {email}
            </RecordBody>
            <RecordBody maxWidth={200} width="200" flex="1" minWidth={150} fontWeight="700">
              {phoneNumber}
            </RecordBody>
            <VerticalDivider displayNone />
            <PetRecordTagsContainer>
              <PetRecordTags>
                {BranchAppUserTags?.map(({ name, id, color }) => (
                  <PetRecordTag color={color} key={id}>
                    {name}
                  </PetRecordTag>
                ))}
              </PetRecordTags>
            </PetRecordTagsContainer>
            {recordOptions}
          </>
        )}
        {/* <PetRecordOptionsContainer>
          <BookingRecordOptions appointments={[appointment]} loadTags={false} refetch={refetch} />
        </PetRecordOptionsContainer> */}

        {mobile && (
          <PetContainer>
            <PetImg src={profile_pic || icons.user.childImageSharp.gatsbyImageData.images.fallback.src} alt={userName} />
            <PetAndInfo mobileFlexDirection="column">
              <RecordBody mobileFlexDirection="column" mobileWidth="100%">
                <RecordBody fontWeight="800" color="#212121" mobileWidth="100%">
                  {userName}
                </RecordBody>
              </RecordBody>
              <RecordBody mobileWidth="100%">{email}</RecordBody>
            </PetAndInfo>
            {recordOptions}
          </PetContainer>
        )}
      </Booking>
      {!mobile && <Divider />}
    </Fragment>
  );
};

export default ClientRecord;
