import { OPTION_DROPDOWN_TYPES } from '../Menus/OptionDropdown/types';
import { SelectedFilter } from './config';
import { FILTER_TYPES, MORE_OPTIONS_TYPES } from './Filters';
import { FiltersControl } from './useFilters';

export const getFilterKeyOptions = <T extends FILTER_TYPES, U extends MORE_OPTIONS_TYPES>({
  filtersControl,
  keys,
  transformValues
}: {
  filtersControl: FiltersControl<T, U>;
  keys: { filterType: T; moreOption: U };
  transformValues?: (values: any) => any;
}) => {
  const { setSelectedFilters, filterItems } = filtersControl;
  const { filterType, moreOption } = keys;
  const filter = filterItems.find(({ value }) => value === filterType)!;
  const moreOptions = filter.moreOptions.find(({ type }) => type === moreOption)!;

  const onChange = (query: any) => {
    if (query) {
      setSelectedFilters(prev => [
        ...prev?.filter(({ value }) => value !== filterType),
        {
          ...filter,
          moreOptions: [{ ...moreOptions, values: transformValues?.(query) || query }],
          requisite: 'true'
        }
      ]);
      return;
    }
    setSelectedFilters(prev => prev.filter(({ value }) => value !== filterType));
  };

  return { onChange, filter, moreOptions };
};

export const getFilter = (selectedFilters: SelectedFilter[], filterType: FILTER_TYPES) => {
  return selectedFilters.find(filter => filter.value === filterType);
};

export const getMoreOptionsValues = (filter: SelectedFilter | undefined, moreOptionsType: MORE_OPTIONS_TYPES, options: { name?: boolean } = { name: false }) => {
  if (!filter) return;

  const { name } = options;

  const moreOption = filter?.moreOptions?.find(option => option.type === moreOptionsType);

  if (name) {
    return (moreOption?.values as { name: string; value: string }[])?.map(({ name }) => name);
  }

  if (moreOption?.optionType === OPTION_DROPDOWN_TYPES.MULTI_SELECT) {
    return (moreOption?.values as { name: string; value: string }[])?.map(({ value }) => value);
  }

  return moreOption?.values as string;
};
