import React, { FC } from 'react';
import { Controller, useFieldArray } from 'react-hook-form';
import { FormError, FormInput, InputContainer, RemoveRowContainer, FormLabel, WideInputGroup } from '../../../components/Shared/Forms/Forms';
import { RemoveAddedImage } from '../styled';
import useIcons from '../../../hooks/useIcons';
import { CustomizationRules, CustomizationsFormProps, TransformedCustomization } from './types';
import CustomizationFormOptions from './CustomizationFormOptions';
import { CenteredCustomizationInputContainer, CustomizationTypeContainer, CustomizationsFormContainer, CustomizationsFormWrapper } from './styled';
import { AddNewBtn, AddNewBtnIcon } from '../Products/styled';
import { Divider } from '../../../components/DrawerBar/styled';

const CustomizationsForm: FC<CustomizationsFormProps> = ({ formOptions, customization, index, handleRemoveRow, watchedCustomizations, busUsers, hideRules, defaultValue }) => {
  const { control, errors } = formOptions;
  const icons = useIcons();
  const inputError = errors?.customizations?.find((_item, itemIndex) => itemIndex === index)?.name;
  const optionsFieldArray = useFieldArray<TransformedCustomization['options'][0]>({
    control,
    name: `customizations[${index}].options`
  });

  const handleAddOptionRow = () => {
    const options = watchedCustomizations?.[index]?.options;
    if (options?.length && !options?.[options?.length - 1]?.title) {
      return;
    }
    optionsFieldArray.append({
      title: '',
      price: 0,
      meta: {
        type: 'none',
        value: ''
      },
      rules: [{ type: CustomizationRules.NONE, value: '' }]
    });
  };

  const handleRemoveOptionRow = (index: number) => {
    optionsFieldArray.remove(index);
  };

  const watchedCustomization = watchedCustomizations[index];

  return (
    <CustomizationsFormContainer>
      <CenteredCustomizationInputContainer>
        <FormLabel>Variation title</FormLabel>
        <CustomizationTypeContainer>
          <WideInputGroup>
            <Controller
              as={<FormInput type={'text'} error={!!inputError} />}
              control={control}
              name={`customizations[${index}].name`}
              defaultValue={customization.name || ''}
              rules={{
                required: true,
                validate: (value: string) => {
                  return value.length > 0 && !value.includes('.');
                }
              }}
            />
          </WideInputGroup>
          <RemoveRowContainer noMargin>
            <RemoveAddedImage src={icons.delete.childImageSharp.gatsbyImageData.images.fallback.src} onClick={() => handleRemoveRow(index)} noMargin />
          </RemoveRowContainer>
        </CustomizationTypeContainer>

        {!!inputError && <FormError>{inputError?.message || 'A valid name is required'}</FormError>}
      </CenteredCustomizationInputContainer>

      <InputContainer>
        {optionsFieldArray.fields.map((item, optionIndex) => (
          <CustomizationsFormWrapper key={item.id}>
            <CustomizationFormOptions
              formOptions={formOptions}
              index={optionIndex}
              option={item}
              handleRemoveRow={handleRemoveOptionRow}
              watchedCustomization={watchedCustomization}
              customizationIndex={index}
              busUsers={busUsers}
              hideRules={hideRules}
              defaultValue={defaultValue?.options[optionIndex]}
            />
            <Divider />
          </CustomizationsFormWrapper>
        ))}
      </InputContainer>

      <AddNewBtn onClick={handleAddOptionRow} noSpaceArround>
        <AddNewBtnIcon src={icons.addPhoto.childImageSharp.gatsbyImageData.images.fallback.src} />
        Add New Option
      </AddNewBtn>
    </CustomizationsFormContainer>
  );
};

export default CustomizationsForm;
