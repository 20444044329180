import React from 'react';
import Colors from '../../Colors';
import { DrawerSubLabel } from '../DrawerBar/styled';

type DeliveryAddressProps = { address: { [key: string]: string }; title: string };
export default function DeliveryAddress({ address, title }: DeliveryAddressProps) {
  return (
    <>
      <DrawerSubLabel weight={600} size={12} marginBottom={8}>
        {title || 'Shipping Address'}
      </DrawerSubLabel>
      {address?.name && (
        <DrawerSubLabel marginBottom={0} weight={800} size={14} color={Colors.black}>
          {address?.name}
        </DrawerSubLabel>
      )}
      {address?.email && (
        <DrawerSubLabel marginBottom={0} weight={800} size={14} color={Colors.black}>
          {address?.email}
        </DrawerSubLabel>
      )}
      {address?.line1 && (
        <DrawerSubLabel marginBottom={0} weight={800} size={14} color={Colors.black}>
          {address?.line1}
        </DrawerSubLabel>
      )}
      {address?.line2 && (
        <DrawerSubLabel marginBottom={0} weight={800} size={14} color={Colors.black}>
          {address?.line2}
        </DrawerSubLabel>
      )}
      {address?.city && (
        <DrawerSubLabel marginBottom={0} weight={800} size={14} color={Colors.black}>
          {address?.city}
        </DrawerSubLabel>
      )}
      {address?.postcode && (
        <DrawerSubLabel marginBottom={0} weight={800} size={14} color={Colors.black}>
          {address?.postcode}
        </DrawerSubLabel>
      )}
      {address?.country && (
        <DrawerSubLabel marginBottom={0} weight={800} size={14} color={Colors.black}>
          {address?.country}
        </DrawerSubLabel>
      )}
    </>
  );
}
