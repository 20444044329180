import React, { useEffect } from 'react';
import useIcons from '../../../hooks/useIcons';
import { OPTION_DROPDOWN_TYPES } from '../Menus/OptionDropdown/types';
import SelectedFilter from './SelectedFilter';
import { FILTERS_ID, FILTER_TYPES, HIDDEN_FILTERS, MORE_OPTIONS_TYPES, SelectedFilter as SelectedFilterType } from './config';
import { CloseButton, FiltersContainer, HorizontalSeparator, RequisiteLabel, SelectedFilters, SelectedFiltersContainer, SelectedFiltersWrapper } from './styled';
import { FiltersControl } from './useFilters';

export type FILTER_TYPES = keyof typeof FILTER_TYPES;
export type MORE_OPTIONS_TYPES = keyof typeof MORE_OPTIONS_TYPES;
export type HIDDEN_FILTERS = keyof typeof HIDDEN_FILTERS;

export type SelectedMoreOptions = {
  filterType: FILTER_TYPES;
  optionsValues: { optionValue: MORE_OPTIONS_TYPES | string; filterType: FILTER_TYPES; values: string | { name: string; value?: string }[] }[];
}[];

export type FilterItem = {
  name: string;
  value: FILTER_TYPES;
  moreOptions: {
    type: MORE_OPTIONS_TYPES;
    filterType: FILTER_TYPES;
    id: string;
    title: string;
    optionType: OPTION_DROPDOWN_TYPES;
    items:
      | {
          value: string | boolean | number;
          name: string;
        }[]
      | [];
  }[];
};

type FiltersProps<T extends FILTER_TYPES, U extends MORE_OPTIONS_TYPES> = {
  filtersControl: FiltersControl<T, U>;
};

const Filters = <T extends FILTER_TYPES, U extends MORE_OPTIONS_TYPES>({ filtersControl }: FiltersProps<T, U>) => {
  const { setSelectedFilters, selectedFiltersForm, REQUISITE_ID, selectedFiltersItems, selectedMoreOptionsState, selectedFiltersTypes, filterItems } = filtersControl;
  const { formState } = selectedFiltersForm;

  const { reset: resetSelectedFilters } = selectedFiltersForm;

  const [selectedMoreOptions, setSelectedMoreOptions] = selectedMoreOptionsState;

  const icons = useIcons();
  const closeIcon = icons?.closeModal?.childImageSharp.gatsbyImageData.images.fallback.src;

  const selectedFiltersWithMoreOptions = selectedFiltersItems
    .map(filter => ({
      ...filter,
      moreOptions: filter.moreOptions?.map(moreOption => ({
        ...moreOption,
        values: selectedMoreOptions.find(({ filterType }) => filterType === filter.value)?.optionsValues.find(({ optionValue }) => optionValue === moreOption.type)?.values!
      }))
    }))
    .map(filter => ({
      ...filter,
      moreOptions: filter.moreOptions?.filter(moreOption => moreOption.values)
    }))
    .filter(({ moreOptions }) => moreOptions?.length);

  useEffect(() => {
    if (selectedFiltersItems?.length) {
      setSelectedMoreOptions(selectedMoreOptions.filter(({ filterType }) => selectedFiltersTypes.includes(filterType)));
    }
  }, [selectedFiltersTypes]);

  useEffect(() => {
    if (formState.isSubmitting || !selectedFiltersWithMoreOptions?.length) {
      setSelectedFilters(selectedFiltersWithMoreOptions as SelectedFilterType[]);
    }
  }, [JSON.stringify(selectedFiltersWithMoreOptions)]);

  const onReset = () => {
    setSelectedFilters([]);
    setSelectedMoreOptions(filterItems.map(({ value }) => ({ filterType: value, optionsValues: [{ optionValue: REQUISITE_ID, filterType: value, values: 'true' }] })));
    resetSelectedFilters({ [FILTERS_ID]: [] });
  };

  const requisiteFilters = selectedFiltersItems.filter(selectedFilter => selectedFilter.requisite === 'true');

  const alternativeFilters = selectedFiltersItems.filter(selectedFilter => selectedFilter.requisite === 'false');

  return (
    <>
      {!!selectedFiltersItems?.length && (
        <SelectedFiltersContainer>
          <SelectedFiltersWrapper>
            {!!requisiteFilters?.length && (
              <SelectedFilters>
                <RequisiteLabel>Requisite</RequisiteLabel>
                <FiltersContainer>
                  {requisiteFilters.map(filter => (
                    <SelectedFilter
                      key={filter.value}
                      filter={filter}
                      REQUISITE_ID={REQUISITE_ID}
                      selectedFiltersForm={selectedFiltersForm}
                      selectedFiltersWithMoreOptions={selectedFiltersWithMoreOptions}
                      selectedMoreOptionsState={selectedMoreOptionsState}
                      setSelectedFilters={setSelectedFilters}
                    />
                  ))}
                </FiltersContainer>
              </SelectedFilters>
            )}
            {!!requisiteFilters?.length && !!alternativeFilters?.length && <HorizontalSeparator dashed />}
            {!!alternativeFilters?.length && (
              <SelectedFilters>
                <RequisiteLabel>Alternative</RequisiteLabel>
                <FiltersContainer>
                  {alternativeFilters.map(filter => (
                    <SelectedFilter
                      key={filter.value}
                      filter={filter}
                      REQUISITE_ID={REQUISITE_ID}
                      selectedFiltersForm={selectedFiltersForm}
                      selectedFiltersWithMoreOptions={selectedFiltersWithMoreOptions}
                      selectedMoreOptionsState={selectedMoreOptionsState}
                      setSelectedFilters={setSelectedFilters}
                    />
                  ))}
                </FiltersContainer>
              </SelectedFilters>
            )}
            <CloseButton bgImg={closeIcon} onClick={onReset} />
          </SelectedFiltersWrapper>
        </SelectedFiltersContainer>
      )}
    </>
  );
};

export default Filters;
