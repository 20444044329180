import React, { useEffect } from 'react';
import InfiniteList from '../../../components/Shared/InfiniteList/InfiniteList';
import usePaginatedQuery from '../../../hooks/usePaginatedQuery';
import { GetBranchForms } from '../../../queries';
import Common from '../Common';
import BranchFormRecord from './BranchFormRecord';
import { BranchForm } from './types';

const BranchForms = () => {
  const [[hasMoreItems, setHasMoreItems], queryResult] = usePaginatedQuery<BranchForm[]>({ query: GetBranchForms });

  const { data: { formGet: branchForms = [] } = {}, loading, fetchMore, refetch } = queryResult;

  useEffect(() => {
    Common.set(`BranchForms.GetBranchForms.refetch`, () => {
      refetch?.().then(() => setHasMoreItems(true));
    });
  }, []);

  return (
    <InfiniteList
      paddingL={20}
      paddingR={20}
      list={branchForms}
      itemRenderer={(form: BranchForm) => <BranchFormRecord key={form.id} branchForm={form} />}
      hasMoreItems={hasMoreItems}
      loading={loading}
      fetchMore={fetchMore}
      offset={branchForms?.length}
      setHasMoreItems={setHasMoreItems}
    />
  );
};

export default BranchForms;
