import { useMutation, useQuery } from '@apollo/client';
import React, { FC, Fragment, useCallback, useEffect, useRef } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import CreatableSelect from 'react-select/creatable';
import { sentenceCase } from 'sentence-case';
import { DRAWER_IDS } from '../../../../components/DrawerBar/types';
import ModalDialog from '../../../../components/Modal/ModalDialog';
import { ModalBody, ModalFooter } from '../../../../components/Modal/styled';
import { BusUserProfile } from '../../../../components/Profile/types';
import {
  FormButtonsContainer,
  FormError,
  FormHeaderLabel,
  FormInput,
  FormLabel,
  FormSelect,
  FormSubmitButton,
  InputsWrapper,
  SectionLabel,
  WideInputGroup,
  selectTheme
} from '../../../../components/Shared/Forms/Forms';
import { Container } from '../../../../components/Shared/Shared';
import { ActivityIndicator } from '../../../../components/Shared/Spinner';
import useIcons from '../../../../hooks/useIcons';
import usePhotoUpload, { useDeletePhotos } from '../../../../hooks/usePhotoUpload';
import useReduceAppointments from '../../../../hooks/useReduceAppointments';
import { GetBranchAppointments, GetBranchPetProfile, NoteAdd, NoteEdit, TrainingReportAdd, TrainingReportEdit } from '../../../../queries';
import { vars } from '../../../../reactive';
import { toReadableDate } from '../../../../utils/dates';
import { BOOKING_STATUS_TYPES, BOOKING_TYPE } from '../../../Bookings/types';
import { ActionBtn } from '../../../Store/styled';
import NotePhotos from '../BinaryUpload';
import NotesMarkupEditor from '../NotesMarkupEditor';
import BinaryUpload from '../BinaryUpload';

export enum NOTE_ACTION_TYPES {
  ADD = 'ADD',
  EDIT = 'EDIT'
}

type TypeOptions = ('behavioral' | 'cooperative_care' | 'management' | 'training_essentials')[];

const typeOptions: TypeOptions = ['behavioral', 'cooperative_care', 'training_essentials'];

const gradeOptions = ['none', 'minimal', 'mild', 'moderate', 'severe', 'extreme'];

const recommendationOptions = [
  'home_training',
  'follow_up_session',
  'vet_check_up',
  'talk_with_vets_about_meds_or_supplements',
  'dog_sports',
  'increase_physical_exercise',
  'decrease_physical_exercise',
  'mental_stimulation'
];

const subCategories = {
  behavioral: [
    'hyperactivity',
    'stealing_things',
    'rough_play',
    'constant_biting',
    'aggression_with_pets',
    'aggression_with_people',
    'aggression_with_dogs',
    'high_drive_or_chasing_things',
    'inappropriate_elimination',
    'jumping_up_on_people',
    'separation_anxiety',
    'excessive_barking',
    'begging_for_food',
    'jumping_on_furniture',
    'mouthing',
    'digging',
    'destructive_chewing',
    'excessive_licking',
    'fear_of_noises',
    'disobedience',
    'overprotective_of_family',
    'overprotective_of_kids',
    'overprotective_of_food',
    'leash_pulling',
    'whining_for_attention',
    'humping',
    'escaping',
    'eating_poop',
    'dog_to_dog_issues',
    'dog_to_human_issues',
    'recall',
    'lead_walking',
    'socialisation',
    'noise_sensitivity_or_phobia',
    'puppy_issues',
    'barking',
    'biting_or_chewing',
    'resource_guarding',
    'hypersocial',
    'repetitive_behaviors',
    'jumping_on_furniture',
    'potty_training',
    'food_manners',
    'vet_training',
    'grooming_or_handling',
    'fear_of_objects_or_novelty',
    'settling'
  ],
  cooperative_care: [],
  management: [],
  training_essentials: []
};

type SubCategoryValues = {
  grade: string;
  description: string;
  recommendation: string[];
  practical_exercises: string;
};

type SubCategoryHtmlValues = {
  description_html: string;
  practical_exercises_html: string;
};

type SubCategoryWithoutValues = {
  value: string;
  label: string;
};

type SubCategory = SubCategoryWithoutValues & {
  htmlValues?: SubCategoryHtmlValues;
  values: SubCategoryValues;
};

type SubCategoryCombined = SubCategoryWithoutValues & {
  values: SubCategoryValues & SubCategoryHtmlValues;
};

type NoteWithoutSubCategories = {
  trainingType: TypeOptions;
  photos: {
    primary: string[];
  };
  attachments: {
    primary: string[];
  };
};

type NoteBodyType = NoteWithoutSubCategories & {
  subCategories: SubCategory[];
  notes?: { notes: string };
};

type FormNoteType = NoteWithoutSubCategories & {
  subCategories: SubCategoryCombined[];
};

export type TrainingNote = {
  id: string;
  timestamp: string;
  body: NoteBodyType;
  BusUser: BusUserProfile;
};

type FormValues = FormNoteType & Record<string, SubCategoryCombined['values']>;

const TrainingReportNoteModal: FC<{
  selectedReport?: Record<string, any>;
  selectedNote?: TrainingNote;
  refetchNotes?: ReturnType<typeof useQuery>['refetch'];
  refetchReports?: ReturnType<typeof useQuery>['refetch'];
}> = ({ selectedReport, selectedNote, refetchNotes, refetchReports }) => {
  const [handleAddNote, { loading: loadingAddNote, error: errorNoteAdd }] = useMutation(NoteAdd);
  const [handleEditNote, { loading: loadingEditNote, error: errorNoteEdit }] = useMutation(NoteEdit);

  const { control, handleSubmit, errors } = useForm<FormValues>();
  const noteIdRef = useRef<string>('');
  const photosRef = useRef<Record<string, any>>({});
  const uploadPhotos = usePhotoUpload();
  const [_, { loading: loadingUploadPhotos, error: errorUploadPhotos }] = uploadPhotos;
  const deletePhotos = useDeletePhotos();

  const loading = loadingAddNote || loadingEditNote || loadingUploadPhotos;
  const error = !!errorNoteAdd?.message || !!errorNoteEdit?.message || !!errorUploadPhotos;

  const drawerBars = vars.drawerBars();
  const drawer = drawerBars.find(drawerBar => drawerBar.drawerId === DRAWER_IDS.PETS_DRAWER);
  const petId = drawer?.recordData;
  const orderId = drawer?.otherData?.orderId;

  const handleSaveNote = handleSubmit(async form => {
    const combinedSubCategories = form.subCategories.map(subCategory => ({
      ...subCategory,
      values: form[subCategory.value]
    }));

    if (selectedNote) {
      noteIdRef.current = selectedNote.id;
    }

    // TODO: for now until we figure out the app situation
    const newBody = {
      ...form,
      subCategories: combinedSubCategories.map(subCategory => {
        const { description_html, practical_exercises_html, ...rest } = subCategory.values;
        return {
          ...subCategory,
          values: rest,
          htmlValues: {
            description_html,
            practical_exercises_html
          }
        };
      })
    };

    if (!selectedNote) {
      const { data: { noteAdd: addedNote = {} } = {} } = await handleAddNote({
        variables: {
          body: newBody,
          timestamp: new Date(),
          trainingReportId: updatedReport?.id
        }
      });

      noteIdRef.current = addedNote.id;
    }
    const photos = await photosRef.current?.getPhotos();
    const attachments = await photosRef.current?.getAttachments();

    await handleEditNote({
      variables: {
        id: noteIdRef.current,
        body: {
          ...newBody,
          photos: {
            primary: photos
          },
          attachments: {
            primary: attachments
          }
        },
        timestamp: new Date()
      }
    });

    ModalDialog.closeModal();
    refetchNotes?.();
  });

  const defaultTrainingType = (selectedNote?.body?.trainingType || []).filter(trainingType => typeOptions.includes(trainingType)) || [];

  const defaultSubCategories =
    selectedNote?.body?.subCategories?.map(subCategory => {
      return {
        ...subCategory,
        values: {
          ...subCategory.values,
          description_html: subCategory.htmlValues?.description_html,
          practical_exercises_html: subCategory.htmlValues?.practical_exercises_html
        }
      };
    }) || [];

  const watchedTrainingTypeValue: TypeOptions = useWatch({
    control,
    name: 'trainingType',
    defaultValue: (defaultTrainingType as TypeOptions) || []
  });

  const watchedSubCategoriesValue = useWatch({
    control,
    name: 'subCategories',
    defaultValue: defaultSubCategories || []
  });

  const selectOptions = watchedTrainingTypeValue.flatMap(trainingTypeValue =>
    subCategories[trainingTypeValue]
      ?.sort((a, b) => a.localeCompare(b))
      ?.map(subCategory => ({
        value: subCategory,
        label: sentenceCase(subCategory)
      }))
  );

  // get pet profile
  const { data: { getBranchPetProfile: petProfile = {} } = {}, loading: loadingPetProfile } = useQuery(GetBranchPetProfile, {
    variables: { id: petId }
  });

  // get past pet appointments

  const { data: { getBranchAppointments: appUserAppointments = [] } = {}, loading: loadingAppUserAppointments } = useQuery(GetBranchAppointments, {
    variables: {
      PetRecordId: [petProfile?.PetRecord?.id],
      status: [BOOKING_STATUS_TYPES.CONFIRMED, BOOKING_STATUS_TYPES.REQUESTED],
      limit: 50,
      offset: 0,
      booking_type: [BOOKING_TYPE.SLOT, BOOKING_TYPE.MULTI_SLOT, BOOKING_TYPE.MULTI_DAY],
      requisite_queries: [],
      alternative_queries: []
    }
  });

  // get the service names from past appointments
  const reducedAppointments = useReduceAppointments(appUserAppointments || []);
  const [handleAddTrainingReport, { loading: loadingAdd, error: errorAdd, data: { trainingReportAdd: addedReport = null } = {} }] = useMutation(TrainingReportAdd);

  const [handleEditTrainingReport, { loading: loadingEdit, error: errorEdit, data: { trainingReportEdit: editedReport = null } = {} }] = useMutation(TrainingReportEdit);

  const handleSaveReport = handleSubmit(async form => {
    const { appointmentId } = form;
    if (selectedReport) {
      await handleEditTrainingReport({ variables: { ...form, id: selectedReport?.id, appointmentId: appointmentId || null, description: '' } });
    } else {
      await handleAddTrainingReport({ variables: { ...form, petRecordId: petProfile.PetRecord.id, appointmentId: appointmentId || null, description: '' } });
    }

    refetchReports?.();
  });

  const updatedReport = addedReport || editedReport;

  const handleSubmitReport = useCallback(() => {
    handleSaveReport();
  }, [handleSaveNote, handleSaveReport]);

  useEffect(() => {
    if (updatedReport?.id) {
      handleSaveNote();
    }
  }, [updatedReport]);

  const defaultAppointment = appUserAppointments.find(appointment => appointment.OrderItem.Order.id === orderId) || {};
  if (loadingPetProfile || loadingAppUserAppointments) {
    return <ActivityIndicator />;
  }

  return (
    <>
      <ModalBody minHeight={600}>
        <Container width={500}>
          <WideInputGroup>
            <FormLabel>Name</FormLabel>
            <Controller as={<FormInput error={errors.name} />} control={control} name={'name'} defaultValue={selectedReport?.name || ''} rules={{ required: true }} rows={6} />
            {errors?.name && <FormError>{errors.name.message || 'name is required'}</FormError>}
          </WideInputGroup>
          <WideInputGroup>
            <FormLabel error={errors?.appointmentId}>Appointment</FormLabel>
            <Controller
              render={({ onChange, value }) => (
                <FormSelect
                  height={48}
                  fontSize={16}
                  error={errors?.appointmentId}
                  onChange={e => {
                    onChange(e.target.value);
                  }}
                  value={value || ''}
                >
                  <option value={''}>Not specified</option>
                  {reducedAppointments?.map((orders, index) => (
                    <option key={index} value={orders[0].id}>
                      {orders[0].OrderItem.item.name} - {toReadableDate(orders[0].timestamp || new Date())}
                    </option>
                  ))}
                </FormSelect>
              )}
              control={control}
              name={'appointmentId'}
              defaultValue={defaultAppointment?.id || selectedReport?.Appointment?.id || ''}
            />
          </WideInputGroup>

          <SectionLabel> Assessment </SectionLabel>
          <InputsWrapper>
            <FormHeaderLabel margin="0 0 20px 0">Training Type:</FormHeaderLabel>

            <WideInputGroup>
              <Controller
                render={({ onChange, value }) => (
                  <FormSelect
                    height={48}
                    fontSize={16}
                    onChange={e => {
                      value.includes(e.target.value) ? onChange([...value].filter(v => v !== e.target.value)) : onChange([...value, e.target.value]);
                    }}
                    value={value || ''}
                  >
                    {typeOptions?.map((option, index) => (
                      <option key={index} value={option}>
                        {sentenceCase(option)}
                      </option>
                    ))}
                  </FormSelect>
                )}
                control={control}
                name={`trainingType`}
                defaultValue={defaultTrainingType || []}
                rules={{ required: true, validate: (value: TypeOptions) => value.length > 0 }}
              />

              {errors.trainingType && <FormError>Training type is required</FormError>}
            </WideInputGroup>

            <WideInputGroup>
              <FormHeaderLabel margin="0 0 20px 0">Training Focus</FormHeaderLabel>
              <Controller
                as={
                  <CreatableSelect
                    defaultValue={defaultSubCategories || []}
                    styles={{ container: (provided, state) => ({ ...provided }), valueContainer: (provided, state) => ({ ...provided, height: 48, overflowY: 'scroll' }) }}
                    options={selectOptions}
                    isMulti
                    theme={selectTheme}
                    name={'subCategories'}
                  />
                }
                control={control}
                name={'subCategories'}
                rules={{ required: true, validate: (value: any) => value.length > 0 }}
                defaultValue={defaultSubCategories || []}
              />

              {errors?.subCategories && <FormError>please select at least 1 Sub-Category</FormError>}
            </WideInputGroup>

            {watchedSubCategoriesValue.map(subCategory => (
              <Fragment key={subCategory.value}>
                <FormHeaderLabel margin="0 0 20px 0">{subCategory.label}</FormHeaderLabel>
                <WideInputGroup>
                  <FormLabel error={errors?.[subCategory.value]?.grade}>Grade</FormLabel>

                  <Controller
                    render={({ onChange, value }) => (
                      <FormSelect height={48} fontSize={16} onChange={e => onChange(e.target.value)} value={value || ''}>
                        {gradeOptions?.map((option, index) => (
                          <option key={index} value={option}>
                            {sentenceCase(option)}
                          </option>
                        ))}
                      </FormSelect>
                    )}
                    control={control}
                    name={`${subCategory.value}.grade`}
                    defaultValue={subCategory.values?.grade || ''}
                    rules={{ required: false }}
                  />
                </WideInputGroup>

                {/* <WideInputGroup>
                  <FormLabel>Description</FormLabel>
                  <NotesMarkupEditor
                    control={control}
                    name={subCategory.value}
                    defaultValue={{
                      details: subCategory.values?.description || '',
                      details_html: subCategory.values?.description_html || ''
                    }}
                    keyName="description"
                  />
                </WideInputGroup> */}

                <WideInputGroup>
                  <FormLabel>Recommendation</FormLabel>

                  <Controller
                    render={({ onChange, value }) => (
                      <FormSelect
                        height={48}
                        fontSize={16}
                        onChange={e => {
                          value.includes(e.target.value) ? onChange([...value].filter(v => v !== e.target.value)) : onChange([...value, e.target.value]);
                        }}
                      >
                        {recommendationOptions?.map((option, index) => (
                          <option key={index} value={option}>
                            {sentenceCase(option)}
                          </option>
                        ))}
                      </FormSelect>
                    )}
                    control={control}
                    name={`${subCategory.value}.recommendation`}
                    defaultValue={subCategory.values?.recommendation || []}
                  />
                </WideInputGroup>

                {/* <WideInputGroup>
                  <FormLabel>Practical Exercises</FormLabel>
                  <NotesMarkupEditor
                    control={control}
                    name={subCategory.value}
                    defaultValue={{
                      details: subCategory.values?.practical_exercises || '',
                      details_html: subCategory.values?.practical_exercises_html || ''
                    }}
                    keyName="practical_exercises"
                  />
                </WideInputGroup> */}
              </Fragment>
            ))}
          </InputsWrapper>

          <InputsWrapper>
            <SectionLabel>Notes</SectionLabel>
            <WideInputGroup>
              <FormLabel>Notes</FormLabel>
              <NotesMarkupEditor control={control} defaultValue={selectedNote?.body?.notes?.notes || {}} name="notes.notes" />
            </WideInputGroup>
          </InputsWrapper>
          <BinaryUpload
            uploadPhotos={uploadPhotos}
            deletePhotos={deletePhotos}
            ref={photosRef}
            getRoute={() => `reports/primary/${noteIdRef.current}`}
            defaultBinaries={{
              attachments: selectedNote?.body?.attachments?.primary || [],
              photos: selectedNote?.body?.photos?.primary || []
            }}
          />
        </Container>
      </ModalBody>
      <ModalFooter>
        <FormButtonsContainer>
          <FormSubmitButton error={error} loading={loading} onClick={handleSubmitReport}>
            Save note
          </FormSubmitButton>
        </FormButtonsContainer>
      </ModalFooter>
    </>
  );
};

const TrainingReportNoteAction: FC<{
  type: NOTE_ACTION_TYPES;
  note?: TrainingNote;
  selectedReport: Record<string, any>;
  refetchNotes?: ReturnType<typeof useQuery>['refetch'];
  noButton?: boolean;
  autoOpenModal?: boolean;
}> = ({ type, note, selectedReport, refetchNotes, autoOpenModal = false }) => {
  const icons = useIcons();
  const icon = type === NOTE_ACTION_TYPES.ADD ? icons.add.childImageSharp.gatsbyImageData.images.fallback.src : icons.moreIcon.childImageSharp.gatsbyImageData.images.fallback.src;

  const showModal = useCallback(
    () =>
      ModalDialog.openModal({
        content: () => <TrainingReportNoteModal selectedNote={note} selectedReport={selectedReport} refetchNotes={refetchNotes} />,
        title: `${sentenceCase(type)} note`,
        autoOpenModal
      }),
    [note, selectedReport, refetchNotes, type]
  );

  useEffect(() => {
    if (autoOpenModal) {
      showModal();
    }
  }, [autoOpenModal, showModal]);

  return <ActionBtn bgImage={icon} onClick={showModal} />;
};

export default TrainingReportNoteAction;
