import firebase from 'gatsby-plugin-firebase';
import React, { useState } from 'react';
import Notifier from '../Notifier';
import { randomId } from '../utils/helpers';

export type UsePhotoUploadReturnType = [
  (
    url: string,
    files: File[],
    options?: {
      resizeImages?: boolean;
      indexId?: boolean;
    }
  ) => Promise<string[] | undefined>,
  {
    data: string[];
    loading: boolean;
    error: Error | null;
  },
  {
    checkPhotosSize: (e: React.ChangeEvent<HTMLInputElement>) => boolean;
  }
];

export default function usePhotoUpload(): UsePhotoUploadReturnType {
  const [data, setData] = useState<string[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);

  const uploadPhoto = async (
    url: string,
    files: File[],
    options: { resizeImages?: boolean; indexId?: boolean } = {
      resizeImages: false,
      indexId: false
    }
  ) => {
    const { resizeImages, indexId } = options;
    try {
      setLoading(true);
      if (!resizeImages) {
        const photos: string[] = await Promise.all(
          files.map(async (file, index) => {
            const refUrl = indexId ? `${url}/${index}` : `${url}/${Date.now()}-${randomId()}`;
            const ref = firebase?.storage().ref(refUrl);
            await ref.put(file);
            return ref.getDownloadURL();
          })
        );
        setData(photos);

        return photos;
      }
      const resizedImages = await Promise.all(
        files.map(async file => {
          const base64 = await new Promise<string>(resolve => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => resolve(reader.result as string);
          });
          return resizeImage(base64);
        })
      );

      const storageRef = firebase.storage().ref();
      const images: string[] = await Promise.all(
        resizedImages.map(async (base64, index) => {
          const refUrl = indexId ? `${url}/${index}` : `${url}/${Date.now()}-${randomId()}`;
          const imageRef = storageRef.child(refUrl);
          const snapshot = await imageRef.putString(base64, 'data_url');
          return snapshot.ref.getDownloadURL();
        })
      );
      setData(images);
      return images;
    } catch (ex) {
      setError(ex as Error);
    } finally {
      setLoading(false);
    }
  };

  const checkPhotosSize = (e: React.ChangeEvent<HTMLInputElement>): boolean => {
    if ([...(e?.target?.files || [])].some((file: File) => file.size > 1e7)) {
      Notifier.error({ message: 'Uploaded files should not exceed 10MB.', title: 'Invalid file size' });
      return false;
    }
    return true;
  };

  return [uploadPhoto, { data, loading, error }, { checkPhotosSize }];
}

export type useDeletePhotosReturnType = [
  (url: string) => Promise<any[]>,
  {
    photosDeleteQueue: string[];
    setPhotosDeleteQueue: React.Dispatch<React.SetStateAction<string[]>>;
  }
];
export const useDeletePhotos = (): useDeletePhotosReturnType => {
  const [photosDeleteQueue, setPhotosDeleteQueue] = useState<string[]>([]);
  const deletePhotos = async (url: string) => {
    try {
      if (photosDeleteQueue.length) {
        const photos = photosDeleteQueue.map(photo => url + decodeURIComponent(photo).split(url)[1].split('?')[0]);
        await Promise.all(photos.map(url => firebase?.storage().ref(url).delete()));
        return Promise.resolve([]);
      }
    } catch (e) {}
    return Promise.resolve([]);
  };
  return [deletePhotos, { photosDeleteQueue, setPhotosDeleteQueue }];
};

export function resizeImage(base64Str: string, maxWidth = 900, maxHeight = 900): Promise<string> {
  return new Promise(resolve => {
    let img = new Image();
    img.src = base64Str;
    img.onload = () => {
      let canvas = document.createElement('canvas');
      const MAX_WIDTH = maxWidth;
      const MAX_HEIGHT = maxHeight;
      let width = img.width;
      let height = img.height;
      let shouldResize = false;

      if (width > height) {
        if (width > MAX_WIDTH) {
          height *= MAX_WIDTH / width;
          width = MAX_WIDTH;
          shouldResize = true;
        }
      } else {
        if (height > MAX_HEIGHT) {
          width *= MAX_HEIGHT / height;
          height = MAX_HEIGHT;
          shouldResize = true;
        }
      }
      if (shouldResize) {
        canvas.width = width;
        canvas.height = height;
        let ctx = canvas.getContext('2d')!;
        ctx.drawImage(img, 0, 0, width, height);
        resolve(canvas.toDataURL('image/jpeg', 0.9));
      } else {
        resolve(base64Str);
      }
    };
  });
}

export const getBase64 = (file: File): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
  });
};
