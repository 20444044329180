import styled, { css } from 'styled-components';
import Colors from '../../../../Colors';
import { OPTION_DROPDOWN_MENU_BUTTON_TYPES, OPTION_DROPDOWN_MENU_OVERLAY_POSITIONS, OPTION_DROPDOWN_MENU_POSITIONS, OPTION_DROPDOWN_TYPES } from './types';

export const OptionDropdownContainer = styled.div<{ relative?: boolean }>`
  ${props =>
    props.relative &&
    css`
      position: relative;
    `}
`;

export const MenuButton = styled.label<{ bgImage: string; active: boolean; buttonType: OPTION_DROPDOWN_MENU_BUTTON_TYPES; mobileDisplayIcon: boolean }>`
  display: flex;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  cursor: pointer;
  /* transform: scale(1);
  transition: all 0.2s ease-in-out; */

  ${props =>
    props.buttonType !== OPTION_DROPDOWN_MENU_BUTTON_TYPES.PLUS &&
    props.buttonType !== OPTION_DROPDOWN_MENU_BUTTON_TYPES.MENU &&
    css`
      ${props.bgImage &&
      css`
        background-image: url(${props.bgImage});
        /* &:hover {
          transform: scale(1.1);
        }

        &:active {
          transform: scale(0.9);
        } */
      `}
    `}

  ${props =>
    props.buttonType === OPTION_DROPDOWN_MENU_BUTTON_TYPES.PLUS &&
    css`
      ${props.bgImage &&
      css`
        background-image: url(${props.bgImage});
      `}
      &:hover {
        transform: ${props.active && 'rotate(45deg)'};
      }

      &:active {
        transform: ${props.active && 'rotate(45deg)'};
      }

      transition: all 0.2s ease-out;
      ${props.active &&
      css`
        transform: rotate(45deg);
      `}

      background-color: ${props.active ? Colors.black : Colors.black};
    `}

  ${props =>
    props.buttonType === OPTION_DROPDOWN_MENU_BUTTON_TYPES.MENU &&
    css`
      background-color: ${Colors.black};
      transform: scale(1);
      justify-content: center;
      align-items: center;
      flex-direction: column;
      gap: 2px;
      &:hover {
        transform: scale(1.1);
        span {
          transform: scale(1.1);
        }
      }

      &:active {
        transform: scale(1);
        span {
          transform: scale(1);
        }
      }

      ${props.active &&
      css`
        transform: scale(1.1);
        span {
          transform: scale(1.1);
        }
      `}

      span {
        position: absolute;
        display: block;
        height: 4%;
        width: 30%;
        background: ${Colors.white};
        opacity: 1;
        transform: rotate(0deg);
        transition: 0.2s ease-in-out;

        &:nth-child(1) {
          top: 10px;
          ${props.active &&
          css`
            left: 35%;
            top: 12px;
            transform: rotate(45deg);
          `}
        }
        &:nth-child(2) {
          top: 14px;
          ${props.active &&
          css`
            top: 12px;

            transform: rotate(-45deg);
          `}
        }
      }
    `}

  input {
    display: none;
  }
  @media (max-width: 768px) {
    visibility: ${props => (props.mobileDisplayIcon ? 'visible' : 'hidden')};
  }
`;

export const Triangle = styled.span<{ menuPosition: OPTION_DROPDOWN_MENU_POSITIONS }>`
  position: absolute;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 5px 5px 5px;
  border-color: transparent transparent ${Colors.white} transparent;
  top: -5px;
  ${props =>
    props.menuPosition === OPTION_DROPDOWN_MENU_POSITIONS.LEFT &&
    css`
      right: 10%;
    `}
  ${props =>
    props.menuPosition === OPTION_DROPDOWN_MENU_POSITIONS.RIGHT &&
    css`
      left: 10%;
    `}
  transform: translateX(-50%);
`;

export const ItemMenu = styled.div<{ active: boolean; menuPosition: OPTION_DROPDOWN_MENU_POSITIONS; fullButton?: boolean; menuOverlayPosition: OPTION_DROPDOWN_MENU_OVERLAY_POSITIONS }>`
  position: absolute;

  ${({ menuPosition, menuOverlayPosition, active }) => {
    const { LEFT, RIGHT } = OPTION_DROPDOWN_MENU_POSITIONS;
    const { UP, DOWN } = OPTION_DROPDOWN_MENU_OVERLAY_POSITIONS;

    const leftPosition = menuPosition === LEFT;
    const rightPosition = menuPosition === RIGHT;
    const upPosition = menuOverlayPosition === UP;
    const downPosition = menuOverlayPosition === DOWN;

    const translateX = leftPosition ? ` ${active ? '-140px' : '-130px'}` : rightPosition && `${active ? '20px' : '0'}`;

    const translateY = downPosition ? ` ${active ? '8px' : '-2px'}` : upPosition && `${active ? '-112%' : '-80%'}`;

    return css`
      transform: translate(${translateX}, ${translateY});
    `;
  }}

  max-width: ${props => (props.active ? '300px' : '-60px')};
  border-radius: 16px;
  box-shadow: 0 2px 30px 0 rgba(0, 0, 0, 0.27);
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 16px;
  opacity: ${props => (props.active ? 1 : 0)};
  visibility: ${props => (props.active ? 'visible' : 'hidden')};
  transition: all 0.2s ease-out;
  z-index: 10;
  user-select: none;
  gap: 16px;

  ${props =>
    props.fullButton &&
    css`
      width: 167px;
      max-width: none;
      padding: 0;
      justify-content: flex-start;
      max-height: 40vh;
      overflow-y: auto;
      overflow-x: hidden;
    `}
`;

export const ItemButton = styled.button<{ active: boolean; danger: boolean; green: boolean; disabled?: boolean; isLast: boolean }>`
  font-size: 14px;
  font-weight: bold;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  text-align: center;
  color: ${props => (props.green ? Colors.primary : props.danger ? '#e26f29' : Colors.white)};
  height: ${props => (props.active ? '50px' : '100%')};
  border: none;
  background-color: ${Colors.toolsBackgroundColorHover};
  cursor: pointer;
  border-bottom: 2px solid ${Colors.dropdownDivider};
  opacity: ${props => (props.active ? 1 : 0)};
  visibility: ${props => (props.active ? 'visible' : 'hidden')};
  &:first-child {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
  }
  ${props =>
    props.isLast &&
    css`
      border-bottom-left-radius: 16px;
      border-bottom-right-radius: 16px;
    `}
  &:hover {
    background-color: ${Colors.dropdownItem};
  }
  width: 100%;

  ${props =>
    props.disabled &&
    css`
      opacity: 0.5;
      cursor: not-allowed;
    `}
`;

export const OptionContainer = styled.div<{ active: boolean; optionType: OPTION_DROPDOWN_TYPES }>`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: ${props => (props.optionType === OPTION_DROPDOWN_TYPES.BUTTONS ? '0' : '4px')};
  width: 100%;
  opacity: ${props => (props.active ? 1 : 0)};
  visibility: ${props => (props.active ? 'visible' : 'hidden')};
  transition: all 0.2s ease-out;
`;

export const ApplyButton = styled.button<{ active: boolean; loading?: boolean }>`
  min-width: 224px;
  width: 100%;
  height: 35px;
  border-radius: 7px;
  background-color: ${Colors.primary};
  color: ${Colors.white};
  font-size: 14px;
  font-weight: bold;
  text-align: center;
  cursor: pointer;
  outline: none;
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;

  ${props =>
    props.loading &&
    css`
      cursor: not-allowed;
      background-color: ${Colors.grey};
      border: 2px solid ${Colors.primary};
    `}

  ${props =>
    !props.active &&
    css`
      opacity: 0;
      visibility: hidden;
    `}
`;

export const ButtonOptionLabel = styled.label<{ padding?: number }>`
  display: inline-block;
  margin-bottom: 20px;
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: 800;
  color: black;
  white-space: nowrap;
  ${props =>
    props.padding &&
    css`
      padding: ${props.padding}px;
    `}
  cursor: default;
  width: 100%;
`;
