import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';
import { OrderInvoice } from '../../components/OrderInvoices/types';
import useIcons from '../../hooks/useIcons';
import usePaginatedQuery from '../../hooks/usePaginatedQuery';
import useURLSearchParams from '../../hooks/useURLSearchParams';
import { GetBranchOrderInvoices, GetBranchOrderSubscriptionRepeats } from '../../queries';
import { ButtonsContainer } from '../../views/Bookings/components/BookingsDrawer/styled';
import OrderInvoicesDownloadModal from '../../views/Store/BranchBilling/OrderInvoicesDownloadModal';
import { BranchAppUser } from '../../views/Store/BranchBilling/types';
import { BranchSummaryCountMode } from '../../views/Store/BranchSummary/styled';
import { ORDER_REPEAT_STATUS, OrderSubscriptionRepeat } from '../../views/Subscriptions/types';
import ModalDialog from '../Modal/ModalDialog';
import InfiniteList from '../Shared/InfiniteList/InfiniteList';
import ToolBar from '../Shared/ToolBar/ToolBar';
import UserOrderInvoiceRecord from './UserOrderInvoiceRecord';
import { BookingsListCotainer, ListHeaderContainer, ListHeaderText, ListHeaderWrapper, ListView } from './styled';

export enum InvoicesTabs {
  ORDER_INVOICES = 'Order Invoices',
  MEMBERSHIP_INVOICES = 'Membership Invoices'
}

export enum InvoicesTabsIds {
  ORDER_INVOICES = 'order_invoices',
  MEMBERSHIP_INVOICES = 'membership_invoices'
}

const UserInvoices = ({ userProfile }: { userProfile: BranchAppUser }) => {
  const [[hasMoreBookings, setHasMoreBookings], queryResult] = usePaginatedQuery<OrderInvoice[]>({
    query: GetBranchOrderInvoices,
    limit: 50,
    otherVariables: {
      AppUserId: [userProfile.id]
    }
  });

  const [tab] = useURLSearchParams('invoicesSelectedTab');
  const currentTab = tab[0] || InvoicesTabsIds.ORDER_INVOICES;

  const { data: { getBranchOrderInvoices: orderInvoices = [] } = {}, loading: loadingOrderInvoices, fetchMore, refetch } = queryResult;

  const paginatedQueryResult = usePaginatedQuery<OrderSubscriptionRepeat[]>({
    query: GetBranchOrderSubscriptionRepeats,
    limit: 20,
    otherVariables: {
      status: [
        ORDER_REPEAT_STATUS.CANCELED,
        ORDER_REPEAT_STATUS.CONFIRMED,
        ORDER_REPEAT_STATUS.PAYMENT_PENDING,
        ORDER_REPEAT_STATUS.PAYMENT_REJECTED,
        ORDER_REPEAT_STATUS.PAYMENT_SUCCEEDED,
        ORDER_REPEAT_STATUS.REFUNDED,
        ORDER_REPEAT_STATUS.REQUESTED
      ]
    }
  });

  const [[hasMoreItems, setHasMoreItems], membershipQueryResult] = paginatedQueryResult;

  const { data: { getBranchOrderSubscriptionRepeats: subscriptionRepeats = [] } = {}, loading, fetchMore: fetchMembershipInvoices, refetch: refetchMembershipInvoices } = membershipQueryResult;

  const toolBarButtons = [
    { name: InvoicesTabs.ORDER_INVOICES, id: InvoicesTabsIds.ORDER_INVOICES },
    { name: InvoicesTabs.MEMBERSHIP_INVOICES, id: InvoicesTabsIds.MEMBERSHIP_INVOICES }
  ];
  const icons = useIcons();
  const exportIcon = icons?.export?.childImageSharp.gatsbyImageData;

  return (
    <ListView padding="0 20px 20px 20px">
      <ListHeaderContainer>
        <ToolBar
          toolBarButtons={toolBarButtons}
          urlSearchParam="invoicesSelectedTab"
          defaultValue={InvoicesTabsIds.ORDER_INVOICES}
          selectOnlyOne={true}
          displayAll={false}
          displayTools={false}
          padding="0 0"
        />

        {currentTab === InvoicesTabsIds.ORDER_INVOICES && (
          <ListHeaderWrapper>
            <ListHeaderText>Invoices</ListHeaderText>
            <ButtonsContainer style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}>
              <BranchSummaryCountMode
                style={{ display: 'flex', gap: 10, paddingBottom: 10 }}
                onClick={() =>
                  ModalDialog.openModal({
                    content: () => (
                      <OrderInvoicesDownloadModal
                        initialValues={{
                          AppUserId: [userProfile.id]
                        }}
                      />
                    ),
                    title: 'Export Invoices'
                  })
                }
              >
                <span>Export</span>
                <GatsbyImage image={exportIcon} alt="export" />
              </BranchSummaryCountMode>
            </ButtonsContainer>
          </ListHeaderWrapper>
        )}
      </ListHeaderContainer>
      {currentTab === InvoicesTabsIds.ORDER_INVOICES && (
        <BookingsListCotainer flexOne>
          <InfiniteList
            hasMoreItems={hasMoreBookings}
            fetchMore={fetchMore}
            loading={loadingOrderInvoices}
            setHasMoreItems={setHasMoreBookings}
            itemRenderer={(invoice: OrderInvoice) => <UserOrderInvoiceRecord invoice={invoice} refetch={refetch} />}
            list={orderInvoices}
            offset={orderInvoices?.length}
          />
        </BookingsListCotainer>
      )}

      {currentTab === InvoicesTabsIds.MEMBERSHIP_INVOICES && (
        <InfiniteList
          list={subscriptionRepeats}
          itemRenderer={(repeat: OrderSubscriptionRepeat) => <UserOrderInvoiceRecord key={repeat?.id} invoice={repeat} refetch={refetchMembershipInvoices} />}
          hasMoreItems={hasMoreItems}
          loading={loading}
          fetchMore={fetchMembershipInvoices as any}
          offset={subscriptionRepeats?.length}
          setHasMoreItems={setHasMoreItems}
        />
      )}
    </ListView>
  );
};

export default UserInvoices;
