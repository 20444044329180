import styled, { css, keyframes } from 'styled-components';
import Colors from '../../../../Colors';
import { HoverRecordOptionsContainer } from '../../../styled';

export const BodyContainer = styled.div`
  width: 100%;
  overflow-x: scroll;
  display: flex;
  justify-content: space-between;
  gap: 30px;
  height: 100%;
`;

export const ServiceHeaderContainer = styled.div`
  display: flex;
  gap: 5px;
  width: 100%;
`;

const slideInFromRight = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
`;

export const ServiceContainer = styled.div<{ minWidth?: number }>`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0 20px;
  min-width: ${props => `${props.minWidth ? props.minWidth : 800}px`};
  &:nth-child(even) {
    background-color: ${Colors.backgroundGrey};
  }

  &:not(:first-child) {
    animation: ${slideInFromRight} 0.4s ease-in-out;
  }
  @media (max-width: 768px) {
    min-width: unset;
    padding: 0 0;
  }
`;

export const ServiceNameInfoContainer = styled.div`
  display: flex;
  gap: 5px;
  padding: 20px 0;
  align-items: center;
  color: ${Colors.black};
  width: 100%;
`;
export const ServiceName = styled.div`
  font-size: 16px;
  font-weight: 800;
  width: 100%;
`;

export const ServiceInfo = styled.div`
  font-size: 14px;
  font-weight: 600;
`;

export const TagsAndMoreIconContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 10px;
  padding: 16px 0;
  align-items: center;
  width: 100%;
`;

export const TagsContainer = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  overflow-x: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
`;

export const Tag = styled.div`
  display: flex;
  gap: 10px;
  flex: 0 0 auto;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 700;
  padding: 6px 10px;
  border-radius: 22px;
  background-color: ${Colors.toolsBarBackgroundColor};
  &:hover {
    background-color: ${Colors.sideTabBackgroundColor};
    cursor: pointer;
  }
`;

export const TagName = styled.div``;

export const TagCount = styled.span``;

export const MoreIcon = styled.div``;

export const MoreIconList = styled.div`
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out;
`;

export const BookingsListContainer = styled.div`
  padding-top: 8px;
  height: 80%;
`;

export const BookingsList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;
  list-style: none;
  height: 90%;
  overflow-y: scroll;
`;

export const Booking = styled.li<{ mobileMinHeight?: string; mobilePadding?: string; mobileGap?: number; gap?: number; mobileBorderRadius?: string; MobileFlexDirection?: string }>`
  display: flex;
  flex: 0 0 auto;
  /* justify-content: space-between; */
  align-items: center;
  padding: 6px 6px;
  &:hover {
    background-color: ${Colors.toolsBarBackgroundColor};
    border-radius: 15px;
    cursor: pointer;
    ${HoverRecordOptionsContainer} {
      opacity: 1;
      visibility: visible;
    }
  }
  gap: ${props => (props.gap ? props.gap : 0)}px;

  @media (max-width: 768px) {
    border-radius: ${props => (props.mobileBorderRadius ? props.mobileBorderRadius : '15px')};
    flex-direction: ${props => (props.MobileFlexDirection ? props.MobileFlexDirection : 'column')};
    align-items: flex-start;
    min-height: ${props => (props.mobileMinHeight ? props.mobileMinHeight : '80px')};
    max-height: unset;
    height: auto;
    /* margin-bottom: 8px; */
    padding: ${props => (props.mobilePadding ? props.mobilePadding : 0)};
    background-color: #f2f2f2;
    text-overflow: ellipsis;
    white-space: nowrap;
    /* align-items: ${props => (props.streach ? 'stretch' : 'flex-start')}; */
    gap: ${props => (props.mobileGap ? props.mobileGap : 10)}px;
    /* ${props =>
      props.noMargin &&
      css`
        margin-bottom: 0;
      `} */
    /* ${props =>
      props.fullWidth &&
      css`
        width: 100%;
      `} */
  }
`;

export const PetStaffContainer = styled.div<{ width?: number }>`
  display: flex;
  gap: 12px;
  color: #212121;
  width: ${props => `${props.width ? props.width : 80}PX`};
`;
export const PetAndStaffInfo = styled.div`
  display: flex;
  gap: 12px;
  justify-content: space-between;
  align-items: center;
`;
export const PetImg = styled.img<{ width?: string; height?: string }>`
  border-radius: 50%;
  width: ${props => (props.width ? props.width : '40px')};
  height: ${props => (props.height ? props.height : '40px')};
`;
export const PetName = styled.span`
  width: 120px;
  font-size: 14px;
  font-weight: 800;
  white-space: nowrap;
`;

export const StaffMemberName = styled.div<{ displayNone?: boolean }>`
  min-width: 80px;
  @media (max-width: 870px) {
    display: ${props => props.displayNone && 'none'};
  }
`;

export const PetRecordTagsAndTimeContainer = styled.div<{ displayNone?: boolean }>`
  display: flex;
  flex: 1;
  gap: 8px;
  height: 30px;
  @media (max-width: 975px) {
    display: ${props => props.displayNone && 'none'};
  }
  @media (max-width: 768px) {
    width: 100%;
    display: flex;
  }
`;

export const PetRecordTagsContainer = styled.div<{ displayNone?: boolean; flex?: string }>`
  display: flex;
  flex: ${props => (props.flex ? props.flex : '1')};
  gap: 10px;
  align-items: center;
  min-width: 0;
  @media (max-width: 995px) {
    display: ${props => props.displayNone && 'none'};
  }
  @media (max-width: 768px) {
    display: flex;
  }
`;

export const PetRecordTags = styled.div<{ width?: number; mediaWidth?: boolean; widthAuto?: boolean; reduceWidth?: string; mobileAutoWidth?: boolean }>`
  display: flex;
  gap: 10px;
  width: ${props => `${props.width ? props.width : 270}px`};
  width: ${props => props.widthAuto && 'auto'};
  min-width: 0;
  flex: 1;
  overflow-x: scroll;
  flex-direction: row-reverse;
  &::-webkit-scrollbar {
    display: none;
  }
  @media (max-width: 1300px) {
    width: ${props => `${props.mediaWidth ? 10 : 270}px`};
    width: ${props => props.mobileAutoWidth && 'auto'};
  }
  @media (max-width: 950px) {
    width: ${props => `${props.reduceWidth ? props.reduceWidth : '270px'}`};
  }

  @media (max-width: 768px) {
    flex-direction: row;
  }
`;

export const PetRecordTag = styled.div`
  white-space: nowrap;
  border-radius: 21px;
  padding: 6px 8px;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  color: ${Colors.white};
  ${({ color }) => css`
    background-color: ${color};
  `}
  @media (max-width: 768px) {
    background-color: #3f3f3f;
    padding: 4px 8px 5px 8px;
    font-weight: 600;
  }
`;

export const BookingTime = styled.span`
  align-self: center;
  white-space: nowrap;
  color: #212121;
  font-size: 14px;
  font-weight: 800;
  text-align: right;
  width: 70px;
`;

export const TagsWrapper = styled.div`
  padding: 16px 0;
`;
