import { BOOKINGS_FILTER_TYPES, BOOKINGS_MORE_OPTIONS_TYPES, HIDDEN_BOOKINGS_FILTERS } from '../../../views/Bookings/BookingsFilters';
import { HIDDEN_PETS_FILTERS, PETS_FILTER_TYPES, PETS_MORE_OPTIONS_TYPES } from '../../../views/Pets/PetsFilters';
import { BRANCH_SCHEDULES_FILTER_TYPES, BRANCH_SCHEDULES_MORE_OPTIONS_TYPES, HIDDEN_BRANCH_SCHEDULES_FILTERS } from '../../../views/Store/BranchSchedules/BranchSchedulesFilters';
import { DISCOUNTS_FILTER_TYPES, DISCOUNTS_MORE_OPTIONS_TYPES, HIDDEN_DISCOUNTS_FILTERS } from '../../../views/Store/Discounts/DiscountsFilters';
import { HIDDEN_VOUCHERS_FILTERS, VOUCHERS_FILTER_TYPES } from '../../../views/Store/Discounts/VouchersFilters';
import { HIDDEN_STORE_PRODUCTS_FILTERS, STORE_PRODUCTS_FILTER_TYPES, STORE_PRODUCTS_MORE_OPTIONS_TYPES } from '../../../views/Store/Products/ProductsFilters';
import { HIDDEN_STORE_SERVICES_FILTERS, STORE_SERVICES_FILTER_TYPES, STORE_SERVICES_MORE_OPTIONS_TYPES } from '../../../views/Store/Services/ServicesFilters';
import { HIDDEN_STORE_SUBSCRIPTIONS_FILTERS, STORE_SUBSCRIPTIONS_FILTER_TYPES, STORE_SUBSCRIPTIONS_MORE_OPTIONS_TYPES } from '../../../views/Store/StoreSubscriptions/StoreSubscriptionsFilters';
import { HIDDEN_SUBSCRIPTIONS_FILTERS, SUBSCRIPTIONS_FILTER_TYPES, SUBSCRIPTIONS_MORE_OPTIONS_TYPES } from '../../../views/Subscriptions/SubscriptionsFilters';
import { CHATS_FILTER_TYPES, CHATS_MORE_OPTIONS_TYPES, HIDDEN_CHATS_FILTERS } from '../../Chat/ChatsFilters';
import { OPTION_DROPDOWN_TYPES } from '../Menus/OptionDropdown/types';

export const FILTERS_ID = 'FILTERS';

type MoreOptionType =
  | PETS_MORE_OPTIONS_TYPES
  | BOOKINGS_MORE_OPTIONS_TYPES
  | STORE_PRODUCTS_MORE_OPTIONS_TYPES
  | SUBSCRIPTIONS_MORE_OPTIONS_TYPES
  | CHATS_MORE_OPTIONS_TYPES
  | BRANCH_SCHEDULES_MORE_OPTIONS_TYPES
  | STORE_SERVICES_MORE_OPTIONS_TYPES
  | STORE_SUBSCRIPTIONS_MORE_OPTIONS_TYPES
  | DISCOUNTS_MORE_OPTIONS_TYPES
  | VOUCHERS_FILTER_TYPES;

type FilterType =
  | PETS_FILTER_TYPES
  | BOOKINGS_FILTER_TYPES
  | STORE_PRODUCTS_FILTER_TYPES
  | SUBSCRIPTIONS_FILTER_TYPES
  | CHATS_FILTER_TYPES
  | BRANCH_SCHEDULES_FILTER_TYPES
  | STORE_SERVICES_FILTER_TYPES
  | STORE_SUBSCRIPTIONS_FILTER_TYPES
  | DISCOUNTS_FILTER_TYPES
  | VOUCHERS_FILTER_TYPES;

type MoreOption = {
  values:
    | string
    | {
        name: string;
      }[]
    | {
        name: string;
      };
  type: MoreOptionType;
  filterType: FilterType;
  id: string;
  title: string;
  optionType: OPTION_DROPDOWN_TYPES;
  items: any[];
};

type SelectedFilterConstructor<T extends FilterType> = {
  name: string;
  value: T;
  moreOptions: MoreOption[];
  requisite: string;
};

export type SelectedPetsFilter = SelectedFilterConstructor<PETS_FILTER_TYPES>;

export type SelectedBookingsFilter = SelectedFilterConstructor<BOOKINGS_FILTER_TYPES>;

export type SelectedStoreProductsFilter = SelectedFilterConstructor<STORE_PRODUCTS_FILTER_TYPES>;

export type SelectedSubscriptionsFilter = SelectedFilterConstructor<SUBSCRIPTIONS_FILTER_TYPES>;

export type SelectedChatsFilter = SelectedFilterConstructor<CHATS_FILTER_TYPES>;

export type SelectedBranchSchedulesFilter = SelectedFilterConstructor<BRANCH_SCHEDULES_FILTER_TYPES>;

export type SelectedStoreServicesFilter = SelectedFilterConstructor<STORE_SERVICES_FILTER_TYPES>;

export type SelectedStoreSubscriptionsFilter = SelectedFilterConstructor<STORE_SUBSCRIPTIONS_FILTER_TYPES>;

export type SelectedDiscountsFilter = SelectedFilterConstructor<DISCOUNTS_FILTER_TYPES>;

export type SelectedVouchersFilter = SelectedFilterConstructor<VOUCHERS_FILTER_TYPES>;

export const FILTER_TYPES = {
  ...PETS_FILTER_TYPES,
  ...BOOKINGS_FILTER_TYPES,
  ...STORE_PRODUCTS_FILTER_TYPES,
  ...SUBSCRIPTIONS_FILTER_TYPES,
  ...CHATS_FILTER_TYPES,
  ...BRANCH_SCHEDULES_FILTER_TYPES,
  ...STORE_SERVICES_FILTER_TYPES,
  ...STORE_SUBSCRIPTIONS_FILTER_TYPES,
  ...DISCOUNTS_FILTER_TYPES,
  ...VOUCHERS_FILTER_TYPES
};

export const MORE_OPTIONS_TYPES = {
  ...PETS_MORE_OPTIONS_TYPES,
  ...BOOKINGS_FILTER_TYPES,
  ...STORE_PRODUCTS_MORE_OPTIONS_TYPES,
  ...SUBSCRIPTIONS_MORE_OPTIONS_TYPES,
  ...CHATS_MORE_OPTIONS_TYPES,
  ...BRANCH_SCHEDULES_MORE_OPTIONS_TYPES,
  ...STORE_SERVICES_FILTER_TYPES,
  ...STORE_SUBSCRIPTIONS_MORE_OPTIONS_TYPES,
  ...DISCOUNTS_MORE_OPTIONS_TYPES,
  ...VOUCHERS_FILTER_TYPES
};

export const HIDDEN_FILTERS = {
  ...HIDDEN_PETS_FILTERS,
  ...HIDDEN_BOOKINGS_FILTERS,
  ...HIDDEN_STORE_PRODUCTS_FILTERS,
  ...HIDDEN_SUBSCRIPTIONS_FILTERS,
  ...HIDDEN_CHATS_FILTERS,
  ...HIDDEN_BRANCH_SCHEDULES_FILTERS,
  ...HIDDEN_STORE_SERVICES_FILTERS,
  ...HIDDEN_STORE_SUBSCRIPTIONS_FILTERS,
  ...HIDDEN_DISCOUNTS_FILTERS,
  ...HIDDEN_VOUCHERS_FILTERS
};

export type SelectedFilter =
  | SelectedPetsFilter
  | SelectedBookingsFilter
  | SelectedStoreProductsFilter
  | SelectedSubscriptionsFilter
  | SelectedChatsFilter
  | SelectedBranchSchedulesFilter
  | SelectedStoreServicesFilter
  | SelectedStoreSubscriptionsFilter
  | SelectedDiscountsFilter
  | SelectedVouchersFilter;
