import React from 'react';
import { DrawerBarContainer } from './styled';
import { useReactiveVar } from '@apollo/client';
import { vars } from '../../reactive';
import DrawerBarWrapper from './DrawerBarWrapper';
import { useWindowSize } from '../../hooks/useWindowResize';

const DrawerBar = () => {
  const drawerBars = useReactiveVar(vars.drawerBars);
  const isExtended = !!drawerBars.filter(drawer => drawer?.isExtended).length;
  const windowSize = useWindowSize();
  const nonFloatingDrawerBars = drawerBars.filter(drawer => !drawer?.isFloating);

  return (
    <DrawerBarContainer drawerCount={nonFloatingDrawerBars.length} isExtended={isExtended} windowHeight={windowSize.height}>
      {drawerBars.map(drawer => (
        <DrawerBarWrapper key={drawer?.drawerId} drawer={drawer} />
      ))}
    </DrawerBarContainer>
  );
};

export default DrawerBar;
