import { useMutation, useReactiveVar } from '@apollo/client';
import { GatsbyImage } from 'gatsby-plugin-image';
import React, { FC, useEffect } from 'react';
import useIcons from '../../hooks/useIcons';
import useMediaQuery from '../../hooks/useMediaQuery';
import useNavigateDrawer from '../../hooks/useNavigateDrawer';
import { StartChatRoomWithAppUser } from '../../queries';
import { vars } from '../../reactive';
import { clearDrawerBars, setDrawerBar, toggleExtendDrawerBar, togglePinDrawerBar } from '../../reactive/actions';
import { ChatButton, DefaultProfilePhoto, DrawerLabelContainer, DrawerSubLabel, Profile, ProfilePhoto } from '../DrawerBar/styled';
import { DRAWER_IDS } from '../DrawerBar/types';
import { FlexRow } from '../Shared/Shared';
import { ProfileButton, ProfilePhotoContainer, ProfileSmallDescription } from './styled';

export enum SMALL_DESCRIPTION_POSITIONS {
  UP = 'UP',
  DOWN = 'DOWN'
}

const ProfileActionBar: FC<{
  profile: any;
  drawerId: DRAWER_IDS;
  showProfile?: boolean;
  showChat?: boolean;
  smallDescription?: boolean;
  smallDescriptionPosition?: SMALL_DESCRIPTION_POSITIONS;
  reverse?: boolean;
  paddingT?: number;
  paddingB?: number;
  paddingL?: number;
  paddingR?: number;
}> = ({
  profile,
  drawerId,
  showProfile = true,
  showChat = true,
  smallDescription = true,
  smallDescriptionPosition = SMALL_DESCRIPTION_POSITIONS.DOWN,
  reverse = false,
  paddingT = 0,
  paddingB = 0,
  paddingL = 0,
  paddingR = 0
}) => {
  const icons = useIcons();
  const [startChat, { data: { startChatRoomWithAppUser: room } = {}, loading, error }] = useMutation(StartChatRoomWithAppUser);
  const { mobile } = useMediaQuery({ mobile: true });

  const drawerBars = useReactiveVar(vars.drawerBars);

  const navigateDrawer = useNavigateDrawer({
    forceAdd: true,
    drawerData: {
      drawerId: DRAWER_IDS.CHAT_DRAWER
    }
  });

  useEffect(() => {
    if (room) {
      if (drawerId) {
        const drawer = drawerBars.find(drawer => drawer.drawerId === drawerId);
        if (!drawer?.isPinned) {
          togglePinDrawerBar(drawerId);
        }
        if (drawer?.isExtended) {
          toggleExtendDrawerBar(drawerId);
        }
      }
      if (mobile) {
        clearDrawerBars();
      }
      setDrawerBar({ drawerId: DRAWER_IDS.CHAT_DRAWER, recordData: room?.id });
      navigateDrawer({ drawerLinkId: room?.id });
    }
  }, [room]);

  const handleClick = () => {
    if (profile) {
      startChat({ variables: { AppUserId: profile?.id } });
    }
  };

  const handleProfileClick = () => {
    if (profile) {
      if (mobile) {
        clearDrawerBars();
      }
      setDrawerBar({ drawerId: DRAWER_IDS.USER_DRAWER, recordData: profile?.id });
    }
  };

  return (
    <Profile paddingT={paddingT} paddingB={paddingB} paddingL={paddingL} paddingR={paddingR}>
      <DrawerLabelContainer>
        {showProfile && (
          <>
            <FlexRow>
              <ProfileButton showSmallDescription={false} onClick={handleProfileClick} reverse={reverse}>
                <ProfileSmallDescription positon={smallDescriptionPosition}>
                  <ProfilePhotoContainer>
                    {profile?.profile_pic && <ProfilePhoto bgImage={profile?.profile_pic} />}

                    {!profile?.profile_pic && <GatsbyImage image={icons.user.childImageSharp.gatsbyImageData} alt={'userImage'} />}

                    <DrawerSubLabel>{profile?.name}</DrawerSubLabel>
                    <DrawerSubLabel>{profile?.email}</DrawerSubLabel>
                  </ProfilePhotoContainer>
                </ProfileSmallDescription>
                {profile?.profile_pic && <ProfilePhoto bgImage={profile?.profile_pic} />}

                {!profile?.profile_pic && <DefaultProfilePhoto image={icons.userInDrawers.childImageSharp.gatsbyImageData} alt={'userImage'} />}
                <DrawerSubLabel weight={800} color="#212121" marginBottom={1} noWrap size={14}>
                  {profile?.name}
                </DrawerSubLabel>
              </ProfileButton>
            </FlexRow>
          </>
        )}
        {showChat && <ChatButton onClick={handleClick} bgImage={icons?.chat?.childImageSharp?.gatsbyImageData?.images.fallback.src} />}
      </DrawerLabelContainer>
    </Profile>
  );
};

export default ProfileActionBar;
