import Alert from '../../components/Alert/Alert';
import { Pet } from '../../components/Pets/types';
import { Slots } from '../../hooks/useCheckBranchAvailability';
import { calculateNights, formatDateDay, formatTime, SUN_THREE_DAYS, toReadableDate } from '../../utils/dates';
import { getBranchCurrencySymbol } from '../../utils/getBranchCurrencySymbol';
import { ORDER_STATUS } from '../Orders/types';
import { BranchAppointmentTag } from '../Store/BranchTags/types';
import { Booking, BOOKING_STATUS_TYPES, BOOKING_TYPE, BookingOrder } from './types';

export const getBookingTitles = (
  booking: BookingOrder,
  options: { noDays: boolean } = {
    noDays: false
  }
) => {
  const { noDays } = options;
  const pets = booking?.map(orders => orders?.PetRecord?.Pet);
  const uniquePetsByPetId = pets?.filter((pet, index, self) => self.findIndex(p => p?.id === pet?.id) === index);
  const petsNames = uniquePetsByPetId?.map(pet => pet?.name || 'DELETED PET').join(' & ');
  const petsProfilePics = uniquePetsByPetId?.map(pet => pet?.profile_pic);
  const petsBreeds = uniquePetsByPetId?.map(pet => pet?.Breed?.name);
  const orderItem = booking?.[0]?.OrderItem;
  const order = orderItem?.Order;
  const orderNumber = order?.number;
  const orderItemName = orderItem?.item?.name;
  const multiDayProduct = booking?.[0]?.OrderItem?.item?.booking_type === BOOKING_TYPE.MULTI_DAY;
  const day = formatDateDay(booking?.[0].timestamp, {
    noWeekDay: multiDayProduct,
    alwaysDate: noDays
  });
  const time = `at ${formatTime(booking?.[0].timestamp, {
    twentyFourHour: true
  })}`;
  const numberOfDays = `(${calculateNights(booking?.[0]?.timestamp_until, booking?.[0]?.timestamp) + 1} days)`;
  const dateTo = toReadableDate(booking?.[0]?.timestamp_until, { noTime: true });
  const totalPrice = Number(booking?.[0]?.OrderItem?.Order?.total);
  const isFree = totalPrice < 0.5;
  const priceString = isFree ? 'Free' : `${getBranchCurrencySymbol()}${totalPrice.toFixed(2)}`;
  const isCanceled = booking?.[0].status === BOOKING_STATUS_TYPES.CANCELED;
  const orderBillStatus = order?.OrderBills?.[0]?.status;
  const orderInvoiceStatus = order?.OrderBills?.[0]?.OrderInvoice?.Order?.status;
  const postpaidBilled = orderInvoiceStatus === ORDER_STATUS.PAYMENT_SUCCEEDED;
  const hasOrderBills = !!order?.OrderBills?.length;
  const hasInvoice = !!order?.OrderBills?.[0]?.OrderInvoice?.status;
  const isUnbilled = hasOrderBills && !hasInvoice;
  const billingStatus = isUnbilled ? 'UNBILLED' : order?.OrderBills?.[0]?.OrderInvoice?.status;
  const isBilled = order?.status === ORDER_STATUS?.PAYMENT_SUCCEEDED || postpaidBilled || !!order?.billed_on;
  const billedOnDate = order?.OrderBills?.[0]?.billed_on ? toReadableDate(order?.OrderBills?.[0]?.billed_on, { noTime: true, isLocale: true }) : null;
  const isPrepaidMarkedAsPaid = !orderBillStatus && !isFree && order?.status === ORDER_STATUS.CONFIRMED && order?.payment?.required === false;

  return {
    petsNames,
    petsProfilePics,
    petsBreeds,
    orderNumber,
    orderItemName,
    day,
    time,
    numberOfDays,
    dateTo,
    priceString,
    multiDayProduct,
    totalPrice,
    isFree,
    isCanceled,
    isBilled,
    isPrepaidMarkedAsPaid,
    isUnbilled,
    billingStatus,
    orderBillStatus,
    orderInvoiceStatus,
    billedOnDate
  };
};

export const getPetsWithTags = (
  pets: Pet[],
  options?: {
    tagsToInclude?: string[];
  }
) => {
  const { tagsToInclude } = options || {};
  const uniquePetsByPetId = pets.filter((pet, index, self) => self.findIndex(p => p?.id === pet?.id) === index);

  const sortedPets = uniquePetsByPetId?.sort((a, b) => a?.name?.localeCompare(b?.name || ''));
  const allPetsTags = sortedPets.map(pet => pet?.PetRecord?.BranchPetRecordTags).flat();
  const filteredAllPetsTags = tagsToInclude ? allPetsTags.filter(tag => tagsToInclude.includes(tag?.id)) : allPetsTags;
  const allPetsTagsUniqueByTagId = filteredAllPetsTags.filter((tag, index, self) => self.findIndex(t => t?.id === tag?.id) === index);
  const petTagsWithCount = allPetsTagsUniqueByTagId.map(tag => {
    const tagCount = filteredAllPetsTags.filter(t => t?.id === tag?.id).length;
    return { tag, tagCount };
  });

  const countOfEachPet = uniquePetsByPetId.map(pet => {
    const petCount = pets.filter(p => p?.id === pet?.id).length;
    return { pet, petCount };
  });

  const splitPetsByTags = allPetsTagsUniqueByTagId.map(tag => {
    const petsWithThisTag = sortedPets.filter(pet => pet?.PetRecord?.BranchPetRecordTags?.find(t => t?.id === tag?.id));
    const countOfPetsWithThisTag = petsWithThisTag.length;

    return { tag, petsWithThisTag, countOfPetsWithThisTag };
  });

  const untaggedPets = sortedPets.filter(pet => !pet?.PetRecord?.BranchPetRecordTags?.length);
  const countOfUntaggedPets = untaggedPets.length;

  return { petTagsWithCount, allPetsTagsUniqueByTagId, sortedPets, countOfEachPet, splitPetsByTags, untaggedPets, countOfUntaggedPets };
};

const checkBookingAvailability = (booking: any) => {
  const isAvailable = booking?.slots?.some?.(slot => slot.available > 0);
  return isAvailable;
};

export const getBookingDates = (slots: Slots, options?: { removeDaysWithoutSlots?: boolean }) => {
  const { removeDaysWithoutSlots } = options || {};
  const bookingDatesWithSlots =
    slots?.availableSlots?.map((date, index) => {
      const fullDate = new Date(date[0]?.timestamp);
      const fullDateDate = fullDate.getUTCDate();
      const day = SUN_THREE_DAYS[fullDate.getUTCDay()];
      const bookingSlots = slots?.availableSlots?.[index];
      const hasSlots = checkBookingAvailability({ slots: bookingSlots });

      if (removeDaysWithoutSlots && !hasSlots) {
        return;
      }

      return {
        ...date,
        fullDate,
        date: fullDateDate,
        day,
        slots: bookingSlots
      };
    }) || [];

  return bookingDatesWithSlots.filter(Boolean);
};

export const getAvailableSlots = (date: ReturnType<typeof getBookingDates>[0]) => {
  const availableSlots = date?.slots?.filter(slot => slot.available > 0) || [];
  return availableSlots;
};

const checkIfTagAtCapacity = (tag: BranchAppointmentTag, appointments: Booking[]) => {
  const capcity = Number(tag.capacity);
  const newTagCount = Number(tag.appointmentsCount) + appointments?.length;
  return capcity !== 0 && capcity < newTagCount;
};

export const checkAddAppointmentTagCapacity = ({
  tags,
  handleAddTag,
  appointments
}: {
  tags: BranchAppointmentTag[];
  handleAddTag: (tags: BranchAppointmentTag[]) => void;
  appointments: Booking[];
}) => {
  if (tags.every(tag => !checkIfTagAtCapacity(tag, appointments))) {
    handleAddTag(tags);
    return;
  }

  const description =
    tags?.length > 1
      ? 'One or more of the tags you selected is at capacity. Are you sure you want to add this booking?'
      : `${tags[0].name} is at capacity of ${tags[0].capacity}. Are you sure you want to add this booking?`;

  Alert.alert({
    title: 'Add Tag',
    description: description,
    acceptButtonText: 'Yes',
    denyButtonText: 'No',
    onAccept: () => handleAddTag(tags)
  });
};
