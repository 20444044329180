import React, { forwardRef } from 'react';
import OptionDropdown, { OptionDropDownItem } from '../../../components/Shared/Menus/OptionDropdown/OptionDropdown';
import { OPTION_DROPDOWN_MENU_BUTTON_TYPES, OPTION_DROPDOWN_MENU_POSITIONS, OPTION_DROPDOWN_TYPES } from '../../../components/Shared/Menus/OptionDropdown/types';
import useAppUserOptions from '../../../hooks/useAppUserOptions';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { RequireAtLeastOne } from '../../../utils/helpers';
import { BranchAppUser } from '../../Store/BranchBilling/types';
import { BranchAppUserTag } from '../../Store/BranchTags/types';
import { HoverRecordOptionsContainer } from '../../styled';

type AppUserRecordOptionsProps = {
  appUserQuickTags?: BranchAppUserTag[];
  appUsers: BranchAppUser[];
  showAlways?: boolean;
  loadTags?: boolean;
  afterTag?: (newAppUsers: BranchAppUser[]) => void;
  right?: boolean;
};

type Props = RequireAtLeastOne<AppUserRecordOptionsProps, ('petQuickTags' & 'bookingQuickTags' & 'appUserQuickTags') | 'loadTags'>;

const AppUserRecordOptions = forwardRef<HTMLDivElement, Props>(({ appUserQuickTags, showAlways = false, loadTags = false, right = false, appUsers, afterTag }, optionsRef) => {
  const { items: appUserItems, loading: appUserItemsLoading } = useAppUserOptions({ appUsers, afterTag, loadTags, quickTags: appUserQuickTags });

  const items = [...appUserItems].filter(Boolean) as OptionDropDownItem[];

  const loading = appUserItemsLoading;
  const { mobile } = useMediaQuery({ mobile: true });
  return (
    <HoverRecordOptionsContainer ref={optionsRef} show={loading || showAlways}>
      <OptionDropdown
        menuButtonType={OPTION_DROPDOWN_MENU_BUTTON_TYPES.MORE}
        noApplyButton
        containerRelative
        buttonLoading={loading}
        options={[
          {
            optionType: OPTION_DROPDOWN_TYPES.BUTTONS,
            id: 'appUserOptions',
            items
          }
        ]}
        menuPosition={right ? OPTION_DROPDOWN_MENU_POSITIONS.RIGHT : OPTION_DROPDOWN_MENU_POSITIONS.LEFT}
      />
    </HoverRecordOptionsContainer>
  );
});

export default AppUserRecordOptions;
