import { GatsbyImage } from 'gatsby-plugin-image';
import styled, { css } from 'styled-components';
import Colors from '../../Colors';
import { Shake } from '../Shared/Animations';
import { isSafariBrowser } from '../Shared/Shared';

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const DrawerSection = styled.div<{
  isScrollable?: boolean;
  isFixed?: boolean;
  maxHeight?: string;
  paddingT?: number;
  paddingB?: number;
  paddingL?: number;
  paddingR?: number;
  paddingY?: number;
  flexRow?: boolean;
  center?: boolean;
  zIndex?: number;
  flexEnd?: boolean;
  mobilePaddingT?: number;
}>`
  ${props =>
    props.paddingY
      ? css`
          padding-top: ${props.paddingY}px;
          padding-bottom: ${props.paddingY}px;
        `
      : css`
          padding-top: ${props.paddingT ? props.paddingT : '16'}px;
          padding-bottom: ${props.paddingB ? props.paddingB : '0'}px;
        `}
  padding-left: ${props => (props.paddingL ? props.paddingL : 20)}px;
  padding-right: ${props => (props.paddingR ? props.paddingR : 20)}px;
  display: block;
  align-items: center;
  ${props =>
    props.zIndex &&
    css`
      z-index: ${props.zIndex};
    `}
  /* overflow: ${props => (props.isFixed ? 'auto' : props.isScrollable ? 'scroll' : 'hidden')}; */
  ${props =>
    props.maxHeight &&
    css`
      max-height: ${props.maxHeight};
    `}

  ${props =>
    props.flexRow &&
    css`
      display: flex;
      flex-direction: row;
    `}

  ${props =>
    props.center &&
    css`
      align-items: center;
    `}

  justify-content: space-between;

  ${props =>
    props.flexEnd &&
    css`
      justify-content: flex-end;
    `}

  @media (max-width: 768px) {
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
    padding-left: 16px;
    padding-right: 16px;
    padding-top: ${props => (props.mobilePaddingT ? props.mobilePaddingT : 18)}px;
  }
  background-color: ${Colors.backgroundGrey};
`;

export const DrawerFooter = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const CallToActionContainer = styled.div`
  width: 100%;
  padding: 12px 20px 20px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  background-color: ${Colors.backgroundGrey};
`;
export const CallToAction = styled.button<{ secondary?: boolean; danger?: boolean; error?: boolean }>`
  font-size: 14px;
  font-weight: 700;
  padding: 10px 48px;
  width: 100%;
  ${props =>
    props.danger &&
    css`
      padding: 8px 48px;
    `};
  border: ${props => (props.danger ? `2px solid ${Colors.orange}` : 'none')};
  border-radius: 25px;
  background: ${props => (props.secondary ? (props.danger ? 'transparent' : '#e7e7e7') : '#3F3F3F')};
  color: ${props => (props.danger ? Colors.orange : props.secondary ? Colors.black : Colors.white)};
  &:focus {
    outline: none;
  }
  &:hover {
    cursor: pointer;
    filter: brightness(95%);
  }
  ${props =>
    props.error &&
    css`
      animation: ${Shake} 0.4s ease;
    `}
`;

export const DrawerDetailes = styled.div``;
export const PriceDetailsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
`;
export const DrawerLabelContainer = styled.div<{ marginY?: number }>`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  ${props =>
    props.marginY &&
    css`
      margin-top: ${props.marginY}px;
      margin-bottom: ${props.marginY}px;
    `}
`;
export const AdjustAmountContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;
export const DrawerLabel = styled.span<{ fontWeight?: number; size?: number; width?: number }>`
  display: block;
  color: #212121;
  font-weight: ${props => (props.fontWeight ? props.fontWeight : 800)};
  font-size: ${props => (props.size ? props.size : 16)}px;
  width: ${props => (props.width ? props.width : 'auto')}px;
  margin-top: 5px;
  margin-bottom: 5px;
  white-space: nowrap;
`;
export const BookedByForContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${Colors.backgroundGrey};
  padding: 20px 20px 0 20px;
`;
export const DrawerSubLabel = styled.div<{
  weight?: number;
  size?: number;
  color?: string;
  marginBottom?: number;
  marginTop?: number;
  noWrap?: boolean;
  paddingT?: number;
  justifyContent?: string;
  marginRAuto?: boolean;
  gap?: number;
  width?: string;
}>`
  display: flex;
  gap: ${props => (props.gap ? props.gap : 0)}px;
  align-items: center;
  justify-content: ${props => (props.justifyContent ? props.justifyContent : 'space-between')};
  color: ${props => (props.color ? props.color : Colors.warmGrey)};
  font-weight: ${props => (props.weight ? props.weight : 600)};
  font-size: ${props => (props.size ? props.size : 16)}px;
  margin-bottom: ${props => (props.marginBottom ? (props.marginBottom >= 0 ? props.marginBottom : 12) : 'auto')}px;
  margin-top: ${props => (props.marginTop ? (props.marginTop >= 0 ? props.marginTop : 12) : 0)}px;
  margin-right: ${props => props.marginRAuto && 'auto'};
  padding-top: ${props => (props.paddingT ? props.paddingT : 0)}px;

  ${props =>
    props.noWrap &&
    css`
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    `};
`;
export const CopyButton = styled.span`
  border: none;
  border-radius: 8px;
  &:hover {
    cursor: pointer;
  }
`;
export const DrawerPetSubLabel = styled.span<{ weight?: number; size?: number; color?: string; marginBottom?: number; marginTop?: number; contentWidth?: number }>`
  color: ${props => (props.color ? props.color : Colors.warmGrey)};
  font-weight: ${props => (props.weight ? props.weight : 600)};
  font-size: ${props => (props.size ? props.size : 16)}px;
  margin-bottom: ${props => (props.marginBottom ? (props.marginBottom >= 0 ? props.marginBottom : 12) : 0)}px;
  margin-top: ${props => (props.marginTop ? (props.marginTop >= 0 ? props.marginTop : 12) : 0)}px;
  width: ${props => (props.contentWidth ? `${props.contentWidth}px` : 'auto')};
  max-width: ${props => (props.contentWidth ? `${props.contentWidth}px` : 'auto')};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  min-width: 0;

  @media (max-width: 768px) {
    width: auto;
    max-width: unset;
  }
`;
export const PaymentLabel = styled.span<{ danger?: boolean; grey?: boolean }>`
  color: ${props => (props.danger ? Colors.orange : props.grey ? Colors.black : Colors.primary)};
  font-weight: 800;
  font-size: 14px;
  padding: 10px 0;
  background-color: ${props => (props.danger ? Colors.transparencyOrange : props.grey ? 'rgba(164, 164, 164, 0.25)' : Colors.transparencyPrimary)};
  border-radius: 8px;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 20px;
  width: 100%;
  gap: 4px;
  position: relative;
`;
export const FailedPaymentReason = styled.span`
  background-color: #ff7a00;
  border-radius: 14px;
  padding: 3px 10px;
  color: ${Colors.white};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;
export const FailReason = styled.span`
  position: absolute;
  bottom: 140px;
  right: 80px;
  background-color: #ff7a00;
  border-radius: 14px;
  color: ${Colors.white};
  padding: 10px 10px;
`;

export const Divider = styled.div<{ marginBottom?: number; marginTop?: number }>`
  min-height: 1px;
  max-height: 1px;
  height: 1px;
  background-color: ${Colors.divider};
  ${props =>
    props.marginBottom &&
    css`
      margin-bottom: ${props.marginBottom}px;
    `}
  ${props =>
    props.marginTop &&
    css`
      margin-top: ${props.marginTop}px;
    `}
`;
// export const DrawerAnimationGroupContainer = styled.div<{ windowHeight: number }>`
//   flex: 1;
//   display: flex;
//   height: ${props => props.windowHeight}px;
//   animation: ${slideIn} 0.4s ease-out forwards;
//   width: 100%;
//   @media (max-width: 768px) {
//     height: 100%;
//   }
// `;
export const DrawerContainer = styled.div<{ scrollable?: boolean; row?: boolean; paddingB?: number; flexZero?: boolean; drawerWidths?: { normal: number; small: number } }>`
  position: relative;
  max-width: ${props => props.drawerWidths?.normal || 350}px;
  min-width: ${props => props.drawerWidths?.normal || 350}px;
  background-color: ${Colors.white};
  box-shadow: 0px 5px 33px -10px #ccc;
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  max-height: 100%;
  ${props =>
    props.scrollable &&
    css`
      overflow-y: scroll;
    `}

  @media (max-width: 1200px) {
    max-width: ${props => props.drawerWidths?.small || 330}px;
    min-width: ${props => props.drawerWidths?.small || 330}px;
  }

  @media (max-width: 768px) {
    width: 100%;
    max-width: unset;
    flex: 1;
    border-radius: 0;
  }

  ${props =>
    props.flexZero &&
    css`
      flex: 0;
      @media (max-width: 768px) {
        flex: 0;
      }
    `}

  ${props =>
    props.row &&
    css`
      flex-direction: row;
    `}

  padding-bottom: ${props => (props.paddingB ? props.paddingB : 0)}px;

  border-radius: 20px;
`;

export const LogoImage = styled(GatsbyImage)`
  height: 73px;
  width: 74px;
`;
export const ChatItem = styled.div<{ selected: boolean }>`
  padding: 29px 32px;
  font-weight: bold;
  font-size: 16px;
  color: ${props => (props.selected ? Colors.primary : Colors.secondaryGrey)};
  &:hover {
    color: ${Colors.primary};
    cursor: pointer;
    transition: 0.1s ease-in all;
  }
`;
export const Name = styled.span`
  display: inline;
`;
export const Icon = styled.img`
  height: 21px;
  width: 21px;
  padding-right: 11px;
`;

export const DrawerList = styled.ul<{ padding?: boolean }>`
  padding: ${props => (props.padding ? '0 0 20px 0' : '0')};
  margin: 0;
  list-style-type: none;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const ProfileListItemsContainer = styled.div<{ noMargin?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 20px;

  ${props =>
    props.noMargin &&
    css`
      margin-bottom: 0px;
    `}
`;

export const Profile = styled.li<{ paddingT: number; paddingB: number; paddingL: number; paddingR: number }>`
  display: flex;
  align-items: center;
  padding-top: ${props => (props.paddingT ? props.paddingT : 0)}px;
  padding-bottom: ${props => (props.paddingB ? props.paddingB : 0)}px;
  padding-left: ${props => (props.paddingL ? props.paddingL : 0)}px;
  padding-right: ${props => (props.paddingR ? props.paddingR : 0)}px;
`;
type ImageInput = { bgImage: string };
export const ProfilePhoto = styled.div<{ ImageInput: string; isAbsolute?: boolean; index?: number }>`
  height: 32px;
  width: 32px;
  background: url(${props => props.bgImage});
  background-size: 100% 100%;
  border-radius: 100%;
  margin-right: 11px;
  position: ${props => (props.isAbsolute ? 'absolute' : '')};
  left: ${props => (props?.index ? props?.index * 25 : 0)}px;
  z-index: ${props => (props?.index ? props.index * 1 : 0)};

  border: ${props => (props?.index && props?.index !== 0 ? '2px solid #F4F4F4' : 'none')};
`;

export const DefaultProfilePhoto = styled(GatsbyImage)<{ isAbsolute?: boolean; index?: number }>`
  height: 32px;
  width: 32px;
  border-radius: 100%;
  margin-right: 11px;
  position: ${props => (props.isAbsolute ? 'absolute' : '')};
  left: ${props => (props?.index ? props?.index * 15 : 0)}px;
  z-index: ${props => (props?.index ? props.index * 1 : 0)};
  border: ${props => (props?.index && props?.index !== 0 ? '2px solid #F4F4F4' : 'none')};
`;

export const ProfileNameContainer = styled.div`
  display: flex;
  gap: 11px;
  overflow: hidden;
  max-width: 100%;
`;

export const ChatButton = styled.button<ImageInput>`
  height: 30px;
  width: 30px;
  border: none;
  border-radius: 19px;
  background: url(${props => props.bgImage}) right no-repeat;
  background-size: 80% 80%;
  &:focus {
    outline: none;
  }
  &:hover {
    cursor: pointer;
  }
`;
export const DrawerContent = styled.div<{ bgColor?: string; justifyFlexStart?: boolean; padding?: string }>`
  display: flex;
  flex-direction: column;
  background-color: ${props => (props.bgColor ? props.bgColor : Colors.white)};
  flex: 1;
  overflow-y: scroll;
  overflow-x: hidden;
  justify-content: ${props => (props.justifyFlexStart ? 'flex-start' : 'space-between')};
  padding: ${props => (props.padding ? props.padding : '12px 20px;')};
  position: relative;
  ::-webkit-scrollbar {
    display: none;
  }
`;

export const PetDrawerContent = styled(DrawerContent)`
  padding: 0;
  @media (max-width: 768px) {
    padding: 0;
  }
`;

export const DrawerBarContainer = styled.div<{ drawerCount: number; isExtended: boolean; windowHeight: number; drawerWidths?: { normal: number; small: number } }>`
  display: flex;
  position: absolute;
  top: 12px;
  bottom: 12px;
  right: 20px;
  border-radius: 20px;
  z-index: 100;
  zoom: 98%;
  ${isSafariBrowser ? '' : 'overflow: hidden;'}

  box-shadow: 0px 5px 33px -10px #ccc;
  transition: width 0.25s cubic-bezier(0, 0.72, 0.2, 0.94);
  --sideBarWidth: 90px;
  --drawerWidth: ${props => props.drawerWidths?.normal || 350}px;

  width: 0px;

  ${props =>
    !!props.drawerCount &&
    !props.isExtended &&
    css`
      width: calc(var(--drawerWidth) * ${props.drawerCount});
    `}

  ${props =>
    props.isExtended &&
    css`
      width: calc(100vw - var(--sideBarWidth));
    `}


  @media (max-width: 1200px) {
    --drawerWidth: ${props => props.drawerWidths?.small || 330}px;
  }

  @media (max-width: 768px) {
    ${props =>
      !!props.drawerCount &&
      css`
        --drawerWidth: 0;
        --sideBarWidth: 0;
        width: 100vw;
        overflow: unset;
        height: calc(${props.windowHeight}px - 70px);
        max-height: calc(${props.windowHeight}px - 70px);
      `}
    overflow-x: hidden;
    top: unset;
    bottom: unset;
    right: unset;
    left: unset;
    zoom: 100%;
    border-radius: 0;
  }
`;
export const DrawerActionButtonContainer = styled.div`
  position: absolute;
  top: 15px;
  right: calc(100% - 350px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 12;

  @media (max-width: 1200px) {
    right: calc(100% - 310px);
  }

  @media (max-width: 768px) {
    left: 15px;
    top: 26px;
  }
`;
export const DrawerActionButton = styled.button<{ isToggled?: boolean; bgImage?: string }>`
  border-radius: 15px;
  border: none;
  object-fit: cover;
  height: 20px;
  width: 20px;
  /* margin-left: 8px; */
  margin-right: 8px;
  background-image: url(${props => props.bgImage});
  ${props =>
    !props.isToggled &&
    css`
      filter: grayscale(1);
      transform: rotate(180deg);
    `};
  &:hover {
    cursor: pointer;
  }
  &:focus {
    outline: none;
  }
  @media (max-width: 768px) {
    height: 30px;
    width: 30px;
  }
`;

export const BackButton = styled.button`
  border: none;
  background: none;
  font-size: 18px;
  font-weight: bold;
  &:focus {
    outline: none;
  }
  &:hover {
    cursor: pointer;
  }
  display: flex;
  align-items: center;
`;

export const BackButtonText = styled.span`
  font-weight: 800;
`;

export const CurrentDrawerContainer = styled.div<{ drawerCount: number; isExtended: boolean; isFloating?: boolean; drawerWidths?: { normal: number; small: number } }>`
  border-radius: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: ${Colors.white};
  scroll-snap-align: start;
  ${props =>
    props.isFloating &&
    css`
      position: fixed;
      top: 0;
      right: 0;
      height: 100vh;
      width: ${props.drawerWidths?.normal ? `${props.drawerWidths?.normal}px` : 'var(--drawerWidth)'};
      max-width: ${props.drawerWidths?.normal ? `${props.drawerWidths?.normal}px` : 'var(--drawerWidth)'};
      min-width: ${props.drawerWidths?.normal ? `${props.drawerWidths?.normal}px` : 'var(--drawerWidth)'};
    `}
  --drawersWidth: calc(${props => props.drawerCount} * var(--drawerWidth));
  --extensionWidth: calc(100vw - var(--sideBarWidth) - var(--drawersWidth));
  --extendedDrawerWidth: calc(var(--extensionWidth) + var(--drawerWidth));
  ${props =>
    props.isExtended &&
    css`
      max-width: var(--extendedDrawerWidth);
      width: var(--extendedDrawerWidth);
      min-width: var(--extendedDrawerWidth);
    `}
  ${props =>
    !props.isExtended &&
    css`
      flex: 1;
    `}
    z-index: 11;

  @media (max-width: 768px) {
    border-radius: 0;
  }
`;

export const DrawerBackground = styled.div<{ isExtended: boolean; hide?: boolean }>`
  position: fixed;
  z-index: 11;
  left: 0;
  top: 0;
  overflow: auto;
  background-color: ${props => (!props.isExtended ? 'rgba(0, 0, 0, 0.3)' : 'transparent')};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;

  ${props =>
    props.hide &&
    css`
      opacity: 0;
    `}
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 1000;
`;
