import React from 'react';
import { DrawerLabel } from '../../components/DrawerBar/styled';
import { RedDot } from '../../components/Shared/Shared';
import { Divider } from '../../components/Shared/SideTabs/styles';
import useIcons from '../../hooks/useIcons';
import useMediaQuery from '../../hooks/useMediaQuery';

import { AppUserImage, AppUserImageContainer, AppUserImageNameContainer, AppUserName, RecordBody } from '../styled';
import { OrderDataContainer } from './styled';
import { getOrderTitles } from './utils';
import { Booking } from '../Bookings/components/BookingsBody/styled';

type Props = { order: any; navigateDrawer: ({ drawerLinkId }: { drawerLinkId: string }) => void; totalAtTheEnd?: boolean };

const OrderRecord = ({ order, navigateDrawer, totalAtTheEnd }: Props) => {
  const icons = useIcons();

  const handleClick = () => {
    navigateDrawer({ drawerLinkId: order.id });
  };

  const { mobile } = useMediaQuery({ mobile: true });

  const { orderNumber, numberOfProducts, total, time, appUserName, user, isNew } = getOrderTitles(order);

  const recordData = (
    <>
      {!mobile && (
        <RecordBody flex="1 1 0%" width="200px" maxWidth={200}>
          {orderNumber}
        </RecordBody>
      )}
      {!mobile && (
        <RecordBody flex="1 1 0%" width="100px" maxWidth={100} fontWeight="800">
          {numberOfProducts} {numberOfProducts > 1 ? 'Items' : 'Item'}
        </RecordBody>
      )}
      {!totalAtTheEnd && (
        <RecordBody flex="1 1 0%" width="100px" maxWidth={100} fontWeight="800">
          {total}
        </RecordBody>
      )}
      <DrawerLabel size={14} fontWeight={600}>
        {time}
      </DrawerLabel>
      {totalAtTheEnd && <RecordBody flexEnd>{total}</RecordBody>}
    </>
  );

  return (
    <>
      <Booking onClick={handleClick} gap={16}>
        <AppUserImageNameContainer>
          {isNew && <RedDot />}
          <AppUserImageContainer>
            <AppUserImage src={user?.profile_pic || icons.user.childImageSharp.gatsbyImageData.images.fallback.src} index={0} />
          </AppUserImageContainer>
          {!mobile && (
            <RecordBody fontWeight="800" width="120">
              {appUserName}
            </RecordBody>
          )}
        </AppUserImageNameContainer>

        {mobile ? <OrderDataContainer>{recordData}</OrderDataContainer> : recordData}
      </Booking>
      <Divider />
    </>
  );
};

export default OrderRecord;
