import { useQuery, useReactiveVar } from '@apollo/client';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import CalendarBookings from '../../components/Calendar/CalendarBookings';
import CalendarHeader from '../../components/Calendar/CalendarHeader';
import { BigCalendarContainer, BookingMessageButton, BookingMessageContainer, BookingMessageText, BookingMessageWrapper, DaysContainer, ExtensionFullBody } from '../../components/Calendar/styled';
import { CALENDAR_MESSAGES, CALENDAR_VIEWS, CALENDAR_VIEWS_NAMES, CALENDAR_VIEWS_VALUES, CALENDAR_VIEW_LENGTH } from '../../components/Calendar/types';
import useCalendarNavigation from '../../components/Calendar/useCalendarNavigation';
import { BusUserProfile } from '../../components/Profile/types';

import BigCalendar from '../../components/Calendar/BigCalendar';
import { CalendarRangeRef } from '../../components/Calendar/CalendarRange';
import { MainContainer, ViewWrapper } from '../../components/Shared/Shared';
import SideTab, { SIDE_TABS_TYPES } from '../../components/Shared/SideTabs/SideTabs';
import useIcons from '../../hooks/useIcons';
import useMediaQuery from '../../hooks/useMediaQuery';
import { GetBusUserProfile } from '../../queries';
import { vars } from '../../reactive';
import { hideCalendarActionMessage } from '../../reactive/actions';
import { isToday } from '../../utils/dates';

const Calendar = () => {
  const icons = useIcons();
  const showCalendarMessage = useReactiveVar(vars.calendarMessage);
  const { mobile } = useMediaQuery({ mobile: true });
  const selectedBookingType = useReactiveVar(vars.calendarSelectedBookingType) || CALENDAR_VIEWS.MY_SCHEDULE;
  const selectedBookingTypeState = [selectedBookingType, vars.calendarSelectedBookingType] as [CALENDAR_VIEWS, (value: CALENDAR_VIEWS) => void];
  const calendarHeaderRef = useRef<{ refetchAvailability: () => void }>(null);
  const multiStaffBookingType = selectedBookingType === CALENDAR_VIEWS.MULTI_STAFF;

  const defaultCalendarLength = useMemo(() => {
    const length = CALENDAR_VIEW_LENGTH[selectedBookingType];
    if (selectedBookingType === CALENDAR_VIEWS.MULTI_STAFF || !mobile) {
      return length;
    }
    return 1;
  }, [selectedBookingType, mobile]);
  const calendarLength = useReactiveVar(vars.calendarLength);

  const currentCalendarLength = calendarLength || defaultCalendarLength;
  const [selectedUser, setSelectedUser] = useState<string>('');

  const currentCalendarLengthState = [currentCalendarLength, vars.calendarLength] as [number, React.Dispatch<React.SetStateAction<number>>];

  const { data: { getBusUserProfile: busUserProfile } = {}, refetch: refetchProfile } = useQuery<{ getBusUserProfile: BusUserProfile }>(GetBusUserProfile, {
    fetchPolicy: 'cache-and-network'
  });

  const profile = (busUserProfile || {}) as BusUserProfile;

  const BusUsers = profile?.Branch?.BusUsers || [];

  const calendarNavigation = useCalendarNavigation({
    step: currentCalendarLength,
    useDefaultPage: !multiStaffBookingType && !mobile
  });

  const [firstDayOfWeek] = calendarNavigation;

  useEffect(() => {
    return () => {
      hideCalendarActionMessage();
      vars.selectedDate(null);
    };
  }, []);

  useEffect(() => {
    if (!calendarLength) {
      vars.calendarLength(defaultCalendarLength);
    }
  }, [calendarLength, defaultCalendarLength]);

  const refetchAvailability = () => {
    if (calendarHeaderRef.current) {
      calendarHeaderRef.current.refetchAvailability();
    }
  };

  const [_nextView, _prevView, decrementDate, resetView] = calendarNavigation;
  const [bookingType, setSelectedBookingType] = selectedBookingTypeState;
  const [CalendarLength, setCurrentCalendarLength] = currentCalendarLengthState;
  const calendarRangeRef = useRef<CalendarRangeRef>(null);
  const tabs = [
    {
      name: CALENDAR_VIEWS_NAMES.ALL,
      value: CALENDAR_VIEWS_VALUES.ALL,
      onClick: () => {
        setSelectedBookingType(CALENDAR_VIEWS.ALL);
        setCurrentCalendarLength(mobile ? 1 : CALENDAR_VIEW_LENGTH[CALENDAR_VIEWS.ALL]);
        calendarRangeRef.current?.clearRange();
        resetView({ goToToday: true });
      },
      divider: true
    },
    // {
    //   name: CALENDAR_VIEWS_NAMES.MY_SCHEDULE,
    //   value: CALENDAR_VIEWS_VALUES.MY_SCHEDULE,
    //   onClick: () => {
    //     setSelectedBookingType(CALENDAR_VIEWS.MY_SCHEDULE);
    //     setCurrentCalendarLength(mobile ? 1 : CALENDAR_VIEW_LENGTH[CALENDAR_VIEWS.MY_SCHEDULE]);
    //     calendarRangeRef.current?.clearRange();
    //     resetView({ goToToday: true });
    //   }
    // },
    {
      name: CALENDAR_VIEWS_NAMES.MULTI_DAY,
      value: CALENDAR_VIEWS_VALUES.MULTI_DAY,
      onClick: () => {
        setSelectedBookingType(CALENDAR_VIEWS.MULTI_DAY);
        setCurrentCalendarLength(mobile ? 1 : CALENDAR_VIEW_LENGTH[CALENDAR_VIEWS.MULTI_DAY]);
        calendarRangeRef.current?.clearRange();
        resetView({ goToToday: true });
      }
    },
    {
      name: CALENDAR_VIEWS_NAMES.MULTI_STAFF,
      value: CALENDAR_VIEWS_VALUES.MULTI_STAFF,
      onClick: () => {
        setSelectedBookingType(CALENDAR_VIEWS.MULTI_STAFF);
        setCurrentCalendarLength(mobile ? 1 : CALENDAR_VIEW_LENGTH[CALENDAR_VIEWS.MULTI_STAFF]);
        calendarRangeRef.current?.clearRange();
        resetView({ goToToday: true });
      }
    }
  ];
  const sections = [{ tabs }];
  const isMultiDay = selectedBookingType === CALENDAR_VIEWS.MULTI_DAY;
  const isStaff = selectedBookingType === CALENDAR_VIEWS.MULTI_STAFF;
  return (
    <ViewWrapper>
      {!mobile && <SideTab sections={sections} defaultTab={CALENDAR_VIEWS_VALUES[selectedBookingType] || CALENDAR_VIEWS_VALUES.ALL} type={SIDE_TABS_TYPES.STATE_BUTTONS} />}
      <MainContainer>
        <BigCalendarContainer display={isMultiDay && 'flex'} isStaff={isStaff} selectedUser={selectedUser}>
          <BigCalendar selectedUser={selectedUser} setSelectedUser={setSelectedUser} />
        </BigCalendarContainer>
        {false && (
          <>
            <BookingMessageContainer active={showCalendarMessage}>
              <BookingMessageWrapper closeButton={true} active={showCalendarMessage}>
                <BookingMessageText>{CALENDAR_MESSAGES.SELECT}</BookingMessageText>
                <BookingMessageButton
                  onClick={() => {
                    hideCalendarActionMessage();
                  }}
                  bgImage={icons.closeTransparent.childImageSharp.gatsbyImageData.images.fallback.src}
                />
              </BookingMessageWrapper>
            </BookingMessageContainer>

            <CalendarHeader
              BusUsers={BusUsers}
              selectedBookingTypeState={selectedBookingTypeState}
              calendarNavigation={calendarNavigation}
              profile={profile}
              currentCalendarLengthState={currentCalendarLengthState}
              defaultCalendarLength={defaultCalendarLength}
              ref={calendarHeaderRef}
            />

            <ExtensionFullBody fullHeight={multiStaffBookingType}>
              <DaysContainer>
                <CalendarBookings
                  BusUsers={BusUsers}
                  selectedBookingType={selectedBookingType}
                  currentCalendarLength={currentCalendarLength}
                  firstDayOfWeek={firstDayOfWeek}
                  profile={profile}
                  refetchProfile={refetchProfile}
                  refetchAvailability={refetchAvailability}
                />
              </DaysContainer>
            </ExtensionFullBody>
          </>
        )}
      </MainContainer>
    </ViewWrapper>
  );
};

export default Calendar;
