import React from 'react';
import ProductsBody from './ProductsBody';
import { useProductsFilters } from './ProductsFilters';
import ProductsHeader from './ProductsHeader';

const Products = () => {
  const filtersControl = useProductsFilters();

  const { selectedFilters } = filtersControl;

  return (
    <>
      <ProductsHeader filtersControl={filtersControl} />
      <ProductsBody selectedFilters={selectedFilters} />
    </>
  );
};

export default Products;
