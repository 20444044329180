import React from 'react';
import { DRAWER_IDS } from '../components/DrawerBar/types';
import ModalDialog from '../components/Modal/ModalDialog';
import { vars } from '../reactive';
import AdjustAppUserOrdersPaymentModal from '../views/Bookings/components/BookingsDrawer/AdjustAppUserOrdersPaymentModal';
import BookingAssignModal from '../views/Bookings/components/BookingsDrawer/BookingAssignModal';
import BookingDurationModal from '../views/Bookings/components/BookingsDrawer/BookingDurationModal';
import BookingOrderBillBilledOnModal from '../views/Bookings/components/BookingsDrawer/BookingOrderBillBilledOnModal';
import BookingRescheduleModal from '../views/Bookings/components/BookingsDrawer/BookingRescheduleModal';
import GroupMessageModal from '../views/Bookings/components/BookingsDrawer/GroupMessageModal';
import { BookingOrder } from '../views/Bookings/types';
import useBookingActions, { useGroupBookingsActions } from './useBookingActions';
import useBookingTagsOptions from './useBookingTagsOptions';
import usePetRecordOptions from './usePetRecordOptions';
import BookingAssignToBranchRoomModal from '../views/Bookings/components/BookingsDrawer/BookingAssignToBranchRoom';

const defaultGroupOptionsToShow = {
  shouldDisplayGroupAssign: true,
  shouldDisplayReschedule: true,
  shouldDisplayGroupMessage: true,
  shouldDisplayGroupCancel: false,
  shouldDisplayGroupConfirmationAndRejection: false,
  shouldDisplayGroupTagsOptions: true,
  shouldDisplayGroupPetsOptions: true,
  shouldDisplayGroupUpdateOrderBills: true
};

export type UseBookingActionsListProps = {
  appointments: BookingOrder;
  bookingActions?: ReturnType<typeof useBookingActions>;
  group?: boolean;
  allGroupOrders?: BookingOrder[];
  groupPetsTags?: boolean;
  loadPetTags?: boolean;
  subGroup?: boolean;
  refetch?: () => void;
  groupOptionsToShow?: {
    shouldDisplayGroupAssign?: boolean;
    shouldDisplayReschedule?: boolean;
    shouldDisplayGroupMessage?: boolean;
    shouldDisplayGroupCancel?: boolean;
    shouldDisplayGroupConfirmationAndRejection?: boolean;
    shouldDisplayGroupTagsOptions?: boolean;
    shouldDisplayGroupPetsOptions?: boolean;
    shouldDisplayGroupUpdateOrderBills?: boolean;
  };
  includeQuickTags?: boolean;
  onClickAction?: () => void;
};

const useBookingActionsList = ({
  appointments,
  bookingActions,
  group = false,
  subGroup = false,
  allGroupOrders,
  groupPetsTags,
  loadPetTags = false,
  refetch: defaultRefetch,
  groupOptionsToShow = defaultGroupOptionsToShow,
  includeQuickTags = true,
  onClickAction
}: UseBookingActionsListProps) => {
  const {
    orderItems,
    shouldDisplay,
    actions,
    loading: loadingBookingActions,
    pets
  } = bookingActions ||
  useBookingActions({
    booking: appointments,
    options: {
      loadOrderItems: !group
    }
  });

  const {
    shouldDisplayAssign,
    shouldDisplayReschedule,
    shouldDisplayCancel,
    shouldDisplayRefund,
    shouldDisplayNewBooking,
    shouldDisplayPetsOptions,
    shouldDisplayTagsOptions,
    shouldDisplayUpdatePayment,
    shouldDisplayUpdateOrderBills,
    shouldDisplayRetryPayment,
    shouldDisplayMarkAsPaid,
    shouldDisplayUpdateDuration,
    shouldDisplayAssignToBranchRoom
  } = shouldDisplay;
  const { handleRejectCancel, handleNewBooking, handleNewProductBooking, handleRetryPayment, handleMarkAsPaid, handleRefund } = actions;

  const refetch = defaultRefetch || vars.drawerBars().find(drawerBar => drawerBar.drawerId === DRAWER_IDS.BOOKING_DRAWER)?.otherData?.refetch || (() => {});

  const {
    shouldDisplay: {
      shouldDisplayGroupAssign,
      shouldDisplayGroupReschedule,
      shouldDisplayGroupMessage,
      shouldDisplayPetsOptions: shouldDisplayGroupPetsOptions,
      shouldDisplayGroupTagsOptions,
      shouldDisplayGroupCancel,
      shouldDisplayGroupConfirmationAndRejection,
      shouldDisplayGroupUpdateOrderBills
    },
    actions: { handleBulkCancel, handleBulkConfirmation },
    groupPets,
    firstOrderId
  } = useGroupBookingsActions({ bookings: allGroupOrders, options: { refetch } });

  const { items: bookingTagsOptions, loading: loadingBookingTagsOptions } = useBookingTagsOptions({
    appointments: appointments,
    onClickAction,
    loadTags: includeQuickTags,
    includeQuickTags
  });

  const { items: petsOptions, loading: loadingPetOptions } = usePetRecordOptions({
    pets: pets || groupPets,
    orderId: firstOrderId,
    loadTags: loadPetTags,
    defaultAppointments: appointments
  });

  const orderAppointments = orderItems?.flatMap(item => item.Appointments);

  const optionsItems = [
    shouldDisplayNewBooking && {
      name: 'New Service',
      value: 'newBooking',
      onClick: () => handleNewBooking()
    },
    shouldDisplayNewBooking && {
      name: 'New Order',
      value: 'newOrder',
      onClick: () => handleNewProductBooking()
    },
    shouldDisplayAssign && {
      name: 'Assign',
      value: 'assign',
      onClick: () => ModalDialog.openModal({ content: () => <BookingAssignModal appointments={appointments} />, title: 'Assign to Staff' })
    },
    shouldDisplayAssignToBranchRoom && {
      name: 'Assign to Branch Room',
      value: 'assignToBranchRoom',
      onClick: () => ModalDialog.openModal({ content: () => <BookingAssignToBranchRoomModal appointments={appointments} />, title: 'Assign to Branch Room' })
    },

    shouldDisplayReschedule && {
      name: 'Reschedule',
      value: 'reschedule',
      onClick: () => ModalDialog.openModal({ content: () => <BookingRescheduleModal appointments={orderAppointments!} />, title: 'Reschedule' })
    },
    shouldDisplayRetryPayment && {
      name: 'Retry Payment',
      value: 'retryPayment',
      onClick: () => handleRetryPayment()
    },
    shouldDisplayMarkAsPaid && {
      name: 'Mark as Paid',
      value: 'markAsPaid',
      onClick: () => handleMarkAsPaid()
    },
    shouldDisplayUpdateOrderBills && {
      name: 'Update Billing Date',
      value: 'updateOrderBills',
      onClick: () =>
        ModalDialog.openModal({
          content: () => <BookingOrderBillBilledOnModal appointments={orderAppointments} />,
          title: 'Update Billing Date'
        })
    },
    shouldDisplayUpdatePayment && {
      name: 'Update Payment',
      value: 'updatePayment',
      onClick: () =>
        ModalDialog.openModal({
          title: 'Adjust Clients Orders',
          content: () => <AdjustAppUserOrdersPaymentModal initialValues={{ selectedAppUsers: appointments[0]?.OrderItem.Order.AppUser?.id ? [appointments[0]?.OrderItem.Order.AppUser?.id] : [] }} />
        })
    },
    ...((shouldDisplayTagsOptions && bookingTagsOptions) || []),
    shouldDisplayUpdateDuration && {
      name: 'Update Duration',
      value: 'updateDuration',
      onClick: () => ModalDialog.openModal({ content: () => <BookingDurationModal appointments={appointments} />, title: 'Update Duration' })
    },
    shouldDisplayRefund && {
      name: 'Refund payment',
      value: 'refund',
      onClick: () => handleRefund(() => refetch())
    },
    shouldDisplayCancel && {
      name: 'Cancel booking',
      value: 'cancel',
      onClick: () => handleRejectCancel(() => refetch()),
      danger: true
    }
  ].filter(Boolean) as { name: string; value: string; onClick: () => void; danger?: boolean }[];

  const groupOptionsItems = [
    shouldDisplayGroupAssign &&
      groupOptionsToShow.shouldDisplayGroupAssign && {
        name: subGroup ? 'Assign Group to staff' : 'Assign All',
        value: 'assignAll',
        onClick: () =>
          ModalDialog.openModal({
            content: () => <BookingAssignModal appointments={allGroupOrders!.flat()} />,
            title: 'Assign to Staff',
            onCloseBySave: onClickAction
          })
      },
    shouldDisplayGroupReschedule &&
      groupOptionsToShow.shouldDisplayReschedule &&
      !subGroup && {
        name: 'Reschedule All',
        value: 'rescheduleAll',
        onClick: () => ModalDialog.openModal({ content: () => <BookingRescheduleModal appointments={appointments} group />, title: 'Reschedule All', onCloseBySave: onClickAction })
      },
    shouldDisplayGroupMessage &&
      groupOptionsToShow.shouldDisplayGroupMessage && {
        name: 'Message All',
        value: 'messageAll',
        onClick: () =>
          ModalDialog.openModal({
            content: () => <GroupMessageModal preSelectedUsersIds={allGroupOrders!.map(booking => booking?.[0].OrderItem.Order.AppUser?.id)} />,
            title: 'Broadcast message',
            onCloseBySave: onClickAction
          })
      },
    shouldDisplayGroupUpdateOrderBills &&
      groupOptionsToShow.shouldDisplayGroupUpdateOrderBills && {
        name: 'Update Billing Date',
        value: 'updateOrderBills',
        onClick: () =>
          ModalDialog.openModal({
            content: () => <BookingOrderBillBilledOnModal appointments={allGroupOrders!.flat()} />,
            title: 'Update Billing Date',
            onCloseBySave: onClickAction
          })
      },
    // shouldDisplayGroupUpdateOrderBills && {
    //     name: 'Bulk Update Billing',
    //     value: 'bulkUpdateBillingDate',
    //     onClick: () =>
    //       ModalDialog.openModal({
    //         title: 'Adjust Billing Date',
    //         content: () => <AdjustAppUserOrderBillsBilledOnModal initialValues={{ selectedAppUsers: allGroupOrders?.flatMap(appointment => appointment?.[0]?.OrderItem?.Order.AppUser?.id) }} />,
    //         onCloseBySave: onClickAction
    //       })
    //   },
    ...((shouldDisplayGroupTagsOptions && !groupPetsTags && groupOptionsToShow.shouldDisplayGroupTagsOptions && bookingTagsOptions) || []),
    ...((shouldDisplayGroupPetsOptions && groupOptionsToShow.shouldDisplayGroupPetsOptions && groupPetsTags && petsOptions.filter(item => item.value !== 'newBooking' && item.value !== 'newOrder')) ||
      []),
    shouldDisplayGroupCancel &&
      groupOptionsToShow.shouldDisplayGroupCancel && {
        name: 'Cancel All',
        value: 'cancelAll',
        onClick: () => {
          handleBulkCancel({
            onAccept: () => onClickAction?.()
          });
        },
        danger: true
      },
    shouldDisplayGroupConfirmationAndRejection &&
      groupOptionsToShow.shouldDisplayGroupConfirmationAndRejection && {
        name: 'Confirm All',
        value: 'confirmAll',
        onClick: () => {
          handleBulkConfirmation({
            onAccept: () => onClickAction?.()
          });
        }
      }
  ].filter(Boolean) as { name: string; value: string; onClick: () => void; danger?: boolean }[];

  const items = group ? groupOptionsItems : optionsItems;

  return { items, loadingBookingActions, loadingBookingTagsOptions, loadingPetOptions };
};

export default useBookingActionsList;
