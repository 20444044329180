import { linearGradientDef } from '@nivo/core';
import { ResponsiveLine } from '@nivo/line';
import React from 'react';
import { calculateAgeFromBirthdate } from '../../../utils/dates';
import { ChartTooltip, HealthChartContainer } from './styled';

const HealthChart = ({ pet }) => {
  const birthdate = new Date(pet?.birthdate || new Date())?.toISOString?.();

  const data = [
    {
      data: pet?.PetRecord?.measurements?.weights?.map(weight => ({
        x: calculateAgeFromBirthdate(birthdate, new Date(weight.date), 'short'),
        y: weight.weight
      })),
      id: 'weight'
    }
  ];
  return (
    <HealthChartContainer>
      {!data?.[0]?.data?.length ? (
        <h3>No data yet!</h3>
      ) : (
        <ResponsiveLine
          data={data}
          margin={{ top: 50, right: 44, bottom: 50, left: 50 }}
          xScale={{ type: 'point' }}
          yScale={{ type: 'linear', max: 'auto', stacked: false, reverse: false, nice: true }}
          axisTop={null}
          axisRight={null}
          curve={'natural'}
          // markers={[
          //   {
          //     legendPosition: 'top-right',
          //     axis: 'x',
          //     value: 30,
          //     lineStyle: { stroke: Colors.orange, strokeWidth: 2 },
          //     textStyle: { fontSize: 12, fill: Colors.orange },
          //     legend: 'Siberian Husky Avg',
          //     legendOrientation: 'vertical'
          //   }
          // ]}
          axisLeft={{ renderTick }}
          pointSize={5}
          pointColor={'#08D668'}
          pointBorderWidth={2}
          pointBorderColor={{ from: 'serieColor' }}
          lineWidth={2}
          colors={['#4CCF00']}
          enableArea={true}
          areaBlendMode={'normal'}
          areaBaselineValue={0}
          enableGridX={false}
          enableGridY={false}
          useMesh={true}
          tooltip={({ point }) => <ChartTooltip>{Number(point.data.y)} kg</ChartTooltip>}
          defs={[
            linearGradientDef('weightGradient', [
              { offset: 0, color: '#08D668' },
              { offset: 100, color: 'inherit', opacity: 0 }
            ])
          ]}
          fill={[{ match: { id: 'weight' }, id: 'weightGradient' }]}
        />
      )}
    </HealthChartContainer>
  );
};

export default HealthChart;

const renderTick = tick => (
  <g transform={`translate(${tick.x},${tick.y})`} style={{ opacity: 1 }}>
    <line
      x1="0"
      x2="-5"
      y1="0"
      y2="0"
      style={{
        stroke: 'rgb(119, 119, 119)',
        strokeWidth: 1
      }}
    ></line>
    <text
      dominantBaseline="central"
      textAnchor="end"
      transform="translate(-10,0) rotate(0)"
      style={{
        fontSize: 14,
        fill: '#878787'
      }}
    >
      {tick.value}
    </text>
  </g>
);
