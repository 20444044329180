import { useMutation, useReactiveVar } from '@apollo/client';
import React, { useCallback, useMemo, useRef, useState } from 'react';
import styled from 'styled-components';
import Colors from '../../Colors';
import useBookingsListBulkActions from '../../hooks/useBookingsListBulkActions';
import usePaginatedQuery from '../../hooks/usePaginatedQuery';
import useReduceAppointments from '../../hooks/useReduceAppointments';
import useURLSearchParams from '../../hooks/useURLSearchParams';
import { GetBillingListBranchAppointments, GetBranchOrderSubscriptions, GetBranchOrders, RetryOrderSubscriptionRepeatInvoice, UpdateOrderSubscriptionRepeatInvoiceStatus } from '../../queries';
import { vars } from '../../reactive';
import { addDrawerBar, unsetDrawerBar } from '../../reactive/actions';
import { getMonthName, getStartOfNMonth } from '../../utils/dates';
import { BookingsBulkActionsRef } from '../../views/Bookings/BookingsList_OLD';
import { BOOKING_STATUS_TYPES, BOOKING_TYPE, Booking, BookingOrder } from '../../views/Bookings/types';
import { getBookingTitles } from '../../views/Bookings/utils';
import { ORDER_STATUS, ProductOrder } from '../../views/Orders/types';
import { getOrderTitles } from '../../views/Orders/utils';
import { BranchAppUser, CurrentPeriod } from '../../views/Store/BranchBilling/types';
import { SUBSCRIPTION_STATUS_TYPES, Subscription } from '../../views/Subscriptions/types';
import { getSubscriptionTitles } from '../../views/Subscriptions/utils';
import { RecordBody } from '../../views/styled';
import { DRAWER_IDS } from '../DrawerBar/types';
import { FILTERS_ID } from '../Shared/Filters/config';
import { Icon } from '../Shared/Forms/Forms';
import { Checkbox, CheckboxItemContainer } from '../Shared/Forms/styled';
import InfiniteList from '../Shared/InfiniteList/InfiniteList';
import ToolBar from '../Shared/ToolBar/ToolBar';
import { BillingStatus, BookingListItem, BookingsDivider, BookingsListCotainer, ListHeaderContainer, ListView, UserBookingsContainer } from './styled';

import { GatsbyImage } from 'gatsby-plugin-image';
import useIcons from '../../hooks/useIcons';
import { BOOKINGS_FILTER_TYPES, BOOKINGS_MORE_OPTIONS_TYPES, getBookingsVariables, useBookingsFilters } from '../../views/Bookings/BookingsFilters';
import { Booking as BookingStyled, PetAndInfo, PetBioExtraInfoContainer, PetContainer, PetImg } from '../../views/Bookings/components/BookingRecord/styled';
import { ViewSwitchContainer } from '../../views/Bookings/components/BookingsHeader/styled';
import { UserImage } from '../../views/Bookings/components/ServiceHeader/styled';
import { DefaultProfilePhoto } from '../DrawerBar/styled';
import { getFilterKeyOptions } from '../Shared/Filters/helpers';

enum UserOrdersListTabs {
  ACTIVE = 'Active',
  ALL = 'All'
}

const UserOrdersListTabsIds = {
  ACTIVE: 'active',
  ALL: 'all'
};

export enum UserSubscriptionsListTabs {
  ACTIVE = 'Active',
  CANCELLED = 'Cancelled',
  INVOICES = 'Invoices'
}

enum UserSubscriptionsListTabsIds {
  ACTIVE = 'active',
  CANCELLED = 'cancelled',
  INVOICES = 'invoices'
}

enum UserBookingsListTabs {
  UPCOMING = 'Upcoming',
  CONFIRMED = 'Confirmed',
  ALL = 'All'
}

enum UserBookingsListTabsIds {
  UPCOMING = 'upcoming',
  CONFIRMED = 'confirmed',
  ALL = 'all'
}

export const Switch = ({ onChange }) => {
  const icons = useIcons();
  const viewSwitchOn = icons.viewSwitchOn.childImageSharp.gatsbyImageData;
  const viewSwitchOff = icons.viewSwitchOff.childImageSharp.gatsbyImageData;
  const [isClicked, setIsClicked] = useState(false);

  const handleClick = () => {
    const newStatus = !isClicked;
    setIsClicked(newStatus);
    onChange(newStatus);
  };

  return (
    <ViewSwitchContainer onClick={handleClick}>
      <GatsbyImage image={isClicked ? viewSwitchOff : viewSwitchOn} alt={isClicked ? 'viewSwitchOff' : 'viewSwitchOn'} style={{ cursor: 'pointer' }} />
    </ViewSwitchContainer>
  );
};

const AppUserBookings = ({ userPetRecordsIds, period }: { userPetRecordsIds: string[]; period: CurrentPeriod }) => {
  const filtersControl = useBookingsFilters();
  const { headerAction, selectedFilters } = filtersControl;
  const { onChange: onSearch } = getFilterKeyOptions({
    filtersControl,
    keys: {
      filterType: BOOKINGS_FILTER_TYPES.BOOKING_SEARCH,
      moreOption: BOOKINGS_MORE_OPTIONS_TYPES.BOOKING_SEARCH
    }
  });

  const [onChange, setOnChange] = useState(false);
  const [tab] = useURLSearchParams('selectedTabBookings');

  const months = useMemo(() => {
    return Array.from({ length: 3 }).map((_, i) => {
      const start = getStartOfNMonth(new Date(), i);
      const end = getStartOfNMonth(start, 1);
      end.setMilliseconds(-1);
      return { name: getMonthName(start), id: `month_${i}`, timestamp_from: start, timestamp_to: end, divider: i === 2 };
    });
  }, []);

  const currentTab = tab[0] || months[0].id;

  const toolBarButtons = [...months, { name: UserBookingsListTabs.UPCOMING, id: UserBookingsListTabsIds.UPCOMING, divider: true }, { name: UserBookingsListTabs.ALL, id: UserBookingsListTabsIds.ALL }];

  const confirmed = onChange;
  const upcoming = currentTab === UserBookingsListTabsIds.UPCOMING;
  const selectedMonth = months.find(({ id }) => id === currentTab);
  const upcomingStartPeriod = months[2].timestamp_to;
  const timeStampFrom = selectedMonth ? selectedMonth.timestamp_from : upcoming ? upcomingStartPeriod : null;
  const timeStampTo = selectedMonth ? selectedMonth.timestamp_to : null;

  const [[hasMoreBookings, setHasMoreBookings], queryResult] = usePaginatedQuery<Booking[]>({
    query: GetBillingListBranchAppointments,
    defaultStatus: [BOOKING_STATUS_TYPES.CANCELED, BOOKING_STATUS_TYPES.CONFIRMED],
    limit: 50,
    otherVariables: {
      ...getBookingsVariables({
        selectedFilters,
        defaultVariables: {
          PetRecordId: userPetRecordsIds,
          timestamp_from: timeStampFrom,
          timestamp_to: timeStampTo,
          booking_type: [BOOKING_TYPE.SLOT, BOOKING_TYPE.MULTI_SLOT, BOOKING_TYPE.MULTI_DAY],
          status: confirmed ? [BOOKING_STATUS_TYPES.CONFIRMED] : [BOOKING_STATUS_TYPES.CANCELED],
          requisite_queries: [],
          alternative_queries: [],
          order: 'ASC'
        }
      })
    },
    otherParams: {
      skip: !userPetRecordsIds.length
    }
  });
  const { data: { getBranchAppointments: appUserAppointments = [] } = {}, loading: loadingAppUserAppointments, fetchMore, refetch } = queryResult;

  const bookingsToRender = useReduceAppointments(appUserAppointments, {
    uniqueByOrderIdAndTimestamp: true,
    sortDesc: false
  });

  const addBookingDrawer = useCallback((booking: BookingOrder) => {
    unsetDrawerBar(DRAWER_IDS.SUBSCRIPTION_DRAWER);
    unsetDrawerBar(DRAWER_IDS.ORDER_DRAWER);
    addDrawerBar({ drawerId: DRAWER_IDS.BOOKING_DRAWER, recordData: booking, otherData: { refetch } });
  }, []);

  const bookingsListRef = useRef<BookingsBulkActionsRef>(null);

  const { bulkSelect } = useBookingsListBulkActions({
    bookingsToRender,
    ref: bookingsListRef,
    inDrawer: true,
    refetch
  });

  const { isSelected, show: showBulkActions, toggleSelectMultiple } = bulkSelect;

  const handleBookingClick = useCallback(
    (booking: BookingOrder) => {
      const appointmentsIds = booking.map(({ id }) => id);
      if (showBulkActions) {
        toggleSelectMultiple(appointmentsIds);
        return;
      }
      addBookingDrawer(booking);
    },
    [showBulkActions, toggleSelectMultiple, addBookingDrawer]
  );

  const headerActions = useReactiveVar(vars.bookingsHeaderActions);
  const filterHeaderActions = headerActions.filter(({ id }) => id !== FILTERS_ID);

  return (
    <ListView padding="0 20px 20px 20px">
      <ListHeaderContainer>
        <ToolBar
          onSearchChange={onSearch}
          middleAction={headerAction}
          toolBarButtons={toolBarButtons}
          urlSearchParam={'selectedTabBookings'}
          displayAll={false}
          selectOnlyOne={true}
          defaultValue={months[0].id}
          padding="0 0"
          customizedToolComponent={<Switch onChange={newStatus => setOnChange(newStatus)} />}
        />
        {/* 
        <ListHeaderContentContainer>
          <FlexRow>
            {filterHeaderActions.map(({ action, id }) => (
              <Fragment key={id}>{action}</Fragment>
            ))}
          </FlexRow>
        </ListHeaderContentContainer> */}
      </ListHeaderContainer>
      <BookingsListCotainer>
        <InfiniteList
          hasMoreItems={hasMoreBookings}
          fetchMore={fetchMore}
          loading={loadingAppUserAppointments}
          setHasMoreItems={setHasMoreBookings}
          itemRenderer={(booking: BookingOrder) => {
            const {
              day,
              time,
              dateTo,
              orderItemName,
              orderNumber,
              priceString,
              multiDayProduct,
              isFree,
              isCanceled,
              isBilled,
              billedOnDate,
              petsNames,
              isPrepaidMarkedAsPaid,
              petsProfilePics,
              petsBreeds
            } = getBookingTitles(booking);
            return (
              <BookingListItem key={booking[0].id}>
                <BookingStyled onClick={() => handleBookingClick(booking)} opacity={isCanceled && 0.45}>
                  {showBulkActions && (
                    <RecordBody width="50">
                      <CheckboxItemContainer checked={isSelected(booking[0].id)} noMargin>
                        <Checkbox>
                          <Icon viewBox="0 0 24 24">
                            <polyline points="20 6 9 17 4 12" />
                          </Icon>
                        </Checkbox>
                      </CheckboxItemContainer>
                    </RecordBody>
                  )}
                  <PetBioExtraInfoContainer>
                    {/* <PetContainer ref={petContainerRef} width={200}> */}
                    <PetContainer width={200}>
                      <PetImg src={petsProfilePics} alt={petsNames} />
                      <PetAndInfo>
                        <RecordBody fontWeight="700" width="100" noMargin>
                          {petsNames}, <RecordBody width="80"> {petsBreeds}</RecordBody>
                        </RecordBody>
                      </PetAndInfo>
                    </PetContainer>
                  </PetBioExtraInfoContainer>
                  <RecordBody width="150" fontWeight={'700'}>
                    {orderItemName}
                  </RecordBody>

                  {!multiDayProduct && (
                    <RecordBody width="250" fontWeight="700">
                      <strong>
                        {day} {time}
                      </strong>
                    </RecordBody>
                  )}
                  {multiDayProduct && (
                    <RecordBody width="250" fontWeight="700">
                      <strong> {day}</strong> to <strong>{dateTo}</strong>
                    </RecordBody>
                  )}

                  <RecordBody width="150" fontWeight={'500'}>
                    {billedOnDate}
                  </RecordBody>
                  <RecordBody width="150" fontWeight="800" flexEnd noMargin>
                    <BillingStatus blue={isFree} gray={isCanceled} green={isBilled || isPrepaidMarkedAsPaid} red={!isFree && !isCanceled && !isBilled && !isPrepaidMarkedAsPaid}>
                      {isCanceled ? 'Cancelled' : isFree ? 'Free' : isBilled ? 'Paid' : isPrepaidMarkedAsPaid ? 'Marked Paid' : 'Unpaid'}
                    </BillingStatus>
                  </RecordBody>
                </BookingStyled>
                <BookingsDivider />
              </BookingListItem>
            );
          }}
          list={bookingsToRender}
          offset={appUserAppointments?.length}
        />
      </BookingsListCotainer>
    </ListView>
  );
};

const RepeatsListContainer = styled(RecordBody)`
  justify-content: space-between;
  display: flex;
  width: 100%;
`;
const SubscriptionManageButton = styled.button`
  color: ${Colors.primary};
`;

const AppUserSubscriptions = ({ userPetRecordsIds }: { userPetRecordsIds: string[] }) => {
  const [updateInvoiceStatus] = useMutation(UpdateOrderSubscriptionRepeatInvoiceStatus);
  const [retryInvoice] = useMutation(RetryOrderSubscriptionRepeatInvoice);

  const userDrawerBar = vars.drawerBars().find(drawer => drawer.drawerId === DRAWER_IDS.USER_DRAWER);
  // const otherData = userDrawerBar?.otherData || {};
  // const defaultTab = otherData?.defaultSubTab || UserSubscriptionsListTabs.ACTIVE;
  // const [currentTab, setCurrentTab] = useSelectedState<typeof UserSubscriptionsListTabs>({ defaultTab, TABS_TYPES: UserSubscriptionsListTabs });

  const [[hasMoreSubscriptions, setHasMoreSubscriptions], { data: { getBranchOrderSubscriptions: appUserSubscriptions = [] } = {}, loading: LoadingSubscriptions, fetchMore, refetch }] =
    usePaginatedQuery<Subscription[]>({
      query: GetBranchOrderSubscriptions,
      defaultStatus: [SUBSCRIPTION_STATUS_TYPES.CONFIRMED, SUBSCRIPTION_STATUS_TYPES.CANCELED],
      limit: 20,
      otherVariables: {
        PetRecordId: userPetRecordsIds,
        requisite_queries: [],
        alternative_queries: []
      },
      otherParams: {
        skip: !userPetRecordsIds.length
      }
    });

  const addSubscriptionDrawer = useCallback((subscription: Subscription) => {
    unsetDrawerBar(DRAWER_IDS.BOOKING_DRAWER);
    unsetDrawerBar(DRAWER_IDS.ORDER_DRAWER);
    addDrawerBar({ drawerId: DRAWER_IDS.SUBSCRIPTION_DRAWER, recordData: subscription, otherData: { refetch } });
  }, []);

  // const renderRepeatsList = (subscription: Subscription) => {
  //   const { subscriptionNumber, subscriptionName } = getSubscriptionTitles({ subscription });
  //   const uniqueRepeatsByInvoiceId = (subscription?.OrderSubscriptionRepeats || []).reduce((acc, repeat) => {
  //     const { payment: { invoice_id: invoiceId = '' } = {} } = repeat;
  //     const invoice = acc.find(({ payment }) => payment?.invoice_id === invoiceId);
  //     if (!invoice) {
  //       acc.push(repeat);
  //     }
  //     return acc;
  //   }, [] as typeof subscription.OrderSubscriptionRepeats);
  //   return (
  //     <>
  //       <RepeatsListContainer fontWeight={'800'} fontSize={16}>
  //         ({subscriptionNumber} - {subscriptionName})<SubscriptionManageButton onClick={() => addSubscriptionDrawer(subscription)}>Manage</SubscriptionManageButton>
  //       </RepeatsListContainer>

  //       {uniqueRepeatsByInvoiceId?.map(repeat => {
  //         const { createdAt, isBlue, isGreen, isRed, statusTitle, repeatTotal, failReason, isBilled, isConfirmed, isMarkedAsPaid, isDraft, isUncollectible } = getSubscriptionRepeatTitles(repeat);
  //         const voidInvoice = () => {
  //           updateInvoiceStatus({ variables: { id: repeat.id, invoice_status: 'void' } });
  //         };
  //         const markInvoiceUncollectible = () => {
  //           updateInvoiceStatus({ variables: { id: repeat.id, invoice_status: 'uncollectible' } });
  //         };
  //         const markInvoiceAsPaid = () => {
  //           updateInvoiceStatus({ variables: { id: repeat.id, invoice_status: 'mark_as_paid' } });
  //         };
  //         const retryInvoicePayment = () => {
  //           retryInvoice({ variables: { id: repeat.id } });
  //         };
  //         const options: OptionDropDownItem[] = isBilled
  //           ? []
  //           : isDraft || isUncollectible
  //             ? [
  //               {
  //                 name: 'Mark As Paid',
  //                 value: 'mark_as_paid',
  //                 onClick: markInvoiceAsPaid
  //               },
  //               {
  //                 name: 'Retry Payment',
  //                 value: 'retry',
  //                 onClick: retryInvoicePayment
  //               }
  //             ]
  //             : [
  //               {
  //                 name: 'Void Invoice',
  //                 value: 'void',
  //                 onClick: voidInvoice
  //               },
  //               {
  //                 name: 'Mark Uncollectible',
  //                 value: 'uncollectible',
  //                 onClick: markInvoiceUncollectible
  //               },
  //               {
  //                 name: 'Mark As Paid',
  //                 value: 'mark_as_paid',
  //                 onClick: markInvoiceAsPaid
  //               },
  //               {
  //                 name: 'Retry Payment',
  //                 value: 'retry',
  //                 onClick: retryInvoicePayment
  //               }
  //             ];
  //         return (
  //           <div style={{ display: 'flex', alignItems: 'center', padding: '0 8px' }} key={repeat.id}>
  //             <RecordBody width="200" fontWeight={'700'}>
  //               {createdAt}
  //             </RecordBody>
  //             <RecordBody width="20" fontWeight="800">
  //               {repeatTotal}
  //             </RecordBody>
  //             <RecordBody width="150" fontWeight="800" flexEnd noMargin>
  //               <BillingStatus blue={isBlue} green={isGreen} red={isRed} gray={!isBlue && !isGreen && !isRed} noMaxWidth={!!failReason}>
  //                 {failReason || (isMarkedAsPaid ? 'Marked Paid' : isUncollectible ? 'Uncollectible' : statusTitle)}
  //               </BillingStatus>
  //             </RecordBody>
  //             <OptionDropdown
  //               containerRelative
  //               menuButtonType={OPTION_DROPDOWN_MENU_BUTTON_TYPES.PLUS}
  //               noApplyButton
  //               options={[{ optionType: OPTION_DROPDOWN_TYPES.BUTTONS, id: 'options', items: options }]}
  //             />
  //           </div>
  //         );
  //       })}
  //     </>
  //   );
  // };

  const renderSubscription = (subscription: Subscription) => {
    const { subscriptionName, period, orderTotal, petsNames, petsBreeds, petsProfilePics, isCanceled } = getSubscriptionTitles({ subscription });
    return (
      <BookingListItem key={subscription.id}>
        <BookingStyled onClick={() => addSubscriptionDrawer(subscription)} opacity={isCanceled && 0.45} gap={16}>
          {
            <>
              <PetContainer width={200}>
                <PetImg src={petsProfilePics} alt={petsNames} />
                <PetAndInfo>
                  <RecordBody fontWeight="700" width="100" noMargin>
                    {petsNames},{' '}
                    <RecordBody width="80" noMargin>
                      {' '}
                      {petsBreeds}
                    </RecordBody>
                  </RecordBody>
                </PetAndInfo>
              </PetContainer>

              <RecordBody width="240" fontWeight={'600'}>
                {subscriptionName} - Billed {period}
              </RecordBody>
              <RecordBody width="20" fontWeight="800">
                {orderTotal}
              </RecordBody>
              <RecordBody width="40" color={!isCanceled && '#0CA82F'} noMargin flexEnd>
                {isCanceled ? 'Cancelled' : 'Active'}
              </RecordBody>
            </>
          }
          {/* <SubscriptionsInvoiceListContainer>{invoices && renderRepeatsList(subscription)}</SubscriptionsInvoiceListContainer> */}
        </BookingStyled>
        <BookingsDivider />
      </BookingListItem>
    );
  };

  return (
    <ListView padding="0 20px 20px 20px">
      <BookingsListCotainer>
        <InfiniteList
          hasMoreItems={hasMoreSubscriptions}
          fetchMore={fetchMore}
          loading={LoadingSubscriptions}
          setHasMoreItems={setHasMoreSubscriptions}
          itemRenderer={renderSubscription}
          list={appUserSubscriptions}
          offset={appUserSubscriptions?.length}
        />
      </BookingsListCotainer>
    </ListView>
  );
};

const AppUserOrders = ({ appUserId, period }: { appUserId: string; period: CurrentPeriod }) => {
  // const [currentTab, setCurrentTab] = useSelectedState<typeof UserOrdersListTabs>({ defaultTab: UserOrdersListTabs.ALL, TABS_TYPES: UserOrdersListTabs });

  const [[hasMoreOrders, setHasMoreOrders], { data: { getBranchOrders: appUserOrders = [] } = {}, loading: LoadingOrders, fetchMore, refetch }] = usePaginatedQuery<ProductOrder[]>({
    query: GetBranchOrders,
    defaultStatus: Object.values(ORDER_STATUS),
    limit: 20,
    otherVariables: {
      type: 'product',
      AppUserId: [appUserId],
      createdAt_from: null,
      createdAt_to: null
    },
    otherParams: {
      skip: !appUserId
    }
  });

  const addOrderDrawer = useCallback((order: ProductOrder) => {
    unsetDrawerBar(DRAWER_IDS.BOOKING_DRAWER);
    unsetDrawerBar(DRAWER_IDS.SUBSCRIPTION_DRAWER);
    addDrawerBar({ drawerId: DRAWER_IDS.ORDER_DRAWER, recordData: order, otherData: { refetch } });
  }, []);

  const icons = useIcons();

  return (
    <ListView padding="0 20px 20px 20px">
      <BookingsListCotainer>
        <InfiniteList
          hasMoreItems={hasMoreOrders}
          fetchMore={fetchMore}
          loading={LoadingOrders}
          setHasMoreItems={setHasMoreOrders}
          itemRenderer={(order: ProductOrder) => {
            const { orderNumber, numberOfProducts, total, time, statusTitle, isRed, isGreen, isBlue, appUserProfilepic, appUserName } = getOrderTitles(order);
            return (
              <BookingListItem key={order.id}>
                <BookingStyled onClick={() => addOrderDrawer(order)} gap={16}>
                  <PetContainer>
                    <PetAndInfo>
                      {!appUserProfilepic && <DefaultProfilePhoto image={icons?.user?.childImageSharp?.gatsbyImageData} alt={'userImage'} />}
                      {appUserProfilepic && <UserImage src={appUserName} alt={appUserName} />}
                      <RecordBody fontWeight="800" width="100" noMargin fontSize={13}>
                        {appUserName}
                      </RecordBody>
                    </PetAndInfo>
                  </PetContainer>

                  <RecordBody width="100" fontWeight={'600'} fontSize={13}>
                    {orderNumber}
                  </RecordBody>

                  <RecordBody width="60" fontWeight={'800'} fontSize={13}>
                    {numberOfProducts} {numberOfProducts === 1 ? 'item' : 'items'}
                  </RecordBody>

                  <RecordBody width="auto" fontWeight="800" fontSize={13}>
                    {total}
                  </RecordBody>

                  <RecordBody width="150" fontWeight="600" fontSize={13}>
                    {time}
                  </RecordBody>

                  {/* <RecordBody width="150" fontWeight="800" flexEnd noMargin>
                    <BillingStatus blue={isBlue} green={isGreen} red={isRed} gray={!isBlue && !isGreen && !isRed}>
                      {statusTitle}
                    </BillingStatus>
                  </RecordBody> */}
                </BookingStyled>
                <BookingsDivider />
              </BookingListItem>
            );
          }}
          list={appUserOrders}
          offset={appUserOrders?.length}
        />
      </BookingsListCotainer>
    </ListView>
  );
};

export const UserOrdersList = ({ userProfile, currentPeriod }: { userProfile: BranchAppUser; currentPeriod: CurrentPeriod }) => {
  return (
    <UserBookingsContainer>
      <AppUserOrders appUserId={userProfile?.id} period={currentPeriod} />
    </UserBookingsContainer>
  );
};

export const UserSubscriptionsList = ({ userProfile, currentPeriod }: { userProfile: BranchAppUser; currentPeriod: CurrentPeriod }) => {
  const userPetRecordsIds = [...(userProfile?.Pets || []), ...(userProfile?.ChappedPets || [])].filter(Boolean)?.map(pet => pet?.PetRecord?.id) || [];

  return (
    <UserBookingsContainer>
      <AppUserSubscriptions userPetRecordsIds={userPetRecordsIds} />
    </UserBookingsContainer>
  );
};

export const UserBookingsList = ({ userProfile, currentPeriod }: { userProfile: BranchAppUser; currentPeriod: CurrentPeriod }) => {
  const userPetRecordsIds = [...(userProfile?.Pets || []), ...(userProfile?.ChappedPets || [])].filter(Boolean)?.map(pet => pet?.PetRecord?.id) || [];

  return (
    <UserBookingsContainer>
      <AppUserBookings userPetRecordsIds={userPetRecordsIds} period={currentPeriod} />
    </UserBookingsContainer>
  );
};
