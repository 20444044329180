import { useQuery } from '@apollo/client';
import React from 'react';
import Colors from '../../../../Colors';
import { Divider } from '../../../../components/DrawerBar/styled';
import ModalDialog from '../../../../components/Modal/ModalDialog';
import { ModalBody, ModalFooter } from '../../../../components/Modal/styled';
import { FormButtonsContainer, FormLabel, FormSubmitButton, HeaderLabel } from '../../../../components/Shared/Forms/Forms';
import { Container } from '../../../../components/Shared/Shared';
import { CenteredLoader } from '../../../../components/Shared/Spinner';
import useCheckBranchAvailability, { Slots } from '../../../../hooks/useCheckBranchAvailability';
import { CheckBranchProductsAppointmentAvailability } from '../../../../queries';
import { getUTCTime, toReadableDate } from '../../../../utils/dates';
import BranchSummaryDataTable from '../../../Store/BranchSummary/BranchSummaryDataTable';
import { BranchAvailability, Product } from '../../../Store/types';

import { BOOKING_TYPE } from '../../types';
import { getBookingDates } from '../../utils';

const SlotAvailabilityModal = ({
  selectedSlot,
  bookingDates,
  index
}: {
  selectedSlot: ReturnType<typeof useCheckBranchAvailability>['selectedSlotForEachDate'][0];
  bookingDates: ReturnType<typeof useCheckBranchAvailability>['bookingDates'];
  index: number;
}) => {
  const bookingDate = bookingDates[index];
  const { mainSlotAvailablity } = selectedSlot || {};
  const { data: { checkBranchProductsAppointmentAvailability: availability } = {}, loading } = useQuery<{
    checkBranchProductsAppointmentAvailability: {
      products: {
        Product: Product;
        availability: BranchAvailability;
      }[];
      branchAvailability: BranchAvailability;
    };
  }>(CheckBranchProductsAppointmentAvailability, {
    variables: {
      start_date: bookingDate?.fullDate,
      slots_length: 1
    },
    fetchPolicy: 'cache-and-network'
  });

  const { products: productsAvailability = [], branchAvailability } = availability || {};

  const products = productsAvailability.filter(item => item.Product.booking_type !== BOOKING_TYPE.MULTI_SLOT);

  const productsBookingDates = products.map(item => {
    const productBookingDates = getBookingDates(item.availability.slots);
    const isMultiDay = item.Product.booking_type === BOOKING_TYPE.MULTI_DAY;
    const slots = isMultiDay ? [productBookingDates[0]?.slots[0]] : productBookingDates[0]?.slots;
    return {
      Product: item.Product,
      productBookingDates: productBookingDates.map(productBookingDate => ({
        ...productBookingDate,
        slots
      }))
    };
  });

  const branchBookingDates = getBookingDates(branchAvailability?.slots || ({} as Slots));

  const productsBookingDatesWithOwnSlot = productsBookingDates.filter(item => {
    return !!item.Product?.slots?.length && item.productBookingDates[0].slots?.length;
  });

  const productsBookingDatesWithBranchSlot = productsBookingDates.filter(item => {
    return !item.Product?.slots?.length;
  });

  return (
    <>
      <ModalBody>
        <Container>
          <div>
            {!loading && (
              <>
                <HeaderLabel>Date: {toReadableDate(bookingDate?.fullDate || new Date(), { noTime: true })}</HeaderLabel>
                {/* <FormLabel bold>
                  Operational Capacity: {branchBookingDates[0]?.slots?.[0]?.count} / {branchBookingDates[0]?.slots?.[0]?.mainSlotAvailablity}
                </FormLabel> */}
                <div>
                  <FormLabel bold>{productsBookingDatesWithBranchSlot?.length ? productsBookingDatesWithBranchSlot?.map(item => item.Product.name).join(', ') : 'No products'}</FormLabel>
                  <BranchSummaryDataTable
                    headerRows={[
                      {
                        label: 'Slot'
                      },
                      {
                        label: 'Availability'
                      }
                    ]}
                    bodyColums={
                      branchBookingDates?.[0]?.slots.map(slot => {
                        return {
                          rows: [
                            {
                              label: getUTCTime(slot) || '',
                              style: {
                                color: !slot?.available ? Colors.warmGrey : Colors.black
                              }
                            },
                            {
                              label: slot?.available ? String(slot?.available) : '0',
                              style: {
                                color: !slot?.available ? Colors.grey : Colors.black
                              }
                            }
                          ]
                        };
                      }) || []
                    }
                  />
                </div>

                <div style={{ marginTop: 10 }}>
                  <Divider />
                  {productsBookingDatesWithOwnSlot.map(item => {
                    const isMultiDay = item.Product.booking_type === BOOKING_TYPE.MULTI_DAY;
                    return (
                      <div key={item.Product.id} style={{ marginTop: 10 }}>
                        <FormLabel bold>{item.Product.name}</FormLabel>
                        <BranchSummaryDataTable
                          headerRows={[
                            ...(!isMultiDay
                              ? [
                                  {
                                    label: 'Slot'
                                  }
                                ]
                              : []),
                            {
                              label: 'Availability'
                            }
                          ]}
                          bodyColums={
                            item?.productBookingDates?.[0]?.slots?.map(slot => {
                              return {
                                rows: [
                                  ...(!isMultiDay
                                    ? [
                                        {
                                          label: getUTCTime({ timestamp: slot?.timestamp }) || '',
                                          style: {
                                            color: !slot?.available ? Colors.warmGrey : Colors.black
                                          }
                                        }
                                      ]
                                    : []),
                                  {
                                    label: slot?.available ? String(slot?.available) : '0',
                                    style: {
                                      color: !slot?.available ? Colors.warmGrey : Colors.black
                                    }
                                  }
                                ]
                              };
                            }) || []
                          }
                        />
                      </div>
                    );
                  })}
                </div>
              </>
            )}
            {loading && <CenteredLoader size={30} />}
          </div>
        </Container>
      </ModalBody>

      <ModalFooter>
        <FormButtonsContainer>
          <FormSubmitButton onClick={ModalDialog.closeModal}>Done</FormSubmitButton>
        </FormButtonsContainer>
      </ModalFooter>
    </>
  );
};

export default SlotAvailabilityModal;
