import { SelectedFilter } from '../../../components/Shared/Filters/config';
import { getFilter, getMoreOptionsValues } from '../../../components/Shared/Filters/helpers';
import useFilters from '../../../components/Shared/Filters/useFilters';
import { OPTION_DROPDOWN_TYPES } from '../../../components/Shared/Menus/OptionDropdown/types';

enum DISCOUNTS_FILTER_TYPES {
  DISCOUNTS_SEARCH = 'DISCOUNTS_SEARCH'
}

const HIDDEN_DISCOUNTS_FILTERS = {
  [DISCOUNTS_FILTER_TYPES.DISCOUNTS_SEARCH]: true
};

const DISCOUNTS_FILTERS_QUERIES = {
  [DISCOUNTS_FILTER_TYPES.DISCOUNTS_SEARCH]: ['search']
} as const;

enum DISCOUNTS_MORE_OPTIONS_TYPES {
  DISCOUNTS_SEARCH = 'DISCOUNTS_SEARCH'
}

const DISCOUNTS_FILTER_TYPES_NAMES: Record<DISCOUNTS_FILTER_TYPES, string> = {
  [DISCOUNTS_FILTER_TYPES.DISCOUNTS_SEARCH]: 'Search'
};

const DISCOUNTS_MORE_OPTIONS_TITLES: Record<DISCOUNTS_MORE_OPTIONS_TYPES, string> = {
  [DISCOUNTS_MORE_OPTIONS_TYPES.DISCOUNTS_SEARCH]: ''
};

const DISCOUNTS_MORE_OPTIONS_DROPDOWN_TYPES: Record<DISCOUNTS_MORE_OPTIONS_TYPES, OPTION_DROPDOWN_TYPES> = {
  [DISCOUNTS_MORE_OPTIONS_TYPES.DISCOUNTS_SEARCH]: OPTION_DROPDOWN_TYPES.TEXT_INPUT
};

const DISCOUNTS_FILTER_TYPES_UNITS: Record<DISCOUNTS_FILTER_TYPES, string> = {
  [DISCOUNTS_FILTER_TYPES.DISCOUNTS_SEARCH]: ''
};
const DISCOUNTS_FILTER_TYPES_MORE_OPTIONS: Record<DISCOUNTS_FILTER_TYPES, DISCOUNTS_MORE_OPTIONS_TYPES[]> = {
  [DISCOUNTS_FILTER_TYPES.DISCOUNTS_SEARCH]: [DISCOUNTS_MORE_OPTIONS_TYPES.DISCOUNTS_SEARCH]
};

const DISCOUNTS_MORE_OPTIONS_ITEMS: Record<DISCOUNTS_MORE_OPTIONS_TYPES, { value: string | boolean; name: string }[] | []> = {
  [DISCOUNTS_MORE_OPTIONS_TYPES.DISCOUNTS_SEARCH]: []
};

const useDiscountsFilters = () => {
  const filtersControl = useFilters<DISCOUNTS_FILTER_TYPES, DISCOUNTS_MORE_OPTIONS_TYPES>({
    toCall: [],
    getOptions: () => ({
      FILTER_TYPES: DISCOUNTS_FILTER_TYPES,
      FILTER_TYPES_MORE_OPTIONS: DISCOUNTS_FILTER_TYPES_MORE_OPTIONS,
      FILTER_TYPES_NAMES: DISCOUNTS_FILTER_TYPES_NAMES,
      FILTER_TYPES_UNITS: DISCOUNTS_FILTER_TYPES_UNITS,
      MORE_OPTIONS_DROPDOWN_TYPES: DISCOUNTS_MORE_OPTIONS_DROPDOWN_TYPES,
      MORE_OPTIONS_TITLES: DISCOUNTS_MORE_OPTIONS_TITLES,
      MORE_OPTIONS_TYPES: DISCOUNTS_MORE_OPTIONS_TYPES,
      MORE_OPTIONS_ITEMS: DISCOUNTS_MORE_OPTIONS_ITEMS
    })
  });

  return filtersControl;
};
const getDiscountsFiltersVariables = ({
  defaultVariables,
  selectedFilters,
  overriddenFilters
}: {
  defaultVariables?: Record<string, any>;
  selectedFilters: SelectedFilter[];
  overriddenFilters?: Partial<Record<(typeof DISCOUNTS_FILTERS_QUERIES)[keyof typeof DISCOUNTS_FILTERS_QUERIES][number], any>>;
}) => {
  const getFilterValue = (filter: DISCOUNTS_FILTER_TYPES) => getFilter(selectedFilters, filter);

  // search
  const searchFilter = getFilterValue(DISCOUNTS_FILTER_TYPES.DISCOUNTS_SEARCH);
  const searchFilterValue = getMoreOptionsValues(searchFilter, DISCOUNTS_MORE_OPTIONS_TYPES.DISCOUNTS_SEARCH);

  const variables = {
    ...defaultVariables,
    search: searchFilterValue ? [searchFilterValue] : null,
    requisite_queries: Object.values(DISCOUNTS_FILTER_TYPES)
      .map(filter => {
        const overriddenFiltersValues = DISCOUNTS_FILTERS_QUERIES[filter]?.filter(query => overriddenFilters?.[query]);
        if (overriddenFiltersValues?.length) {
          return overriddenFiltersValues;
        }
        const filterType = getFilterValue(filter);
        return filterType?.requisite === 'true' ? DISCOUNTS_FILTERS_QUERIES[filter] : null;
      })
      .filter(Boolean)
      .flat(),
    alternative_queries: Object.values(DISCOUNTS_FILTER_TYPES)
      .map(filter => {
        const overriddenFiltersValues = DISCOUNTS_FILTERS_QUERIES[filter]?.filter(query => overriddenFilters?.[query]);
        if (overriddenFiltersValues?.length) {
          return null;
        }
        const filterType = getFilterValue(filter);
        return filterType?.requisite === 'false' ? DISCOUNTS_FILTERS_QUERIES[filter] : null;
      })
      .filter(Boolean)
      .flat()
  };

  return variables;
};

export { DISCOUNTS_FILTER_TYPES, DISCOUNTS_MORE_OPTIONS_TYPES, HIDDEN_DISCOUNTS_FILTERS, getDiscountsFiltersVariables, useDiscountsFilters };
