import React, { FC } from 'react';

import { useQuery, useReactiveVar } from '@apollo/client';
import { GetBranchBusUsersAppointments, GetBranchProductsAppointments, GetBusUserProfile, GetListBranchAppointments } from '../../../../queries';

import { BusUserProfile } from '../../../../components/Profile/types';
import { FiltersControl } from '../../../../components/Shared/Filters/useFilters';
import { InfiniteListGroup } from '../../../../components/Shared/InfiniteList/InfiniteList';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import usePaginatedQuery from '../../../../hooks/usePaginatedQuery';
import useURLSearchParams from '../../../../hooks/useURLSearchParams';
import { vars } from '../../../../reactive';
import { formatDateDay, getNextDay, getStartOfDate } from '../../../../utils/dates';
import { Product } from '../../../Store/types';
import { BOOKINGS_FILTER_TYPES, BOOKINGS_MORE_OPTIONS_TYPES, getBookingsVariables } from '../../BookingsFilters';
import { GroupHeader } from '../../styled';
import { BOOKING_RECORD_VIEW_SETTINGS, BOOKING_STATUS_TYPES, BOOKING_TAB, BOOKING_TABS_TYPES, BOOKING_TABS_VALUES_TYPES, BOOKING_TYPE, Booking, BookingOrder } from '../../types';
import BookingsList, { BookingsListAppointmentsList } from '../BookingsList/BookingsList';

type BookingsBodyProps = {
  tabs: BOOKING_TAB[];
  services: Product[];
  filtersControl: FiltersControl<BOOKINGS_FILTER_TYPES, BOOKINGS_MORE_OPTIONS_TYPES>;
};

const BookingsBody: FC<BookingsBodyProps> = ({ tabs, services, filtersControl }) => {
  const { mobile } = useMediaQuery({ mobile: true });
  const { selectedFilters } = filtersControl;
  const [selectedServices] = useURLSearchParams<string[]>('services');
  const [selectedStatuses] = useURLSearchParams<string[]>('status');
  const [selectedStaffMembers] = useURLSearchParams('staff');
  const [urlDate] = useURLSearchParams('date');
  const isMultiStaffView = useReactiveVar(vars.isMultiStaffView);
  const isAllServices = !selectedServices?.length;
  const isAllStaffMembers = !selectedStaffMembers?.length;
  const activeViewSettings = useReactiveVar(vars.activeViewSettings);

  const { data: { getBusUserProfile: userProfile } = {} } = useQuery<{ getBusUserProfile: BusUserProfile }>(GetBusUserProfile, {
    fetchPolicy: 'cache-only'
  });

  const servicesIds = services.map(service => service.id);
  const filteredServices = servicesIds.filter(id => selectedServices.includes(id));
  const filteredServicesNames = services.filter(service => filteredServices.includes(service.id)).map(service => service.name);
  const filteredServicesDetails = services.filter(service => filteredServices.includes(service.id));

  const selectedDate = new Date(urlDate)?.getTime() ? new Date(urlDate) : new Date();

  const tabsValues = tabs.map(tab => tab.value);
  const tabValue = tabsValues?.find(tab => tab === location.pathname.split('/')[3]) || BOOKING_TABS_TYPES.CONFIRMED;
  const selectedTab = tabs.find(tab => tab.value === tabValue);
  const isConfirmed = selectedTab?.value === BOOKING_TABS_VALUES_TYPES.CONFIRMED;
  const isUpcoming = selectedTab?.value === BOOKING_TABS_VALUES_TYPES.UPCOMING;

  const {
    data: { getBranchProductsAppointments: { productsAppointments = [] } = {} } = {},
    loading: loadingServices,
    refetch
  } = useQuery<{
    getBranchProductsAppointments: {
      productsAppointments: {
        Product: Product;
        Appointments: Booking[];
      }[];
    };
  }>(GetBranchProductsAppointments, {
    fetchPolicy: 'cache-and-network',
    variables: {
      ...getBookingsVariables({
        selectedFilters,
        defaultVariables: {
          ProductId: filteredServices.length ? filteredServices : null,
          status: selectedTab?.status,
          booking_type: selectedTab?.booking_type,
          timestamp_from: selectedTab?.timestamp_from,
          timestamp_to: selectedTab?.timestamp_to,
          filter_by_role: userProfile?.role,
          ...BOOKING_RECORD_VIEW_SETTINGS.map(settings => ({ [settings.id]: false })).reduce((acc, curr) => ({ ...acc, ...curr }), {}),
          ...(activeViewSettings?.record ? { [activeViewSettings.record]: true } : {}),
          BranchId: userProfile?.Branch.id
        }
      })
    },
    skip: !isConfirmed || isMultiStaffView
  });

  const paginatedQueryResult = usePaginatedQuery<Booking[]>({
    query: GetListBranchAppointments,
    otherVariables: {
      ...getBookingsVariables({
        selectedFilters,
        defaultVariables: {
          status: selectedTab?.status,
          booking_type: selectedTab?.booking_type,
          timestamp_from: selectedTab?.timestamp_from,
          timestamp_to: selectedTab?.timestamp_to,
          filter_by_role: userProfile?.role,
          order_status: selectedTab?.order_status || null,
          ...BOOKING_RECORD_VIEW_SETTINGS.map(settings => ({ [settings.id]: false })).reduce((acc, curr) => ({ ...acc, ...curr }), {}),
          ...(activeViewSettings?.record ? { [activeViewSettings.record]: true } : {}),
          BranchId: userProfile?.Branch.id
        },
        overriddenFilters: {
          appointment_product_name: filteredServices.length ? filteredServicesNames : null
        }
      })
    },
    otherParams: {
      fetchPolicy: 'cache-and-network',
      skip: isConfirmed || isMultiStaffView
    }
  });

  const { data: { getBranchAppointments: bookingOrderAppointments = [] } = {}, loading: loadingAppointments, refetch: refetchAppointments } = paginatedQueryResult?.[1] || {};

  const { data: { getBranchBusUsersAppointments: { busUsersAppointments = [] } = {} } = {}, loading: loadingUsers } = useQuery<{
    getBranchBusUsersAppointments: {
      busUsersAppointments: {
        BusUser: BusUserProfile;
        Appointments: Booking[];
      }[];
    };
  }>(GetBranchBusUsersAppointments, {
    fetchPolicy: 'cache-and-network',
    variables: {
      ...getBookingsVariables({
        selectedFilters,
        defaultVariables: {
          BusUserId: selectedStaffMembers?.length ? selectedStaffMembers : null,
          status: [BOOKING_STATUS_TYPES.CONFIRMED],
          booking_type: [BOOKING_TYPE.SLOT],
          timestamp_from: getStartOfDate(selectedDate),
          timestamp_to: getStartOfDate(getNextDay(selectedDate)),
          ...BOOKING_RECORD_VIEW_SETTINGS.map(settings => ({ [settings.id]: false })).reduce((acc, curr) => ({ ...acc, ...curr }), {}),
          ...(activeViewSettings?.record ? { [activeViewSettings.record]: true } : {}),
          BranchId: userProfile?.Branch.id
        }
      })
    },
    skip: !isMultiStaffView
  });

  const busUsers = userProfile?.Branch?.BusUsers;

  const productsAppointmentsProducts = productsAppointments?.map(p => p.Product);

  const allProducts = productsAppointmentsProducts?.length ? productsAppointmentsProducts : services;

  const productsToDisplay = isAllServices ? allProducts : filteredServicesDetails;

  const staffIds = busUsers?.map(user => user.id);
  const filteredStaff = staffIds?.filter(id => selectedStaffMembers.includes(id));
  const filteredStaffDetails = busUsers?.filter(user => filteredStaff?.includes(user.id));
  const staffMembers = isAllStaffMembers ? busUsers : filteredStaffDetails;

  // const statuses = selectedStatuses?.length ? selectedStatuses : ['UPCOMING', 'ACTIVE'];
  const statuses = selectedStatuses?.length && selectedStatuses;
  // const multiDayStatusesToDispaly = multiDayToolBarButtons.filter(button => statuses.includes(button.id));

  const itemsToDisplay = isConfirmed && !isMultiStaffView ? productsToDisplay : isMultiStaffView ? staffMembers : [];

  const group = (bookingsList: BookingOrder[]) =>
    ({
      by: (booking: Booking) => {
        const appointment = booking;
        const multiDayAppointment = appointment.timestamp_until;
        // if (multiDayAppointment) {
        //   if (new Date().getTime() <= new Date(appointment.timestamp).getTime()) {
        //     return 'Upcoming';
        //   }
        //   return 'Active';
        // }
        console.log('appointment', new Date(appointment.timestamp).getDate());
        return `${formatDateDay(appointment?.timestamp)}`;
      },
      separator: (seperatedBookings: Booking[], groupLabel: string) => {
        const multiDayBookings = !!seperatedBookings[0].timestamp_until;
        const pets = seperatedBookings.map(booking => booking?.PetRecord?.id);
        const uniquePets = [...new Set(pets)];

        // if (multiDayBookings) {
        //   return (
        //     <GroupHeader>
        //       <strong>{groupLabel}</strong>
        //     </GroupHeader>
        //   );
        // }
        return (
          <GroupHeader>
            <span style={{ fontWeight: 800 }}>{groupLabel} | </span>
            <span style={{ fontWeight: 600 }}>
              {uniquePets.length} {uniquePets.length === 1 ? 'Pet' : 'pets'}
            </span>
          </GroupHeader>
        );
      }
    } as InfiniteListGroup);

  const appointmentsList = (
    isConfirmed && !isMultiStaffView
      ? productsAppointments
      : isMultiStaffView
      ? busUsersAppointments
      : [
          {
            Appointments: bookingOrderAppointments
          }
        ]
  ) as BookingsListAppointmentsList;

  const loadingAppointmentsProducts = isConfirmed ? loadingServices : loadingAppointments;
  const refetchAppointmentsProducts = isConfirmed ? refetch : refetchAppointments;

  return (
    <BookingsList
      appointmentsList={appointmentsList}
      loadingAppointments={loadingAppointmentsProducts}
      refetchAppointments={refetchAppointmentsProducts}
      selectedTab={selectedTab}
      itemsToDisplay={itemsToDisplay}
      paginatedQueryResult={paginatedQueryResult}
      group={isUpcoming ? group : undefined}
      filtersControl={filtersControl}
    />
  );
};

export default BookingsBody;
