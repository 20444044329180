import { useMutation, useQuery } from '@apollo/client';
import React, { FC, useEffect } from 'react';
import { Controller, UseFormMethods, useForm } from 'react-hook-form';
import ModalDialog from '../../../components/Modal/ModalDialog';
import { ModalBody, ModalFooter } from '../../../components/Modal/styled';
import { FlexContainer } from '../../../components/Pets/styled';
import { Pet } from '../../../components/Pets/types';
import { FormButtonsContainer, FormError, FormInput, FormLabel, FormSelect, FormSubmitButton, InputsWrapper, SectionLabel, WideInputGroup } from '../../../components/Shared/Forms/Forms';
import { Container } from '../../../components/Shared/Shared';
import { EditPetRecord, GetAllCountries } from '../../../queries';
import { Country } from '../../Store/types';

type VetInsurerPetRecordModalFormProps = {
  onSubmit: () => void;
  loading: boolean;
  error: boolean;
  formOptions: UseFormMethods<FormType>;
  defaultValues: Partial<FormType>;
  countries: Country[];
};

const VetPetRecordModalForm: FC<VetInsurerPetRecordModalFormProps> = ({ loading, error, formOptions, onSubmit, defaultValues, countries }) => {
  const { control, errors } = formOptions;

  return (
    <>
      <ModalBody>
        <Container>
          <InputsWrapper noWrap>
            <WideInputGroup>
              <FormLabel>Practice</FormLabel>
              <Controller
                as={<FormInput error={errors.practice} type={'text'} flexBasis={78} />}
                control={control}
                name={'practice'}
                defaultValue={defaultValues?.practice || ''}
                rules={{ required: false }}
              />
              {errors.practice && <FormError>{errors.practice.message || 'Practice is required'}</FormError>}
            </WideInputGroup>
            <WideInputGroup>
              <SectionLabel>Address</SectionLabel>
              <FormLabel>Country</FormLabel>
              <Controller
                render={({ onChange, value }) => (
                  <FormSelect error={!!errors?.address?.country} height={48} fontSize={16} value={value} onChange={onChange}>
                    {countries.map(country => (
                      <option key={country.id} value={country.id}>
                        {country.name}
                      </option>
                    ))}
                  </FormSelect>
                )}
                control={control}
                name={'address.country'}
                defaultValue={defaultValues?.address?.country || String(countries[0]?.id) || ''}
                rules={{ required: 'Please add your country' }}
              />
            </WideInputGroup>
            <WideInputGroup>
              <FormLabel>City</FormLabel>
              <Controller
                as={<FormInput error={!!errors?.address?.city} type={'text'} name={'address.city'} />}
                control={control}
                name={'address.city'}
                defaultValue={defaultValues?.address?.city || ''}
                rules={{ pattern: /^[a-zA-Z\s]{3,}$/ }}
              />
              {errors?.address?.city && <FormError>{errors?.address?.city?.message || 'Please add a valid city'}</FormError>}
            </WideInputGroup>
            <WideInputGroup>
              <FormLabel>Address Line 1</FormLabel>
              <Controller
                as={<FormInput error={!!errors?.address?.line1} type={'text'} name={'address.line1'} />}
                control={control}
                name={'address.line1'}
                defaultValue={defaultValues?.address?.line1 || ''}
                rules={{ pattern: /^[a-zA-Z0-9\s,.'-]{3,}$/ }}
              />
              {errors?.address?.line1 && <FormError>{errors?.address?.line1?.message || 'Please add a valid address'}</FormError>}
            </WideInputGroup>
            <WideInputGroup>
              <FormLabel>Address Line 2</FormLabel>
              <Controller
                as={<FormInput error={!!errors?.address?.line2} type={'text'} name={'address.line2'} />}
                control={control}
                name={'address.line2'}
                defaultValue={defaultValues?.address?.line2 || ''}
                rules={{ pattern: /^[a-zA-Z0-9\s,.'-]{3,}$/ }}
              />
              {errors?.address?.line2 && <FormError>{errors?.address?.line2?.message || 'Please add a valid address'}</FormError>}
            </WideInputGroup>

            <WideInputGroup>
              <SectionLabel>Phone</SectionLabel>

              <FlexContainer gap={6}>
                <Controller
                  as={<FormInput error={!!errors?.phoneNumber?.country_code} type={'text'} name={'phoneNumber.country_code'} width={80} />}
                  control={control}
                  name={'phoneNumber.country_code'}
                  defaultValue={defaultValues?.phoneNumber?.country_code}
                  rules={{ pattern: /^(\+?\d{1,3}|\d{1,4})$/ }}
                />
                <Controller
                  as={<FormInput error={!!errors?.phoneNumber?.number} type={'text'} name={'phoneNumber.number'} fullWidth />}
                  control={control}
                  name={'phoneNumber.number'}
                  defaultValue={defaultValues?.phoneNumber?.number || ''}
                  rules={{ pattern: /^[a-zA-Z0-9\s,.'-]{3,}$/ }}
                />
              </FlexContainer>
              {errors?.phoneNumber?.country_code && <FormError>{errors?.phoneNumber?.country_code?.message || 'Please add a valid country code'}</FormError>}
              {errors?.phoneNumber?.number && <FormError>{errors?.phoneNumber?.number?.message || 'Please add a valid number'}</FormError>}
            </WideInputGroup>

            <WideInputGroup>
              <FormLabel>Email</FormLabel>
              <Controller as={<FormInput error={errors.email} type={'text'} flexBasis={78} />} control={control} name={'email'} defaultValue={defaultValues?.email || ''} rules={{ required: false }} />

              {errors.email && <FormError>{errors.email.message || 'Email is required'}</FormError>}
            </WideInputGroup>
          </InputsWrapper>
        </Container>
      </ModalBody>
      <ModalFooter>
        <FormButtonsContainer>
          <FormSubmitButton error={error} loading={loading} onClick={onSubmit}>
            Update
          </FormSubmitButton>
        </FormButtonsContainer>
      </ModalFooter>
    </>
  );
};

type FormType = {
  practice: string;
  address: {
    country: string;
    city: string;
    line1: string;
    line2: string;
  };
  email: string;

  phoneNumber: {
    country_code: string;
    number: string;
  };
};

const VetPetRecordModal = ({ petVetInsurer }: { petVetInsurer: Pet }) => {
  const { data: { countryGet: countries = [] } = {} } = useQuery<{ countryGet: Country[] }>(GetAllCountries, {
    fetchPolicy: 'cache-first'
  });

  const getCountryIdFromName = (name: string) => String(countries.find(country => country.name === name)?.id) || '';
  const getCountryNameFromId = (id: string) => countries.find(country => String(country.id) === id)?.name || '';
  const vet = petVetInsurer?.PetRecord?.vet || {};
  const practice = vet?.practice;
  const address = vet?.address;
  const phone = vet?.phone;
  const email = vet?.email;

  const defaultValues: FormType = {
    practice: practice || '',
    address: {
      country: getCountryIdFromName(address?.country || 'United Kingdom') || (countries[0]?.id ? String(countries[0]?.id) : '1'),
      city: address?.city || 'London',
      line1: address?.line1 || '',
      line2: address?.line2 || ''
    },

    email: email || '',
    phoneNumber: {
      country_code: phone?.country_code || '+44',
      number: phone?.number || ''
    }
  };
  const formOptions = useForm<FormType>({ defaultValues });
  const { handleSubmit } = formOptions;

  const [handleEditPetRecord, { data: editedPetRecord, loading: loadingEditPetRecord, error: errorEditPetRecord }] = useMutation(EditPetRecord);

  const onSubmit = handleSubmit(form => {
    const addressToSend = { ...form.address, country: getCountryNameFromId(form.address.country) };

    handleEditPetRecord({
      variables: {
        id: petVetInsurer?.PetRecord?.id,
        vet: {
          practice: form.practice,
          address: addressToSend,
          phone: form.phoneNumber,
          email: form.email
        }
      }
    });
  });

  useEffect(() => {
    if (editedPetRecord?.editPetRecord?.id) {
      ModalDialog?.closeModal();
    }
  }, [editedPetRecord]);

  const loading = loadingEditPetRecord;
  const error = !!errorEditPetRecord?.message;

  return <VetPetRecordModalForm onSubmit={onSubmit} loading={loading} error={error} formOptions={formOptions} defaultValues={defaultValues} countries={countries} />;
};

export default VetPetRecordModal;
