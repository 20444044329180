import { TransformedCustomization, Customizations as CustomizationsType, Customizations } from './types';

export const getSelectedCustomizations = (customizations?: TransformedCustomization[]) => {
  const customizationsState: CustomizationsType = (customizations || [])?.reduce((acc, customization) => {
    return {
      ...acc,
      [customization.name]: customization.options.map(option => ({
        title: option.title,
        price: option.price,
        meta: option.meta.type !== 'none' ? { [option.meta.type]: option.meta.value } : 'none',
        rules: option.rules?.filter(rule => rule.type !== 'none')
      }))
    };
  }, {});

  return customizationsState;
};

export const getCustomizationsDefaultValues = (defaultValue?: Customizations) => {
  const transformedValue = Object.keys(defaultValue || {}).map(key => ({
    name: key,
    options: (defaultValue || {})[key]?.map(item => {
      const metaKey = Object.keys(item.meta || {})?.[0];
      const meta = item.meta && item.meta !== 'none' ? metaKey : 'none';
      const metaValue = item.meta && item.meta !== 'none' ? item.meta[metaKey] : '';
      return {
        title: item.title,
        price: item.price,
        meta: {
          type: meta,
          value: metaValue
        },
        rules: item.rules
      };
    })
  }));

  return transformedValue;
};
