import RRule, { Options } from 'rrule';
import { sentenceCase } from 'sentence-case';
import { WEEK_DAYS, WEEK_DAYS_MAP } from '../../../../utils/dates';
import { ValueOfArrayKeys } from '../../../../utils/helpers';
import { BOOKING_TYPE } from '../../../Bookings/types';
export type ALLOWED_FREQUENCIES = 'WEEKLY' | 'MONTHLY' | 'YEARLY';

export type BranchScheduleForm = {
  name: string;
  booking_type: BOOKING_TYPE | 'OTHER' | 'ONE_OFF';
  slots_recurrence: number;
  slots_start_date: string | null;
  numberOfSlots: number;
  selectedSlots: {
    time: string;
    frequency: ALLOWED_FREQUENCIES;
    interval: number;
    count: number;
    selectedDays: ValueOfArrayKeys<
      typeof WEEK_DAYS_MAP,
      {
        isSelected: boolean;
        numberOfSlots: number;
        nth: number;
      }
    >;
    extra_dates: string[];
  }[];
  closingSlots: {
    selectedDays: ('MO' | 'TU' | 'WE' | 'TH' | 'FR' | 'SA' | 'SU')[];
    time: string;
  }[];
};

export const frequencies: { label: string; value: ALLOWED_FREQUENCIES; title: string }[] = [
  { label: 'Monthly', value: 'MONTHLY', title: 'Month' },
  { label: 'Weekly', value: 'WEEKLY', title: 'Week' },
  { label: 'Yearly', value: 'YEARLY', title: 'Year' }
];

export const typesOptions = [
  { id: 'SLOT', name: 'Single Slot' },
  { id: 'MULTI_SLOT', name: 'Multiple Sessions' },
  { id: 'MULTI_DAY', name: 'Boarding' },
  { id: 'OTHER', name: 'Recurring Sessions' },
  { id: 'ONE_OFF', name: 'One-Off' }
];

export const defaultRRule = new RRule({
  freq: RRule.WEEKLY,
  interval: 1,
  count: 1,
  byweekday: [RRule.MO],
  byhour: [1],
  byminute: [0]
}).toString();
export const nthDays: { [key in ALLOWED_FREQUENCIES]: number[] } = {
  MONTHLY: Array.from(Array(4).keys()).map(i => i + 1),
  WEEKLY: [1],
  YEARLY: Array.from(Array(56).keys()).map(i => i + 1)
};
export const rruleToText = (options: Partial<Options>) => {
  try {
    const rrule = new RRule({
      ...options
    }).toText();

    return typeof rrule === 'string' ? sentenceCase(rrule) : 'Wrong format';
  } catch (e) {
    return 'Wrong format';
  }
};

export const getFullWeekDayFrom2Letter = (day: string) => WEEK_DAYS[WEEK_DAYS_MAP.findIndex(weekDay => weekDay.toLowerCase() === day.toLowerCase())];

export const getThreeLetterWeekDay = (day: string) => day.slice(0, 3).toUpperCase();

export const defaultSlots = [
  {
    available: 0,
    time: defaultRRule,
    available_by_day: WEEK_DAYS_MAP.reduce((acc, day) => {
      acc[day] = 0;
      return acc;
    }, {} as { [key: string]: number }),
    extra_dates: [],
    is_closing: false
  }
];
