import React from 'react';
import { FormError, FormInput, FormLabel, InputGroup, InputsWrapper, selectTheme } from '../../../../components/Shared/Forms/Forms';
import { allowBookingFromOptions, petAgeTypeOptions, petMaxNumberOptions, petTypesOptions, StoreProductModalSectionsCommonProps } from './types';
import { Controller } from 'react-hook-form';
import BreedsList from '../../../../components/Shared/Lists/BreedsList';
import ProductsList from '../../../../components/Shared/Lists/ProductsList';
import Select from '../../../../components/Shared/Forms/Select';
import { FlexRow } from '../../../../components/Shared/Shared';
import { Divider } from '../../../Pets/Health/styled';

const StoreProductModalBookingRules = ({ formOptions, defaultValues }: StoreProductModalSectionsCommonProps) => {
  const { control, errors, watch, setValue } = formOptions;

  const watchedPetRules = watch('pet_rules');
  const watchedAllowBookingFrom = watch('allow_booking_from');
  const watchedMaxPetCount = watch('pet_rules.max_pet_count_type');

  return (
    <InputsWrapper noWrap>
      <FlexRow gap={10} width="400px">
        <InputGroup width={200}>
          <FormLabel>Max pet count per booking</FormLabel>
          <Controller
            render={({ onChange, value }) => {
              return (
                <Select
                  theme={selectTheme}
                  options={petMaxNumberOptions}
                  value={{ value, label: petMaxNumberOptions.find(option => option.value === value)?.label }}
                  onChange={option => {
                    const newValue = option?.value;

                    setValue('pet_rules.max_pet_count', newValue === 'no_limit' ? 0 : 1);

                    onChange(newValue);
                  }}
                />
              );
            }}
            control={control}
            name={'pet_rules.max_pet_count_type'}
            defaultValue={defaultValues?.pet_rules?.max_pet_count_type}
          />
        </InputGroup>
        {watchedMaxPetCount === 'limited' && (
          <InputGroup flex1>
            <FormLabel>Pet count</FormLabel>
            <Controller
              as={<FormInput error={errors.pet_rules?.max_pet_count?.message} type="number" min={1} />}
              control={control}
              name={'pet_rules.max_pet_count'}
              defaultValue={defaultValues?.pet_rules?.max_pet_count}
              rules={{ required: true, min: 1 }}
            />
            {errors.pet_rules?.max_pet_count && <FormError>{errors.pet_rules?.max_pet_count.message || 'Max pet count is required'}</FormError>}
          </InputGroup>
        )}
      </FlexRow>
      <Divider verticalMargin={20} />
      <InputGroup>
        <FormLabel>Allowed pet types</FormLabel>
        <Controller
          render={({ onChange, value }) => {
            return (
              <Select
                isMulti
                theme={selectTheme}
                options={petTypesOptions}
                onChange={values => {
                  const newValues = values.map(option => option.value);
                  const removedValues = value.filter((val: string) => !newValues.includes(val));
                  onChange(newValues);
                  if (removedValues.length) {
                    setValue('pet_rules.excluded_pet_breeds', []);
                  }
                }}
                value={petTypesOptions.filter(option => value.includes(option.value))}
              />
            );
          }}
          control={control}
          name={'pet_rules.allowed_pet_types'}
          defaultValue={defaultValues?.pet_rules?.allowed_pet_types}
        />
        {errors.pet_rules?.allowed_pet_types && <FormError>{errors.pet_rules?.allowed_pet_types.filter(Boolean).join(', ') || 'Allowed pet types are required'}</FormError>}
      </InputGroup>

      <InputGroup>
        <FormLabel>Excluded pet breeds</FormLabel>
        <BreedsList
          formOptions={formOptions}
          name="pet_rules.excluded_pet_breeds"
          petType={watchedPetRules?.allowed_pet_types}
          defaultValues={defaultValues?.pet_rules?.excluded_pet_breeds}
          isMulti
          rules={{
            required: true
          }}
        />
        {errors.pet_rules?.excluded_pet_breeds && <FormError>{errors.pet_rules?.excluded_pet_breeds.filter(Boolean).join(', ') || 'Excluded pet breeds are required'}</FormError>}
      </InputGroup>

      <Divider verticalMargin={20} />

      <FlexRow gap={10} width="400px">
        <InputGroup width={160}>
          <FormLabel>Block bookings from Pets</FormLabel>
          <Controller
            render={({ onChange, value }) => {
              return (
                <Select
                  theme={selectTheme}
                  options={petAgeTypeOptions}
                  value={{ value: value, label: petAgeTypeOptions.find(option => option.value === value)?.label }}
                  onChange={option => {
                    const newValue = option?.value;
                    if (['none', 'above'].includes(newValue)) {
                      setValue('pet_rules.pet_max_age', null);
                    }
                    if (['none', 'below'].includes(newValue)) {
                      setValue('pet_rules.pet_min_age', null);
                    }
                    onChange(newValue);
                  }}
                />
              );
            }}
            control={control}
            name={'pet_rules.pet_age_type'}
            defaultValue={defaultValues?.pet_rules?.pet_age_type}
          />
        </InputGroup>
        {['above', 'between'].includes(watchedPetRules?.pet_age_type) && (
          <InputGroup flex1>
            <FormLabel error={!!errors.pet_rules?.pet_min_age}>Min age</FormLabel>
            <Controller
              as={<FormInput error={!!errors.pet_rules?.pet_min_age} />}
              control={control}
              name={'pet_rules.pet_min_age'}
              defaultValue={defaultValues?.pet_rules?.pet_min_age}
              rules={{ required: true }}
            />
          </InputGroup>
        )}
        {['below', 'between'].includes(watchedPetRules?.pet_age_type) && (
          <InputGroup flex1>
            <FormLabel error={!!errors.pet_rules?.pet_max_age}>Max age</FormLabel>
            <Controller
              as={<FormInput error={!!errors.pet_rules?.pet_max_age} />}
              control={control}
              name={'pet_rules.pet_max_age'}
              defaultValue={defaultValues?.pet_rules?.pet_max_age}
              rules={{ required: true }}
            />
          </InputGroup>
        )}
      </FlexRow>

      <Divider verticalMargin={20} />

      <FlexRow gap={10} width="400px">
        <InputGroup width={160}>
          <FormLabel>Allow bookings from</FormLabel>
          <Controller
            render={({ onChange, value }) => {
              return (
                <Select
                  theme={selectTheme}
                  options={allowBookingFromOptions}
                  value={{ value: value, label: allowBookingFromOptions.find(option => option.value === value)?.label }}
                  onChange={option => {
                    const newValue = option?.value;
                    if (['no_rule'].includes(newValue)) {
                      setValue('product_subscription_dependencies', []);
                    }
                    onChange(newValue);
                  }}
                />
              );
            }}
            control={control}
            name={'allow_booking_from'}
            defaultValue={defaultValues?.allow_booking_from}
          />
        </InputGroup>
        {watchedAllowBookingFrom === 'subscribed_to' && (
          <InputGroup flex1>
            <ProductsList
              formOptions={formOptions}
              name="product_subscription_dependencies"
              defaultValues={defaultValues?.product_subscription_dependencies}
              type={['subscription']}
              isMulti
              rules={{
                required: true
              }}
            />
          </InputGroup>
        )}
      </FlexRow>
    </InputsWrapper>
  );
};

export default StoreProductModalBookingRules;
