import React, { FC } from 'react';
import Filters from '../../../components/Shared/Filters/Filters';
import SearchBar from '../../../components/Shared/Filters/SearchBar';
import { getFilterKeyOptions } from '../../../components/Shared/Filters/helpers';
import { FiltersControl } from '../../../components/Shared/Filters/useFilters';
import { TabsHeaderContainer } from '../../../components/Shared/Shared';
import ToolBar from '../../../components/Shared/ToolBar/ToolBar';
import useMediaQuery from '../../../hooks/useMediaQuery';
import { areObjectsEqual } from '../../../utils/helpers';
import { PETS_TABS_TYPES } from '../Pets';
import { PETS_FILTER_TYPES, PETS_MORE_OPTIONS_TYPES } from '../PetsFilters';
import PetTagsQuickFilter from './PetTagsQuickFilter';

type PetsHeaderProps = {
  filtersControl: FiltersControl<PETS_FILTER_TYPES, PETS_MORE_OPTIONS_TYPES>;
  showFilters?: boolean;
  SelectedTab: PETS_TABS_TYPES;
};

const petsToolBarButtonsAll = [
  { name: 'Dogs', id: 'DOGS' },
  { name: 'Cats', id: 'Cats' }
];

const PetsHeader: FC<PetsHeaderProps> = ({ filtersControl, showFilters = true, SelectedTab }) => {
  const { mobile } = useMediaQuery({ mobile: true });

  const { onChange } = getFilterKeyOptions({
    filtersControl,
    keys: {
      filterType: PETS_FILTER_TYPES.SEARCH,
      moreOption: PETS_MORE_OPTIONS_TYPES.SEARCH
    }
  });

  const { headerAction } = filtersControl;
  const isAllTabSelected = SelectedTab === PETS_TABS_TYPES.ALL.toLowerCase();

  const petToolBarButtons = isAllTabSelected && !mobile ? petsToolBarButtonsAll : [];

  return (
    <>
      {!mobile && <ToolBar toolBarButtons={petToolBarButtons} urlSearchParam="status" onSearchChange={onChange} middleAction={headerAction} />}
      {mobile && SelectedTab !== PETS_TABS_TYPES.CLIENTS.toLowerCase() && <SearchBar onSearch={onChange} showAlways />}
      {showFilters && (
        <>
          {!mobile && <Filters filtersControl={filtersControl} />}
          {!mobile && SelectedTab !== PETS_TABS_TYPES.FORMS.toLowerCase() && SelectedTab !== PETS_TABS_TYPES.CLIENTS.toLowerCase() && (
            <TabsHeaderContainer padding="12px 20px">
              <PetTagsQuickFilter filtersControl={filtersControl} SelectedTab={SelectedTab} />
            </TabsHeaderContainer>
          )}
        </>
      )}
    </>
  );
};

export default React.memo(PetsHeader, areObjectsEqual);
