import React from 'react';
import { UseFieldArrayMethods, UseFormMethods } from 'react-hook-form';
import useMemoizedFullDates, { Slot, getRRuleWeekDays } from '../../../../hooks/useMemoizedFullDates';
import { SUN_THREE_DAYS, THREE_LETTER_WEEK_DAYS } from '../../../../utils/dates';
import { isValidURL } from '../../../../utils/validators';
import { BOOKING_TYPE } from '../../../../views/Bookings/types';
import { BranchAppUser } from '../../../../views/Store/BranchBilling/types';
import { Discount, Voucher } from '../../../../views/Store/Discounts/types';
import { Customization, Product } from '../../../../views/Store/types';
import { Pet } from '../../../Pets/types';
import { BusUserProfile } from '../../../Profile/types';
import { ColorCircle, PatternCircle } from '../../Shared';
import { BranchRoom } from '../../../../views/Store/BranchRooms/types';

export type NewBookingFormType = {
  orderGroups: {
    slots: Slot[];
    productId: string;
    customizations: Record<string, string>;
    recurrenceType: 'one-off' | 'recurring';
    repetition: number;
    bookingDays: string[];
    quantity: number;
  }[];
  appUserId: string;
  discountId?: string;
  voucherId?: string;
  overrideTotal: boolean;
  total: number;
  pets: string[];
  ignoreStock: boolean;
  autoConfirm: boolean;
  busUserId: BusUserProfile[];
  branchRoomId: BranchRoom[];
  disableNotifications: boolean;
};

export type NewBookingModalFormProps = {
  products: Product[];
  loadingStartOrder: boolean;
  onSubmit: (values: NewBookingFormType) => Promise<void>;
  formOptions: UseFormMethods<NewBookingFormType>;
  preOrderSummaryForAppUserItems: { getPreOrderSummaryVariables: () => any; validateValues: () => boolean };
  appUserProfileResponse: Record<string, any>;
  selectedProductsResponse: {
    selectedProducts: Product[];
    loadingSelectedProducts: boolean;
  };
  allPets: Pet[];
  selectedOrderGroups: { slots: Slot[] }[];
  defaultValues: { defaultAppUserId: string; defaultPets: string[]; defaultAppUserProfile: BranchAppUser };
  groupsFullDates: ReturnType<typeof useMemoizedFullDates>;
  orderGroups: UseFieldArrayMethods<NewBookingFormType['orderGroups'][0], 'id'>;
  onRemoveSlotGroup: (index: number) => void;
  children?: React.ReactNode;
  productModalType: boolean;
  discounts: Discount[];
  vouchers: Voucher[];
  busUsers: BusUserProfile[];
};

export const customizationsQuantity = 1 as const;

export const getNewOrderVariables = ({
  selectedCustomizations,
  petsProfiles,
  fullDates,
  selectedProduct,
  appUserId,
  overrideTotal,
  total,
  ignoreStock,
  disableNotifications,
  voucherId,
  autoConfirm,
  busUserId,
  branchRoomId
}: {
  selectedCustomizations: { [key: string]: string };
  petsProfiles: Pet[];
  fullDates: ReturnType<typeof useMemoizedFullDates>[0];
  selectedProduct: Product;
  appUserId: string;
  overrideTotal: boolean;
  total: number | null;
  ignoreStock: boolean;
  disableNotifications: boolean;
  voucherId?: string;
  autoConfirm: boolean;
  busUserId?: BusUserProfile[];
  branchRoomId?: BranchRoom[];
}) => {
  const subscriptionProduct = selectedProduct?.type === 'subscription';
  const hasCustomizations = Object.keys(selectedProduct?.customizations || {}).length;
  const appointments = petsProfiles
    .map(pet =>
      fullDates.map(date => ({
        PetRecordId: pet.PetRecord!.id,
        timestamp: date?.timestamp.fullDate,
        timestamp_until: selectedProduct?.booking_type === BOOKING_TYPE.MULTI_DAY ? date.timestamp_until!.fullDate : null
      }))
    )
    .flat();

  const customizations = hasCustomizations
    ? [selectedCustomizations]?.map(customization => ({
        ...customization,
        quantity: customizationsQuantity
      }))
    : null;

  const subscriptions = petsProfiles.map(pet => ({
    PetRecordId: pet!.PetRecord!.id
  }));

  return {
    items: [{ id: selectedProduct?.id, quantity: 1, customizations }],
    appointments: !subscriptionProduct ? appointments : null,
    subscriptions: subscriptionProduct ? subscriptions : null,
    vouchers: voucherId ? [{ id: voucherId }] : [],
    AppUserId: appUserId,
    overriddenTotal: overrideTotal ? String(total) : null,
    autoConfirm,
    autoConfirmAppointments: !autoConfirm,
    BusUserId: busUserId && busUserId?.length ? busUserId.map(busUser => busUser.id) : null,
    BranchRoomId: branchRoomId && branchRoomId?.length ? branchRoomId.map(room => room.id) : null,
    ignoreStock: ignoreStock || false,
    disableNotifications: disableNotifications || false
  };
};

export const getMultiProductNewOrderVariables = ({
  appUserId,
  overrideTotal,
  total,
  ignoreStock,
  disableNotifications,
  paymentOffSession,
  selectedProductsValues,
  voucherId,
  autoConfirm,
  busUserId,
  branchRoomId
}: {
  appUserId: string;
  overrideTotal: boolean;
  total: number | null;
  ignoreStock: boolean;
  disableNotifications: boolean;
  paymentOffSession: boolean;
  selectedProductsValues: {
    selectedProduct: Product;
    quantity: number;
    selectedCustomizations: { [key: string]: string };
  }[];
  voucherId?: string;
  autoConfirm: boolean;
  busUserId?: BusUserProfile[];
  branchRoomId?: BranchRoom[];
}) => {
  const items = selectedProductsValues.map(({ selectedProduct, quantity, selectedCustomizations }) => {
    const hasCustomizations = Object.keys(selectedProduct?.customizations || {}).length;
    return {
      id: selectedProduct?.id,
      quantity: Number(quantity),
      customizations: hasCustomizations
        ? [selectedCustomizations]?.map(customization => ({
            ...customization,
            quantity: quantity
          }))
        : null
    };
  });

  return {
    items: items,
    appointments: null,
    subscriptions: null,
    vouchers: voucherId ? [{ id: voucherId }] : [],
    AppUserId: appUserId,
    overriddenTotal: overrideTotal ? String(total) : null,
    autoConfirm,
    autoConfirmAppointments: !autoConfirm,
    BusUserId: busUserId && busUserId?.length ? busUserId.map(busUser => busUser.id) : null,
    BranchRoomId: branchRoomId && branchRoomId?.length ? branchRoomId.map(room => room.id) : null,
    ignoreStock: ignoreStock || false,
    disableNotifications: disableNotifications || false,
    paymentOffSession: paymentOffSession || false
  };
};

export const getMultiNewOrderVariables = ({
  selectedCustomizations,
  petsProfiles,
  groupsFullDates,
  selectedProduct,
  appUserId,
  overrideTotal,
  total,
  ignoreStock,
  disableNotifications,
  autoConfirm,
  busUserId,
  branchRoomId,
  options = {
    summary: false
  }
}: {
  selectedCustomizations: { [key: string]: string };
  petsProfiles: Pet[];
  groupsFullDates: ReturnType<typeof useMemoizedFullDates>;
  selectedProduct: Product;
  appUserId: string;
  overrideTotal: boolean;
  total: number | null;
  ignoreStock: boolean;
  disableNotifications: boolean;
  autoConfirm: boolean;
  busUserId?: BusUserProfile[];
  branchRoomId?: BranchRoom[];
  options?: {
    summary?: boolean;
  };
}) => {
  const { summary } = options;
  const variables = groupsFullDates.map(groupFullDates => {
    return getNewOrderVariables({
      selectedCustomizations,
      petsProfiles,
      fullDates: groupFullDates,
      selectedProduct,
      appUserId,
      overrideTotal,
      total,
      ignoreStock,
      disableNotifications,
      autoConfirm,
      busUserId,
      branchRoomId
    });
  });

  if (summary) {
    return {
      getMultiOrderPreOrderSummary: variables.map(variable => ({
        ...variable,
        overriddenTotal: undefined,
        autoConfirm: undefined,
        autoConfirmAppointments: undefined,
        ignoreStock: undefined,
        disableNotifications: undefined,
        paymentOffSession: undefined,
        BusUserId: undefined,
        BranchRoomId: undefined
      })),
      AppUserId: appUserId
    };
  }

  return {
    startMultiOrder: variables,
    AppUserId: appUserId
  };
};

export const getSelectedCustomizations = ({
  customizations,
  customizationsMap
}: {
  customizations: { [key: string]: string };
  customizationsMap: { [key: string]: { title: string; price: number }[] };
}) => {
  const selectedCustomizations: {
    fields: { [key: string]: { title: string; price: number } };
    quantity: number;
  } = {
    fields: Object.keys(customizations || {}).reduce(
      (all, key) => ({
        ...all,
        [key]: {
          title: customizations[key],
          price: customizationsMap?.[key]?.find(customization => customizations[key] === customization.title)?.price
        }
      }),
      {}
    ),
    quantity: 1
  };

  return selectedCustomizations;
};

export const getRecurringNewOrderVariables = ({
  selectedSlots,
  petsProfiles,
  fullDates,
  selectedProduct,
  selectedCustomizations,
  appUserId,
  ignoreStock,
  overrideTotal,
  total,
  busUserId,
  branchRoomId,
  disableNotifications,
  autoConfirm
}: {
  selectedSlots: Slot[];
  petsProfiles: Pet[];
  fullDates: ReturnType<typeof useMemoizedFullDates>[0];
  selectedProduct: Product;
  selectedCustomizations: { [key: string]: string };
  appUserId: string;
  overrideTotal: boolean;
  total: number | null;
  busUserId?: BusUserProfile[];
  branchRoomId?: BranchRoom[];
  ignoreStock: boolean;
  disableNotifications: boolean;
  autoConfirm: boolean;
}) => {
  const hasCustomizations = Object.keys(selectedProduct?.customizations || {}).length;
  return {
    weeks_count: Number(selectedSlots?.[0]?.repetition?.repeat),
    start_date: fullDates?.[0]?.timestamp?.fullDate,
    day_of_week: (selectedSlots?.[0]?.days || []).map(day => SUN_THREE_DAYS.indexOf(day)),
    PetRecordId: petsProfiles?.map(pet => pet.PetRecord?.id),
    ProductId: selectedProduct?.id,
    AppUserId: appUserId,
    customizations: hasCustomizations
      ? [selectedCustomizations]?.map(customization => ({
          ...customization,
          quantity: 1
        }))
      : null,
    overriddenTotal: overrideTotal ? String(total) : null,
    autoConfirm,
    BusUserId: busUserId && busUserId?.length ? busUserId.map(busUser => busUser.id) : null,
    BranchRoomId: branchRoomId && branchRoomId?.length ? branchRoomId.map(room => room.id) : null,
    ignoreStock: ignoreStock || false,
    disableNotifications: disableNotifications || false,
    autoConfirmAppointments: !autoConfirm
  };
};

export const filterSelectedOrderGroupSlots = ({ watchedOrderGroups, products }: { watchedOrderGroups: NewBookingFormType['orderGroups']; products: Product[] }) => {
  return watchedOrderGroups
    ?.map(orderGroup => {
      const { slots, bookingDays, repetition, productId, recurrenceType } = orderGroup;
      const product = products.find(product => product.id === productId);
      const isMultiDay = product?.booking_type === BOOKING_TYPE.MULTI_DAY;
      const isRecurring = recurrenceType === 'recurring';
      return {
        ...orderGroup,
        slots: slots
          ?.map(slot => {
            const date = `${slot.date}`;
            const time = isMultiDay ? '' : slot.time || '09:00';
            const dateUntil = slot.dateUntil;
            const hasDate = !!new Date(date).getTime();
            const output = {
              date: new Date(date),
              time: String(time || ''),
              dateUntil: new Date(dateUntil)
            };

            if (!hasDate) {
              return null;
            }

            if (!isRecurring) {
              return output;
            }

            return {
              ...output,
              startDate: new Date(date),
              repetition: {
                repeat: repetition,
                type: 'WEEKLY'
              },
              days: bookingDays
            };
          })
          .filter(Boolean)
      };
    })
    .filter(({ slots }) => slots?.length) as NewBookingFormType['orderGroups'];
};

export const getProductsItems = ({ orderGroups, products }: { orderGroups: NewBookingFormType['orderGroups']; products: Product[] }) => {
  return orderGroups?.map(({ productId, recurrenceType }) => {
    const product = products.find(product => product.id === productId);
    const subscriptionProduct = product?.type === 'subscription';

    const customizationsMap: Record<string, Customization> = product?.customizations || {};

    const titlesArray: string[] = Object.keys(customizationsMap)
      .slice()
      .sort((a, b) => a.localeCompare(b));
    const sessions = Array.from(Array(product?.sessions).keys());
    const hasCustomizations = Object.keys(product?.customizations || {}).length;

    const customizationsList: Customization[] = titlesArray.map(property =>
      customizationsMap?.[property]?.map(item => ({
        ...item,
        id: item?.title,
        name: item?.title,
        Indicator: item?.meta?.hex ? <ColorCircle color={item?.meta?.hex} /> : item?.meta?.url && isValidURL.test(item?.meta?.url) && <PatternCircle src={`${item?.meta?.url}`} alt={item?.title} />
      }))
    );

    const recurringProduct = product?.allows_repeated_orders;
    const isRecurring = recurrenceType === 'recurring' && recurringProduct;
    const isMultiDay = product?.booking_type === BOOKING_TYPE.MULTI_DAY;

    const bookingDays = (() => {
      const productSlots = (product?.slots?.length ? product?.slots : product?.Branch?.booking_slots || [])?.map(slot => slot.time);
      const daysIndicies = getRRuleWeekDays(productSlots);
      return THREE_LETTER_WEEK_DAYS.map((day, index) => ({ day, isAvailable: daysIndicies.includes(index) }));
    })();
    return {
      product,
      subscriptionProduct,
      sessions,
      hasCustomizations,
      customizationsList,
      customizationsMap,
      titlesArray,
      isRecurring,
      isMultiDay,
      recurringProduct,
      bookingDays
    };
  });
};
