import React, { useMemo, useRef } from 'react';
import ChatsBody, { ChatsBodyRef } from '../../components/Chat/ChatsBody';
import { useChatsFilters } from '../../components/Chat/ChatsFilters';
import ChatsHeader from '../../components/Chat/ChatsHeader/ChatsHeader';
import { CHAT_TABS_TYPES, CHAT_TABS_TYPES_LABELS } from '../../components/Chat/types';
import { MainContainer, ViewWrapper } from '../../components/Shared/Shared';
import SideTab from '../../components/Shared/SideTabs/SideTabs';
import { NOTIFICATION_COUNT_TYPES } from '../../components/Shared/SideTabs/notificationCountTypes';
import useMediaQuery from '../../hooks/useMediaQuery';

const Chats = () => {
  const { mobile } = useMediaQuery({ mobile: true });
  const filtersControl = useChatsFilters();
  const bodyRef = useRef<ChatsBodyRef>(null);
  const tabs = [
    { name: CHAT_TABS_TYPES_LABELS.CLIENTS, value: CHAT_TABS_TYPES.CLIENTS.toLowerCase(), component: ChatsBody },
    { name: CHAT_TABS_TYPES_LABELS.STAFF, value: CHAT_TABS_TYPES.STAFF.toLowerCase(), component: () => null },
    { name: CHAT_TABS_TYPES_LABELS.BROADCASTS, value: CHAT_TABS_TYPES.BROADCASTS.toLowerCase() },
    { name: CHAT_TABS_TYPES_LABELS.GROUPS, value: CHAT_TABS_TYPES.GROUPS.toLowerCase(), component: () => null }
  ];
  const mobileTabs = [
    { name: CHAT_TABS_TYPES_LABELS.CLIENTS, value: CHAT_TABS_TYPES.CLIENTS.toLowerCase() },
    { name: CHAT_TABS_TYPES_LABELS.STAFF, value: CHAT_TABS_TYPES.STAFF.toLowerCase(), component: () => null }
  ];
  const sections = !mobile ? [{ tabs }] : [{ tabs: mobileTabs }];

  const tabsValues = tabs.map(tab => tab.value);
  const tabValue = tabsValues?.find(tab => tab === location.pathname.split('/')[3]);
  const selected = tabValue || CHAT_TABS_TYPES.CLIENTS.toLowerCase();

  const SelectedTab = useMemo(() => tabs.find(tab => tab.value === selected)?.component!, [selected, JSON.stringify(tabs)]);

  return (
    <ViewWrapper>
      {!mobile && (
        <SideTab sections={sections} count={true} tabsWithCount={[CHAT_TABS_TYPES_LABELS.CLIENTS]} defaultTab={CHAT_TABS_TYPES.CLIENTS} notificationType={NOTIFICATION_COUNT_TYPES.MESSAGE} />
      )}

      <MainContainer>
        {mobile && (
          <SideTab sections={sections} count={true} tabsWithCount={[CHAT_TABS_TYPES_LABELS.CLIENTS]} defaultTab={CHAT_TABS_TYPES.CLIENTS} notificationType={NOTIFICATION_COUNT_TYPES.MESSAGE} />
        )}

        <ChatsHeader tabs={tabs} filtersControl={filtersControl} />
        {SelectedTab && <SelectedTab filtersControl={filtersControl} />}
      </MainContainer>
    </ViewWrapper>
  );
};

export default Chats;
