import React, { FC } from 'react';
import OptionDropdown from '../../../../components/Shared/Menus/OptionDropdown/OptionDropdown';
import { OPTION_DROPDOWN_MENU_BUTTON_TYPES, OPTION_DROPDOWN_MENU_OVERLAY_POSITIONS, OPTION_DROPDOWN_TYPES } from '../../../../components/Shared/Menus/OptionDropdown/types';
import { CenteredLoader } from '../../../../components/Shared/Spinner';
import useBookingActionsList, { UseBookingActionsListProps } from '../../../../hooks/useBookingActionsList';
import { ButtonsContainer } from './styled';

type BookingDrawerButtonsProps = {
  menuButtonType?: OPTION_DROPDOWN_MENU_BUTTON_TYPES;
  menuOverlayPosition?: OPTION_DROPDOWN_MENU_OVERLAY_POSITIONS;
  containerStyle?: React.CSSProperties;
} & UseBookingActionsListProps;

const BookingDrawerButtons: FC<BookingDrawerButtonsProps> = ({
  menuButtonType = OPTION_DROPDOWN_MENU_BUTTON_TYPES.MENU,
  menuOverlayPosition = OPTION_DROPDOWN_MENU_OVERLAY_POSITIONS.DOWN,
  containerStyle,
  ...rest
}) => {
  const { items, loadingBookingActions, loadingBookingTagsOptions, loadingPetOptions } = useBookingActionsList({ ...rest, includeQuickTags: false });

  return (
    <ButtonsContainer style={containerStyle}>
      {loadingBookingActions && <CenteredLoader size={20} />}

      {!loadingBookingActions && (
        <OptionDropdown
          buttonLoading={loadingPetOptions || loadingBookingTagsOptions}
          menuButtonType={menuButtonType || OPTION_DROPDOWN_MENU_BUTTON_TYPES.MENU}
          menuOverlayPosition={menuOverlayPosition || OPTION_DROPDOWN_MENU_OVERLAY_POSITIONS.DOWN}
          noApplyButton
          options={[
            {
              optionType: OPTION_DROPDOWN_TYPES.BUTTONS,
              id: 'bookingOptions',
              items
            }
          ]}
        />
      )}
    </ButtonsContainer>
  );
};

export default BookingDrawerButtons;
