import { useQuery } from '@apollo/client';
import React, { FC } from 'react';
import Colors from '../../../../Colors';
import { ExtensionColumn, ExtensionColumnContent, ExtensionColumnHeader, ExtensionPetCaseLabel } from '../../../../components/Pets/styled';
import InfiniteList from '../../../../components/Shared/InfiniteList/InfiniteList';
import { CenteredLoader } from '../../../../components/Shared/Spinner';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import WalkingReport from './WalkingReport';
import WalkingReportNoteAction, { NOTE_ACTION_TYPES } from './WalkingReportNoteAction';

export type report = Record<string, any>;

type WalkingHistoryProps = {
  walkingReports: report[];
  setSelectedReportId: (noteId: string) => void;
  loading: boolean;
  setHasMoreItems: React.Dispatch<React.SetStateAction<boolean>>;
  fetchMore: ReturnType<typeof useQuery>['fetchMore'];
  hasMoreItems: boolean;
  selectedReportId: string;
  refetchWalkingReports: ReturnType<typeof useQuery>['refetch'];
  autoOpenModal: boolean;
  onModalClose: () => void;
  renderHeader: () => JSX.Element;
};

const WalkingHistory: FC<WalkingHistoryProps> = ({
  walkingReports,
  setSelectedReportId,
  fetchMore,
  setHasMoreItems,
  hasMoreItems,
  loading,
  selectedReportId,
  refetchWalkingReports,
  autoOpenModal,
  onModalClose,
  renderHeader
}) => {
  const { tablet } = useMediaQuery({ tablet: true });

  return (
    <ExtensionColumn>
      <ExtensionColumnHeader>{renderHeader()}</ExtensionColumnHeader>
      <ExtensionColumnHeader justifyContent="flex-start" gap={8} padding="10px 8px" background marginT={8} marginB={8} mobilePadding="8px 16px">
        <>
          <WalkingReportNoteAction type={NOTE_ACTION_TYPES.ADD} refetchReports={refetchWalkingReports} autoOpenModal={autoOpenModal} onClose={onModalClose} />
          <ExtensionPetCaseLabel fontSize={14} fontWeight={'700'} color={Colors.black}>
            New Report
          </ExtensionPetCaseLabel>
        </>
      </ExtensionColumnHeader>

      <ExtensionColumnContent>
        {tablet && !walkingReports?.length && loading && <CenteredLoader />}
        {!!walkingReports?.length && (
          <InfiniteList
            mobilePadding="8px 16px"
            list={walkingReports}
            itemRenderer={(report: report, index: number) => (
              <WalkingReport selected={report.id === selectedReportId} key={index} walkingReport={report} onClick={() => setSelectedReportId(report?.id)} />
            )}
            loading={loading}
            offeset={walkingReports?.length}
            setHasMoreItems={setHasMoreItems}
            fetchMore={fetchMore}
            hasMoreItems={hasMoreItems}
            offset={walkingReports?.length}
            listContainerStyles={tablet ? { width: '100%' } : {}}
          />
        )}

        {!walkingReports?.length && !loading && (
          <ExtensionPetCaseLabel fontSize={16} fontWeight={'700'}>
            There are no reports
          </ExtensionPetCaseLabel>
        )}
      </ExtensionColumnContent>
    </ExtensionColumn>
  );
};

export default WalkingHistory;
