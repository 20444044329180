import { useLazyQuery } from '@apollo/client';
import React, { useEffect } from 'react';
import { Controller, UseFormMethods } from 'react-hook-form';
import AsyncSelect from 'react-select/async';
import { GetBreeds } from '../../../queries';
import debounce from '../../../utils/debounce';
import { selectTheme } from '../Forms/Forms';
import { VerticallyCenteredLoader } from '../Spinner';
import { Pet } from '../../Pets/types';

const getOptions = (data: Record<string, Pet['Breed'][]>) => {
  return Object.values(data || {})?.[0]?.map((petbreed: { id: string; name: string }) => ({ value: petbreed.id, label: petbreed.name })) || [];
};

const BreedsList = ({
  name,
  formOptions,
  defaultValues,
  rules,
  isMulti,
  petType
}: {
  formOptions: UseFormMethods<any>;
  defaultValues?: string[];
  name?: string;
  rules?: Record<string, any>;
  isMulti?: boolean;
  petType?: string[];
}) => {
  const { control, watch } = formOptions;

  const watchedValue = watch(name || 'breedId');

  const [getSearchBreeds, { data }] = useLazyQuery(GetBreeds, { fetchPolicy: 'cache-and-network' });

  const [getBreedById, { data: { getBreeds: defaultBreeds = [] } = {}, loading, called: calledDefault, previousData }] = useLazyQuery<{ getBreeds: Pet['Breed'][] }>(GetBreeds, {
    fetchPolicy: 'cache-and-network'
  });

  const loadOptions = (inputValue: string, callback: (options: { value: string; label: string }[]) => void) => {
    debounce(async () => {
      const { data } = await getSearchBreeds({ variables: { name: [inputValue], ...(petType && { type: petType }) } });
      const options = getOptions(data);
      callback(options);
    }, 500);
  };

  const defaultSelectedBreeds = [...new Set(defaultValues || [])];

  useEffect(() => {
    if (defaultSelectedBreeds?.[0] && !loading && !defaultBreeds.length && !calledDefault) {
      getBreedById({ variables: { id: [...defaultSelectedBreeds, ...[watchedValue || []].flat()], ...(petType && { type: petType }) } });
    }
  }, [defaultSelectedBreeds?.[0], loading, defaultBreeds.length, calledDefault]);

  const defaultPetBreeds = defaultBreeds?.filter(petbreed => defaultSelectedBreeds?.includes(petbreed.id))?.map(petbreed => ({ value: petbreed.id, label: petbreed.name }));

  const options = [...(defaultPetBreeds || []), ...getOptions(data)];

  const defaultValue = isMulti ? defaultPetBreeds?.map(item => item.value) : defaultPetBreeds?.[0]?.value;

  if (loading && !previousData && defaultValues?.length) {
    return <VerticallyCenteredLoader size={30} />;
  }

  return (
    <Controller
      name={name || 'breedId'}
      control={control}
      isMulti={isMulti}
      defaultValue={defaultValue}
      rules={{ required: true, ...rules }}
      render={({ onChange, value }) => {
        const updatedValue = isMulti
          ? [...(value || [])]?.map((item: string) => ({
              value: item,
              label:
                defaultBreeds?.find(petbreed => petbreed.id === item)?.name ||
                options?.find(option => option.value === item)?.label ||
                previousData?.getBreeds?.find(petbreed => petbreed.id === item)?.name ||
                'Loading...'
            })) || []
          : value
          ? {
              value: value,
              label:
                defaultBreeds?.find(petbreed => petbreed.id === value)?.name ||
                options?.find(option => option.value === value)?.label ||
                previousData?.getBreeds?.find(petbreed => petbreed.id === value)?.name ||
                'Loading...'
            }
          : null;

        return (
          <AsyncSelect
            defaultOptions={options}
            styles={{ container: provided => ({ ...provided }), valueContainer: provided => ({ ...provided, height: 48, overflowY: 'scroll' }) }}
            loadOptions={loadOptions}
            theme={selectTheme}
            name={name || 'breedId'}
            onChange={(option: any) => {
              let updatedValue = option?.value;
              if (isMulti) {
                updatedValue = option?.map((item: any) => item.value);
              }
              getBreedById({ variables: { id: [updatedValue].flat() } });
              onChange(updatedValue);
            }}
            value={updatedValue}
            options={options}
            isMulti={isMulti}
            cacheOptions
            noOptionsMessage={({ inputValue }) => (inputValue === '' ? 'Start typing to search' : 'No results found')}
          />
        );
      }}
    />
  );
};

export default BreedsList;
