import React, { FC, useEffect } from 'react';
import RadioSwitch from '../../components/Shared/Forms/RadioSwitch';
import RefreshButton from '../../components/Shared/RefreshButton';
import useCheckSelectedTabAndRefetch from '../../hooks/useCheckSelectedTabAndRefetch';
import usePaginatedQuery from '../../hooks/usePaginatedQuery';
import { GetBranchOrderSubscriptions } from '../../queries';
import { manageSubscriptionsFilters } from '../../reactive/actions';
import Common from '../Store/Common';
import SubscriptionsList from './SubscriptionsList';
import { SUBSCRIPTION_STATUS_TYPES, SUBSCRIPTION_TABS_TYPES, Subscription } from './types';

type SubscriptionsBodyProps = {
  selectedTabsState: string;
  tabs: { name: string; status: string[]; otherVariables: Record<string, any>; value: string }[];
};

const SubscriptionsBody: FC<SubscriptionsBodyProps> = ({ tabs, selectedTabsState: selected }) => {
  const { addHeaderAction, clearHeaderAction } = manageSubscriptionsFilters;
  const selectedTab = tabs.find(tab => tab.value === selected) || tabs[0];
  const { name, value, status, otherVariables } = selectedTab;

  const isCanceled = selected === SUBSCRIPTION_TABS_TYPES.CANCELED;
  const paginatedQueryResult = usePaginatedQuery<Subscription[]>({
    query: GetBranchOrderSubscriptions,
    defaultStatus: [SUBSCRIPTION_STATUS_TYPES.CONFIRMED],
    limit: 20,
    otherVariables: {
      status,
      ...otherVariables,
      requisite_queries: [],
      alternative_queries: []
    }
  });

  const [[_hasMoreItems, setHasMoreItems], queryResult] = paginatedQueryResult;

  const { refetch, variables: previousVariables } = queryResult;

  const checkSelectedTabAndRefetch = useCheckSelectedTabAndRefetch<Subscription>({
    tabs,
    queryResult,
    setHasMoreItems,
    selected
  });

  const action = (
    <>
      <RefreshButton checkSelectedTabAndRefetch={checkSelectedTabAndRefetch} setHasMoreItems={setHasMoreItems} />
      <RadioSwitch
        onChange={(on: boolean) => {
          setHasMoreItems(true);
          refetch(
            isCanceled
              ? {
                  order_by_end_date: on,
                  order_by_name: false
                }
              : {
                  order_by_name: on,
                  order_by_end_date: false
                }
          );
        }}
      />
    </>
  );

  useEffect(() => {
    Common.set(`Subscriptions.GetOrderSubscriptions.refetch`, () => {
      refetch?.().then(() => setHasMoreItems(true));
    });
  }, []);

  useEffect(() => {
    addHeaderAction({ action, id: 'refresh' });
    return () => {
      clearHeaderAction({ id: 'refresh' });
    };
  }, [selected]);

  // const loadingFilters = useRefetchSubscriptionsOnFiltersChange({
  //   refetch,
  //   defaultVariables: { ...selectedTab?.otherVariables, status: selectedTab?.status, limit: 20 } || {},
  //   setHasMoreItems,
  //   previousVariables
  // });

  return (
    // <>
    //   {loadingFilters && <CenteredLoader />}
    //   {!loadingFilters && <SubscriptionsList paginatedQueryResult={paginatedQueryResult} reverseItems={[SUBSCRIPTION_STATUS_TYPES.CANCELED].includes(selected)} showTotal={{ orderTotal: true }} />}
    // </>
    <SubscriptionsList paginatedQueryResult={paginatedQueryResult} reverseItems={[SUBSCRIPTION_STATUS_TYPES.CANCELED].includes(selected)} showTotal={{ orderTotal: true }} />
  );
};

export default SubscriptionsBody;
