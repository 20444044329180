import styled, { css } from 'styled-components';

export const SittingReportNotesContainer = styled.div`
  padding-top: 8px;
  padding-right: 8px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const SittingReportNoteContainer = styled.div``;

export const ReportNoteSubCategoryContainer = styled.div<{ direction?: 'row' | 'column' }>`
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 14px;
  display: flex;
  ${({ direction }) =>
    direction &&
    css`
      flex-direction: ${direction || 'column'};
    `}
  align-items: center;
  gap: 4px;
`;

export const ReportNoteSubCategoryTitle = styled.span<{ direction?: 'row' | 'column' }>`
  font-weight: 700;
  display: flex;
  ${({ direction }) =>
    direction &&
    css`
      flex-direction: ${direction || 'column'};
    `}
  align-items: center;
  gap: 8px;
`;
