import React from 'react';
import { sentenceCase } from 'sentence-case';
import Colors from '../../Colors';
import useMediaQuery from '../../hooks/useMediaQuery';
import { toReadableDate } from '../../utils/dates';
import { getKeys } from '../../utils/helpers';
import { Divider, PetDrawerContent } from '../DrawerBar/styled';
import { PetDrawerTag, PetDrawerTagsContainer } from '../PetDrawer/styled';
import { Pet } from '../Pets/types';
import { ProfileInfoContainer, ProfileInfoWrapper, ProfileLabel, ProfileSection, ProfileSubLabel } from './styled';

const PetProfileInfo = ({ profile }: { profile: Pet }) => {
  const { mobile } = useMediaQuery({ mobile: true });
  const allergies = profile?.PetRecord?.allergies;
  const conditions = getKeys(profile?.PetRecord?.chronic_conditions || {})
    .filter(cond => profile?.PetRecord?.chronic_conditions[cond])
    .map(cond => sentenceCase(cond))
    .join(', ');
  const petAllergies = getKeys(allergies || {})
    ?.filter(allergy => allergies[allergy])
    .map(cond => sentenceCase(cond))
    .join(', ');
  const diet = profile?.PetRecord?.BranchCategories?.map(category => category?.name).join(', ');
  const dietBranch = [...new Set(profile?.PetRecord?.Meals?.map(meal => meal?.name))].join(', ');
  const vet = profile?.PetRecord?.Branch?.name;
  const weight = profile?.PetRecord?.measurements?.weights?.slice(-1)?.[0]?.weight;

  const sections = [
    { subLabel: 'Breed', label: profile?.Breed?.name, unit: '' },
    { subLabel: 'Gender', label: sentenceCase(profile?.gender || ''), unit: '' },
    { subLabel: profile?.gender === 'FEMALE' ? 'Spayed' : 'Neutered', label: profile?.PetRecord?.hasOwnProperty('neutred') ? (profile?.PetRecord?.neutred ? 'Yes' : 'No') : '', unit: '' },
    { subLabel: 'Birthdate', label: toReadableDate(profile?.birthdate, { noTime: true, isLocale: true }), unit: '' },
    { subLabel: 'Colour', label: profile?.PetColor?.name, unit: '' },
    { subLabel: 'Weight', label: weight, unit: 'kg' },
    { subLabel: 'Allergies', label: petAllergies, unit: '' },
    { subLabel: 'Conditions', label: conditions, unit: '' },
    { subLabel: 'Behavior', label: null, unit: '' },
    { subLabel: 'Diet', label: diet, unit: '' },
    { subLabel: 'Food Brands', label: dietBranch, unit: '' },
    { subLabel: 'Vet', label: vet, unit: '' },
    { subLabel: 'Microchip', label: profile?.PetRecord?.microchip_number, unit: '' },
    { subLabel: 'Microchip Provider', label: profile?.PetRecord?.microchip_provider, unit: '' },
    { subLabel: 'Passport Number', label: profile?.PetRecord?.passport_number, unit: '' }
  ];

  const petTags = profile?.PetRecord?.BranchPetRecordTags;

  return (
    <PetDrawerContent>
      {!!petTags?.length && (
        <>
          <PetDrawerTagsContainer padding="12px 20px">
            {petTags?.map((item, index) => (
              <PetDrawerTag color={Colors.black} key={index}>
                <span>{item.name}</span>
              </PetDrawerTag>
            ))}
          </PetDrawerTagsContainer>
        </>
      )}
      <ProfileInfoWrapper>
        <ProfileInfoContainer>
          {/* {profile?.PetRecord?.id && <BranchNotes type={BranchNoteType.PET_RECORD} PetRecordId={profile?.PetRecord?.id} />}
          <Divider marginBottom={12} marginTop={12} /> */}

          {sections.map(({ subLabel, label, unit }, i) => (
            <>
              <ProfileSection key={i}>
                <ProfileSubLabel>{subLabel}</ProfileSubLabel>
                <ProfileLabel>{label ? label + (unit || '') : '-'}</ProfileLabel>
              </ProfileSection>
              {mobile && i !== sections.length - 1 && <Divider marginBottom={17} />}
            </>
          ))}
        </ProfileInfoContainer>
      </ProfileInfoWrapper>
    </PetDrawerContent>
  );
};

export default PetProfileInfo;
