import { useMutation, useQuery } from '@apollo/client';
import React, { FC, useEffect } from 'react';
import { Controller, UseFormMethods, useForm } from 'react-hook-form';
import { AddAppUsersToBranch, AddBranchAppUser, EditBranchAppUser, GetAllCountries, GetBusUserProfile } from '../../queries';
import { errorCodes } from '../../utils/constants/errors';
import { RequireAtLeastOne, getKeys } from '../../utils/helpers';
import { BranchAppUser } from '../../views/Store/BranchBilling/types';
import { Country } from '../../views/Store/types';
import Alert from '../Alert/Alert';
import { Divider } from '../DrawerBar/styled';
import ModalDialog from '../Modal/ModalDialog';
import { ModalBody, ModalFooter } from '../Modal/styled';
import { FlexContainer } from '../Pets/styled';
import { BusUserProfile } from '../Profile/types';
import { FormButtonsContainer, FormError, FormInput, FormLabel, FormSelect, FormSubmitButton, InputsWrapper, RadioBtnsGroup, SectionLabel, WideInputGroup } from '../Shared/Forms/Forms';
import { Container } from '../Shared/Shared';
import { CenteredLoader } from '../Shared/Spinner';
import PetDetailsModal from './PetDetailsModal';

type Address = { line1: string; line2: string; city: string; country: string };
type PhoneNumber = { countryCode: string; number: string };

type AppUserDetailsForm = {
  address: Address;
  phoneNumbers: { primary: PhoneNumber; emergency: PhoneNumber };
  postcode: string;
  name: string;
  email: string;
  isTermsAccepted: boolean;
};

type AppUserDetailsModalFormProps = {
  onSubmit: () => void;
  profile?: BranchAppUser;
  loading: boolean;
  error: boolean;
  formOptions: UseFormMethods<AppUserDetailsForm>;
  countries: Country[];
  busUserProfile?: BusUserProfile;
  defaultValues?: Partial<AppUserDetailsForm>;
};

const AppUserDetailsModalForm: FC<AppUserDetailsModalFormProps> = ({ error, formOptions, loading, onSubmit, profile, countries, busUserProfile, defaultValues }) => {
  const { control, errors, watch } = formOptions;
  const watchedTerms = profile?.id ? true : !!watch('isTermsAccepted');
  return (
    <>
      <ModalBody>
        <Container width={500}>
          <InputsWrapper noWrap>
            {!profile?.id && (
              <WideInputGroup>
                <SectionLabel>Email</SectionLabel>
                <FormLabel>Email</FormLabel>
                <Controller
                  as={<FormInput error={!!errors?.email} type={'text'} name={'email'} />}
                  control={control}
                  name={'email'}
                  defaultValue={defaultValues?.email || ''}
                  rules={{ required: 'Please add your email', pattern: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/ }}
                />
                {errors?.email && <FormError>{errors?.email?.message || 'Please add a valid email'}</FormError>}
              </WideInputGroup>
            )}
            <WideInputGroup>
              <SectionLabel>Details</SectionLabel>
              <FormLabel>Name</FormLabel>
              <Controller
                as={<FormInput error={!!errors?.name} type={'text'} name={'name'} />}
                control={control}
                name={'name'}
                defaultValue={defaultValues?.name || ''}
                rules={{ required: 'Please add your name', pattern: /^[a-zA-Z0-9\s,.'-]{3,}$/ }}
              />
              {errors?.name && <FormError>{errors?.name?.message || 'Please add a valid name'}</FormError>}
            </WideInputGroup>
            <WideInputGroup>
              <SectionLabel>Address</SectionLabel>
              <FormLabel>Country</FormLabel>
              <Controller
                render={({ onChange, value }) => (
                  <FormSelect error={!!errors?.address?.country} height={48} fontSize={16} value={value} onChange={onChange}>
                    {countries.map(country => (
                      <option key={country.id} value={country.id}>
                        {country.name}
                      </option>
                    ))}
                  </FormSelect>
                )}
                control={control}
                name={'address.country'}
                defaultValue={defaultValues?.address?.country || String(countries[0]?.id) || ''}
                rules={{ required: 'Please add your country' }}
              />
            </WideInputGroup>
            <WideInputGroup>
              <FormLabel>City</FormLabel>
              <Controller
                as={<FormInput error={!!errors?.address?.city} type={'text'} name={'address.city'} />}
                control={control}
                name={'address.city'}
                defaultValue={defaultValues?.address?.city || ''}
                rules={{ required: 'Please add your city', pattern: /^[a-zA-Z0-9\s,.'-]{3,}$/ }}
              />
              {errors?.address?.city && <FormError>{errors?.address?.city?.message || 'Please add a valid city'}</FormError>}
            </WideInputGroup>
            <WideInputGroup>
              <FormLabel>Address Line 1</FormLabel>
              <Controller
                as={<FormInput error={!!errors?.address?.line1} type={'text'} name={'address.line1'} />}
                control={control}
                name={'address.line1'}
                defaultValue={defaultValues?.address?.line1 || ''}
                rules={{ pattern: /^[a-zA-Z0-9\s,.'-]{3,}$/ }}
              />
              {errors?.address?.line1 && <FormError>{errors?.address?.line1?.message || 'Please add a valid address'}</FormError>}
            </WideInputGroup>
            <WideInputGroup>
              <FormLabel>Address Line 2</FormLabel>
              <Controller
                as={<FormInput error={!!errors?.address?.line2} type={'text'} name={'address.line2'} />}
                control={control}
                name={'address.line2'}
                defaultValue={defaultValues?.address?.line2 || ''}
                rules={{ pattern: /^[a-zA-Z0-9\s,.'-]{3,}$/ }}
              />
              {errors?.address?.line2 && <FormError>{errors?.address?.line2?.message || 'Please add a valid address'}</FormError>}
            </WideInputGroup>
            <WideInputGroup>
              <FormLabel>Post Code</FormLabel>
              <Controller
                as={<FormInput error={!!errors?.postcode} type={'text'} name={'postcode'} />}
                control={control}
                name={'postcode'}
                defaultValue={defaultValues?.postcode || ''}
                rules={{ pattern: /^[a-zA-Z0-9\s,.'-]{3,}$/ }}
              />
              {errors?.postcode && <FormError>{errors?.postcode?.message || 'Please add a valid postcode'}</FormError>}
            </WideInputGroup>
            <Divider />
            <WideInputGroup>
              <SectionLabel>Phone Numbers</SectionLabel>
              <FormLabel>Primary</FormLabel>
              <FlexContainer gap={6}>
                <Controller
                  as={<FormInput error={!!errors?.phoneNumbers?.primary?.countryCode} type={'text'} name={'phoneNumbers.primary.countryCode'} width={80} />}
                  control={control}
                  name={'phoneNumbers.primary.countryCode'}
                  defaultValue={defaultValues?.phoneNumbers?.primary?.countryCode || '+44'}
                  rules={{ pattern: /^(\+?\d{1,3}|\d{1,4})$/ }}
                />
                <Controller
                  as={<FormInput error={!!errors?.phoneNumbers?.primary?.number} type={'text'} name={'phoneNumbers.primary.number'} fullWidth />}
                  control={control}
                  name={'phoneNumbers.primary.number'}
                  defaultValue={defaultValues?.phoneNumbers?.primary?.number || ''}
                  rules={{ pattern: /^[a-zA-Z0-9\s,.'-]{3,}$/ }}
                />
              </FlexContainer>
              {errors?.phoneNumbers?.primary?.countryCode && <FormError>{errors?.phoneNumbers?.primary?.countryCode?.message || 'Please add a valid country code'}</FormError>}
              {errors?.phoneNumbers?.primary?.number && <FormError>{errors?.phoneNumbers?.primary?.number?.message || 'Please add a valid number'}</FormError>}
            </WideInputGroup>
            <WideInputGroup>
              <FormLabel>Emergency</FormLabel>
              <FlexContainer gap={6}>
                <Controller
                  as={<FormInput error={!!errors?.phoneNumbers?.emergency?.countryCode} type={'text'} name={'phoneNumbers.emergency.countryCode'} width={80} />}
                  control={control}
                  name={'phoneNumbers.emergency.countryCode'}
                  defaultValue={defaultValues?.phoneNumbers?.emergency?.countryCode || '+44'}
                  rules={{ pattern: /^(\+?\d{1,3}|\d{1,4})$/ }}
                />
                <Controller
                  as={<FormInput error={!!errors?.phoneNumbers?.emergency?.number} type={'text'} name={'phoneNumbers.emergency.number'} fullWidth />}
                  control={control}
                  name={'phoneNumbers.emergency.number'}
                  defaultValue={defaultValues?.phoneNumbers?.emergency?.number || ''}
                  rules={{ pattern: /^[a-zA-Z0-9\s,.'-]{3,}$/ }}
                />
              </FlexContainer>
              {errors?.phoneNumbers?.emergency?.countryCode && <FormError>{errors?.phoneNumbers?.emergency?.countryCode?.message || 'Please add a valid country code'}</FormError>}
              {errors?.phoneNumbers?.emergency?.number && <FormError>{errors?.phoneNumbers?.emergency?.number?.message || 'Please add a valid number'}</FormError>}
            </WideInputGroup>
            {!profile?.id && (
              <WideInputGroup>
                <SectionLabel>Terms</SectionLabel>
                <FormLabel>Accept Terms</FormLabel>
                <Controller
                  render={({ onChange, value }) => (
                    <RadioBtnsGroup
                      options={[
                        `I confirm that ${busUserProfile?.Branch?.name} have obtained full consent form the customer whose details entered above to store their personal details and create an account for them for the purpose of fulfilling services and/or product orders. I confirm that they have been informed of our terms and condition including service, payment and privacy policies.`
                      ]}
                      name="isTermsAccepted"
                      defaultValue={''}
                      inputType={'checkbox'}
                      margin={'0 0 0 10px'}
                      top={'3%'}
                      onChange={() => onChange(!value)}
                      useSentenceCase={false}
                    />
                  )}
                  control={control}
                  name={'isTermsAccepted'}
                  defaultValue={false}
                  rules={{ required: 'Please accept the terms' }}
                />

                {errors?.isTermsAccepted && <FormError>{errors?.isTermsAccepted?.message || 'Please accept the terms'}</FormError>}
              </WideInputGroup>
            )}
          </InputsWrapper>
        </Container>
      </ModalBody>
      <ModalFooter>
        <FormButtonsContainer>
          <FormSubmitButton error={error} loading={loading} onClick={onSubmit} disabled={!watchedTerms}>
            {profile?.id ? 'Update' : 'Add'}
          </FormSubmitButton>
        </FormButtonsContainer>
      </ModalFooter>
    </>
  );
};

const AppUserDetailsModalContent = ({ countries, profile, currentBusUserProfile }: { profile?: BranchAppUser; currentBusUserProfile?: BusUserProfile; countries: Country[] }) => {
  const getCountryIdFromName = (name: string) => String(countries.find(country => country.name === name)?.id) || '';
  const getCountryNameFromId = (id: string) => countries.find(country => String(country.id) === id)?.name || '';

  const defaultValues = {
    name: profile?.name || '',
    address: {
      country: getCountryIdFromName(profile?.addresses?.[0]?.country || 'United Kingdom') || (countries[0]?.id ? String(countries[0]?.id) : '1'),
      city: profile?.addresses?.[0]?.city || 'London',
      line1: profile?.addresses?.[0]?.line1 || '',
      line2: profile?.addresses?.[0]?.line2 || ''
    },
    postcode: profile?.postcode || '',
    phoneNumbers: {
      primary: {
        countryCode: profile?.phone_numbers?.find(phone => phone?.label === 'primary')?.country_code || '+44',
        number: profile?.phone_numbers?.find(phone => phone?.label === 'primary')?.number || ''
      },
      emergency: {
        countryCode: profile?.phone_numbers?.find(phone => phone?.label === 'emergency')?.country_code || '+44',
        number: profile?.phone_numbers?.find(phone => phone?.label === 'emergency')?.number || ''
      }
    },
    email: profile?.email || '',
    isTermsAccepted: false
  };

  const formOptions = useForm<AppUserDetailsForm>({ defaultValues });
  const { handleSubmit, getValues } = formOptions;

  const [handleEditUser, { data: editedUser, loading: loadingEditUser, error: errorEditUser }] = useMutation<
    { editBranchAppUser: BranchAppUser },
    { id: string; addresses: Address[]; phone_numbers: { label: 'emergency' | 'primary'; country_code: string; number: string }[]; postcode: string; name: string }
  >(EditBranchAppUser);

  const [addAppUsersToBranch, { data: addedAppUsersToBranch, loading: loadingAddAppUsersToBranch, error: errorAddAppUsersToBranch }] = useMutation<
    { addAppUsersToBranch: BranchAppUser[] },
    RequireAtLeastOne<{ email: string[]; id: string[] }>
  >(AddAppUsersToBranch, {
    onError: error => {
      if (error.graphQLErrors?.[0]?.code === errorCodes.BRANCH_APP_USER_ALREADY_ADDED) {
        Alert.alert({
          title: 'User already exists',
          description: 'This user already exists in the system and in the branch.',
          acceptButtonText: 'Okay',
          denyButtonText: 'Close',
          onDeny: () => {
            ModalDialog.closeModal();
          }
        });
      }
    }
  });

  const [handleAddUser, { data: addedUser, loading: loadingAddUser, error: errorAddUser }] = useMutation<
    { addBranchAppUser: BranchAppUser },
    { addresses: Address[]; phone_numbers: { label: 'emergency' | 'primary'; country_code: string; number: string }[]; postcode: string; name: string; email: string }
  >(AddBranchAppUser, {
    refetchQueries: ['getBranchAppUsers'],
    awaitRefetchQueries: true,
    onError: error => {
      if (error.graphQLErrors?.[0]?.code === errorCodes.BRANCH_APP_USER_ALREADY_ADDED) {
        Alert.alert({
          title: 'User already exists',
          description: 'This user already exists in the system and in the branch.',
          acceptButtonText: 'Okay',
          denyButtonText: 'Close',
          onDeny: () => {
            ModalDialog.closeModal();
          }
        });
      }
      if (error.graphQLErrors?.[0]?.code === errorCodes.APP_USER_ALREADY_EXISTS) {
        Alert.alert({
          title: 'User already exists',
          description: 'This user already exists in the system. Do you want to add this user to the branch instead?',
          acceptButtonText: 'Add to branch',
          onAccept: () => {
            addAppUsersToBranch({ variables: { email: [getValues().email] } });
          }
        });
      }
    }
  });

  const onSubmit = handleSubmit(form => {
    const addresses = [{ ...form.address }].filter(address => address.line1 && address.city && address.country)?.map(address => ({ ...address, country: getCountryNameFromId(address.country) })) || [];

    const phoneNumbers = getKeys(form.phoneNumbers)
      .map(key => {
        const phone = form.phoneNumbers[key];
        if (phone.number && phone.countryCode) {
          return { label: key, country_code: phone.countryCode, number: phone.number };
        }
        return { label: key, country_code: '', number: '' };
      })
      .filter(phone => phone.number && phone.country_code);

    const variables = { addresses, phone_numbers: phoneNumbers, postcode: form.postcode || '', name: form.name, country_id: form.address.country };

    if (!profile?.id) {
      if (!form.isTermsAccepted) {
        return;
      }
      handleAddUser({ variables: { ...variables, email: form.email } });
      return;
    }

    handleEditUser({ variables: { ...variables, id: profile?.id } });
  });

  useEffect(() => {
    if (editedUser?.editBranchAppUser?.id || addedUser?.addBranchAppUser?.id || addedAppUsersToBranch?.addAppUsersToBranch?.length) {
      ModalDialog.closeModal();

      if (editedUser?.editBranchAppUser?.id) {
        return;
      }

      ModalDialog.openModal({
        content: () => <PetDetailsModal initialValues={{ AppUserId: addedUser?.addBranchAppUser?.id || addedAppUsersToBranch?.addAppUsersToBranch?.[0]?.id }} />,
        title: 'Add Pet'
      });
    }
  }, [editedUser, addedUser, addedAppUsersToBranch]);

  const loading = loadingEditUser || loadingAddUser || loadingAddAppUsersToBranch;
  const error = !!errorEditUser?.message || !!errorAddUser?.message || !!errorAddAppUsersToBranch?.message;

  return (
    <AppUserDetailsModalForm
      onSubmit={onSubmit}
      loading={loading}
      error={error}
      profile={profile}
      formOptions={formOptions}
      countries={countries}
      busUserProfile={currentBusUserProfile}
      defaultValues={defaultValues}
    />
  );
};

const AppUserDetailsModal = ({ profile }: { profile?: BranchAppUser }) => {
  const { data: { countryGet: countries = [] } = {}, loading: loadingCountries } = useQuery<{ countryGet: Country[] }>(GetAllCountries, {
    fetchPolicy: 'cache-first'
  });
  const { data: { getBusUserProfile: currentBusUserProfile = {} } = {}, loading: loadingBusUser } = useQuery(GetBusUserProfile, {
    fetchPolicy: 'cache-only'
  });

  const loading = loadingCountries || loadingBusUser;

  if (loading) {
    return <CenteredLoader />;
  }

  return <AppUserDetailsModalContent countries={countries} profile={profile} currentBusUserProfile={currentBusUserProfile} />;
};

export default AppUserDetailsModal;
