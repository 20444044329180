import styled, { css } from 'styled-components';
import Colors from '../../../../Colors';
import { HoverRecordOptionsContainer } from '../../../styled';

export const ServiceContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0 20px;
  min-width: 800px;
  margin-top: 16px;
`;

export const ServiceName = styled.div`
  width: 200px;
  min-width: 150px;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
`;

export const TagsAndMoreIconContainer = styled.div<{ gap?: number }>`
  display: flex;
  justify-content: space-between;
  gap: ${props => (props.gap ? props.gap : 10)}px;
  align-items: center;
  width: 100%;
`;

export const TagsContainer = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  overflow-x: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
`;

export const Tag = styled.div`
  display: flex;
  gap: 10px;
  flex: 0 0 auto;
  justify-content: space-between;
  font-size: 12px;
  font-weight: 700;
  padding: 6px 10px;
  border-radius: 22px;
  background-color: ${Colors.toolsBarBackgroundColor};
  &:hover {
    background-color: ${Colors.sideTabBackgroundColor};
    cursor: pointer;
  }
`;

export const TagName = styled.div``;

export const TagCount = styled.span``;

export const MoreIcon = styled.div``;

export const MoreIconList = styled.div`
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out;
`;

export const BookingsListContainer = styled.div`
  margin-top: 16px;
  padding: 0 20px;
  padding-top: 8px;
  height: 100%;
`;

export const BookingsList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 8px;
  list-style: none;
  height: 90%;
  overflow-y: scroll;
`;

export const Booking = styled.li<{ gap?: number; opacity?: number }>`
  display: flex;
  gap: ${props => (props.gap ? props.gap : 20)}px;
  flex: 0 0 auto;
  justify-content: space-between;
  align-items: center;
  padding: 6px 6px;
  opacity: ${props => (props.opacity ? props.opacity : 1)};
  &:hover {
    background-color: ${Colors.toolsBarBackgroundColor};
    border-radius: 15px;
    cursor: pointer;
    ${HoverRecordOptionsContainer} {
      opacity: 1;
      visibility: visible;
    }
  }
`;
export const PetBioExtraInfoContainer = styled.div<{ flex?: boolean }>`
  display: flex;
  flex: ${props => (props.flex ? 1 : 0)};
  gap: 20px;
  align-items: center;
  justify-content: center;
  @media (max-width: 768px) {
    width: 100%;
    justify-content: space-between;
    gap: 8px;
  }
`;
export const PetContainer = styled.div`
  display: flex;
  gap: 12px;
  color: #212121;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
export const PetAndInfo = styled.div<{ mobileFlexDirection?: string }>`
  display: flex;
  gap: 4px;
  align-items: center;
  min-width: 200px;
  @media (max-width: 768px) {
    width: 100%;
    flex-direction: ${props => props.mobileFlexDirection || 'row'};
  }
`;
export const PetImg = styled.img`
  border-radius: 50%;
  width: 40px;
  height: 40px;
`;
export const PetName = styled.span`
  font-size: 14px;
  font-weight: 800;
  white-space: nowrap;
`;

export const PetInfo = styled.span`
  font-size: 14px;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 14px;
  font-weight: 600;
  align-self: center;
  text-align: center;
`;

export const PetRecordTagsAndTimeContainer = styled.div`
  display: flex;
  flex: 1;
  gap: 8px;
  height: 30px;
`;

export const PetRecordTagsContainer = styled.div`
  display: flex;
  flex: 1;
  gap: 10px;
  align-items: center;
`;

export const PetRecordTags = styled.div`
  display: flex;
  gap: 10px;
  width: 200px;
  flex: 1;
  overflow-x: scroll;
  flex-direction: row-reverse;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const PetRecordTag = styled.div`
  white-space: nowrap;
  border-radius: 21px;
  padding: 6px 8px;
  font-size: 12px;
  font-weight: 700;
  text-align: center;
  color: ${Colors.white};
  ${({ color }) => css`
    background-color: ${color};
  `}
`;

export const BookingTime = styled.span`
  align-self: center;
  white-space: nowrap;
  color: #212121;
  font-size: 14px;
  font-weight: 800;
  width: 180px;
  min-width: 180px;
`;

export const TagsWrapper = styled.div`
  padding: 16px 20px 16px 20px;
`;

export const NewTabBodyContainer = styled.div`
  width: 100%;
  height: 100%;
`;
