import { useQuery } from '@apollo/client';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';
import React, { useRef } from 'react';
import OptionDropdown from '../../components/Shared/Menus/OptionDropdown/OptionDropdown';
import { OPTION_DROPDOWN_MENU_BUTTON_TYPES, OPTION_DROPDOWN_MENU_POSITIONS, OPTION_DROPDOWN_TYPES } from '../../components/Shared/Menus/OptionDropdown/types';
import { MainContainer, TabNotification, ViewWrapper } from '../../components/Shared/Shared';
import SideTabs from '../../components/Shared/SideTabs/SideTabs';
import { NOTIFICATION_COUNT_TYPES } from '../../components/Shared/SideTabs/notificationCountTypes';
import { GetBranchUnreadItemsCountReturn } from '../../hooks/useBranchUnreadItemsCount';
import useIcons from '../../hooks/useIcons';
import useMediaQuery from '../../hooks/useMediaQuery';
import { GetBranchUnreadItemsCount } from '../../queries';
import { BOOKING_TABS_TYPES } from '../Bookings/types';
import BookingsContent from './components/BookingsContent/BookingsContent';

const Bookings = () => {
  const { mobile } = useMediaQuery({ mobile: true });
  const icons = useIcons();
  // const moreTabs = icons.moreTabs.childImageSharp.gatsbyImageData;
  const optionDropdownRef = useRef(null);
  const location = useLocation();

  const sections = [
    {
      tabs: [
        {
          name: 'Daily',
          value: 'confirmed'
        }
      ]
    },
    {
      tabs: [
        {
          name: 'New',
          value: 'requested'
        },
        { name: 'Upcoming', value: 'upcoming' }
      ]
    },
    {
      tabs: [
        {
          name: 'Payment',
          value: 'payment'
        }
      ]
    },
    {
      tabs: [
        {
          name: 'Cancelled',
          value: 'canceled'
        }
      ]
    }
  ];

  const mobielSections = [
    {
      tabs: [
        {
          name: 'Today',
          value: 'confirmed'
        },

        {
          name: 'Upcoming',
          value: 'upcoming'
        }
      ]
    }
  ];

  const sectionsToDisplay = mobile ? mobielSections : sections;
  const { data: unreadOrdersCount } = useQuery<{ getBranchUnreadItemsCount: GetBranchUnreadItemsCountReturn }>(GetBranchUnreadItemsCount, {
    fetchPolicy: 'cache-only',
    notifyOnNetworkStatusChange: true
  });
  const { unreadServiceOrdersCount } = unreadOrdersCount?.getBranchUnreadItemsCount || {};

  const MoreTabsDropdown = () => (
    <OptionDropdown
      menuButtonType={OPTION_DROPDOWN_MENU_BUTTON_TYPES.MORE}
      noApplyButton
      containerRelative
      // buttonLoading={loading}
      options={[
        {
          optionType: OPTION_DROPDOWN_TYPES.BUTTONS,
          id: 'bookingOptions',
          items: [
            {
              name: 'New',
              value: 'requested',
              onClick: () => {
                navigate(`${location.pathname.split('/').slice(0, 3).join('/')}/requested`, { replace: true });
              }
            },
            {
              name: 'Payment',
              value: 'payment',
              onClick: () => {
                navigate(`${location.pathname.split('/').slice(0, 3).join('/')}/payment`, { replace: true });
              }
            },

            {
              name: 'Reject',
              value: 'canceled',
              onClick: () => {
                navigate(`${location.pathname.split('/').slice(0, 3).join('/')}/canceled`, { replace: true });
              }
            }
          ]
        }
      ]}
      menuPosition={OPTION_DROPDOWN_MENU_POSITIONS.LEFT}
      ref={optionDropdownRef}
    />
  );

  const NewTabButton = () => (
    <TabNotification
      onClick={() => {
        navigate(`${location.pathname.split('/').slice(0, 3).join('/')}/requested`, { replace: true });
      }}
    >
      {unreadServiceOrdersCount}
    </TabNotification>
  );
  const components = [NewTabButton, MoreTabsDropdown];

  return (
    <ViewWrapper>
      {!mobile && (
        <SideTabs
          sections={sectionsToDisplay}
          count={true}
          tabsWithCount={[BOOKING_TABS_TYPES.NEW, BOOKING_TABS_TYPES.PAYMENT]}
          defaultTab="confirmed"
          notificationType={NOTIFICATION_COUNT_TYPES.BOOKING}
        />
      )}
      <MainContainer>
        {mobile && (
          <SideTabs
            sections={sectionsToDisplay}
            count={true}
            tabsWithCount={[BOOKING_TABS_TYPES.NEW, BOOKING_TABS_TYPES.PAYMENT]}
            defaultTab="confirmed"
            notificationType={NOTIFICATION_COUNT_TYPES.BOOKING}
            components={components}
          />
        )}
        <BookingsContent />
      </MainContainer>
    </ViewWrapper>
  );
};

export default Bookings;
