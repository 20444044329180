import { useQuery } from '@apollo/client';
import React, { FC } from 'react';
import Colors from '../../../../Colors';
import { ExtensionColumn, ExtensionColumnContent, ExtensionColumnHeader, ExtensionPetCaseLabel } from '../../../../components/Pets/styled';
import InfiniteList from '../../../../components/Shared/InfiniteList/InfiniteList';
import { CenteredLoader } from '../../../../components/Shared/Spinner';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import GroomingReport from './GroomingReport';
import GroomingReportNoteAction, { NOTE_ACTION_TYPES } from './GroomingReportNoteAction';

export type report = Record<string, any>;

type GroomingHistoryProps = {
  groomingReports: report[];
  setSelectedReportId: (noteId: string) => void;
  loading: boolean;
  setHasMoreItems: React.Dispatch<React.SetStateAction<boolean>>;
  fetchMore: ReturnType<typeof useQuery>['fetchMore'];
  hasMoreItems: boolean;
  selectedReportId: string;
  refetchGroomingReports: ReturnType<typeof useQuery>['refetch'];
  autoOpenModal: boolean;
  onModalClose: () => void;
  renderHeader: () => JSX.Element;
};

const GroomingHistory: FC<GroomingHistoryProps> = ({
  groomingReports,
  setSelectedReportId,
  fetchMore,
  setHasMoreItems,
  hasMoreItems,
  loading,
  selectedReportId,
  refetchGroomingReports,
  autoOpenModal,
  onModalClose,
  renderHeader
}) => {
  const { tablet } = useMediaQuery({ tablet: true });

  return (
    <ExtensionColumn>
      <ExtensionColumnHeader>{renderHeader()}</ExtensionColumnHeader>
      <ExtensionColumnHeader justifyContent="flex-start" gap={8} padding="10px 8px" background marginT={8} marginB={8} mobilePadding="8px 16px">
        <>
          <GroomingReportNoteAction type={NOTE_ACTION_TYPES.ADD} refetchReports={refetchGroomingReports} autoOpenModal={autoOpenModal} onClose={onModalClose} />
          <ExtensionPetCaseLabel fontSize={14} fontWeight={'700'} color={Colors.black}>
            New Report
          </ExtensionPetCaseLabel>
        </>
      </ExtensionColumnHeader>
      <ExtensionColumnContent>
        {tablet && !groomingReports?.length && loading && <CenteredLoader />}
        {!!groomingReports?.length && (
          <InfiniteList
            mobilePadding="8px 16px"
            list={groomingReports}
            itemRenderer={(report: report, index: number) => (
              <GroomingReport selected={report.id === selectedReportId} key={index} groomingReport={report} onClick={() => setSelectedReportId(report?.id)} />
            )}
            loading={loading}
            offeset={groomingReports?.length}
            setHasMoreItems={setHasMoreItems}
            fetchMore={fetchMore}
            hasMoreItems={hasMoreItems}
            offset={groomingReports?.length}
            listContainerStyles={tablet ? { width: '100%' } : {}}
          />
        )}

        {!groomingReports?.length && !loading && (
          <ExtensionPetCaseLabel fontSize={16} fontWeight={'700'}>
            There are no reports
          </ExtensionPetCaseLabel>
        )}
      </ExtensionColumnContent>
    </ExtensionColumn>
  );
};

export default GroomingHistory;
