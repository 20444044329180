import { useQuery } from '@apollo/client';
import React from 'react';
import { Divider } from '../../../../components/DrawerBar/styled';
import { DRAWER_IDS } from '../../../../components/DrawerBar/types';
import ProfileActionBar from '../../../../components/Profile/ProfileActionBar';
import ProfileCover from '../../../../components/Profile/ProfileCover';
import { LoadingOverlay } from '../../../../components/Shared/Spinner';
import useMediaQuery from '../../../../hooks/useMediaQuery';
import { GetBranchOrdersAppointments } from '../../../../queries';
import { calculateAgeFromBirthdate, displayTime, findDayName } from '../../../../utils/dates';
import { BookingOrder } from '../../types';
import { DrawerContainer, DrawerContent, DrawerSection, OwnerChatSection, SectionContent, SectionName, Slot, Slots } from './styles';

const PetOrderDrawer = ({ data: { orderId, appointmentId } }) => {
  const { data: { getBranchOrders: [bookingOrder] = [] } = {}, loading } = useQuery<{
    getBranchOrders: BookingOrder[];
  }>(GetBranchOrdersAppointments, {
    variables: {
      id: [orderId],
      offset: 0,
      limit: 1000,
      type: 'service'
    },
    fetchPolicy: 'cache-and-network'
  });

  const { mobile } = useMediaQuery({ mobile: true });

  const appointmentToDisplay = bookingOrder?.OrderItems?.[0]?.Appointments?.find(appointment => appointment.id === appointmentId);

  const appointment = appointmentToDisplay;
  const pet = appointment?.PetRecord?.Pet;
  const service = bookingOrder?.OrderItems?.[0]?.item.name;
  const date = new Date(appointment?.timestamp);
  const day = findDayName(date?.getDay());
  const time = displayTime(date?.getUTCHours(), date?.getMinutes());
  const age = calculateAgeFromBirthdate(pet?.birthdate);
  const slots = bookingOrder?.OrderItems?.[0]?.Appointments?.map(appointment => new Date(appointment?.timestamp));

  return (
    <>
      {!mobile && (
        <DrawerContainer>
          {loading && <LoadingOverlay />}
          <ProfileCover profile={pet} />
          <DrawerContent>
            <DrawerSection>
              <SectionName>Service</SectionName>
              <SectionContent>{service}</SectionContent>
            </DrawerSection>
            <DrawerSection>
              <SectionContent>
                <Slots>
                  {slots?.map((slot, index: number) => (
                    <Slot key={index}>
                      <SectionName>Booking Slot</SectionName>
                      {findDayName(slot?.getDay())} at {displayTime(slot?.getUTCHours(), slot?.getMinutes())}
                    </Slot>
                  ))}
                </Slots>
              </SectionContent>
            </DrawerSection>
          </DrawerContent>
          <OwnerChatSection>
            <DrawerSection>
              <Divider />
              <ProfileActionBar drawerId={DRAWER_IDS.PET_ORDER_DRAWER} profile={pet?.AppUser} paddingT={20} paddingB={20} paddingL={20} paddingR={20} />
            </DrawerSection>
          </OwnerChatSection>
        </DrawerContainer>
      )}
    </>
  );
};

export default PetOrderDrawer;
