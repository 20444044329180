import React, { useRef } from 'react';
import { OrderInvoice } from '../../components/OrderInvoices/types';
import { addDrawerBar } from '../../reactive/actions';
import { getBranchCurrencySymbol } from '../../utils/getBranchCurrencySymbol';

import { Booking, TagsAndMoreIconContainer } from '../../views/Bookings/components/BookingRecord/styled';
import { getSubscriptionRepeatTitles } from '../../views/Subscriptions/utils';
import { RecordBody } from '../../views/styled';
import { DRAWER_IDS } from '../DrawerBar/types';
import OrderInvoiceOptions from '../OrderInvoices/OrderInvoiceOptions';
import { getOrderInvoiceTitles } from '../OrderInvoices/utils';
import { BillingStatus, BookingListItem, BookingsDivider } from './styled';

const UserOrderInvoiceRecord = ({ invoice, refetch }: { invoice: OrderInvoice; refetch: () => void }) => {
  const { id, amount } = invoice;
  const { isCanceled, isFree, isBilled, isRefunded, isRejected, isPending, creationDate, statusTitle, orderInvoiceNumber, isUnbilled } = getOrderInvoiceTitles(invoice);

  const {
    createdAt,
    repeatTotal,
    subscriptionRepeatOrderNumber,
    statusTitleString,
    isSubscriptionRepeatBilled,
    isSubscriptionRepeatRefunded,
    isSubscriptionRepeatRejected,
    isSubscriptionRepeatPending,
    isSubscriptionRepeatCanceled,
    isSubscriptionRepeatUnbilled,
    subscriptionRepeatId
  } = getSubscriptionRepeatTitles(invoice);

  const optionsRef = useRef<HTMLDivElement>(null);

  const handleClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (optionsRef?.current?.contains(e.target as Node)) {
      return;
    }
    addDrawerBar({ drawerId: DRAWER_IDS.ORDER_INVOICE_DRAWER, recordData: id || subscriptionRepeatId, isFloating: true, drawerWidths: { normal: 800, small: 800 } });
  };

  const invoiceType = invoice?.__typename;
  const isOrderSubscriptionRepeat = invoiceType === 'OrderSubscriptionRepeat';

  return (
    <BookingListItem key={id}>
      <Booking
        onClick={event => {
          !isOrderSubscriptionRepeat ? handleClick(event) : null;
        }}
        gap={20}
      >
        <RecordBody width="200" fontWeight={'800'}>
          {orderInvoiceNumber || subscriptionRepeatOrderNumber || '-'}
        </RecordBody>
        <RecordBody width="200" fontWeight={'800'}>
          {creationDate}
        </RecordBody>
        <RecordBody width="200" fontWeight={'700'}>
          {isFree && 'Free'}
          {amount && !isFree ? `${getBranchCurrencySymbol()} ${amount}` : repeatTotal}
        </RecordBody>

        <TagsAndMoreIconContainer gap={1}>
          <RecordBody width="150" fontWeight="800" flexEnd noMargin>
            {isOrderSubscriptionRepeat && (
              <BillingStatus
                noMaxWidth
                blue={isSubscriptionRepeatPending}
                gray={isSubscriptionRepeatCanceled}
                green={isSubscriptionRepeatBilled}
                red={isSubscriptionRepeatRefunded || isSubscriptionRepeatRejected || isSubscriptionRepeatUnbilled}
              >
                {statusTitleString}
              </BillingStatus>
            )}
          </RecordBody>
          {!isOrderSubscriptionRepeat && (
            <RecordBody width="100" fontWeight="800" flexEnd noMargin>
              <BillingStatus noMaxWidth blue={isPending} gray={isCanceled} green={isBilled} red={isRefunded || isRejected || isUnbilled}>
                {statusTitle}
              </BillingStatus>
            </RecordBody>
          )}

          <OrderInvoiceOptions orderInvoice={invoice} refetchOrderInvoices={refetch} isOrderSubscriptionRepeat={isOrderSubscriptionRepeat} ref={optionsRef} />
        </TagsAndMoreIconContainer>
      </Booking>
      <BookingsDivider />
    </BookingListItem>
  );
};

export default UserOrderInvoiceRecord;
