import { useReactiveVar } from '@apollo/client';
import React, { useEffect, useImperativeHandle, useRef } from 'react';
import ModalDialog from '../components/Modal/ModalDialog';
import PetRecordOptions from '../components/Pets/PetRecordOptions';
import { Pet } from '../components/Pets/types';
import { Icon } from '../components/Shared/Forms/Forms';
import { Checkbox, CheckboxItemContainer } from '../components/Shared/Forms/styled';
import { OPTION_DROPDOWN_MENU_BUTTON_TYPES } from '../components/Shared/Menus/OptionDropdown/types';
import { vars } from '../reactive';
import { managePetsFilters } from '../reactive/actions';
import AdjustAppUserOrderBillsBilledOnModal from '../views/Bookings/components/BookingsDrawer/AdjustAppUserOrderBillsBilledOnModal';
import AdjustAppUserOrdersPaymentModal from '../views/Bookings/components/BookingsDrawer/AdjustAppUserOrdersPaymentModal';
import PetSlideDownActions, { PetSlideDownActionsRef } from '../views/Pets/PetSlideDownActions';
import useBulkSelect from './useBulkSelect';

export type PetsBulkActionsRef = { clearBulkActions: () => void; hideBulkActions: () => void };

const usePetsListBulkActions = ({ pets, ref, inDrawer, refetch }: { pets: Pet[]; ref: React.ForwardedRef<PetsBulkActionsRef>; inDrawer?: boolean; refetch?: () => Promise<any> }) => {
  const { addHeaderAction, clearHeaderAction } = managePetsFilters;
  const bulkSelect = useBulkSelect({ items: pets });
  const { selected, show: showBulkActions, clearSelected, hide, selectedAll, toggleSelectAllItems } = bulkSelect;
  const bulkActionSelectedPets = pets?.filter(pet => selected.includes(pet.id));

  const drawerBars = useReactiveVar(vars.drawerBars);
  const petSlideDownActionsRef = useRef<PetSlideDownActionsRef>(null);

  const handleOnClickAction = petSlideDownActionsRef.current?.handleOnClickAction;

  const selectAction = (
    <PetSlideDownActions bulkSelect={bulkSelect} bulkActionSelectedPets={bulkActionSelectedPets} ref={petSlideDownActionsRef}>
      <PetRecordOptions
        pets={bulkActionSelectedPets}
        loadTags
        showAlways
        addedItems={[
          {
            name: 'Update Pricing',
            value: 'updatePricing',
            onClick: () =>
              ModalDialog.openModal({
                title: 'Adjust Clients Orders',
                content: () => <AdjustAppUserOrdersPaymentModal initialValues={{ selectedAppUsers: [...new Set(bulkActionSelectedPets?.map(pet => pet?.AppUser?.id))] }} />,
                onCloseBySave: handleOnClickAction
              })
          },
          {
            name: 'Update Billing Date',
            value: 'updateBillingDate',
            onClick: () =>
              ModalDialog.openModal({
                title: 'Adjust Billing Date',
                content: () => <AdjustAppUserOrderBillsBilledOnModal initialValues={{ selectedAppUsers: [...new Set(bulkActionSelectedPets?.map(pet => pet?.AppUser?.id))] }} />,
                onCloseBySave: handleOnClickAction
              })
          }
        ]}
        menuButtonType={OPTION_DROPDOWN_MENU_BUTTON_TYPES.MORE}
        onClickAction={handleOnClickAction}
        refetchPetsAfterTag
      />
    </PetSlideDownActions>
  );

  const selectAllAction = (
    <CheckboxItemContainer checked={selectedAll} noMargin onClick={toggleSelectAllItems} noWidth>
      <Checkbox>
        <Icon viewBox="0 0 24 24">
          <polyline points="20 6 9 17 4 12" />
        </Icon>
      </Checkbox>
      <span style={{ fontWeight: 700 }}>{selectedAll ? 'Deselect All' : 'Select All'}</span>
    </CheckboxItemContainer>
  );

  useEffect(() => {
    if (drawerBars.length && !inDrawer) {
      clearHeaderAction({ id: 'bulkActions' });
      clearHeaderAction({ id: 'bulkSelectAll' });
    }

    if (!drawerBars.length || (drawerBars.length && inDrawer)) {
      addHeaderAction({ action: selectAction, id: 'bulkActions' });
      if (showBulkActions) {
        addHeaderAction({ action: selectAllAction, id: 'bulkSelectAll' });
      }
    }

    return () => {
      clearHeaderAction({ id: 'bulkActions' });
      clearHeaderAction({ id: 'bulkSelectAll' });
    };
  }, [drawerBars, showBulkActions, inDrawer, selected, selectedAll, showBulkActions]);

  useImperativeHandle(ref, () => ({
    clearBulkActions: () => {
      clearSelected();
    },
    hideBulkActions: () => {
      hide();
    }
  }));

  return { bulkSelect, bulkActionSelectedPets };
};

export default usePetsListBulkActions;
