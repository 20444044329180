import { useQuery, useReactiveVar } from '@apollo/client';
import React, { FC, forwardRef, Fragment, useCallback, useEffect, useImperativeHandle } from 'react';
import useMediaQuery from '../../hooks/useMediaQuery';
import useNavigateDrawer from '../../hooks/useNavigateDrawer';
import usePaginatedQuery from '../../hooks/usePaginatedQuery';
import useURLSearchParams from '../../hooks/useURLSearchParams';
import { ChatSubscription, GetBranchAppUserTags, GetBranchChatRooms, GetBusUserProfile } from '../../queries';
import { vars } from '../../reactive';
import { isChatRoomSeen } from '../../views/Chats/utils';
import { BranchAppUserTag } from '../../views/Store/BranchTags/types';
import Common from '../../views/Store/Common';
import { GroupHeader } from '../../views/styled';
import { DRAWER_IDS } from '../DrawerBar/types';
import { FiltersControl } from '../Shared/Filters/useFilters';
import RadioSwitch from '../Shared/Forms/RadioSwitch';
import InfiniteList from '../Shared/InfiniteList/InfiniteList';
import RefreshButton from '../Shared/RefreshButton';
import ChatRecord, { Room } from './ChatRecord';
import { CHATS_FILTER_TYPES, CHATS_MORE_OPTIONS_TYPES, getChatsVariables } from './ChatsFilters';

type ChatsBodyProps = {
  filtersControl: FiltersControl<CHATS_FILTER_TYPES, CHATS_MORE_OPTIONS_TYPES>;
};

export type ChatsBodyRef = {
  onRefresh: () => void;
  setHasMoreItems: React.Dispatch<React.SetStateAction<boolean>>;
};

const ChatsBody = forwardRef<ChatsBodyRef, ChatsBodyProps>(({ filtersControl }, ref) => {
  const [readStatus] = useURLSearchParams('status');
  const { data: { getBusUserProfile: profile = {} } = {} } = useQuery(GetBusUserProfile);
  const { selectedFilters } = filtersControl;

  const activeChatViewSettings = useReactiveVar(vars.activeChatViewSettings);
  const orderByIsRead = activeChatViewSettings?.orderByIsRead || false;

  const [[hasMoreItems, setHasMoreItems], queryResult] = usePaginatedQuery<Room[]>({
    query: GetBranchChatRooms,
    otherVariables: {
      ...getChatsVariables({
        selectedFilters,
        defaultVariables: {
          order_by_is_read: orderByIsRead
        },
        overriddenFilters: {
          chat_seen: readStatus?.[0] ? readStatus?.[0] === 'READ' : undefined
        }
      })
    }
  });

  const { subscribeToMore, data: { getBranchChatRooms: rooms } = { getBranchChatRooms: [] }, loading, fetchMore, refetch, variables: previousVariables, updateQuery } = queryResult;

  useEffect(() => {
    const sub = subscribeToMore<{ messageAdded: Room['Messages'][0] }>({
      document: ChatSubscription,
      variables: { BranchId: [profile?.Branch?.id] },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData) {
          return prev;
        }
        const newMessage = subscriptionData?.data?.messageAdded;
        const currentRoom = prev?.getBranchChatRooms?.find(room => room.id === newMessage?.ChatRoomId);
        if (!currentRoom) {
          refetch();
          return prev;
        }
        const chatDrawer = vars.drawerBars()?.find(drawer => drawer.drawerId === DRAWER_IDS.CHAT_DRAWER);
        const chatRoomDrawerId = chatDrawer?.recordData as string;
        const newRooms = prev?.getBranchChatRooms?.map(room => {
          if (room.id === newMessage?.ChatRoomId) {
            return {
              ...room,
              last_message: newMessage,
              read_status: [
                { user_id: newMessage?.user_id, is_read: true },
                ...(newMessage?.user_id !== profile?.id
                  ? [
                      {
                        user_id: profile?.id,
                        is_read: chatRoomDrawerId === room.id || newMessage?.isSilent
                      }
                    ]
                  : [])
              ]
            };
          }
          return room;
        });

        return {
          getBranchChatRooms: newRooms
        };
      }
    });
    return () => {
      sub();
    };
  }, []);

  const { mobile } = useMediaQuery({ mobile: true });

  const isRoomSeen = (room: Room) => isChatRoomSeen(room, profile);

  const sortByMessageTimestamp = (rooms: Room[]) => [...(rooms || [])].sort((a, b) => new Date(b.last_message?.timestamp).getTime() - new Date(a.last_message?.timestamp).getTime());

  const seenRooms = sortByMessageTimestamp(rooms?.filter(isRoomSeen)) || [];
  const unseenRooms = sortByMessageTimestamp(rooms?.filter(room => !isRoomSeen(room))) || [];
  const roomsSorted = orderByIsRead ? [...unseenRooms, ...seenRooms] : [...sortByMessageTimestamp(rooms)];

  const navigateDrawer = useNavigateDrawer({
    drawerData: {
      drawerId: DRAWER_IDS.CHAT_DRAWER
    }
  });

  const { data: { getBranchAppUserTags: allTags = [] } = {} } = useQuery<{
    getBranchAppUserTags: BranchAppUserTag[];
  }>(GetBranchAppUserTags, {
    fetchPolicy: 'cache-and-network',
    variables: { offset: 0, limit: 1000 }
  });

  const quickTags = allTags?.filter(tag => tag?.quick_action) || [];
  const tagsVisibleOnItem = allTags?.filter(tag => tag?.visibility?.showOnTaggedItem) || [];

  const updateChatRooms = (newRooms: Room[]) => {
    updateQuery(prev => {
      const updatedRooms = prev?.getBranchChatRooms?.map(room => {
        const updatedRoom = newRooms.find(newRoom => newRoom.id === room.id);
        if (updatedRoom) {
          return updatedRoom;
        }
        return room;
      });
      return {
        getBranchChatRooms: updatedRooms
      };
    });
  };

  useEffect(() => {
    Common.set(`Chats.GetChatRooms.updateChatRooms`, (args: Room[]) => {
      return updateChatRooms(args);
    });

    return () => {
      Common.clear(`Chats.GetChatRooms.updateChatRooms`);
    };
  }, []);

  const onRefresh = () => {
    refetch().then(() => setHasMoreItems(true));
  };

  useImperativeHandle(
    ref,
    () => {
      return {
        onRefresh,
        setHasMoreItems
      };
    },
    [onRefresh, setHasMoreItems]
  );

  return (
    <InfiniteList
      paddingL={20}
      paddingR={20}
      mobilePadding="8px 0"
      list={roomsSorted}
      itemRenderer={item => (
        <ChatRecord
          room={item}
          key={item.id}
          navigateDrawer={navigateDrawer}
          isRoomSeen={isRoomSeen}
          appUserQuickTags={quickTags}
          profile={profile}
          tagsVisibleOnItem={tagsVisibleOnItem}
          updateChatRooms={updateChatRooms}
        />
      )}
      hasMoreItems={hasMoreItems}
      loading={loading}
      // group={
      //   !mobile && orderByIsRead
      //     ? {
      //         by: room => (isRoomSeen(room) ? 'true' : 'false'),
      //         separator(separatedItems) {
      //           return <GroupHeader>{isRoomSeen(separatedItems?.[0]) ? 'Read Messages' : 'New Messages'}</GroupHeader>;
      //         }
      //       }
      //     : undefined
      // }
      fetchMore={fetchMore}
      offset={rooms?.length}
      setHasMoreItems={setHasMoreItems}
      backgroundColor={mobile ? '#f2f2f2' : '#fff'}
    />
  );
});

export default ChatsBody;
