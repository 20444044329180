import React from 'react';
import { InputsWrapper } from '../../../../components/Shared/Forms/Forms';
import ProductOptions, { ProductOptionsTypes } from '../../ProductOptions';
import ProductPrice from '../../ProductPrice';
import { StoreProductModalSectionsCommonProps } from './types';

const StoreProductModalPricing = ({ formOptions, defaultValues, type }: StoreProductModalSectionsCommonProps) => {
  return (
    <InputsWrapper noWrap>
      <ProductPrice formOptions={formOptions} defaultValues={defaultValues} type={type} />
      <ProductOptions
        formOptions={formOptions}
        type={type}
        defaultValues={defaultValues?.productOptions}
        optionsToShow={[
          ProductOptionsTypes.AUTOMATICALLY_PICK_BEST_DISCOUNT,
          ProductOptionsTypes.FROM,
          ProductOptionsTypes.SHOW_CUSTOMIZATIONS_PRICE,
          ProductOptionsTypes.USE_PETS_COUNT,
          ProductOptionsTypes.AUTO_CONFIRM_ORDER,
          ProductOptionsTypes.ENABLE_CUSTOMIZATIONS_QUANTITY_FACTOR,
          ProductOptionsTypes.OPTIONAL_CARD_DETAILS
        ]}
      />
    </InputsWrapper>
  );
};

export default StoreProductModalPricing;
