import { toReadableDate } from '../../utils/dates';
import { getBranchCurrencySymbol } from '../../utils/getBranchCurrencySymbol';
import { ORDER_STATUS, ORDER_STATUS_TITLES, ORDER_STATUS_TITLES_COLORS, ORDER_STATUS_TYPES, ProductOrder } from './types';

export const getOrderTitles = (order: ProductOrder) => {
  const user = order?.AppUser;
  const orderNumber = order?.number;
  const numberOfProducts = order?.Products?.length;
  const total = `${getBranchCurrencySymbol()}${Number(order?.total) + Number(order?.delivery?.method?.price || 0)}`;
  const time = toReadableDate(order.createdAt, { isLocale: true, noTime: true });
  const appUserName = user?.name;
  const appUserProfilepic = user?.profile_pic;
  const isFree = order?.total < 0.5;
  const orderInvoiceStatus = order?.OrderBills?.[0]?.OrderInvoice?.Order?.status;
  const postpaidBilled = orderInvoiceStatus === ORDER_STATUS.PAYMENT_SUCCEEDED;
  const isBilled = order.status === ORDER_STATUS.PAYMENT_SUCCEEDED || postpaidBilled || !!order.billed_on;
  const isConfirmed = order.status === ORDER_STATUS.CONFIRMED;
  const status = order?.status;
  const statusTitle = ORDER_STATUS_TITLES[status];
  const color = ORDER_STATUS_TITLES_COLORS[status];
  const isRed = (!isBilled && !isConfirmed) || color === 'red';
  const isGreen = isBilled && color === 'green';
  const isBlue = isFree || isConfirmed || color === 'blue';
  const isNew = ORDER_STATUS_TYPES.NEW.includes(status);
  return { user, orderNumber, numberOfProducts, total, time, appUserName, statusTitle, isRed, isGreen, isBlue, isBilled, isNew, status, appUserProfilepic };
};
